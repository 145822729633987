import { Image } from "@storybook";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { darkThemeState, TriggerUserApi, userPersonalDetails } from "states";
import styles from "./dvp-email-verify.module.sass";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { Loader } from "@components/Loader2";
import classNames from "classnames";
import { imageConfig } from "@utils/imageConfig";

export const DvpEmailVerify = () => {
    const { patch, data, loading } = useNetwork();
    const { post: resendVerifyMail, loading: resendLoading } = useNetwork();
    const themeDark = useRecoilValue(darkThemeState);
    const personalData = useRecoilValue(userPersonalDetails);
    const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [email, setEmail] = useState("");
    const [next, setNext] = useState(false);

    const {svg: {email_verifi, email_verifi_light, email_sent_dark, email_sent_light}} = imageConfig;

    useEffect(() => {
        setNext(false);
    }, [])

    const validateEmail = (email: string) => {
        const regex =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return regex.test(email);
    };

    const changeInputHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value);
    };

    const handleProceed = useCallback(() => {
        let error = false;
        if (email) {
            if (!validateEmail(email)) {
                setIsEmailError(true);
                error = true;
            } else {
                setIsEmailError(false);
                error = false;
            }
        } else {
            setIsEmailError(true);
            error = true;
        }
        if (!error) {
            const payload = {
                email: email.toLocaleLowerCase(),
            };
            patch(`${APIS.Users}/${personalData?.id}`, payload)
                .then(() => {
                    setIsEdit(false);
                    setTriggerUserApi((prev) => !prev);
                })
        }
    }, [email, patch, personalData?.id, setTriggerUserApi]);

    const handleEdit = useCallback(() => {
        setEmail(personalData?.email || "")
        setIsEdit(true);
    }, [personalData?.email]);


    const onClickResend = useCallback(async () => {
        if (resendLoading) return;
        await resendVerifyMail(APIS.ResendVerificationEmail, {})
            .then(() => {
                setNext(true);
                setIsEdit(false);
            });
    }, [resendLoading]);

    const renderEmailPara = useMemo(() => {
        return (
            <p className={styles.dvpEmail_para2}>A verification email will be sent to
                {!isEdit ? <div><span className={styles.dvpEmail_email}>{personalData?.email}</span> <span onClick={handleEdit} className={styles.dvpEmail_edit}>Edit</span></div>
                    : <div className={classNames(styles.dvpEmail_input,{[styles.dvpEmail_input2]: isEmailError})}>
                        <div className={styles.dvpEmail_inputerror_wrapper}>
                            <input type="text" onChange={changeInputHandle} value={email} className={classNames(styles.dvpEmail_inputField, {[styles.inputError]: isEmailError})} />
                            {isEmailError && <div className={styles.errorMsg}>Invalid Email</div>}
                        </div>
                        <span className={styles.dvpEmail_edit} onClick={handleProceed}>{loading ? <Loader dimension={20} /> : "Save and verify"}</span>
                    </div>}
            </p>
        )
    }, [isEdit, personalData?.email, handleEdit, email, handleProceed, loading, isEmailError])


    return (
        <>
            {!next ? (<div className={styles.dvpEmail_container}>
                {themeDark ?
                    <Image fileName={email_verifi} height={"120px"} /> :
                    <Image fileName={email_verifi_light} height={"120px"} />
                }
                <h2 className={styles.dvpEmail_heading}>Email not verified</h2>
                <p className={styles.dvpEmail_para}>Please verify your email before proceeding with the request.</p>
                {renderEmailPara}
                <button className={`${styles.proceed_btn} ${styles.dvpEmail_btn}`} onClick={onClickResend}>
                    {resendLoading ? <Loader dimension={20} /> : "Send verification email"}
                </button>
            </div>)
                :
                (
                    <div className={classNames(styles.dvpEmail_container, styles.dvpEmail_container2)}>
                        {themeDark ?
                            <Image fileName={email_sent_dark} height={"120px"} /> :
                            <Image fileName={email_sent_light} height={"120px"} />
                        }
                        <h2 className={styles.dvpEmail_heading}>Verification email sent</h2>
                        {renderEmailPara}
                        <p className={classNames(styles.dvpEmail_para2, styles.border)}>Please check your inbox and spam folder. Kindly click on the link provided in the email and complete the verification process from there.</p>
                        <p className={styles.dvpEmail_para2}>Not recieved the code yet? <span className={styles.resend} onClick={onClickResend}>{resendLoading ? <Loader dimension={20} /> : "Resend"}</span></p>
                    </div>
                )
            }
        </>
    );
};