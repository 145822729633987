export const getAllUrlParams = (url: string | undefined): Record<string, string> | undefined | string => {
    if (!url) {
       return url
    }
    const params: Record<string, string> = {};
    const queryString = url.split('?')[1];

    if (!queryString) return params;

    queryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });

    return params;
}

