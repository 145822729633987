import React, { useCallback, useEffect, useState } from 'react'
import { formatFileSize } from 'utils';
import { Json } from "types";
import "./removeCoowner.scss"
import { useNetwork, useNotification } from 'hooks';
import { APIS, FILE_EXTENSION_URL } from 'constant';
import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  RemovalRequestFilesState,
  UseAuthorisedUser,
  authorisedDeleteFileStatus,
  authorisedUploadedFileStatus
} from '../../store';
import { SkeletonWallet } from '@components/skeleton-loader';
import classNames from 'classnames';
import { Image } from "../../../../../../@storybook";
import useDarkMode from "use-dark-mode";
import { imageConfig } from '@utils/imageConfig';


interface FileData {
  coOwnerId: string;
  createdAt: string;
  docName: string;
  fileName: string;
  fileSize: string;
  status: boolean;
  updatedAt: string;
  uploadStatus: string;
  __v: number;
  _id: string;
}

const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/pdf": "pdf",
  pdf: "pdf",
  xlsx: "xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  docx: "docx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
  "application/msword": "doc",
  doc: "doc",
  "text/csv": "csv",
  csv: "csv",
  pptx: "ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "ppt",
  "application/vnd.ms-powerpoint": "ppt",
};

export const RemoveCoOwner = ({ coOwnerId, editUserId, editingRemovalRequest, callback }: any) => {
  let id = coOwnerId || editUserId
  const [dataRoomFiles, setDataRoomFiles] = useState<Json | any>([]);
  const [fileStatus, setFileStatus] = useRecoilState(authorisedUploadedFileStatus)
  const setDeleteFileStatus = useSetRecoilState(authorisedDeleteFileStatus)
  const setRemovalFileState = useSetRecoilState(RemovalRequestFilesState)
  const removalFiles = useRecoilValue<any>(
    RemovalRequestFilesState
  );
  const darkMode = useDarkMode(false);
  const { getCoownersDocument, removalFilesLoading } = UseAuthorisedUser()
  const { errorNotification } = useNotification();
  const { post: getSignedUrl } = useNetwork();

  const {svg: {view, no_data_dark, no_data_light}} = imageConfig;
   
  useEffect(() => {
    setRemovalFileState([])
    if (coOwnerId) {
      getCoownersDocument(id)
    }
  }, [coOwnerId, getCoownersDocument, id, setRemovalFileState])


  useEffect(() => {
    if (removalFiles && removalFiles.filesData) {
      setDataRoomFiles([])
      setDataRoomFiles((prev: FileData[]) => {
        const mergedData = removalFiles.filesData.map((apiFile: FileData) => ({
          ...apiFile,
          fileName: apiFile.docName || "",
          fileSize: apiFile?.fileSize || 0,
          id: apiFile._id,
          fileLink: apiFile.fileName,
          isSuccessfullyUploaded: true,
          isfileuploading: true,
          isCancelled: false,
        }));

        return [...prev, ...mergedData];
      });
    }
  }, [getCoownersDocument, removalFiles]);


  // function tp get signed url and upload it to GCP
  const uploadToGcp = async (fileList: any) => {
    let gcpResponse;
    const GcpFileStatus: any = [];


    // post for getting signed URL
    const signedurlPayload = {
      id,
      files: fileList?.map(({ fileName, fileSize, extension }: any) => {
        return {
          fileName,
          fileSize,
          extension,
        };
      }),
    };

    try {
      const signedurlResponse = await getSignedUrl(
        APIS.UPLOADDOCUMENT,
        signedurlPayload,
        { apiResponse: true }
      );
      if (signedurlResponse.message === "ok") {
        const {
          data: signedUrlResponseFile
        } = signedurlResponse;

        gcpResponse = await signedUrlResponseFile?.map(async (files: any) => {
          const controller = new AbortController();
          const signal = controller.signal;
          let found = fileList?.find((e: any) => e.fileName === files.docName);

          found = {
            ...found,
            id: files.id,
            abortController: controller,
          };

          setDataRoomFiles((prev: any) => [...prev, found]);

          const res = axios.put(files.signedUrl, found?.file, {
            headers: {
              "Content-Type": found?.extension,
            },
            onUploadProgress: (progressEvent) => {
              if (
                typeof progressEvent.total === "number" &&
                !isNaN(progressEvent.total)
              ) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setDataRoomFiles((prev: any) => {
                  const temp = prev?.map((item: any) => {
                    if (item.id === files.id) {
                      item.isfileuploading = true;
                      item.downLoadingStatus = percentCompleted;
                    }
                    return item;
                  });
                  return [...temp];
                });
              }
            },
            signal: signal,
          }).then((response) => {
            const obj = {
              id: files.id,
              status: response.status === 200 ? "SUCCESS" : "FAILED",
            };
            GcpFileStatus.push(obj);
            setDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item.id === files.id) {
                  item.loadingStatus =
                    response?.status === 200
                      ? ""
                      : "failed";
                  item.abortController = null;
                  item.isSuccessfullyUploaded =
                    response?.status === 200 ? true : false;
                  item.status =
                    response?.status === 200 ? "SUCCESS" : "FAILED";
                }
                return item;
              });
              return [...temp];
            });
          }).catch((err) => {
            console.log(err?.message);
            setDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item.id === files.id) {
                  item.loadingStatus = "Cancelled";
                  item.abortController = null;
                  item.isSuccessfullyUploaded = false;
                  item.status = "FAILED";
                }
                return item;
              });
              return [...temp];
            });
          });

          return res;
        });
      } else {
        console.log(signedurlResponse.message);
      }
    } catch (err) {
      console.log("signedurlResponse", err);
    }


    return { gcpResponse, GcpFileStatus };
  };


  const updateFileStatusToDb = async (
    GcpFileStatus: Json[],
  ) => {
    setFileStatus((prevFileStatus: any) => {
      const updatedFileStatus = [...prevFileStatus, ...GcpFileStatus];
      return updatedFileStatus;
    });
  };


  const fileValidations = (files: any) => {
    for (let index = 0; index < files.length; index++) {
      if (!fileFormat[files[index].type]) {
        errorNotification(`${files[index].name} is not a valid file format`);
        return false;
      }
    }

    return true;
  };

  const fileDetailUploading = async (IsFilesValid: boolean, files: FileList) => {
    if (IsFilesValid) {
      const fileList = [...files]?.map((file: any) => ({
        fileName: file.name,
        fileSize: file.size,
        extension: file.type,
        downLoadingStatus: 0,
        isCancelled: false,
        abortController: null,
        isfileuploading: false,
        isSuccessfullyUploaded: false,
        loadingStatus: "uploading",
        status: "pending",
        file: file,
        fileLink: file
      }));

      const { gcpResponse, GcpFileStatus } = await uploadToGcp(
        fileList
      );

      Promise.all(gcpResponse).then((response) => {
        updateFileStatusToDb(GcpFileStatus);
      }).catch((err) => console.log(err?.message));
    }
  }

  const handleFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    let IsFilesValid: boolean | undefined = true;
    event.preventDefault();
    const { files } = event.dataTransfer;
    IsFilesValid = fileValidations(files);
    fileDetailUploading(IsFilesValid, files)

  };


  const handleOnChange = async (event: any) => {
    let IsFilesValid: boolean | undefined = true;
    const { files } = event.target;
    IsFilesValid = fileValidations(files);
    fileDetailUploading(IsFilesValid, files)
    event.target.value = ""
  };

  const deleteFile = (id: string) => {
    const filteredfiles = dataRoomFiles?.filter(
      (items: any) => items.id !== id
    );
    const newArray = fileStatus.filter((item: any) => item.id !== id);
    setFileStatus(newArray)
    setDeleteFileStatus((prev: any) => [...prev, id])
    setDataRoomFiles([...filteredfiles]);

  };

  const cancelFileUpload = (abortController: any, id: string) => {
    abortController.abort();
    setDataRoomFiles((prev: any) => {
      const temp = prev?.map((item: any) => {
        if (item.id === id) {
          item.loadingStatus = "cancelled";
          item.isCancelled = true;
          item.abortController = null;
          item.status = "FAILED";
          item.isSuccessfullyUploaded = false;
        }
        return item;
      });
      return [...temp];
    });

    removeCancelledFiles(id);
  };

  const removeCancelledFiles = (id: string) => {
    setTimeout(() => {
      setDataRoomFiles((prev: any) =>
        prev?.filter((files: Json) => files.id !== id)
      );
    }, 1000);
  };

  useEffect(() => {
    const hasOngoingUpload = dataRoomFiles.some((file: any) => !file.isSuccessfullyUploaded);

    callback({
      length: dataRoomFiles?.length,
      isDisabled: hasOngoingUpload
    })

  }, [callback, dataRoomFiles])

  const handleFileView = useCallback((fileLink: any, file: any) => {
    const urlToOpen = file ? URL.createObjectURL(file) : fileLink;
    if (urlToOpen) {
      window.open(urlToOpen, "_blank");
    } else {
      console.error("File link or content is not valid");
    }

  }, [])

  return (
    <div className='removeCoownerSection'>
      <div className='authorised_dataroom'>{editingRemovalRequest ? "Edit Removal Request" : "Remove Co-owner"}</div>
      <div className="authorised-form-data-room">
        <div className='authorised_dataroom__heading'>
          <div className='authorised_dataroom__title'>Upload Documents</div>
          <div className='authorised_dataroom__desc'>Upload necessary documents for co-owner removal. Co-owner will be
                                                     removed upon acceptance.
          </div>

        </div>

        <div className="authorised_dataroom-file-container">
          <div className="authorised_dataroom-content"
               onDrop={handleFileDrop}
          >
            <span className="ri ri-file-upload-line"></span>
            <p>Drag and drop files or <span className='browse'>browse</span></p>
            <small>Supported file format: .pdf, .docx, .doc, .ppt, .xls and .csv </small>
            <input
              id={"dataroom-file-browse"}
              type="file"
              placeholder="select logo"
              className={"image_browse"}
              accept=".pdf, .docx, .xlsx, .ppt ,.csv,.doc,.xls"
              onChange={handleOnChange}
              multiple={true}
            />
          </div>
        </div>
        <div className="authorised-dataroom-document">
          <div className='authorised-dataroom-document__nav'>
            <p className="authorised-dataroom-document__header">Documents</p>
            <p
              className='authorised-dataroom-document__fileCount'>{dataRoomFiles?.length} {dataRoomFiles?.length > 1 ? "Files" : "File"}</p>
          </div>
          <div className='authorised-dataroom-files'>
            {removalFilesLoading ? <SkeletonWallet listsToRender={3} /> : dataRoomFiles.length > 0 ? (
                <div className="uploadedFileConaitner">
                  <div className="authorised-data-room-files">
                    {dataRoomFiles?.map(
                      ({
                        fileName,
                        fileSize,
                        extension,
                        abortController,
                        id,
                        loadingStatus,
                        downLoadingStatus,
                        isSuccessfullyUploaded,
                        fileLink,
                        file
                      }: any) => {

                        return (
                          <div className='removeCoowner-main'>
                            <div key={id} className="authorised-file-details">
                              <div className='fileLogo'>
                                <img
                                  src={FILE_EXTENSION_URL[fileName?.split(".").pop().toLowerCase()]}
                                  alt={fileName} />
                              </div>
                              <div className="files-description-container">
                                <div className="authorised-files-description">
                                  <div
                                    className={classNames("authorised-files-description__upper", { "authorised-files-description__upper__uploading": !isSuccessfullyUploaded })}>
                                    <div className="file-names">
                                      <p className='fileName'
                                         data-tooltip-id={id}>{fileName}</p>
                                      <ReactTooltip
                                        id={id}
                                        place="bottom"
                                        content={fileName}
                                        className={"theme-tooltip"}
                                      />
                                      {isSuccessfullyUploaded && <><span
                                        style={{
                                          width: "4px",
                                          height: "4px",
                                          borderRadius: "4px",
                                          backgroundColor: "#878C99",
                                          display: "inline-block",
                                        }}
                                      ></span>

                                        <small
                                          className='fileSize'>{formatFileSize(fileSize)}</small></>}
                                    </div>
                                    <span className="file-loading-status">
                                                                        {loadingStatus}
                                                                    </span>
                                  </div>
                                  {!isSuccessfullyUploaded &&
                                    <div className="loading-placeholder">

                                      <div
                                        style={{ width: downLoadingStatus + "%" }}
                                        className="loading-percentage"
                                      ></div>
                                    </div>}
                                </div>


                              </div>
                            </div>
                            <div className='removeCoownerfile-right'>
                              {isSuccessfullyUploaded && <button className='viewButton'
                                                                 onClick={() => handleFileView(fileLink, file)}>View < img
                                src={view} alt="view" /></button>}

                              {abortController ? (
                                <i
                                  onClick={() => cancelFileUpload(abortController, id)}
                                  className="ri ri-close-circle-line authorised-delete-file"
                                ></i>
                              ) : (
                                <i
                                  onClick={() => deleteFile(id)}
                                  className="ri ri-delete-bin-6-line authorised-delete-file authorised-delete-file__bin"
                                ></i>
                              )}
                            </div>


                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) :
              <div className="no-data-found">
                <Image fileName={darkMode.value ? no_data_dark : no_data_light} />
                No documents available
              </div>}

          </div>

        </div>

      </div>
    </div>
  )
}

RemoveCoOwner.defaultProps = {
  coOwnerId: "",
  editUserId: "",
  editingRemovalRequest: false,
  callback: () => { }
}
