export const CANCEL_ORDER = {
    TITLE: "Are you sure you want to logout from the selected device?",
    DESCRIPTION: "This will log you out on the selected device and remove it from your linked devices.",
    BOLD_DESCRIPTION: "Do you want to logout?",
  };
  
  export const CANCEL_ORDER_BTN = {
    CANCEL_YES: "Logout",
    CANCEL_NO: "Cancel",
  };
  