import { Json } from "../types";

const baseURl = `https://storage.googleapis.com/satschel-assets-public/Liquidity/public/exchange-assets/`;

const contentType = {
  images: "images",
  icon: "icon",
  media: "media",
  bankLogo: "bank-logos"
};

export const imageConfig: Json = {
  images: {
    LoginBg: `${baseURl}${contentType.images}/LoginBackground.png`,
    LoginCards: `${baseURl}${contentType.images}/LoginCards.png`,
    MorningLineBanner: `${baseURl}${contentType.images}/MorningLineBanner.png`,
    galleryImg: `${baseURl}${contentType.media}/gallaryImage.png`,
    pattern: `${baseURl}${contentType.media}/${contentType.images}/pattern.png`,
    LoginImageCard: `${baseURl}${contentType.images}/Login_cardImage.png`,
  },
  logo: {
    LiquidityLogo: `${baseURl}${contentType.images}/LiquidityLogo2.png`,
    Liquid: `${baseURl}${contentType.images}/Liquidity_Blue_Logo.png`,
    Liquid_small: `${baseURl}${contentType.media}/${contentType.images}/LiquidSmall.svg`,
    morningLineSmallLight: `${baseURl}${contentType.images}/morningLineSmalLightLogo.svg`,
    morningLineSmallDark: `${baseURl}${contentType.images}/moningLineSmallDarkLogo.svg`,
  
    // bank logo
    default: `${baseURl}${contentType.media}/${contentType.bankLogo}/Bank_Default.svg`,
    "Navy Federal Credit Union": `${baseURl}${contentType.media}/${contentType.bankLogo}/Navy_Federal_Credit_Union.svg`,
    Fidelity: `${baseURl}${contentType.media}/${contentType.bankLogo}/Fidelity.svg`,
    Wealthfront: `${baseURl}${contentType.media}/${contentType.bankLogo}/Wealthfront.svg`,
    "TD Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/TD.svg`,
    "Citibank Online": `${baseURl}${contentType.media}/${contentType.bankLogo}/Citi.svg`,
    "Citizens Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/Citizens.svg`,
    Chase: `${baseURl}${contentType.media}/${contentType.bankLogo}/Chase.svg`,
    "Bank of America": `${baseURl}${contentType.media}/${contentType.bankLogo}/Bank_of_America.svg`,
    USAA: `${baseURl}${contentType.media}/${contentType.bankLogo}/USAA.svg`,
    "Regions Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/Regions_Financial_Corporation.svg`,
    "Charles Schwab": `${baseURl}${contentType.media}/${contentType.bankLogo}/Charles_Schwab_Corporation.svg`,
    "American Express - Personal Savings": `${baseURl}${contentType.media}/${contentType.bankLogo}/American_Express.svg`,
    "American Express": `${baseURl}${contentType.media}/${contentType.bankLogo}/American_Express.svg`,
    "Digital Federal Credit Union": `${baseURl}${contentType.media}/${contentType.bankLogo}/DigitalFederal_Credit_Union.svg`,
    "Wells Fargo": `${baseURl}${contentType.media}/${contentType.bankLogo}/Wells_Fargo.svg`,
    "Marcus by Goldman Sachs": `${baseURl}${contentType.media}/${contentType.bankLogo}/Marcus_By_Goldman_Sachs.svg`,
    "Capital One": `${baseURl}${contentType.media}/${contentType.bankLogo}/CapitalOne.svg`,
    "U.S. Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/USBank.svg`,
    "Ally Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/Ally.svg`,
    "Truist Bank": `${baseURl}${contentType.media}/${contentType.bankLogo}/Truist.svg`,
    UBS: `${baseURl}${contentType.media}/${contentType.bankLogo}/UBS.svg`,
  },
  svg: {
    cancelDark: `${baseURl}${contentType.images}/cancel-dark.svg`,
    cancelLight: `${baseURl}${contentType.images}/cancel-light.svg`,
    draggableIcon: `${baseURl}${contentType.images}/Icon.svg`,
    ReportBugIcon: `${baseURl}${contentType.images}/ReportBugIcon.svg`,
    lockedLight: `${baseURl}${contentType.images}/lockedLight.svg`,
    lockedDark: `${baseURl}${contentType.images}/locked.svg`,
    noInviteDark: `${baseURl}${contentType.images}/noInvites.svg`,
    noInviteLight: `${baseURl}${contentType.images}/noInvitesLight.svg`,
    view: `${baseURl}${contentType.images}/view.svg`,
    greenCompleted: `${baseURl}${contentType.images}/greenCompleted.svg`,
    WalletLight: `${baseURl}${contentType.images}/WalletLight.svg`,
    WalletDark: `${baseURl}${contentType.images}/WalletDark.svg`,
    users: `${baseURl}${contentType.images}/Users.svg`,
    UsersLight: `${baseURl}${contentType.images}/UsersLight.svg`,
    clock: `${baseURl}${contentType.images}/clock.svg`,
    noDataDark: `${baseURl}${contentType.images}/no_data_dark.svg`,
    noDataLight: `${baseURl}${contentType.images}/no_data_light.svg"`,
    errorImg: `${baseURl}${contentType.images}/emptyNews.svg`,
    noAssetImage: `${baseURl}${contentType.images}/noasset.svg`,
    ARQSecIcon: `${baseURl}${contentType.images}/ARQSecIcon.svg`,
    maintenanceLight: `${baseURl}${contentType.images}/maintenanceLight.svg`,
    maintenanceDark: `${baseURl}${contentType.images}/maintenanceDark.svg`,
    bank: `${baseURl}${contentType.images}/bank.svg`,
    Time: `${baseURl}${contentType.images}/Time.svg`,
    LiteTime: `${baseURl}${contentType.images}/LiteTime.svg`,
    previewImg: `${baseURl}${contentType.images}/defaultImg.svg`,
    approve: `${baseURl}${contentType.images}/approve.svg`,
    proceed: `${baseURl}${contentType.images}/proceeding.svg`,
    qrCode: `${baseURl}${contentType.images}/qr-code.svg`,
    notSupported: `${baseURl}${contentType.icon}/notSupported.svg`,
    LiqLogo: `${baseURl}${contentType.icon}/liqLogo.svg`,
    line: `${baseURl}${contentType.icon}//CheckLine.svg`,
    cross: `${baseURl}${contentType.icon}/icon/cross-line.svg`,
    user: `${baseURl}${contentType.media}/${contentType.images}/user.svg`,
    ArrowTail: `${baseURl}${contentType.media}/${contentType.icon}/ArrowTail.svg`,
    emptyCards: `${baseURl}${contentType.media}/${contentType.icon}/emptyCards.svg`,
    fileCheckDark: `${baseURl}${contentType.media}/${contentType.icon}/file-check-dark.svg`,
    fileChecklight: `${baseURl}${contentType.media}/${contentType.icon}/file-check-light.svg`,
    fileSearchDark: `${baseURl}${contentType.media}/${contentType.icon}/file-search-dark.svg`,
    fileSearchlight: `${baseURl}${contentType.media}/${contentType.icon}/file-search-light.svg`,
    '506b': `${baseURl}${contentType.media}/${contentType.icon}/506b.svg`,
    '506c': `${baseURl}${contentType.media}/${contentType.icon}/506c.svg`,
    empty_table: `${baseURl}${contentType.media}/${contentType.images}/empty_table.svg`,
    logo_1: `${baseURl}${contentType.media}/${contentType.images}/logo.svg`,
    logo_light: `${baseURl}${contentType.media}/${contentType.images}/logo-light.svg`,
    logo_dark: `${baseURl}${contentType.media}/${contentType.images}/logo-dark.svg`,
    email_sent_dark: `${baseURl}${contentType.media}/${contentType.images}/email-sent.svg`,
    email_sent_light: `${baseURl}${contentType.media}/${contentType.images}/email-sent-light.svg`,
    avatar_dark: `${baseURl}${contentType.media}/${contentType.images}/avtarDark.svg`,
    avatar_light: `${baseURl}${contentType.media}/${contentType.images}/avatarLight.svg`,
    appstore: `${baseURl}${contentType.media}/${contentType.images}/appstore.svg`,
    Originalwordmark: `${baseURl}${contentType.media}/${contentType.images}/Originalwordmark.svg`,
    android_appstore: `${baseURl}${contentType.media}/${contentType.images}/android-appstore.svg`,
    LiquidSmall: `${baseURl}${contentType.media}/${contentType.images}/LiquidSmall.svg`,
    empty_img: `${baseURl}${contentType.media}/${contentType.images}/emptyImg.svg`,
    empty_img_light: `${baseURl}${contentType.media}/${contentType.images}/emptyLight.svg`,
    no_data_dark: `${baseURl}${contentType.media}/${contentType.images}/no_data_dark.svg`,
    no_data_light: `${baseURl}${contentType.media}/${contentType.images}/no_data_light.svg`,
    email_verifi: `${baseURl}${contentType.media}/${contentType.images}/email-verifi.svg`,
    email_verifi_light: `${baseURl}${contentType.media}/${contentType.images}/email-verifi-light.svg`,
    linked_device_desktopIcon: `${baseURl}${contentType.media}/${contentType.images}/linked-device-desktopIcon.svg`,
    linked_device_mobileIcon: `${baseURl}${contentType.media}/${contentType.images}/linked-device-mobileIcon.svg`,
    linked_device: `${baseURl}${contentType.media}/${contentType.images}/linked-device.svg`,
    linked_device_light: `${baseURl}${contentType.media}/${contentType.images}/linked-device-light.svg`,
    app_qr_stage: `${baseURl}${contentType.media}/${contentType.images}/app-qr-stage.svg`,
    app_qr_pp: `${baseURl}${contentType.media}/${contentType.images}/app-qr-pp.svg`,
    app_qr_beta: `${baseURl}${contentType.media}/${contentType.images}/app-qr-beta.svg`,
    app_qr_prod: `${baseURl}${contentType.media}/${contentType.images}/app-qr-prod.svg`,
    no_chart: `${baseURl}${contentType.media}/${contentType.images}/no-chart.svg`,
    no_chart_light: `${baseURl}${contentType.media}/${contentType.images}/no-chart-light.svg`,
    kyc_start: `${baseURl}${contentType.media}/${contentType.images}/kyc-start.svg`,
    kyc_start_light: `${baseURl}${contentType.media}/${contentType.images}/kyc-start-light.svg`,
    kyc_success: `${baseURl}${contentType.media}/${contentType.images}/kyc-success.svg`,
    kyc_success_light: `${baseURl}${contentType.media}/${contentType.images}/kyc-success-light.svg`,
  
  },

  gif: {
    liquidity: `${baseURl}${contentType.icon}/liquidity.gif`,
    success: `${baseURl}${contentType.icon}/success.gif`,
    morningLineGif: `${baseURl}${contentType.images}/morningLineGif.gif`
  }
};
