import { Image } from "@storybook";

import { useRecoilValue, } from "recoil";
import { darkThemeState, linkedDeviceState } from "states";
import "./linkedDevice.scss";
import { useState, useCallback } from "react";
import { useNetwork } from "hooks";
import { ReactModal, SkeletonWallet } from 'components';
import { RemoveDevice } from "@views/exchange/components/logout-session/remove-device";
import { formatDateWithTime } from "utils";
import { imageConfig } from "@utils/imageConfig";


export const LinkedDevice = () => {
    const themeDark = useRecoilValue(darkThemeState);
    const [showLogout, setShowLogout] = useState<any>({});
    const devicelist = useRecoilValue(linkedDeviceState);
    const { loading } = useNetwork();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(null);

    const {svg: {linked_device_desktopIcon, linked_device_mobileIcon, linked_device, linked_device_light}} = imageConfig;

    const toggleConfirmationModal = useCallback((device: any) => {
        if (!isRemoveModalOpen) {
            setIsRemoveModalOpen(device);
        }
    }, [isRemoveModalOpen])

    const closeRemoveModal = useCallback(() => {
        setIsRemoveModalOpen(null);
    }, [])

    return (
        <div className="linked-devices">{loading ? <SkeletonWallet listsToRender={3} /> :
            devicelist?.length ?
                <div >
                    {devicelist.map((device: { _id: string; deviceType: string; deviceOS: string; device: string; userId: string; updatedAt: string; }) =>
                        <div className="device-wrapper">
                            <div className="devices" key={device?._id} onClick={() => setShowLogout({ ...showLogout, [device?._id]: !(showLogout[device?._id] ?? false) })}>
                                <div className="device-detail">
                                    {(device?.deviceType.toLowerCase() === "desktop") ?
                                        <Image fileName={linked_device_desktopIcon} height={"48px"} /> :
                                        <Image fileName={linked_device_mobileIcon} height={"48px"} />
                                    }
                                    <div>
                                        <p className="heading">{(device?.deviceType.toLowerCase() === "desktop") ? device?.deviceOS : device?.device}</p>
                                        <p className="para">Last active {formatDateWithTime(device?.updatedAt, 'MMM dd, yyyy')}</p>
                                    </div>
                                </div>
                                <div className="icons-arrow">{showLogout[device?._id] ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>}</div>
                            </div>
                            <div className={`logout-area ${showLogout[device?._id] ? "visibleBtn" : ""}`}>
                                <span></span>
                                <button onClick={() => toggleConfirmationModal(device)} className="logout-button">Logout</button>
                            </div>
                        </div>
                    )}
                    <ReactModal
                        visible={!!isRemoveModalOpen}
                        onClose={() => setIsRemoveModalOpen(null)}
                    >
                        <RemoveDevice device={isRemoveModalOpen} closeRemoveModal={closeRemoveModal} />
                    </ReactModal>
                </div>
                : <div className="empty-devices" >
                    {themeDark ?
                        <Image fileName={linked_device} height={"170px"} /> :
                        <Image fileName={linked_device_light} height={"170px"} />
                    }
                    <p className="heading">No linked devices</p>
                </div>
        }</div>
    );
}