export const auctionDetails = {
  lenser_loan_number: "Lenser loan number",
  gp_number: "GP number",
  gp_prefix: "GP Prefix",
  borrower_name: "Borrower name",
  Address: "Address",
  borrower_city: "Borrower city",
  borrower_state: "Borrower State",
  borrower_zip: "Borrower zip",
  naics_code: "Naics code",
  sba_authorization_date: "SBA authorization date",
  note_data: "Note date",
  sba_fee_payment_date: "SBA fee payment date",
  first_disbursement_date: "First disbursement date",
  final_disbursement_date: "Final disbursement date",
  first_payment_date: "First payment date",
  paid_to_date: "Paid to date",
  maturity_date: "Maturity date",
  reset_frequency: "Reset Frequency",
  payment_frequency: " Payment Frequency",
  accrual_basis: "Accrual basis",
  sba_guarantee_per: "SBA guarantee %",
  lifetime_cap: "Lifetime cap",
  lifetime_floor: "Lifetime floor",
  original_loan_amount: "Original loan amount",
  current_loan_balance: "Current loan balance",
  payment_amount: "Payment amount",
  base_rate: "Base rate",
  gross_margin: "Gross margin",
};
