import React, { useCallback, useEffect, useMemo, useState } from "react"
import "./sba-order-details.scss"
import { useRecoilValue } from "recoil";
import { OrderDetailsState } from "@views/exchange/components/Orders/store/state";
import { Image } from "../../../@storybook";
import { getCategoryLabel, mergeStringWithSymbol } from "@utils/getString";
import { ASSETS_CONFIG, HIDE_ISSUER_NAME } from "@views/exchange/constants";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { MUTUAL_FUND } from "@views/MyOrders/constants";
import { formatNumberWithCommas } from "@utils/format-number";
import { useCurrency } from "@hooks/currency";
import { DataRoom } from "@components/dataRoom";
import { Button } from "@components/button";
// @ts-ignore
import { Json } from "@types/common";
import { APIS, FILE_EXTENSION_URL } from "../../../constant";
import { useNotification } from "@hooks/notification";
import { useNetwork } from "@hooks/network";
import { Loader } from "@components/Loader2";
import { fileFormat } from "@components/dataRoom/constant";
import { formatFileSize } from "@utils/common";
import { useDownloadGoogleapisFile } from "@hooks/download-googleapis-file";
import { Skeleton } from "@components/skeleton-loader";
import OrderDetailKyc from "@views/MyOrders/components/OrderDetailKyc";
import { imageConfig } from "@utils/imageConfig";
import { userPersonalDetails } from "@states/user";
import { useParams } from "react-router-dom";

interface StepTimelineTitleProps {
  isCompleted: boolean
  isActive: boolean
  children?: JSX.Element | JSX.Element[];
  title: string
  note?: string
  isLoading?: boolean
}

interface SBAOrderDetailsProps {}

const StepTimelineTitle: React.FC<StepTimelineTitleProps> = (props): React.JSX.Element => {

  return (<div className={`step-item ${props?.isActive && "active"}`}>
    <div className={`step-marker ${props?.isCompleted && "completed"}`} />
    <div className="step-content">
      <h3 className="step-title">{props?.title}</h3>
      {!!props?.note && <div className="certification-box">
        <div className="certification-icon">
          <i className="ri-alarm-warning-fill" />
        </div>
        <p className="certification-text">{props?.note}</p>
      </div>}

      {props.isLoading ? <Skeleton className="sba-order-detail-skeleton" width={100} height={140} /> : props?.children}
    </div>
  </div>)
}

const SBAOrderDetails: React.FC<SBAOrderDetailsProps> = ({}) => {
  const params = useParams();
  const { errorNotification } = useNotification();
  const { formatCurrencyWithBillion } = useCurrency();
  const { get: getOrderDetailsSteps, data: orderDetailRes, loading: isOrderDetailLoading } = useNetwork();
  const { data: orderDetailResData } = orderDetailRes || {};
  const { put: updateDataRoomFileStatus } = useNetwork();
  const { post: postAddendumOnNo, loading: loadingAddendumOnNo } = useNetwork();
  const { patch: deleteDataRoomFile } = useNetwork();
  const orderDetails = useRecoilValue(OrderDetailsState);
  const personalData = useRecoilValue(userPersonalDetails);
  const { downloadGoogleapisFile, loadingStates } = useDownloadGoogleapisFile();
  const [orderDetailDataRoom, setOrderDetailDataRoom] = useState<Json>({});
  const [promissoryDataRoom, setPromissoryDataRoom] = useState<Json>({});
  const [addendumDataRoom, setAddendumDataRoom] = useState<Json>({});
  const [dataRoomUploading, setDataRoomUploading] = useState<Json>({ step: 2, loading: false });
  const [isAddendumAvailable, setIsAddendumAvailable] = useState<boolean>(false);
  const [showKycModal, setShowKycModal] = useState<Json | null>(null);
  const { data: orderDetail } = orderDetails;
  const issuerNameString = HIDE_ISSUER_NAME[orderDetail?.assetType] ? "" : orderDetail?.issuerDetails?.issuerName;

  const updateDataRoomFilesAfterSuccess = async (step: number) => {
    if (dataRoomUploading?.loading) return;
    const _dataRoomObject = step === 2 ? promissoryDataRoom : addendumDataRoom;
    if (!_dataRoomObject?.filesStatus?.length && !_dataRoomObject?.deletedFiles?.length) return
    setDataRoomUploading({ step, loading: true });
    await uploadGcpfileStatus(_dataRoomObject?.filesStatus || []);
    await deleteDataRoomFiles(_dataRoomObject?.deletedFiles || []);
    setDataRoomUploading({ step, loading: false });
  };

  useEffect(() => {
    if (params?.id) {
      getOrderDetailsSteps(`${APIS.ORDER_SETTLEMENT_STEPS}/${params?.id}`).then((response: any) => {
        if (response?.message === "ok") {
          let _data: Json = {};
          (response?.data?.steps || [])?.map((step: Json) => {
            if (!_data[step?.stepNumber]) {
              const isCompleted = step?.assignedTo === "both" ? [step?.status?.buyer, step?.status?.seller].every((sta) => sta === "completed") : step?.status?.[step?.assignedTo] === "completed"
              _data[step?.stepNumber] = { ...step, isCompleted, file: step?.orderDocuments?.[0] }
            }
          })
          setOrderDetailDataRoom(_data)
        } else {
          errorNotification(response?.message || "failed to load data room files");
        }
      }).catch((err: any) => {
        errorNotification(err?.message);
      });
    }
  }, [params?.id]);

  const uploadGcpfileStatus = async (files: Json[]) => {
    const payload = {
      files: [...files],
    };
    if (!payload?.files?.length) return false
    try {
      const response = await updateDataRoomFileStatus(
        `${APIS.ORDER_DOCUMENTS}/${orderDetail?.id}`,
        payload, { apiResponse: true }
      );
      if (response?.message === "ok") {
        const file = response?.data?.find((_file: Json) => _file?.status === "SUCCESS");
        setOrderDetailDataRoom((prev: Json) => ({
          ...prev,
          [file?.stepNumber]: {
            ...prev?.[file?.stepNumber],
            status: { ...prev?.[file?.stepNumber]?.status, [prev?.[file?.stepNumber]?.assignedTo]: "completed" },
            isCompleted: true,
            file
          }
        }));
      }
      return response;
    } catch (err: any) {
      errorNotification(err?.message);
      return false;
    }
  };

  const deleteDataRoomFiles = async (files: Json[]) => {
    const fileData: Json[] = [...files];
    if (fileData.length > 0) {
      deleteDataRoomFile(
        `${APIS.ORDER_DOCUMENTS}/${orderDetail?.id}`,
        {
          files: fileData,
        },
        {
          apiResponse: true
        }
      ).then((response: Json) => {
        if (response?.message === "ok") {
          //
        } else {
          errorNotification("failed to delete file");
        }
      }).catch((err: any) => {
        errorNotification(err?.message);
      });
    }
  };

  const handleNoAddendum = async () => {
    const payload = {
      orderId: orderDetail?.id,
      documentType: "note_addendum",
      stepNumber: 3,
      assetId: orderDetail?.assetId,
      files: []
    }
    postAddendumOnNo(`${APIS.ORDER_DOCUMENTS}`, payload, { apiResponse: true }).then((response: any) => {
      if (response.message === "ok") {
        setOrderDetailDataRoom((prev: Json) => ({
          ...prev,
          3: {
            ...prev?.[3],
            status: { ...prev?.[3]?.status, [prev?.[3]?.assignedTo]: "completed" },
            isCompleted: true,
            file: { stepNumber: 3, fileUrl: "" }
          }
        }));
      } else {
        errorNotification(response.message || "Something went wrong!");
      }
    }).catch((err: any) => {
      errorNotification(err?.message);
    });
  };

  const getValue = (value: string | number, type: string) => {
    const decimal = ASSETS_CONFIG[orderDetail?.assetType as string]?.qtyDecimal
    if (type === "string") {
      return value;
    } else if (type === "price") {
      return formatCurrencyWithBillion(value, decimal);
    } else if (type === "number") {
      return formatNumberWithCommas(value, decimal, false);
    }
  };

  const orderData = useMemo(() => {
    const assetType = getCategoryLabel(orderDetail?.category);

    return [
      {
        label: "Order type",
        value: orderDetail?.type ? orderDetail?.type : "-",
        type: "string",
        className: `order-type-${orderDetail?.type}`
      },
      {
        label: "Order ID",
        value: orderDetail?.id ? orderDetail?.id : "-",
        type: "string",
      },
      {
        label: "Time & Date",
        value: orderDetail?.orderdate
          ? new Date(orderDetail?.orderdate).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }) +
          ", " +
          new Date(orderDetail?.orderdate).toLocaleDateString("en-US", {
            month: "long",
            day: "2-digit",
            year: "numeric",
          })
          : "-",
        type: "string",
      },
      {
        label: "Quantity",
        value:
          orderDetail?.quantity && orderDetail?.quantity !== 0
            ? orderDetail?.quantity
            : orderDetail?.initialQuantity,
        type: "number",
      },
      {
        label: "Price",
        value: orderDetail?.price ? orderDetail?.price : "-",
        type: "price",
      },
      {
        label: "Issuer name",
        value: orderDetail?.issuerName ? orderDetail?.issuerName : "-",
        type: "string",
      },
      {
        label: "Asset type",
        value:
          orderDetail?.symbol === MUTUAL_FUND.Asset
            ? MUTUAL_FUND.Text
            : assetType || "-",
        type: "string",
      },
      {
        label: "Order placed by",
        value: orderDetail?.orderCreatedBy
          ? orderDetail?.orderCreatedBy
          : "-",
        type: "string",
      },
      {
        label: "Total amount (USD)",
        value: orderDetail?.totalamount ? orderDetail?.totalamount : "-",
        type: "price",
      },
    ]
  }, [orderDetail]);

  const renderUploadedFiles = useCallback((step: number) => {
    const file: Json = orderDetailDataRoom?.[step]?.file;
    const fileMetaData = file?.metadata;

    return (<div className="date-room-files">
      <div className="file-details-no-gap">
        <div className="file-icon-container">
          <img
            src={FILE_EXTENSION_URL[fileFormat[fileMetaData?.extension]]}
            alt={fileMetaData?.docName} className="pointer-evenet-none" />
        </div>
        <div className="files-description-container">
          <div className="files-description">
            <div className="file-names">
              <p
                title={fileMetaData?.docName}>{fileMetaData?.docName}</p> -<small>{formatFileSize(fileMetaData?.fileSize)}</small>
            </div>
          </div>
        </div>
        {!!loadingStates[file?.fileUrl] ?
          <Loader dimension={20} /> :
          <i className="ri-download-line delete-file"
             onClick={() => downloadGoogleapisFile(file?.fileUrl, fileMetaData?.docName)} />
        }
      </div>
    </div>)
  }, [orderDetailDataRoom, loadingStates]);

  const renderDataRoomPart = useCallback((step: number, dataRoomState: Json, setDataRoomState: any) => {
    const successFile = dataRoomState?.filesStatus?.filter((file: Json) => file?.status === "SUCCESS")

    return (<div
      className={`sba-order-detail-dataroom-container ${successFile?.length ? "hide-uploader" : ""} ${dataRoomUploading?.loading ? "dataRoomUploading" : ""}`}>
      <DataRoom dataRoomFilesRes={[]}
                allowFilesRearrange={false}
                allowFilesRename={false}
                multipleFiles={false}
                editId={""}
                editFolderid={orderDetail?.id}
                payloadForSignUrl={{
                  orderId: orderDetail?.id,
                  documentType: step === 2 ? "form_147" : "note_addendum",
                  stepNumber: step,
                  assetId: orderDetail?.assetId,
                }}
                dataRoomApi={APIS.ORDER_DOCUMENTS}
                onSuccess={(files) => setDataRoomState(files)} />
      <div className="data-room-button-container">
        <Button type="data-room-button" label={dataRoomUploading?.step == step && dataRoomUploading?.loading ?
          <Loader
            className="white-transparent"
            dimension={15}
          /> : "Upload"} handleClick={() => updateDataRoomFilesAfterSuccess(step)}
                disabled={dataRoomUploading?.loading || !successFile?.length}
        />
      </div>
    </div>)
  }, [dataRoomUploading, updateDataRoomFilesAfterSuccess]);

  const renderCounts = useMemo(() => {
    const completedSteps = Object.values(orderDetailDataRoom || {})?.filter((step: Json) => !!step?.isCompleted)
    return {
      totalSteps: (Object.values(orderDetailDataRoom || {})?.length || 0) + 1,
      completedSteps: completedSteps?.length || 0
    }
  }, [orderDetailDataRoom])

  const handleOpenEsignModal = useCallback(() => {
    if (orderDetailDataRoom?.[1]?.status?.["seller"] !== "completed" && orderDetailResData?.userType === "buyer")
      return errorNotification("Seller has not signed the document yet.");
    if (orderDetailDataRoom?.[1]?.status?.[orderDetailResData?.userType] === "completed") return;

    const recipient = orderDetailDataRoom?.[1]?.esignDetails?.recipients?.filter((recipient: Json) => recipient?.userType === orderDetailResData?.userType)?.[0];

    setShowKycModal(recipient)
  }, [orderDetailDataRoom, orderDetailResData]);

  const handleCloseEsignModal = useCallback(() => {
    if (orderDetailDataRoom?.[1]?.status?.[orderDetailResData?.userType] === "completed") return;
    let _data: Json = { ...orderDetailDataRoom };
    _data[1] = { ..._data[1], status: { ..._data[1]?.status, [orderDetailResData?.userType]: "completed" } }
    _data[1].isCompleted = [_data[1]?.status?.buyer, _data[1]?.status?.seller].every((sta) => sta === "completed");
    setOrderDetailDataRoom(_data);
    setShowKycModal(null)
  }, [orderDetailDataRoom, orderDetailResData]);

  return (
    <div className="sba-order-details-container">
      {!!showKycModal &&
        <OrderDetailKyc open={!!showKycModal} details={showKycModal}
                        onSuccess={handleCloseEsignModal}
                        onClose={() => setShowKycModal(null)} />}

      <div className="issuer-info">
        <div className="issuer-logo">
          <Image
            url={orderDetail?.logo}
            initials={orderDetail?.name}
            className="logo-placeholder"
            initialsClassName="logo-placeholder"
          />
        </div>
        <div className="issuer-details">
          <div className="issuer-symbol">
            {orderDetail?.assetType === "music"
              ? orderDetail?.symbol : orderDetail?.assetType === "horse_trade" ? orderDetail?.symbol
                : mergeStringWithSymbol(issuerNameString, orderDetail?.symbol?.toUpperCase(), ".")}
          </div>
          <div className="issuer-name">
            {orderDetail?.assetType === "music"
              ? orderDetail?.name : orderDetail?.assetType === "horse_trade" ? orderDetail?.name
                : mergeStringWithSymbol(issuerNameString, orderDetail?.name?.toUpperCase(), ".")}
          </div>
        </div>
        <CircularProgressbarWithChildren
          value={renderCounts?.completedSteps} maxValue={renderCounts?.totalSteps} className="step-circle"
          strokeWidth={6}>
          <div className="step-inner-circle">
            <span className="step-text">Step</span>
            <span className="step-number">{renderCounts?.completedSteps}/{renderCounts?.totalSteps}</span>
          </div>
        </CircularProgressbarWithChildren>
      </div>

      <div className="order-content">
        <div className="order-info-panel">
          {orderData?.map((_data) => (
            <div className="info-section">
              <div className="info-label">{_data?.label}</div>
              <div className={`info-value ${_data?.className}`}>{getValue(_data?.value, _data?.type)}</div>
            </div>
          ))}
        </div>

        <div className="order-steps-panel">
          <div className="steps-timeline">
            {/* Step 1 */}
            <StepTimelineTitle title="Review and sign SBA Form 1086."
                               isCompleted={!!orderDetailDataRoom?.[1]?.isCompleted} isActive={true}
                               isLoading={isOrderDetailLoading}>
              <div className="signers-section">
                <div className="document-card">
                  <div className="document-icon">
                    <Image
                      url={imageConfig?.logo?.EsignNew}
                      initials={orderDetail?.name}
                      className="document-icon-img"
                      initialsClassName="document-icon-img"
                    />
                  </div>
                  <div className="document-name">SBA Form 1086</div>
                  <button className="review-button" onClick={handleOpenEsignModal}
                          disabled={orderDetailDataRoom?.[1]?.status?.[orderDetailResData?.userType] === "completed"}
                  >Review & Sign
                  </button>
                </div>
                <h4 className="signers-title">Signers</h4>
                {orderDetailDataRoom?.[1]?.esignDetails?.recipients?.map((signer: Json, index: number) => (
                  <div className="signer-item" key={signer.id}>
                    <div className="signer-number">{index + 1}</div>
                    <div className="signer-info">
                      <div className="signer-role">
                        {signer.userType === orderDetailResData?.userType ? "You" : signer.userType}</div>
                      <div className="signer-email">{signer.email}</div>
                    </div>
                    <div className="signer-status">
                      {orderDetailDataRoom?.[1]?.status?.[signer.userType] === "completed" ? <>
                          <i className="ri-checkbox-circle-fill checkbox-signed" />
                          <span>Signed</span>
                        </> :
                        <>
                          <div className="status-indicator" />
                          <span>Not Signed</span>
                        </>}
                    </div>
                  </div>
                ))}
              </div>
            </StepTimelineTitle>

            {/* Step 2 */}
            <StepTimelineTitle isCompleted={!!orderDetailDataRoom?.[2]?.isCompleted}
                               isActive={!!orderDetailDataRoom?.[1]?.isCompleted}
                               isLoading={isOrderDetailLoading}
                               title="Upload a certified copy of Promissory Note(SBA Form 147) that is physically signed(preferably in blue ink) and dated with the following certification:"
                               note={`“We certify this document to be a true and certified copy of the Original Promissory Note.”`}>
              <>
                {orderDetailResData?.userType === orderDetailDataRoom?.[2]?.assignedTo && !!orderDetailDataRoom?.[1]?.isCompleted && !orderDetailDataRoom?.[2]?.isCompleted &&
                  renderDataRoomPart(2, promissoryDataRoom, setPromissoryDataRoom)}
                {!!orderDetailDataRoom?.[2]?.isCompleted && <div className="sba-order-detail-dataroom-container">
                  {renderUploadedFiles(2)}
                </div>}
              </>
            </StepTimelineTitle>

            {/* Step 3 */}
            <StepTimelineTitle isCompleted={!!orderDetailDataRoom?.[3]?.isCompleted}
                               isActive={!!orderDetailDataRoom?.[2]?.isCompleted}
                               isLoading={isOrderDetailLoading}
                               title="Upload a certified copy of Note addendum (if any) that is physically signed(preferably in blue ink) and dated with the following certification:"
                               note={`“We certify this document to be a true and certified copy of the Original Note Addendum.”`}>
              <>
                {orderDetailResData?.userType === orderDetailDataRoom?.[3]?.assignedTo && !!orderDetailDataRoom?.[2]?.isCompleted && !orderDetailDataRoom?.[3]?.file?.fileUrl && !isAddendumAvailable &&
                  <div className="addendum-checkbox-container">
                    {!!orderDetailDataRoom?.[3]?.isCompleted ? "No Note Addendum available" : "Is there a Note Addendum available?"}
                    {!orderDetailDataRoom?.[3]?.isCompleted && <div className="data-room-button-container">
                      <Button type="data-room-button btn-no" label={loadingAddendumOnNo ? <Loader dimension={15}
                      /> : "No"} handleClick={handleNoAddendum} />
                      <Button type="data-room-button" label="Yes" handleClick={() => setIsAddendumAvailable(true)} />
                    </div>}
                  </div>}

                {!orderDetailDataRoom?.[3]?.isCompleted && isAddendumAvailable && renderDataRoomPart(3, addendumDataRoom, setAddendumDataRoom)}

                {!!orderDetailDataRoom?.[3]?.file?.fileUrl && <div className="sba-order-detail-dataroom-container">
                  {renderUploadedFiles(3)}
                </div>}
              </>
            </StepTimelineTitle>

            {/* Step 4 */}
            <StepTimelineTitle isCompleted={false} isActive={!!orderDetailDataRoom?.[3]?.isCompleted}
                               isLoading={isOrderDetailLoading}
                               title="Send the following documents to the Fiscal and Transfer Agent (FTA)">
              <>
                {!!orderDetailDataRoom?.[3]?.isCompleted && <div className="sba-order-detail-dataroom-container mt-0">
                  {!!orderDetailDataRoom?.[1]?.file && renderUploadedFiles(1)}
                  {!!orderDetailDataRoom?.[2]?.file && renderUploadedFiles(2)}
                  {!!orderDetailDataRoom?.[3]?.file?.fileUrl && renderUploadedFiles(3)}
                  <div className="data-room-button-container">
                    <Button type="data-room-button" label="Send to FTA for Approval" handleClick={() => {}} />
                  </div>
                </div>}
              </>
            </StepTimelineTitle>

            {/* Step 5 */}
            {/*<StepTimelineTitle isCompleted={false} isActive={false}
            isLoading={isOrderDetailLoading}
                               title="Document verification by FTA." />*/}

            {/* Step 6 */}
            {/*<StepTimelineTitle isCompleted={false} isActive={false}
            isLoading={isOrderDetailLoading}
                               title="Assignment of a settlement date by FTA." />*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SBAOrderDetails

