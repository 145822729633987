import { atom } from "recoil";
interface IRemovalFileState {
  filesData: any[];
  removalStatus: string;
  token: string;
  updatedAt: string;
}
export const authorisedUploadedFileStatus = atom<JSON[]>({
  key: "authorised-uploaded-file-status",
  default: [],
});
export const authorisedDeleteFileStatus = atom<any>({
  key: "authorised-delete-file-status",
  default: [],
});

export const RemovalFileState = atom<IRemovalFileState>({
  key: "Removal-file-state",
  default: {
    filesData: [],
    removalStatus: "",
    token: "",
    updatedAt: "",
  },
});

export const RemovalRequestFilesState = atom({
  key: "removal-request-files-state",
  default: [],
});
