import { atom } from "recoil";
import { IFirebaseIsFeatureEnableState, ISelectedCurrencyState, IUserSelectedCurrencyState } from "./type";

export const SelectedCurrencyState = atom<ISelectedCurrencyState>({
  key: "selected-currency-state",
  default: {currencyType: "USD"}
});

export const userSelectedCurrencyState = atom<IUserSelectedCurrencyState>({
  key: "user-selected-currency-state",
  default: {
    code: "USD",
    symbol: "$",
    thousandsSeparator: ",",
    decimalSeparator: ".",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    decimalDigits: 2,
    rate: 1,
  },
});

export const isCurrencyLoadingState = atom<boolean>({
  key: "is-currency-loading-state",
  default: false,
});

export const isLockinState = atom<boolean>({
  key: "is-lockin-state",
  default: false,
});

export const firebaseIsFeatureEnableState = atom<IFirebaseIsFeatureEnableState>({
  key: "firebase-is-feature-enable-state",
  default: {
    crypto: true
  },
});

export const showCurrentOverlayInMobileState = atom<boolean>({
  key: "show-current-overlay-in-mobile-state",
  default: false,
});
