import { ReactModal } from "@components/react-modal";
import classNames from "classnames";
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import styles from "./IssuerNameModal.module.sass";
import { Button } from "@components/button";
import { validateIssuerName } from "./store/common";
import useIssuerNameApi from "./store/useIssuerNameApi";
import { Loader } from "@components/Loader2";

interface IIssuerNameModal {
  openIssuerNameModal: boolean;
  setOpenIssuerNameModal: any;
}
const IssuerNameModal = ({
  openIssuerNameModal,
  setOpenIssuerNameModal,
}: IIssuerNameModal) => {
  const [issuerName, setIssuerName] = useState<string>("");
  const [issuerNameError, setIssuerNameError] = useState<string>("");
  const [isIssuerNameInvalid, setIsIssuerNameInvalid] = useState<boolean>(false);
  const {UpdateIssuerName,IssuerNameUpdateLoading}=useIssuerNameApi()

  const handleInputIssuerName = useCallback((e: any) => {
    setIsIssuerNameInvalid(false)
    let { value } = e.target;
    value=value.trim().replace(/\s+/g, ' ')
    setIssuerName(value);
    const error = validateIssuerName(value);
    setIssuerNameError(error);
  }, []);

  const closeIssuerNameModal=useCallback(()=>{
    setOpenIssuerNameModal(false)
  },[])

  const submitIssuerName = useCallback(() => {
    const payload={issuerName:issuerName}
    UpdateIssuerName(payload,closeIssuerNameModal,setIsIssuerNameInvalid,setIssuerNameError)
   
  }, [UpdateIssuerName, issuerName]);

  const renderIssuerNameModal = useMemo(
    () => (
      <>
        <div className={styles.issuerNameModal__top}>
          <div className={styles.issuerNameModal__top__heading}>
            Issuer name
          </div>
          <div className={styles.issuerNameModal__top__subHeading}>
            Create a unique issuer name between 3 to 12 characters.
          </div>
        </div>
        <div className={styles.issuerNameModal__middle}>
          <p className={styles.issuerNameModal__middle__label}>Issuer name</p>
          <div className={styles.issuerNameModal__middle__input}>
            <input
              onChange={(e) => handleInputIssuerName(e)}
              // onKeyDown={handleKeyDown}
              className={styles.issuerNameModal__middle__input__field}
            />
            { isIssuerNameInvalid && (
              <i
                className={`${"ri-close-circle-fill"
                } ${
                    styles.issuerNameModal__middle__input__field__red
                }`}
              />
            )}
          </div>

          {issuerNameError && (
            <div className={styles.issuerNameModal__middle__error}>
              <i
                className={`${styles.issuerNameModal__middle__error__icon} ri-information-fill`}
              />
              <span className="error-message">{issuerNameError}</span>
            </div>
          )}
        </div>
        <div className={styles.IssuerNameModaBtnContainer}>
          <Button
            label="Cancel"
            buttonType="submit"
            handleClick={() => setOpenIssuerNameModal(false)}
            type={classNames(styles.IssuerNameModaBtnContainer__cancel)}
          />
          {
            <Button
              disabled={
                issuerNameError || issuerName?.length < 3 
                  ? true
                  : false
              }
              label={IssuerNameUpdateLoading ? <Loader dimension={18} /> :"Save"}
              handleClick={submitIssuerName}
              type={classNames(styles.IssuerNameModaBtnContainer__save)}
            />
          }
        </div>
      </>
    ),
    [IssuerNameUpdateLoading, handleInputIssuerName, issuerName?.length, issuerNameError, setOpenIssuerNameModal, submitIssuerName]
  );

  return (
    <ReactModal
      visible={openIssuerNameModal}
      onClose={() => setOpenIssuerNameModal(false)}
      closeBtn={true}
      maskClosable={false}
      outerClassName={classNames(styles.IssuerNameModal)}
    >
      {renderIssuerNameModal}
    </ReactModal>
  );
};

export default IssuerNameModal;
