import UseOrdersApi from "@views/exchange/components/Orders/store/hook";
import {
  OrderDetailsState,
} from "@views/exchange/components/Orders/store/state";
import { useCallback, useEffect, useMemo } from "react";
import { useCurrency } from "hooks";
import { Loader } from "components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MUTUAL_FUND, ORDER_TYPE, getTransactionIcon } from "../constants";
import { getCategoryLabel, mergeStringWithSymbol } from "@utils/getString";

import "./order-list.scss";
import { ASSETS_CONFIG, HIDE_ISSUER_NAME } from "@views/exchange/constants";
import { formatNumberWithCommas } from "@utils/format-number";
import SBAOrderDetails from "@views/MyOrders/components/sba-order-details";

export const OrderDetails = () => {
  const { formatCurrencyWithBillion } = useCurrency();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { FetchOrderDetails, orderDetailsStatusCode }: any = UseOrdersApi();
  useEffect(() => {
    FetchOrderDetails(params?.id, location?.state?.tradeId ? location?.state : {});
    if (orderDetailsStatusCode === 400 || orderDetailsStatusCode === 500) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FetchOrderDetails, params?.id, location?.state]);

  const orderDetails = useRecoilValue(OrderDetailsState);
  const { data: orderDetail } = orderDetails;

  const capitalizeFirstLetterOnly = useCallback((input: string): string => {
    if (input?.length === 0) {
      return input;
    }
    return input?.charAt(0).toUpperCase() + input?.slice(1).toLowerCase();
  }, []);

  const data = useMemo(() => {
    const symbolNameArr = orderDetail?.symbol?.split(".");
    const symbolName = symbolNameArr?.[symbolNameArr?.length - 1] ?? "";
    const assetType = getCategoryLabel(orderDetail?.category);


    return {
      totalAmount: orderDetail?.totalamount ? orderDetail?.totalamount : "-",
      symbol: orderDetail?.symbol ? orderDetail?.symbol : "-",
      issuerName: orderDetail?.issuerName ? orderDetail?.issuerName : "",
      assetName: orderDetail?.name ? orderDetail?.name : "-",
      DateTime: orderDetail?.orderdate
        ? new Date(orderDetail?.orderdate).toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }) +
        ", " +
        new Date(orderDetail?.orderdate).toLocaleDateString("en-US", {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })
        : "-",
      status: orderDetail?.status ?? "-",
      details: [
        {
          label: "Order ID",
          value: orderDetail?.id ? orderDetail?.id : "-",
          type: "string",
        },
        {
          label: "Time & Date",
          value: orderDetail?.orderdate
            ? new Date(orderDetail?.orderdate).toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }) +
            ", " +
            new Date(orderDetail?.orderdate).toLocaleDateString("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            })
            : "-",
          type: "string",
        },
        {
          label: "Asset name",
          value: orderDetail?.name
            ? `${orderDetail?.name} (${symbolName.toUpperCase()})`
            : "-",
          type: "string",
        },
        {
          label: "Issuer name",
          value: orderDetail?.issuerName ? orderDetail?.issuerName : "-",
          type: "string",
        },
        {
          label: "Quantity",
          value:
            orderDetail?.quantity && orderDetail?.quantity !== 0
              ? orderDetail?.quantity
              : orderDetail?.initialQuantity,
          type: "number",
        },
        {
          label: "Order type",
          value: orderDetail?.type ? orderDetail?.type : "-",
          type: "string",
        },
        {
          label: "Avg. price",
          value: orderDetail?.price ? orderDetail?.price : "-",
          type: "price",
        },
        {
          label: `Fee (${orderDetail?.tax?.taxPercent}%)`,
          value: orderDetail?.tax?.taxAmount
            ? orderDetail?.tax?.taxAmount
            : "-",
          type: "price",
        },
        {
          label: "Total amount",
          value: orderDetail?.totalamount ? orderDetail?.totalamount : "-",
          type: "price",
        },
        {
          label: "Asset type",
          value:
            orderDetail?.symbol === MUTUAL_FUND.Asset
              ? MUTUAL_FUND.Text
              : assetType || "-",
          type: "string",
        },
        {
          label: "Order placed by",
          value: orderDetail?.orderCreatedBy
            ? orderDetail?.orderCreatedBy
            : "-",
          type: "string",
        },
        {
          label: `Order ${
            orderDetail?.status === ORDER_TYPE.PENDING
              ? "modified"
              : orderDetail?.status === ORDER_TYPE.EXECUTED
                ? "modified"
                : "canceled"
          } by`,
          value:
            orderDetail?.status === ORDER_TYPE.CANCELLED
              ? orderDetail?.orderCancelledBy
              : orderDetail?.orderModifiedBy ?? "-",
          type: "string",
        },
      ],
    };
  }, [orderDetail?.category, orderDetail?.id, orderDetail?.initialQuantity, orderDetail?.issuerName, orderDetail?.name, orderDetail?.orderCancelledBy, orderDetail?.orderCreatedBy, orderDetail?.orderModifiedBy, orderDetail?.orderdate, orderDetail?.price, orderDetail?.quantity, orderDetail?.status, orderDetail?.symbol, orderDetail?.tax?.taxAmount, orderDetail?.tax?.taxPercent, orderDetail?.totalamount, orderDetail?.type]);

  const getValue = (value: string | number, type: string) => {
    const decimal = ASSETS_CONFIG[orderDetail?.assetType as string]?.qtyDecimal
    if (type === "string") {
      return value;
    } else if (type === "price") {
      return formatCurrencyWithBillion(value, decimal);
    } else if (type === "number") {
      return formatNumberWithCommas(value, decimal, false);
    }
  };

  const handleBack = useCallback(() => {
    const tab = ORDER_TYPE[orderDetails?.data["status"]];
    navigate(`/orders?tab=${tab}`);
  }, [orderDetails]);

  const issuerNameString = HIDE_ISSUER_NAME[orderDetail?.assetType] ? "" : data?.issuerName;

  const modifiedStatus = data?.status?.replace("CANCELLED", "CANCELED");

  return (
    <div className="order-details-container">
      <div className="order-header">
        <div className="order-header-left">
          <span onClick={handleBack}>
            <i className="ri ri-arrow-left-s-line"></i>
          </span>
          <p>Order details</p>
        </div>
      </div>

      {params?.id === orderDetail?.id && Object.keys(orderDetail)?.length > 0 ? (
        orderDetail?.assetType === "sba7" && orderDetail?.status === ORDER_TYPE.EXECUTED ? <SBAOrderDetails /> :
          <div className="order-details">
            <div className="order-details-main-status-info">
              <p className="amount">
                {orderDetails?.data?.assetType === "horse_trade" ? orderDetails?.data?.symbol : mergeStringWithSymbol(issuerNameString, data.symbol?.toUpperCase(), ".")}
              </p>
              <span className="order-details__date">{data?.DateTime}</span>
              <span className={`order-status ${data?.status} `}>
              <i className={`ri ${getTransactionIcon[data?.status]} `}></i>
              <p>{capitalizeFirstLetterOnly(modifiedStatus)}</p>
            </span>
            </div>
            <div className="order-details-body">
              {data?.details &&
                data?.details?.map((items: any) => {
                  return (
                    <div key={items?.label} className="order-panel">
                      <small>{items?.label}</small>
                      <p>{getValue(items?.value, items?.type)}</p>
                    </div>
                  );
                })}
            </div>
          </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default OrderDetails;
