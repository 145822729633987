import { useCallback, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { allowedCardAuthMethods, allowedCardNetworks } from "./constants";
import { GOOGLE_PAY_ENV_TYPE } from "envs";
import { APIS } from "constant";
import { UseTransaction } from "@views/MyWallet/store/hooks";

export const useGooglePay = () => {
  // Hooks
  const { errorNotification, successNotification } = useNotification();
  const { post } = useNetwork();
  const { getTransactions, getFortressAccountInfo } = UseTransaction();

  // States
  const [loading, setLoading] = useState({
    buttonLoader: false,
    bodyLoader: false,
  });
  const [hasGooglePayTransactionError, setGooglePayTransactionError] =
    useState(false);

  const updateGooglePayTransactionError = (
    hasError: boolean | ((prevState: boolean) => boolean)
  ) => {
    setGooglePayTransactionError(hasError);
  };

  const handleGooglePayClick = useCallback(
    async (amount: string, handleClose: (value?: boolean) => void) => {
      setGooglePayTransactionError(false);
      const payload = {
        mode: "googlePaySession",
      };
      setLoading({
        buttonLoader: true,
        bodyLoader: false,
      });
      const resp = await post(APIS.PAYMENT_INITIATION, payload);
      const googlePayClient = new google.payments.api.PaymentsClient(
        GOOGLE_PAY_ENV_TYPE === "PRODUCTION"
          ? { environment: GOOGLE_PAY_ENV_TYPE }
          : {}
      );
      const paymentDataRequest: google.payments.api.PaymentDataRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: allowedCardAuthMethods,
              allowedCardNetworks: allowedCardNetworks,
              billingAddressRequired: false,
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "finix",
                gatewayMerchantId: resp?.gateWayMerchantId ?? "",
              },
            },
          },
        ],
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPrice: (amount ?? 0).toString(),
          currencyCode: "USD",
          countryCode: "US",
        },
        merchantInfo: {
          merchantName: resp?.merchantName,
          merchantId: resp?.merchantId ?? "",
        },
      };

      googlePayClient
        .loadPaymentData(paymentDataRequest)
        .then(async (paymentData) => {
          setLoading({
            bodyLoader: true,
            buttonLoader: true,
          });
          const paymentToken =
            paymentData?.paymentMethodData?.tokenizationData?.token;
          if (paymentToken) {
            const payload = {
              mode: "google-pay",
              transactionType: "deposit",
              amount: amount,
              third_party_token: paymentToken,
            };
            try {
              const response = await post(APIS.PAYMENT_INITIATION, payload);
              if (response?.transactionId) {
                successNotification(
                  "Your money will be successfully deposited into your account shortly."
                );
                getFortressAccountInfo();
                getTransactions();
                handleClose?.(true);
              } else {
                setGooglePayTransactionError(true);
                errorNotification("Something went wrong. Please try another method.");
                handleClose?.();
              }
            } catch (error) {
              errorNotification("Something went wrong. Please try another method.");
              setGooglePayTransactionError(true); 
              console.log("Error initiating payment:", error);
              handleClose?.();
            } finally {
              setLoading({
                bodyLoader: false,
                buttonLoader: false,
              });
            }
          }
        })
        .catch((error) => {
          setLoading({
            bodyLoader: false,
            buttonLoader: false,
          });
          setGooglePayTransactionError(true);
          errorNotification("Transaction declined .");
          console.error("Error loading payment data:", error);
        });
    },
    [errorNotification]
  );

  return {
    loading,
    hasGooglePayTransactionError,
    handleGooglePayClick,
    updateGooglePayTransactionError,
  };
};
