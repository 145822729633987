import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { LoginPhoneNumberState } from "states";
import { APIS } from "constant";
import { LoginUserState, MobileVerificationState } from "@states/user";
import { Loader } from "@components/Loader2";
import { useMobileAuthentication } from "@views/SignIn/hooks/useMobileAuthentication";
import { useNotification } from "@hooks/notification";
import { useCookie } from "@hooks/cookie";
import { ROUTES } from "@routes/constants";

import "./mobile-link-sent.scss";
import { useSwitchWorkSpace } from "@views/SignIn/hooks/useSwitchWorkSpace";
import { signInWithTokenAndSetPersistence, useHandleSignInFireBase } from "libs";

export const MobileLinkSent = () => {
  const verificationId = useRecoilValue(MobileVerificationState);
  const loginDetails = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginDetails;
  const { checkMobileVerificationStatus } = useMobileAuthentication();
  const { successNotification, errorNotification } = useNotification();
  const { set: setCookieToken } = useCookie();
  const { EXCHANGE, SWITCH_ACCOUNT } = ROUTES;
  const setLoginUser = useSetRecoilState(LoginUserState);
  const { fetchWorkSpaceUser } = useSwitchWorkSpace();
  const { handleSigninFireBase } = useHandleSignInFireBase();

  const { LOGIN, MOBILELINKSENT } = ROUTES

  const navigate = useNavigate();

  let mobileLinkVerifyInterval: any = useRef(null);

  const loginUser = async (token: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      const res = await fetchWorkSpaceUser();
      if (res?.message !== "ok") return;
      if (res?.data?.length > 1) {
        navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
        return;
      }

      successNotification("Logged In Successfully");
      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      navigate(EXCHANGE);
    }
  };

  const checkStatus = async () => {
    const payload = {
      type: "getTokenWithCode",
      verificationId,
    };

    const res = await checkMobileVerificationStatus(payload);
    if (res) {
      const { status, token } = res?.data;
      if (token && status === "approved") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        loginUser(token);
        handleSigninFireBase(token)
      }
      if (status === "rejected") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        errorNotification("Login Verification Denied");
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (verificationId) {
      mobileLinkVerifyInterval.current = setInterval(() => {
        checkStatus();
      }, 5000);
    }

    return () => clearInterval(mobileLinkVerifyInterval);
  }, [verificationId]);

  const cancelApproval = () => {
    clearInterval(mobileLinkVerifyInterval.current);
    navigate(-1);
  };

  useEffect(() => {
    if (!phone) {
      navigate(LOGIN);
    }
  }, [phone, navigate]);


  return (
    <div className="mobile-link">
      <div className="mobile-link__wrapper">
        <div className="mobile-link__row">
          <div className="mobile-link__title">Waiting For Approval</div>
          <div className="mobile-link__description">
            {/* {`We have sent you a text message to ${country} ${phone} . Open the link
                provided in the text message`} */}
            We have sent a text message to {countryCode} {phone ? phone : 'Redirecting...'}.
            <strong className="mobile-link__mobile-number"> </strong>Open the
            link provided in the text message and approve from there.
          </div>
        </div>
        <div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
          <Loader className="loader-blue" dimension={60} />
          {/* <div className="mobile-link__number">{securityCode}</div>
          <div className="mobile-link__specification">
            Tap <strong>{securityCode}</strong> on your phone to verify your
            phone number{" "}
          </div> */}
        </div>
        <div className="mobile-link__row">
          <button onClick={cancelApproval}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
export default MobileLinkSent
