import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";

import { ActiveCurrencyState, CollapseState } from "states";
import { navItems } from "./constants";
import { OrderTable } from "./components";
import { ExecutedOrderToasterState } from "./store/state";
import { assestType } from "@views/exchange/constants";

import styles from "./Orders.module.sass";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { getJsonToParams } from "@utils/common";
import { useParams } from "react-router-dom";
// @ts-ignore
import { Json } from "@types/common";
import { useCookie } from "@hooks/cookie";

type Tab = "PENDING" | "EXECUTED" | "CANCELLED";

export const Orders = () => {
  const orderTableRef = useRef<any>(null)

  // new states
  const [collapse, setCollapse] = useRecoilState(CollapseState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const { id: activeCurrencyId }: any = activeCurrency ?? {};

  const tabs: Tab[] = ["PENDING", "EXECUTED", "CANCELLED"];
  const [activeTab, setActiveTab] = useState<Tab>("PENDING");
  const initialState = {
    PENDING: { data: [], offset: 0, limit: 20, loaded: false },
    EXECUTED: { data: [], offset: 0, limit: 20, loaded: false },
    CANCELLED: { data: [], offset: 0, limit: 20, loaded: false },
  }
  const { get: getCookieData } = useCookie();
  const cookieData = useMemo(() => {
    return getCookieData("userDetails");
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); // Empty dependency array ensures this runs only once


  const [tabState, setTabState] = useState<any>(initialState);
  const [loading, setLoading] = useState<boolean>(false);
  const orderExecutedToaster = useRecoilValue(ExecutedOrderToasterState);
  const params = useParams();
  const { get: getAssetData } = useNetwork();

  // Function to fetch data for a specific tab
  const fetchDataForTab = async (
    tab: Tab,
    offset = 0
  ): Promise<{ tab: Tab; data: any[] }> => {
    if(!cookieData?.token){
      return { tab, data: [] };
    }

    setLoading(true);
    try {
      const query = {
        assetId: activeCurrencyId,
        offset,
        limit: tabState[tab].limit,
        type: tab,
      };

      const response = await getAssetData(APIS.ORDERS + getJsonToParams(query));
      const fetchedData = response?.data || [];

      setTabState((prevState: any) => ({
        ...prevState,
        [tab]: {
          data: offset === 0 ? [...fetchedData] : [...prevState[tab].data, ...fetchedData],
          offset: offset || prevState[tab].offset,
          limit: prevState[tab].limit,
          loaded: fetchedData?.length ? true : false,
        },
      }));

      return { tab, data: fetchedData };
    } catch (error) {
      setTabState((prevState: any) => ({
        ...prevState,
        [tab]: {
          ...prevState[tab],
          loaded: false,
          offset: prevState[tab].offset,
          limit: prevState[tab].limit,
        },
      }));
      return { tab, data: [] };
    } finally {
      setLoading(false);
    }
  };

  // Fetch data for all tabs initially
  const fetchAllTabsInitially = async (): Promise<void> => {
    const tabToShow: Tab[] = activeCurrency?.type === assestType.crypto ? ["EXECUTED"] : ["PENDING", "EXECUTED", "CANCELLED"];
    const results = await Promise.all(
      tabToShow?.map((tab) =>
        fetchDataForTab(tab)
      )
    );

    // Exclude CANCELLED tab from fallback logic
    const filteredTabs = new Set(["PENDING", "EXECUTED"]);
    let fallbackTab: Json | string | undefined = results.find((result) => filteredTabs?.has(result.tab) && result.data.length > 0);
    fallbackTab = fallbackTab?.tab || (activeCurrency?.type === assestType.crypto ? "EXECUTED" : "PENDING");
    setActiveTab(fallbackTab as Tab);
  };

  useEffect(() => {
    if (activeCurrencyId) {
      setTabState(initialState)
      fetchAllTabsInitially();
    }
  }, [activeCurrencyId, orderExecutedToaster]);

  const handleTabSwitch = (tab: any): void => {
    if (tab === activeTab) return
    if (orderTableRef.current) {
      orderTableRef.current.scrollTop = 0
    }

    if (!tabState[tab]?.loaded) {
      fetchDataForTab(tab);
    }
    setActiveTab(tab);
  };

  const handleFetchMore = useCallback(() => {
    fetchDataForTab(activeTab, tabState[activeTab].offset + 1);

    setTabState((prevState: any) => ({
      ...prevState,
      [activeTab]: {
        data: [...prevState[activeTab].data],
        offset: prevState[activeTab].offset + 1,
        limit: prevState[activeTab].limit,
        loaded: prevState[activeTab].loaded,
      },
    }));


  }, [activeTab, tabState]);

  const handleCollapse = useCallback(() => {
    setCollapse((pre) => !pre);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNavigation = useMemo(() => {
    let items = navItems
    if (activeCurrency?.type === assestType.crypto) {
      items = navItems?.filter((item) => item.type === "EXECUTED");
    }

    return (
      <div className={`${styles.nav} ${styles.nav_header}`}>
        <div className={styles.nav}>
          {items.map((key, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: key.type === activeTab,
              })}
              onClick={() => handleTabSwitch(key.type)}
              key={index}
            >
              {key.title}
            </button>
          ))}
        </div>
        <div
          onClick={handleCollapse}
          className={cn(styles.collapse, {
            [styles.collapse_true]: collapse,
            [styles.collapse_false]: !collapse,
          })}
        >
          <i className="ri-arrow-down-s-line" />
        </div>
      </div>
    );
  }, [activeTab, collapse, handleCollapse, tabState]);

  return (
    <div
      className={cn({
        [styles.orders]: collapse,
        [styles.collapse_orders]: !collapse,
      })}
    >
      <div className={styles.head}>{renderNavigation}</div>
      <OrderTable
        tableRef={orderTableRef}
        activeTab={activeTab}
        loading={loading}
        handleFetchMore={handleFetchMore}
        filters={{
          offset: tabState[activeTab]?.offset,
          limit: tabState[activeTab]?.limit,
        }}
        ordersData={tabState[activeTab].loaded ? tabState[activeTab]?.data : []} setOrdersData={() => {}} />
    </div>
  );
};
