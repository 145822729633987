import { Button } from "components"
import useDarkMode from "use-dark-mode";
import cn from "classnames";
import { Image } from "@storybook";
import { imageConfig } from "@utils/imageConfig";

import styles from "./invitation.module.sass"

export const Invitation = () => {
    const darkMode = useDarkMode()
    const {logo: {Liquid}} = imageConfig
    return (
        <>
            <div className={cn(darkMode.value ? styles.invitaionSection : "")}>
                <div className={styles.invitationContainer}>
                    <div >
                        <Image
                            className={styles.logoContainer}
                            fileName={Liquid}
                        />
                    </div>
                    <div className={styles.invitationDetails}>
                        <div className={styles.invitation}>Invitation</div>
                        <div className={styles.messageContainer}>
                            <p className={styles.greeting}>Hi John Doe</p>
                            <p className={styles.message}>
                                Micheal has invited you on this phone number, +1 (123) 456 7899, as co-owner, to explore his liquidity portfolio.
                            </p>
                        </div>
                        {/* <div className={styles.buttonSection}>
                            <Button type="button__outline styles.deny" label={"Deny"} />
                            <Button type="button__outline styles.approve " label={"Approve"} />
                        </div> */}
                        <div className={styles.buttonSection}>
                            <Button
                                label="Deny"
                                // handleClick={handleClose}
                                type={cn(styles.deny)}
                            />
                            {
                                <Button
                                    label={"Approve"}
                                    // handleClick={handleInviteUser}
                                    type={cn("button__filled button__filled--primary", styles.approve)}
                                />
                            }
                        </div>

                    </div>
                    <div className={styles.invitationFooter}>
                        <div className={styles.invitaionSocial}>
                            <img src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Linkdin-logo-gray.png" alt="LinkedIn" />
                            <img src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/Twitter-logo-gray.png" alt="Twiiter" />
                        </div>
                        <p className={styles.footerDisclaimer}>© 2024 Satschel Inc. All rights reserved.</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Invitation