import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LoginPhoneNumberState } from "states";
import { MobileVerificationState } from "@states/user";
import { useNotification } from "@hooks/notification";
import { ApprovalStatusState } from "@views/SignIn/stores";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useBiometricAuthentication } from "@views/SignIn/hooks/useBiometricAuthentication";
import { useIsTabActive } from "@hooks/common";
import {useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/constants";
import { imageConfig } from "@utils/imageConfig";

import "./biometric-approval-sent.scss";

export const BiometricApprovalSent = () => {
  const verificationId = useRecoilValue(MobileVerificationState);
  const loginDetails = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginDetails;
  const { successNotification, errorNotification } = useNotification();
  const [isSmsSending, setIsSmsSending] = useState(false);
  const isTabActive = useIsTabActive();
  let mobileLinkVerifyInterval: any = useRef(null);
  const navigate = useNavigate();

  const { gif: { liquidity, morningLineGif } } = imageConfig;

  const setVerificationStatus = useSetRecoilState(ApprovalStatusState);

  const { registrationApproval } = useBiometricAuthentication();
  const { CHOOSEMETHOD, LOGIN} = ROUTES

  const { get: checkLinkStatus } = useNetwork();
  const checkBiometricMobileVerificationStatus = async (id: string) => {
    try {
      const response = await checkLinkStatus(`${APIS.VerifyPhone}/${id}`);
      return response;
    } catch (err: any) {
      console.log(err?.message);
      clearInterval(mobileLinkVerifyInterval.current);
    }
  };

  const checkStatus = async () => {
    const res = await checkBiometricMobileVerificationStatus(verificationId);
    if (res) {
      const { status } = res?.data;
      if (status === "approved") {
        clearInterval(mobileLinkVerifyInterval.current);
        setVerificationStatus(res?.data?.status);
       navigate(CHOOSEMETHOD);
        successNotification("Mobile verified successfully");
      }
      if (status === "rejected") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        errorNotification("Login Verification Denied");
        navigate(CHOOSEMETHOD);
      }
    }
  };

  useEffect(() => {
    if (verificationId) {
      mobileLinkVerifyInterval.current = setInterval(() => {
        if (isTabActive) checkStatus();
       
      }, 5000);
    }
    return () => clearInterval(mobileLinkVerifyInterval.current);
  }, [verificationId, isTabActive]);

  const cancelApproval = () => {
    clearInterval(mobileLinkVerifyInterval.current);
    navigate(CHOOSEMETHOD);
  };

  const resendSms = useCallback(async () => {
    if (isSmsSending) return;
    if (phone && countryCode) {
      setIsSmsSending(true);
      const approvalPayload = {
        type: "registrationApproval",
        phone,
        countryCode,
        deviceInfo: navigator.userAgent ?? {},
      };
      const response = await registrationApproval(approvalPayload);
      if (response) {
        const { message, success, verificationId } = response;
        if (success && verificationId) {
          successNotification(message);
          return;
        } else {
          errorNotification(message);
        }
        setIsSmsSending(false);
      }
    }
  }, [
    countryCode,
    errorNotification,
    isSmsSending,
    phone,
    registrationApproval,
    successNotification,
  ]);

  const renderContent = useCallback(() => {
    if (!phone) {
      navigate(LOGIN);
      return 'Redirecting...';
    }
    return phone;
  }, [phone]);
  return (
    <div className="biometric-approval">
      <div className="biometric-approval__wrapper">
        <div className="biometric-approval__row">
          <div className="biometric-approval__title">Waiting For Approval</div>
          <div className="biometric-approval__description">
            We have sent you a text message to &nbsp;
            <strong className="biometric-approval__number">
              {countryCode} {renderContent()} &nbsp;
            </strong>
            Open the link provided in the text message and approve from there.
          </div>
        </div>
        <div className="biometric-approval__row">
          <img src={liquidity} alt="logo" />
        </div>
        <div className="biometric-approval__row">
          <button onClick={cancelApproval}>Back</button>
        </div>
        <div className="biometric-approval__row">
          <span className="resent-link">
            Didn’t receive text message? <i onClick={resendSms}> Resend </i>
          </span>
        </div>
      </div>
    </div>
  );
};
export default BiometricApprovalSent