import React from "react";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

import "./PaymentUnavailable.scss"; // Import the CSS file

export const PaymentUnavailable: React.FC = () => {
      const darkMode = useDarkMode(false);
      const {svg: {WalletDark, WalletLight}} = imageConfig

  return (
    <div className="payment-unavailable">
      <div className="wallet-icon">
        <img src={darkMode.value ? WalletDark : WalletLight} alt="" />
      </div>
      <h2>Payment services are temporarily unavailable</h2>
      <p>
        We’re sorry for the inconvenience. Please try again in a little while,
        and thank you for your understanding.
      </p>
    </div>
  );
};


