import { FC, useCallback, useMemo, useState } from "react";
import useDarkMode from "use-dark-mode";
import { useRecoilValue } from "recoil";

import { transactionDataState, userSelectedCurrencyState } from "states";
import { useNetwork, useNotification } from "hooks";
import { formatNumberWithCommas, getCategoryLabel, getFormattedDate, mergeStringWithSymbol } from "utils";
import { SkeletonTable } from "components";

import styles from "./report-table.module.sass";
import { APIS } from "constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { Json } from "../../../../types";
import { assestType } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";

interface IReportTable  {
  loading: boolean,
  handleFetchMore: () => void
  filters: Json,
  refToTable: any
}

export const ReportTable: FC<IReportTable> = ({loading, handleFetchMore, filters, refToTable}) => {
  const darkMode = useDarkMode(false);
  const { symbol : currencySymbol } = useRecoilValue(userSelectedCurrencyState);
  const { get: getform } = useNetwork();
  const { errorNotification } = useNotification();
  const transactionData = useRecoilValue(transactionDataState);
  const [transactionLoading, setTransactionLoading] = useState<{
    [key: number]: boolean;
  }>({});

  const {svg: {no_data_light, no_data_dark}} = imageConfig;
  
  const handleDownload_1099b = useCallback(
    (index: any) => {
      setTransactionLoading((prevState) => ({
        ...prevState,
        [index]: true,
      }));

      const getTransctionDetails = transactionData[index];
      getform(
        `${APIS.Form1099}?assetName=${getTransctionDetails?.assetName}&quantity=${getTransctionDetails?.quantity}&buyDate=${getTransctionDetails?.buyDate}&sellDate=${getTransctionDetails?.sellDate}&sellPrice=${getTransctionDetails?.sellPrice}&buyPrice=${getTransctionDetails?.buyPrice}&gainAndLoss=${getTransctionDetails?.gainAndLoss}`
      )
        .then((res) => {
          if (res.data[0].signedUrl) {
            window.open(res.data[0].signedUrl, "_blank");
          } else {
            errorNotification("Not able to download please try after sometime");
          }
        })
        .finally(() => {
          setTransactionLoading((prevState) => ({
            ...prevState,
            [index]: false,
          }));
        });
    },
    [errorNotification, getform, transactionData]
  );

  const capitalizeFirstLetter = useCallback((str: string) => {
    return str.charAt(0)?.toUpperCase() + str.slice(1);
  }, []);

  const reportDetails = useMemo(
    () =>
      transactionData?.map((asset: any, index: number) => {
        const issuerNameString = asset?.assetType === assestType.crypto ? "" : asset?.issuerName;
        return (
          <tr className={`${styles.row} ${asset?.signedUrl}`} key={index}>
            <td className={styles.assetname}>
              <div className={styles.transactionInfo}>
                <p className={styles.assetSymbol}>
                  {mergeStringWithSymbol(
                    issuerNameString,
                    asset?.assetSymbol?.toUpperCase(),
                    "."
                  ) ?? "--"}
                </p>
                <p className={styles.assetName}>
                  {mergeStringWithSymbol(
                    issuerNameString,
                    asset?.assetName,
                    " "
                  ) ?? "--"}
                </p>
              </div>
            </td>
            <td>{getCategoryLabel(asset?.assetType)}</td>
            <td className={styles.assetType}>
              <div className={styles.transactionType}>
                <span
                  className={`${styles.circle} ${
                    asset?.type === "SELL"
                      ? styles.red_circle
                      : styles.green_circle
                  } `}
                ></span>
                <p className={styles.transactionData}>
                  {asset?.type?.toLowerCase()}
                </p>
              </div>
            </td>
            <td>{getFormattedDate(asset?.createdAt)}</td>
            <td className={styles.assetQty}>
              {formatNumberWithCommas(asset?.quantity, 2, false)}
            </td>
            <td>
              {currencySymbol}
              {formatNumberWithCommas(Number(asset.price))}
            </td>
            <td>
              {currencySymbol}
              {formatNumberWithCommas(Number(asset?.fee))}
            </td>
            <td>
              {currencySymbol}
              {formatNumberWithCommas(Number(asset?.totalAmount))}
            </td>
            <td
              className={
                Number(asset?.realised_pnl) < 0
                  ? styles.loss_text
                  : Number(asset?.realised_pnl) > 0
                  ? styles.profit_text
                  : ""
              }
            >
              {asset?.type === "SELL" && asset?.realised_pnl !== undefined
                ? `${currencySymbol}${formatNumberWithCommas(
                    Number(asset?.realised_pnl)
                  )}${
                    asset?.PnL_Percentage ? ` (${asset?.PnL_Percentage}%)` : ""
                  }`
                : "--"}
            </td>
            {/* <td>
            <>
              {asset.type === "SELL" ? (
                <span>
                  {transactionLoading[index] ? (
                    <LoaderLine />
                  ) : (
                    <i
                      id={`download-${index}`}
                      className={`ri-download-fill ${styles.download} `}
                      // onClick={() => handleDownload_1099b(index)}
                    />
                  )}
                </span>
              ) : (
                ""
              )}
            </>
          </td> */}
          </tr>
        );
      }),
    [
      transactionData,
      transactionLoading,
    ]
  );


  return (
    <div
      ref={refToTable}
      id="transaction-data-reports"
      className={`${styles.report_table} ${
        transactionData.length === 0 && styles.no_result
      }`}
    >
      <InfiniteScroll
        className={`${styles.reportScroll}`}
        dataLength={transactionData.length || 0}
        next={handleFetchMore}
        hasMore={
          transactionData.length ===
          filters.offset * filters.limit + filters.limit
        }
        loader={""}
        scrollableTarget="transaction-data-reports"
      >
        <table>
          <thead className={styles.head}>
          <tr>
            <th className={styles.header}>Name Of Assets</th>
            <th>Asset Type</th>
            <th className={styles.assetType}>Transaction Type</th>
            <th>Date</th>
            <th className={styles.assetQty}>Quantity</th>
            <th>Unit Price</th>
            <th>Fee</th>
            <th>Total Amount</th>
            <th>Realised P&L</th>
            {/* <th>Download(1099b)</th> */}
          </tr>
          </thead>
          <tbody>
          {transactionData.length > 0 && reportDetails}
          <tr>
            <td
              colSpan={100}
              className={`${
                transactionData.length === 0 && styles.empty_illustration
              }`}
            >
              {loading && (
                <SkeletonTable listsToRender={4} numberColumn={7} />
              )}

              {!loading && transactionData.length === 0 && (
                <div className={styles.illustration}>
                  <img
                    src={darkMode.value ? no_data_dark : no_data_light}
                    alt=""
                    height={96}
                  />
                  <span className={styles.empty_image}>No data available</span>
                </div>
              )}
            </td>
          </tr>
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};
