import React, { useMemo, useState, useRef, useEffect } from "react";
import Cropper from 'react-easy-crop'

import "./crop-image-on-upload.scss";
import { Json } from "../../types";
import NewModal from "../../components/new-modal/new-modal";
import { getCroppedImage } from "./cropHepler";
import { useNotification } from "@hooks/notification";
import { Button } from "@components/button";
import { getFileObjectFromBlobURL } from "@utils/common";

type ICropImageOnUpload = {
  open: boolean;
  onClose: () => void;
  onCropDone: (proppedData: Json) => void;
  details: {
    file: File;
    cropSize: { width: number; height: number };
    aspect?: number;
    xaxis?: number;
    yaxis?: number;
  };
  textContent: {
    title: string;
    subtitle: string;
    stepsTitle?: string;
    steps?: string[];
  };
  className?: string;
};

interface ImageDimension {
  height: number;
  width: number;
}

const initialImageDimension: ImageDimension = {
  height: 0,
  width: 0, 
};

export const CropImageOnUpload = ({ open, onClose, details: detailProp, onCropDone, textContent, className }: ICropImageOnUpload) => {
  const { errorNotification } = useNotification();
  const imgBlobUrl = useRef<string>(URL.createObjectURL(detailProp.file));
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<string | number>(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Json | null>(null)
  const [imageDimension, setImageDimension] = useState<ImageDimension>(initialImageDimension); 

  const onCropComplete = (_: any, croppedAreaPixels: Json) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const handleGetCroppedImage = async () => {
    try {
      const options = {
        imageName: detailProp?.file?.name,
        imageType: detailProp?.file?.type,
        cropSize: detailProp?.cropSize
      }
      const croppedData = await getCroppedImage(imgBlobUrl.current, croppedAreaPixels as any, options)
      onCropDone(croppedData as Json)
    } catch (e: any) {
      errorNotification(e?.message)
    }
  }

  useEffect(()=>{
    (async()=>{
      const fileData = await getFileObjectFromBlobURL(imgBlobUrl.current);
      setImageDimension(fileData as ImageDimension)
          })()
  }, [])

  const cropImageSize = useMemo(() => {
    const widthToAdd = detailProp.cropSize.width === imageDimension.width ? 0 : (detailProp?.xaxis ?? 0);
    const heightToAdd = detailProp.cropSize.height === imageDimension.height ? 0 : (detailProp?.yaxis ?? 0);
    
    return {
      width: detailProp?.cropSize?.width + widthToAdd,
      maxWidth: detailProp?.cropSize?.width + widthToAdd,
      height: detailProp?.cropSize?.height + heightToAdd,
    }
  }, [detailProp.cropSize.height, detailProp.cropSize.width, detailProp?.xaxis, detailProp?.yaxis, imageDimension.height, imageDimension.width])

  const contentCheck = !!textContent?.title || !!textContent?.subtitle || !!textContent?.stepsTitle || (!!textContent?.steps && !!textContent?.steps?.length);

  return (
    <NewModal
      isOpen={open}
      modalName="Crop image"
      className={`new-react-modal crop-image-on-upload-modal ${className}`}
      closeModal={onClose}
      title=""
      showCloseBtn={true}
      isStopOutsideClick={false}
    >
      { contentCheck && (<div className="crop-image-on-upload-modal__container">
          {!!textContent?.title && <div className="crop-image-on-upload-modal__heading">
            {textContent.title}
          </div>}
         {!!textContent?.subtitle &&  <p className="crop-image-on-upload-modal__description">
            {textContent.subtitle}
          </p>}
        </div>)}
        <div className="crop-image-on-upload-modal__listStyle">
          { contentCheck && (<div>
         {!!textContent?.stepsTitle && <h2 className="crop-image-on-upload-modal__heading">
            {textContent.stepsTitle}
          </h2>}
         {!!textContent?.steps && !!textContent?.steps.length && <ul className="crop-image-on-upload-modal__list">
          {(textContent.steps ?? []).map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>}
        </div>)}
      
      <div className="crop-image-container" style={cropImageSize}>
        <Cropper
          image={imgBlobUrl.current}
          crop={crop}
          zoom={zoom as number}
          aspect={detailProp?.aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropSize={detailProp?.cropSize}
          maxZoom={10}
          objectFit="cover"
        />
      </div>
      </div>
      <div className="crop-controls">
        <input
          type="range"
          value={zoom}
          min={1}
          max={10}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => setZoom(e.target.value)}
          className="zoom-range"
        />
      </div>
      <div className="crop-image-action">
        <Button
          type="crop-close-btn mr-10"
          label="Cancel"
          handleClick={onClose}
        />
        <Button type="" label="Apply" handleClick={handleGetCroppedImage} />
      </div>
    </NewModal>
  );
};
