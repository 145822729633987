import { useRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";

import NewModal from "@components/new-modal/new-modal";
import { primaryIssuanceState } from "../Orders/store/state";
import { Loader } from "components";
import { imageConfig } from "@utils/imageConfig";

import "./signDoc.scss";


interface IIssuanceIframe {
  onSuccess: () => void;
}

export const IssuanceSignDoc = ({ onSuccess }: IIssuanceIframe) => {
  const [primaryIssuance, setPrimaryIssuance] =
    useRecoilState(primaryIssuanceState);
  const [iframeLoading, setIframeLoading] = useState(true);

  const {svg: {ARQSecIcon}} = imageConfig;

  const handleDownload = useCallback(() => {
    window.open(primaryIssuance?.downloadUrl, "_blank");
  }, [primaryIssuance?.downloadUrl]);



  useEffect(() => {
    const messageHandler = (event: any) => {
      const receivedData: {
        type: "submit_esign";
        data: Record<string, never>;
      } = event.data;
      if (receivedData.type === "submit_esign") {
        onSuccess();
        setPrimaryIssuance((prev: any) => {
          return { ...prev, signDocModal: false };
        });
      }
    };
    window.addEventListener("message", messageHandler);
    // Removing event listener on unmounting the modal
    return () => {
      window.removeEventListener("message", messageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   const handleLoadComplete = () => {
     setTimeout(() => {
       setIframeLoading(false);
     }, 3000);
   };

  return (
    <>
      <NewModal
        isOpen={true}
        closeModal={() =>
          setPrimaryIssuance((prev: any) => {
            return { ...prev, signDocModal: false };
          })
        }
        modalName={"Compliance"}
        showCloseBtn={true}
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        className="Modal__wrapper issuance_modal"
      >
        <div className="issuanceSignDoc">
          <div className="issuance__header">
            <div className="issuance__header--inner">
              <div className="issuance_profile">
                <img src={ARQSecIcon} alt="ARQSecIcon" />
              </div>
              <div>
                <h3>ARQ Securities, LLC Broker dealer</h3>
                <p>
                  Finra Broker Check :
                  <a
                    href="https://brokercheck.finra.org/firm/summary/289499"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    https://brokercheck.finra.org/firm/summary/289499
                  </a>
                </p>
              </div>
            </div>

            <button onClick={handleDownload}>
              {" "}
              <i className="ri-download-fill" /> Download
            </button>
          </div>
          <iframe
            src={
              primaryIssuance?.docUrl ??
              "https://esign.beta.simplici.io/sign-doc/6717695b491077bc69d694cf?recipient=6717695b491077bc69d694d0"
            }
            title="IssuanceDoc"
            className=""
            allow="camera; microphone"
            onLoad={handleLoadComplete}
            style={{ display: iframeLoading ? "none" : "" }}
          />
          {iframeLoading && (
            <div className="issuance-iframe__loading">
              <Loader className="loader-blue" dimension={60}/>
            </div>
          )}
        </div>
      </NewModal>
    </>
  );
};
