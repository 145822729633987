import { useRecoilValue, useSetRecoilState } from "recoil";

import { DepositAmountState, depositStepState } from "states";
import { capitalizeFirstLetterOnly, useFormatNumber } from "utils";
import { useCurrency } from "hooks";

import styles from "./deposit.module.sass";
import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const DepositSuccess = ({
  method = "deposited",
  transactionID,
  status,
  handleCloseDeposit,
  id,
  userDetails,
  trackEventSG,
}: any) => {
  const depositAmount = useRecoilValue(DepositAmountState);

  const navigate = useNavigate();
  const { dollarFormat } = useFormatNumber();
  const { currencySymbol } = useCurrency();
  const setDepositState = useSetRecoilState(depositStepState);

  useEffect(() => {
    const {id:userId} = userDetails;
    const eventData = { 
      timestamp: new Date(), 
      user_id: userId
    };
    trackEventSG("deposit_success_web", eventData);
  }, [trackEventSG, userDetails]);

  useEffect(() => {
    return () => {
      setDepositState("amount");
    };
  }, [setDepositState]);

  const getTransactionDetails = (status: any) => {
    handleCloseDeposit();
    if (status === "FAILED") {
      navigate(`/wallet`);
    } else {
      navigate(`/wallet/transactions/${id}`);
    }
  };

  const renderMessage = useMemo(() => {
    switch (status) {
      case "SUCCESSFUL":
        return (
          <div className={styles.successPara}>
            You successfully deposited
            <span>
              {" "}
              {currencySymbol}
              {Number(depositAmount).toFixed(2)}
            </span>{" "}
            from your wallet
          </div>
        );
      case "PROCESSING":
        return (
          <div className={styles.successPara}>
            We are processing your
            <span>
              {" "}
              {currencySymbol}
              {Number(depositAmount).toFixed(2)}
            </span>{" "}
            deposit request. Please wait for a while as this may take some time.
          </div>
        );
      case "FAILED":
        return "Your deposit request could not be processed at this time. Please try again later.";
      default:
        return "";
    }
  }, []);

  return (
    <div className={styles.transactionSuccess}>
      <div className={styles.successSubHead}>
        {status === "SUCCESSFUL"
          ? "Yay! 🎉"
          : status === "PROCESSING"
          ? "Processing deposit request"
          : "Oops! 😬"}
      </div>
      <div>{renderMessage}</div>
      <div className={styles.transactionDetail}>
        <div className={styles.transactionCol}>
          Status
          <div
            className={classNames(styles.transactionStatus, {
              [styles.transactionStatus__processing]: status === "PROCESSING",
              [styles.transactionStatus__success]: status === "SUCCESSFUL",
              [styles.transactionStatus__failed]: status === "FAILED",
            })}
          >
            {capitalizeFirstLetterOnly(status)}
          </div>
        </div>
        <div className={styles.transactionCol}>
          Transaction ID
          <div className={styles.transactionId}>{transactionID}</div>
        </div>
      </div>
      <div className={styles.transactionSuccess__footer}>
        <button
          className={styles.transactionSuccess__footer__cancel}
          onClick={handleCloseDeposit}
        >
          Close
        </button>
        <button
          className={styles.transactionSuccess__footer__view}
          onClick={() => getTransactionDetails(status)}
        >
          {status === "FAILED" ? "Try Again" : "View transaction"}
        </button>
      </div>
    </div>
  );
};
