import React, { useCallback } from 'react'
import { LOAN_TYPE } from '../auctionLoanForm/constant';
import { Json } from 'types';

type Props = {
  auctionFormData: any
  setAuctionFormData: any
}

export const AuctionLoanType = (props: Props) => {
  const {auctionFormData, setAuctionFormData} = props;

  const handleChangeLoanType = useCallback((type: string) => {
    setAuctionFormData((prev: Json) => ({ ...prev, loanType: type }));
  }, [auctionFormData])

  return (
    <div className="auction-loan-form__heading">
    Loan Type
    <div className={`auction-loan-form__container`}>
      <div
        className="auction-loan-form__radio"
        onClick={() => handleChangeLoanType(LOAN_TYPE.WHOLE_LOANS)}
      >
        <input
          type="radio"
          className="auction-loan-form__radio-btn"
          checked={
            auctionFormData?.loanType === LOAN_TYPE.WHOLE_LOANS
          }
        />
        <div className="auction-loan-form__details">
          <p>Whole Loans</p>
          <span className="auction-loan-form__span">
            Includes both government guaranteed and unguaranteed
            portions of the loans.
          </span>
        </div>
      </div>
      <div style={{ borderLeft: "1px solid #E6E8EC" }}></div>
      <div
        className="auction-loan-form__radio"
        onClick={() => handleChangeLoanType(LOAN_TYPE.GOVERMENT_LOANS)}
        >
        <input
          type="radio"
          className="auction-loan-form__radio-btn"
          checked={auctionFormData?.loanType === LOAN_TYPE.GOVERMENT_LOANS}
        />
        <div className="auction-loan-form__details">
          <p>Government Guaranteed Portion of Loans</p>
          <span className="auction-loan-form__span">
            Includes only the government guaranteed portions of
            the loans.
          </span>
        </div>
      </div>
    </div>
  </div>
  )
}
