import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useWebSocket } from "@hooks/web-socket";
// @ts-ignore
import { Json } from "@types/common";
import { userPersonalDetails } from "@states/user";
import { selectedUserChatState } from "@views/Chat/ChatModal/state";
import { ChatsOnAssetState } from "./state";
import UseChatroomApi from "../store/chatroomApi";
import { debounce, formatDateWithCustomFormat } from "utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { SkeletonWallet } from "@components/skeleton-loader";

interface AssetChatMessagesProps {
  customers: any;
  setIsAssetChatOpen: any;
  setChatSelectedAsset: any;
  chatSelectedAsset: any;
  socketUpdateUser: any
  setSocketUpdateUser: any
}

export const AssetChatMessages: React.FC<AssetChatMessagesProps> = ({
  customers,
  setIsAssetChatOpen,
  setChatSelectedAsset,
  chatSelectedAsset, socketUpdateUser, setSocketUpdateUser
}) => {
  const { socket } = useWebSocket();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({ limit: 5, offset: 0, search: searchTerm })
  const personalData = useRecoilValue(userPersonalDetails);
  const setSelectedUserChat = useSetRecoilState(selectedUserChatState);
  const setChatsOnAsset = useSetRecoilState(ChatsOnAssetState);
  const { getAssetWiseChatList, updateReadChat } = UseChatroomApi();

  useEffect(() => {
    if (socketUpdateUser && socketUpdateUser?.assetId === chatSelectedAsset?.assetId) {
      setChatsOnAsset((prev: Json) => {
        let tempData = JSON.parse(JSON.stringify(prev?.data || []));
        const existIdx = tempData?.findIndex((asset: Json) => asset?.chatId === socketUpdateUser?.chatId);
        if (existIdx > -1) tempData?.splice(existIdx, 1);
        if (!!searchTerm && existIdx === -1) return prev
        return { ...prev, data: [socketUpdateUser, ...tempData] }
      });
    }

    return () => {
      setSocketUpdateUser(null)
    }
  }, [socketUpdateUser]);

  useEffect(() => {
    handleCallAssetChatApi()
  }, [filters])

  const handleBack = useCallback(() => {
    setIsAssetChatOpen(true);
    setChatSelectedAsset(null);
  }, []);

  const handleStartChat = useCallback((customer: Json) => {
    setSelectedUserChat(null);
    socket?.emit("chat", {
      type: "JOINROOM",
      chatId: customer?.chatId,
      customerId: personalData?.customerId,
    });
    updateReadChat(customer?.chatId, {
      receiverId: customer?.receiverId,
      assetId: chatSelectedAsset?.assetId,
      readCount: customer?.readCount || 0
    });
  }, [socket]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(debounce((value: string) => {
    setFilters((prev: any) => ({ ...prev, search: value || "", offset: 0 }));
  }, 500), []);

  const handleCallAssetChatApi = useCallback(() => {
    const options = { updateNewData: !!filters?.offset };
    getAssetWiseChatList(filters, options, setChatsOnAsset, chatSelectedAsset?.assetId, chatSelectedAsset?._id)
  }, [chatSelectedAsset?._id, chatSelectedAsset?.assetId, filters, getAssetWiseChatList, setChatsOnAsset])

  return (
    <>
      <div className="asset-info">
        <span className="back-arrow" onClick={handleBack}>
          <i className="ri-arrow-left-line ri-xl"></i>
        </span>

        {!!chatSelectedAsset ? (
          <img src={chatSelectedAsset?.logo} alt="Asset" className="asset-image" />
        ) : (
          <span className="chatAssetName">
            {chatSelectedAsset.name?.charAt(0).toUpperCase() +
              chatSelectedAsset.name?.charAt(1, 2).toUpperCase() ?? ""}
          </span>
        )}
        <span className="asset-text">
          {chatSelectedAsset?.name}
        </span>
      </div>
      <div className="search-input-wrapper">
        <input
          type="text"
          className="search-input"
          placeholder="Search user"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debounceSearch(e.target.value);
          }}
        />
        {!searchTerm ? <span className="search-icon">
          <i className="ri-search-line ri-xl"></i>
        </span>
        :
        <span className="empty-icon">
          <i className="ri-close-line ri-xl" onClick={()=>{
            setSearchTerm("");
            debounceSearch("");
            }}>
          </i>
        </span>}
      </div>
      <div className="asset-info__chat-list" id="asset-message-scrollableDiv">
        <InfiniteScroll
          dataLength={customers?.data?.length || 0}
          next={() => setFilters((prev: any) => ({ ...prev, offset: customers?.data?.length }))}
          hasMore={customers?.data?.length >= filters?.offset + filters?.limit}
          loader={<SkeletonWallet listsToRender={1} />}
          scrollableTarget="asset-message-scrollableDiv"
        >
          {
            customers?.data?.map((customer: Json) => (
              <div
                key={customer.chatId}
                className="asset-info__chat-item"
                onClick={() => handleStartChat(customer)}
              >
                <div className="asset-info__chat-avatar">
                  {customer?.receiverInfo?.initials || "LU"}
                  <span className={`asset-notification-badge ${!customer?.receiverInfo?.isOnline ? "offline" : ""}`} />
                </div>
                <div className="asset-info__chat-info">
                  <div className="asset-info__chat-name">
                    {customer.receiverId}
                    <div className="asset-info__chat-time">
                      {!!customer.messageCreatedAt &&
                        formatDateWithCustomFormat(customer.messageCreatedAt, {
                          lessMinute: "Just now",
                          dataFormat: "hh:mma, dd/MM/yyyy",
                          yesterdayFormat: "hh:mma, dd/MM/yyyy",
                          hourFormat: "hh:mma",
                        })}
                    </div>
                  </div>
                  <div className="asset-info__chat-message-count">
                    <div className="asset-info__chat-last-message">
                      {customer.message}
                    </div>
                    {customer.readCount > 0 && (
                      <div className="asset-info__chat-unread-count">
                        {customer.readCount}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          }
        </InfiniteScroll>
        {customers?.loading && <SkeletonWallet listsToRender={3} />}
        {!customers?.loading && !customers?.data?.length && !!searchTerm &&
          <div className="no-results">No user found</div>}
      </div>
    </>
  );
};
