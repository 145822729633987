import React, { useEffect } from "react";
import { Input } from "components";
import { Dataroom } from "../DataRoom.tsx";
import "./wire-verify-form.scss";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

export const WireVerifyForm = () => {
  const { trackEvent } = useFullStoryTrackEvent();
  useEffect(() => {
    trackEvent("WIRE_VERIFICATION_FORM", { });
  }, []);
  return (
    <>
      <div className="form-container">
        <div className="wallet-form-1oo gap-16">
          <Input
            key="transactionId"
            placeholder="Enter your transaction ID"
            label="Transaction ID"
            className="grid-width-100"
            value=""
            //value={auctionFormData[items.name]}
            inputType="text"
            handleChange={() => {}}
            inputName="transaction"
            required={true}
            suffixText=""
            readonly={false}
          />
          <div>
            <p>Upload your transaction script</p>
            <Dataroom />
          </div>
        </div>
      </div>
    </>
  );
};
