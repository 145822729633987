import React from "react";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

import "./maintenance.scss"; // Import the CSS file
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const Maintenance: React.FC = () => {
  const darkMode = useDarkMode(false);
  const {svg: {maintenanceLight, maintenanceDark}} = imageConfig;
  const { config } = usePermissionContext();

  return (
    <div className="maintenance-container">
      <div className="icon-container">
        <img src={darkMode.value ? maintenanceDark : maintenanceLight} alt="" />
      </div>
      <h2>We are under maintenance</h2>
      <p>
        We're currently performing maintenance. We'll be back soon. Thank you
        for your patience!
        <br />
        Please check back in a little while.
      </p>
      <p>
        If you need to get in touch, please contact us at{" "}
        <a
          href={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(config?.supportEmail || "")}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {config?.supportEmail}
        </a>
        .
      </p>
    </div>
  );
};
