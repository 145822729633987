import { FC, useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

import { ActiveCurrencyState, userPersonalDetails,assetDetailLoadState } from "states";

import styles from "./AssetDetail.module.sass";
import "./asset-details.scss"
import { assestType } from "@views/exchange/constants";
import { onValue, ref } from "firebase/database";
import { db } from "../../../../libs";
import { AssetStatus } from "constant";
import { useCookie } from "@hooks/cookie";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "private-assets": any;
    }
  }
}

interface IAssetDetail {
  isPrivate: boolean;
  isCaptable?:boolean;
}

export const AssetDetail: FC<IAssetDetail> = ({ isPrivate, isCaptable }) => {
  //globle state
  const assetDetailLoad = useRecoilValue(assetDetailLoadState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const PersonalDetails = useRecoilValue(userPersonalDetails); 
  // hook
  const darkMode = useDarkMode();

  const { get: getCookieData } = useCookie();
  
  const userDetails = {
    firstName: PersonalDetails?.firstName,
    lastName: PersonalDetails?.lastName,
    email: PersonalDetails?.email,
    VerifiedEmail: PersonalDetails?.isVerifiedEmail,
  };
  const assetAddress = useMemo(() => {
    return activeCurrency?.type === assestType.crypto
      ? activeCurrency._id
      : activeCurrency.address;
  }, [activeCurrency]);
  // const assetAddress = activeCurrency?.type === assestType.crypto ? activeCurrency._id : activeCurrency.address

  useEffect(() => {
    if (activeCurrency?.type === assestType.crypto) {
      const dataRef = ref(db, `cryptocurrencies/${activeCurrency.symbol}`);
      const unsubscribe = onValue(dataRef, (snapshot) => {
        const data = snapshot.val();
        const event = new CustomEvent("FirebaseCryptoData", {
          detail: data,
        } as CustomEventInit);
        window.dispatchEvent(event);
      });
      return () => unsubscribe();
    }
  }, [activeCurrency?.type, activeCurrency.symbol]);

   const cookieData = useMemo(() => {
     return getCookieData("userDetails");
   }, []); 

  return (
    <div
      key={assetDetailLoad}
      className={styles.AssetDetail}
      dangerouslySetInnerHTML={{
        __html: `<private-assets
          theme=${isCaptable ? "light" : darkMode.value ? "dark" : "light"}
          is_not_published=${
            cookieData?.token &&
            (activeCurrency?.status === AssetStatus.DRAFT ||
              activeCurrency?.status === AssetStatus.REJECTED)
              ? "true"
              : "false"
          }
          edit="false"
          asset_id=${assetAddress ?? "87283611"}
          id=${activeCurrency.id} 
          asset_name="${activeCurrency?.name ?? ""}"
          asset_symbol=${activeCurrency?.symbol ?? ""}
          asset_status= ${activeCurrency?.status}
          folder_id=${activeCurrency?.folderId}
          private=${isPrivate.toString()}
          user= ${JSON.stringify(userDetails) ?? ""}
          type=${activeCurrency?.type ?? ""}
          is_captable=${isCaptable ? "true" : "false"}
          offer_type="${activeCurrency.offeringType ?? "---"}"
          asset_wrapper="#exchange--wrapper"
        />`,
      }}
    />
  );
};
