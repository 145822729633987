import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";
import useDarkMode from "use-dark-mode";

import { format } from "date-fns";
import enUS from "date-fns/locale/en-US";

import { convertToCurrencySystem, useFormatNumber } from "utils";
import {
  AssetAllDetailsState,
  AssetLoaderState,
  CollapseState,
  ActiveCurrencyState,
} from "states";
import { Loader, Skeleton } from "components";
import { AssetsInfo, navItems, navItemsMusic } from "./constants";
import { useCurrency } from "hooks/currency";
import { MusicOverview } from "../music/musicOverview";
import { assestType } from "views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";

import styles from "./NonPrivateAssest.module.sass";

const AssetDetailNonPrivate = () => {
  // globle states
  const [collapse, setCollapse] = useRecoilState(CollapseState);
  const AssestsDetails = useRecoilValue(AssetAllDetailsState);
  const assestLoaderState = useRecoilValue(AssetLoaderState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  //local states
  const [activeTab, setActiveTab] = useState({
    key: "Overview",
    index: 0,
  });
  const [isImageError, setIsImageError] = useState(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  //hooks
  const darkMode = useDarkMode(false);
  const { formatCurrency } = useCurrency();
  const { NumberWithCommas } = useFormatNumber();

  const { balanceSheet, profile, detail, news } = AssestsDetails;
  const { marketCap, sharesOutstanding, price } = detail ?? {};
  const { netDebt, totalAssets, totalDebt } = balanceSheet ?? {};
  const { description, symbol, companyName } = profile ?? {};
  const { symbol: activeSymbol } = activeCurrency ?? {};
  const { enterpriseValue, priceToBook, priceToEarning, dividendYield } =
    AssestsDetails;
 
  const {svg: {noDataDark, noDataLight, errorImg}} = imageConfig;

  useEffect(() => {
    setShowMore(false);
  }, [AssestsDetails]);

  const handleClickNavigation = useCallback((key: string, index: number) => {
    setActiveTab({ key, index });
  }, []);

  //TODO: usage not clear, so commenting out the code. We were not using this anywhere
  // const handleCollapse = useCallback(() => {
  //   setCollapse((pre) => !pre);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isMusic = useMemo(
    () => activeCurrency?.type === assestType.music,
    [activeCurrency?.type]
  );

  const renderNavigation = useMemo(() => {
    return (
      <div className={`${styles.nav} ${styles.nav_header}`}>
        <div className={styles.nav}>
          {(isMusic ? navItemsMusic : navItems).map((key, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeTab.index,
                // [styles.disabled]: index === 1,
              })}
              onClick={() => handleClickNavigation(key, index)}
              key={index}
            >
              {key}
            </button>
          ))}
        </div>
      </div>
    );
  }, [activeTab.index, handleClickNavigation, isMusic]);

  const showCell = (label: string, value: any) => {
    return (
      <div className={`${styles.cellbody}`}>
        <div className={`${styles.subHeading}`}>{label}</div>

        <div className={`${styles.cellValue}`}>{value ?? "--"}</div>
      </div>
    );
  };

  const formatDate = useCallback((date: any) => {
    const newDate = new Date(date);
    // Define the format string for "Oct 19, 2023"
    const formatString = "LLL d, yyyy";
    // Format the date using date-fns
    const formattedDate = format(newDate, formatString, { locale: enUS });

    return formattedDate;
  }, []);

  const infoTableData = useCallback(
    (key: string) => {
      switch (key) {
        case "market_cap":
          return marketCap;
        case "enterprise_value":
          return enterpriseValue;
        case "total_shares_outstanding":
          return sharesOutstanding;
        case "price_revenue":
          return price;
        case "price_book":
          return priceToBook;
        case "price_earning":
          return priceToEarning;
        case "net_debt":
          return netDebt;
        case "total_debt":
          return totalDebt;
        case "total_assets":
          return totalAssets;
        case "dividend_yield":
          return dividendYield;
        case "price_to_earn":
          return priceToEarning;
        default:
          return 0;
      }
    },
    [
      dividendYield,
      enterpriseValue,
      marketCap,
      netDebt,
      price,
      priceToBook,
      priceToEarning,
      sharesOutstanding,
      totalAssets,
      totalDebt,
    ]
  );

  const infoData = useCallback(
    (key: string, operation: string) => {
      switch (operation) {
        case "convertToCurrency":
          return infoTableData(key)
            ? convertToCurrencySystem(infoTableData(key))
            : "--";
        case "formatCurrency":
          return formatCurrency(infoTableData(key), 2);
        case "numberWithComma":
          return NumberWithCommas(infoTableData(key));
      }
    },
    [NumberWithCommas, formatCurrency, infoTableData]
  );

  const renderOverViewTable = useMemo(
    () =>
      AssetsInfo.map(({ header_label, key, data }) => (
        <React.Fragment key={key}>
          <div className={`${styles.cellHead}`}>{header_label}</div>
          <div className={`${styles.cellWrapper}`}>
            {data.map(({ key: dataKey, label, operation }) => (
              <React.Fragment key={dataKey}>
                {showCell(
                  label,
                  activeSymbol === "" || assestLoaderState.IsLoading ? (
                    <Skeleton />
                  ) : (
                    infoData(dataKey, operation)
                  )
                )}
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      )),
    [activeSymbol, assestLoaderState.IsLoading, infoData]
  );

  const renderNews = useMemo(
    () => (
      <div className={`${styles.overViewwrapper}`}>
        {assestLoaderState.IsLoading ? (
          <div className={styles.loaderContainer}>
            <Loader className="loader-grey" dimension={60} />
          </div>
        ) : (
          news?.length > 0 &&
          news.map((item) => (
            <React.Fragment key={item.title}>
              <div className={`${styles.newsWrapper}`}>
                <div
                  className={styles.pic}
                  onClick={() => window.open(item.url)}
                >
                  <img
                    className={styles.pic}
                    srcSet={isImageError ? errorImg : item.image}
                    src={isImageError ? errorImg : item.image}
                    alt="BitCloud"
                    onError={() => setIsImageError(true)}
                  />
                </div>

                <div className={`${styles.newsDetailsWrapper}`}>
                  <p className={`${styles.newsdescription}`}>{item.title}</p>
                  <p className={`${styles.newSubHeading}`}>{item.text}</p>
                  <div className={`${styles.flexbox}`}>
                    <div className={`${styles.cardTitle}`}>
                      {formatDate(item.publishedDate)}
                    </div>
                    <div className={`${styles.dot}`}></div>
                    <div className={`${styles.symbol}`}>{item.symbol}</div>
                  </div>
                  <button
                    onClick={() => window.open(item.url)}
                    className={`${styles.readmore}`}
                  >
                    Read more
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))
        )}

        {news?.length === 0 && (
          <div className={styles.emptyState}>
            <img
              src={darkMode.value ? noDataDark : noDataLight}
              alt=""
              height={collapse ? 96 : 60}
            />
          </div>
        )}
      </div>
    ),
    [
      assestLoaderState.IsLoading,
      collapse,
      darkMode.value,
      formatDate,
      isImageError,
      news,
    ]
  );

  return (
    <div className={styles.orders}>
      <div className={styles.head}>{renderNavigation}</div>
      {activeTab.key === "Overview" && (
        <div className={`${styles.overViewwrapper}`}>
          {isMusic ? (
            <MusicOverview />
          ) : (
            <>
              <div className={`${styles.heading}`}>{symbol}</div>
              <div className={`${styles.subHeading}`}>{companyName}</div>
              {showMore ? (
                <div className={`${styles.description}`}>{description}</div>
              ) : (
                <div className={`${styles.showLess}`}>{description}</div>
              )}
              {description?.length > 0 && (
                <button
                  onClick={() => setShowMore(!showMore)}
                  className={`${styles.readmore}`}
                >
                  {showMore ? "show less" : "show more"}
                </button>
              )}
              {renderOverViewTable}
            </>
          )}
        </div>
      )}
      {activeTab.key === "News" && renderNews}
    </div>
  );
};
export default AssetDetailNonPrivate;
