import NewModal from "@components/new-modal/new-modal";
import { showUserEditModalState, TriggerUserApi } from "@states/user";
import { Compliance } from "@views/exchange/components/compliance";
import { useCallback } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";


export const EditUserDetailsModal = () => {
     const [showUserEditModal, setShowUserEditModal] = useRecoilState(
        showUserEditModalState
      );
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);

       const handleAfterEdit = useCallback(() => {
          setTriggerUserApi((prev) => !prev);
          setShowUserEditModal("");
        }, []);
  return (
    <NewModal
      isOpen={!!showUserEditModal}
      closeModal={() => setShowUserEditModal("")}
      modalName={
        showUserEditModal === "email"
          ? "Update email address"
          : "Update profile name"
      }
      title={
        showUserEditModal === "email"
          ? "Update email address"
          : "Update profile name"
      }
      isStopOutsideClick={false}
      closeOnEscBtn={false}
      className="Modal__wrapper exchange-modal-wrapper update-email-address-modal"
    >
      <Compliance
        isEditingUserDetails
        showUserEditModal={showUserEditModal}
        handleSuccess={handleAfterEdit}
        handleClose={() => setShowUserEditModal("")}
        disabledData={{
          firstName: true,
          lastName: true,
          email: showUserEditModal !== "email",
          accountName: showUserEditModal !== "accountName",
        }}
      />
    </NewModal>
  );
};
