import { userPersonalDetails } from "@states/user";
import "./wireNotification.scss";
import { imageConfig } from "@utils/imageConfig";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";
import { UseTransaction } from "@views/MyWallet/store/hooks";

interface NotificationModalProps {
  onClose: () => void;
  amount: number;
  confirmationId: string;
}

export const NotificationModal: React.FC<NotificationModalProps> = ({
  onClose,
  amount,
  confirmationId,
}) => {
  const userDetails = useRecoilValue(userPersonalDetails);
  const { getTransactions, getFortressAccountInfo } = UseTransaction();
  const {
    svg: { Time, LiteTime },
  } = imageConfig;
  const darkMode = useDarkMode(false);

  const handleClose = () => {
    getTransactions(); 
    getFortressAccountInfo(); 
    onClose(); 
  };

  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

  return (
    <div className="modal-overlay" onClick={() => {}}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <i className="ri-close-line modal__close" onClick={handleClose}></i>

        <img
          src={darkMode.value ? Time : LiteTime}
          alt="Notification Icon"
          className="modal__icon"
        />

        <div className="modal__content">
          <div className="modal__title">
            A notification has been created of your intent to transfer{" "}
            <span className="modal__amount">{formattedAmount}</span> via wire
            deposit into Account {userDetails?.customerId}
          </div>

          <div className="modal__confirmation">
            Request Confirmation: {confirmationId}
          </div>

          <div className="modal__note">
            <span className="modal__note-icon">
              <i className="ri-lightbulb-fill ri-xl"></i>
            </span>
            <div className="modal__note-text">
              Note: You are only notifying us of your intent to send money to
              your account.
            </div>
          </div>

          <button className="modal__button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
