import { ITransactionsType } from "../type";

import { APIS } from "constant";
import { useCookie, useNetwork } from "hooks";
import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { AllTransactionsData, FortressAccountInfo } from "../state";
import { Json } from "../../../../types/common";
import { getJsonToParams } from "@utils/common";

export const UseTransaction = () => {
  const { get: getAllTransactions, loading: transactionLoader } = useNetwork();
  const { get: getAccountInfo } = useNetwork();
  const setAllTransaction = useSetRecoilState(AllTransactionsData);
  const setFortressAccountInfo = useSetRecoilState(FortressAccountInfo);
    const { get: getCookieData } = useCookie();
  
        const cookieData = useMemo(() => {
          return getCookieData("userDetails");
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 

  const getTransactions = useCallback((query: Json = {}, options?: any, setOrdersData?: any): Promise<void> => {
    return new Promise((resolve) => {
  
      setAllTransaction((prev: any) => ({
        ...prev,
        data: !options?.updateNewData ? [] : prev.data,
        loading: true
      }));
  
      getAllTransactions(APIS.TRANSACTION + getJsonToParams(query), { signal: options?.signal })
        .then((res: Json) => {
          if (res?.data?.length > 0) {
            if (!options?.updateNewData) {
              setAllTransaction({
                loading: false,
                data: [...res?.data],
                offset: query.offset,
                limit: query.limit,
              });
            } else {
              setAllTransaction((prev: any) => ({
                ...prev,
                loading: false,
                data: [...prev?.data, ...res?.data],
                offset: query.offset,
                limit: query.limit,
              }));
            }
          } 
          resolve();
        }).finally(()=>{
          setAllTransaction((prev:ITransactionsType) => ({
            ...prev,
            loading: false,
          }));
        })
    });
  }, [getAllTransactions, setAllTransaction]);

  const getFortressAccountInfo = useCallback(() => {
    if(!cookieData?.token){
      return;
    }
    getAccountInfo(`${APIS.ACCOUNTINFO}`).then((res) => {
      if (res) {
        setFortressAccountInfo(res?.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieData?.token]);

  return {
    getTransactions,
    transactionLoader,
    getFortressAccountInfo,
  };
};
