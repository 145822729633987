export const NAVIGATION = [
  {
    title: "My Profile",
    icon: "ri-user-3-line",
    activeIcon: "ri-user-3-fill",
    url: "/profile",
    type: "profile",
  },
  {
    title: "Co-owner/Authorised User",
    icon: "ri-parent-line",
    activeIcon: "ri-parent-fill",
    url: "/profile/authorise-users",
    type: "authorisedUser",
  },
  // {
  //   title: "My Wallet",
  //   icon: "ri-wallet-3-fill",
  //   activeIcon: "ri-wallet-3-fill",
  //   url: "/profile/wallet",
  //   type: "wallet",
  // },
  {
    title: "Linked Devices",
    icon: "ri-qr-scan-2-line",
    activeIcon: "ri-qr-scan-2-fill",
    url: "/profile/linked-devices",
    type: "linkedDevices",
  },
];

export const COOWNER_NAVIGATION = [
  // {
  //   title: "My Wallet",
  //   icon: "ri-wallet-3-fill",
  //   activeIcon: "ri-wallet-3-fill",
  //   url: "/profile/wallet",
  //   type: "wallet",
  // },
];
