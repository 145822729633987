import { FC, useState } from "react";
import { Image } from "@storybook";
import { Loader } from "components";
import "./social-login-options.scss";
import { useLogin } from "@views/SignIn/hooks/login";
// @ts-ignore
import { Json } from "@types/common";
import useDarkMode from "use-dark-mode";

interface ISocialLoginOptions {
  options?: Json[]; // Array of options to display, each option should have `key` and `image` properties
  handleSelect?: (e: any) => any; //functino of option select
  customDimensions?: {
    width: string;
    height: string;
    logoWidth: string;
    logoHeight: string;
    optionsPostion?: string;
    optionWrapperMarginBlock?: string;
  };
}

export const SocialLoginOptions: FC<ISocialLoginOptions> = ({ options = [], customDimensions }) => {
  const { loginWithSocial } = useLogin();
  const darkMode = useDarkMode(false);
  const [loader, setLoader] = useState({ key: "", loading: false });

  const handleClick = async (social: Json) => {
    if (loader.loading) return;
    setLoader({ key: social?.key, loading: true });
    if (social?.url) {
      (window as any).location.href = social?.url;
      return;
    }
    const payload = {
      type: social?.key,
      state: {
        device: "web",
        host: window.location.origin.includes("localhost") ? "https://exchange.stage.satschel.com" : window.location.origin,
        app: "liquidity"
      }
    }
    const resData = await loginWithSocial(payload);
    if (resData?.[0]?.url) {
      window.location.href = resData?.[0]?.url;
    }else{
      setLoader({ key: social?.key, loading: false });
    }
  
  };

  return (
    <div style={{ marginBlock : customDimensions?.optionWrapperMarginBlock ?? "28px 16px"}} className="social-login-options-wrapper">
      <div style={{ justifyContent : customDimensions?.optionsPostion ?? "center"}} className="social-login-options-wrapper__options">
        {/* Map through the options and render each one */}
        {options.map(
          (social: Json) =>
            !social.hide && (
              <div
                style={{
                  ...(customDimensions?.width && {
                    width: customDimensions.width,
                  }),
                  ...(customDimensions?.height && {
                    height: customDimensions.height,
                  }),
                  background: darkMode?.value
                    ? social.darkBackgroundColour
                    : social.lightBackgroundColour,
                }}
                key={social.key} // Use the option's key as the unique key for each item
                className={`social-login-options-wrapper__options__item ${
                  social.disabled || loader.loading
                    ? "social-login-options-wrapper__options__item--disabled" // Add disabled class if loading
                    : ""
                }`}
                onClick={() =>
                  !social.disabled && !loader.loading ? handleClick(social) : {}
                } // Handle click event if not loading
              >
                {loader.key === social.key && loader.loading ? (
                  <Loader className="loader-blue" dimension={20} /> // Show loader if this option is loading
                ) : (
                  <Image
                    width={customDimensions?.logoWidth ?? "24"}
                    height={customDimensions?.logoHeight ?? "24"}
                    fileName={
                      darkMode?.value
                        ? social.darkModeImage
                        : social.lightModeImage
                    }
                  />
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};
