import { ISearchedData } from "./types";

import { ChangeEvent, useCallback, useMemo, useState, useEffect, useRef } from "react";
import useDarkMode from "use-dark-mode";
import cn from "classnames";

import { Icon, SkeletonSearch } from "components";
import { APIS } from "constant";
import { useNetwork, usePrevious } from "hooks";
import { debounce, getJsonToParams } from "utils";
import { SearchedRow } from "./componets/searchRow";
import { Image } from "@storybook";

import styles from "./CurrencySearch.module.sass";
import { searchTab } from "@views/exchange/constants";
// @ts-ignore
import { Json } from "@types/common";
import { imageConfig } from "@utils/imageConfig";

export const CurrencySearch = () => {
  const [searchedData, setSearchedData] = useState<ISearchedData[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [isLoadingData, setLoadingData] = useState(false);
  const [activeTab, setActiveTab] = useState({
    key: searchTab[0],
    index: 0,
  });
  const prevGlobalSearch = usePrevious(globalSearch);
  const searchApiSignalRef = useRef<any>(null);

  const {
    get: searchGlobal,
    loading: searching,
    isLoaded: isSearched,
  } = useNetwork();

  const darkMode = useDarkMode();

  useEffect(() => {
    if (prevGlobalSearch && !globalSearch) {
      setActiveTab({ key: searchTab[0], index: 0 });
    }
  }, [globalSearch]);

  useEffect(() => {
    if (!searching && isSearched) {
      setLoadingData(false);
    } else {
      setLoadingData(true);
    }
  }, [isSearched, searching]);

  const handleSearchOperation = useCallback(
    (query?: Json) => {
      if (searchApiSignalRef.current) {
        searchApiSignalRef.current.abort();
      }
      searchApiSignalRef.current = new AbortController();
      const { signal } = searchApiSignalRef.current;
      const queryParams = { asset: query?.globalSearch?.trim(), tab: (query?.activeTab?.key)?.toUpperCase() };
      if (query?.globalSearch?.trim().length !== 0) {
        setLoadingData(true);
        searchGlobal(`${APIS.Search}` + getJsonToParams(queryParams), { signal }).then(
          (res) => {
            if (res?.data) {
              setSearchedData(res?.data);
              setLoadingData(false);
            }
          }
        );
      } else {
        setSearchedData([]);
        setLoadingData(false);
      }
    },
    [activeTab?.key]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounceSearch = useCallback(debounce(handleSearchOperation, 800), []);

  const handleSearchDebounce = useCallback(debounce((value: Json) => {
    handleSearchOperation(value)
  }, 500), [])

  useEffect(() => {
    handleSearchDebounce({ activeTab, globalSearch })
  }, [activeTab, globalSearch]);

  const handleClickClose = useCallback((value: string) => {
    if (value === "close") {
      setGlobalSearch("");
    }
  }, []);

  const renderForm = useMemo(() => {
    return (
      <div className={styles.form}>
        <input
          className={styles.input}
          type="text"
          name="Global"
          placeholder="Search"
          value={globalSearch}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLoadingData(true);
            setGlobalSearch(e.target.value);
          }}
          autoComplete="off"
        />
        <button
          onClick={() => handleClickClose(globalSearch && "close")}
          className={`${styles.result} ${globalSearch && styles.closeBtn}`}
        >
          <Icon name={globalSearch ? "close" : "search"} size="20" />
        </button>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalSearch]);

  const renderSearchedData = useMemo(() => {
    if (searchedData) {
      return searchedData.map(
        (
          {
            symbol,
            name,
            type,
            image,
            id,
            imageFrom,
            imageTo,
            issuerDetails,

          },
          index
        ) => (
          <SearchedRow
            symbol={symbol}
            issuerDetails={issuerDetails}
            name={name}
            type={type}
            index={index}
            image={image}
            id={id}
            imageFrom={imageFrom}
            imageTo={imageTo}
          />
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedData]);

  const handleClickNavigation = useCallback(
    (key: string, index: number) => {
      setActiveTab({ key, index });

    },
    []
  );

  const serachTabs = useMemo(() => {
    return (
      <div className={`${styles.nav} currency__nav`}>
        {searchTab.map((key: any, index: number) => (
          <button
            className={cn(
              styles.link,
              {
                [styles.active]: index === activeTab.index,
              }
            )}
            onClick={() => handleClickNavigation(key, index)}
            key={index}
          >
            {key}
          </button>
        ))}
      </div>
    );
  }, [activeTab.index]);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;
 
  return (
    <div className={styles.currencySearch}>
      {renderForm}
      {globalSearch && (
        <>
          {serachTabs}
          {isLoadingData ? (
            <div className={styles.loader}>
              <SkeletonSearch listsToRender={6} />
            </div>
          ) : (
            isSearched &&
            (searchedData.length !== 0 ? (
              <div className={styles.searchData}> {renderSearchedData}</div>
            ) : (
              <div className={styles.noData}>
                <div className={styles.noDataContent}>
                  <Image
                    fileName={darkMode.value ? no_data_dark : no_data_light}
                  />

                  <div>No Result for "{globalSearch}"</div>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </div>
  );
};
