import { useCallback, useEffect, useMemo, useState } from "react";
import useDarkMode from "use-dark-mode";

import { useNotification } from "@hooks/notification";
import { Loader } from "@components/Loader2";

import { FINIX_CARD_ENV_TYPE } from "envs";
import {
  BinInformation,
  FormState,
  TokenizationResponse,
} from "./card-details-types";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { UseTransaction } from "@views/MyWallet/store/hooks";

declare global {
  interface Window {
    Finix: {
      getSessionKey: () => string | null;
      Auth: (
        environment: "sandbox" | "production" | "live",
        applicationId: string,
        callback: (sessionKey: string) => void
      ) => void;
      CardTokenForm: (
        elementId: string,
        options: any
      ) => {
        submit: (
          environment: string,
          applicationId: string,
          callback: (
            err: Error | null,
            res: TokenizationResponse | null
          ) => void
        ) => void;
      };
    };
    Auth: (
      environment: "sandbox" | "production" | "live",
      applicationId: string,
      callback: (sessionKey: string) => void
    ) => void;
  }
}

interface IProps {
  handleCloseDeposit?: (arg0?: boolean) => void;
  amount: string;
}
export const CardDetailsScreen = ({ handleCloseDeposit, amount }: IProps) => {
  const { errorNotification, successNotification } = useNotification();
  const { getTransactions, getFortressAccountInfo } = UseTransaction();
  const darkMode = useDarkMode(true);

  const [form, setForm] = useState<ReturnType<
    typeof window.Finix.CardTokenForm
  > | null>(null);
  const [formHasErrors, setFormHasErrors] = useState(true);
  const [isformLoaded, setFormLoaded] = useState(true);
  const [loading, setLoading] = useState(false);
  const { post } = useNetwork();
  const [applicationId, setApplicationId] = useState("");

  const theme = useMemo(() => {
    return darkMode.value ? "dark" : "light";
  }, [darkMode]);

  useEffect(() => {
    const initiatePayment = async () => {
      try {
        const payload = { mode: "cardSession" };
        const resp = await post(APIS.PAYMENT_INITIATION, payload);
        setApplicationId(resp?.application ?? "");
      } catch (error) {
        console.error("Payment initiation failed:", error);
      }
    };

    initiatePayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if CardTokenForm is a valid function
    if (typeof window?.Finix?.CardTokenForm !== "function") {
      return;
    }
    setFormLoaded(true);
    const finixForm = window?.Finix?.CardTokenForm("finix-form", {
      showAddress: false,
      success: {
        color: "#5cb85c",
      },
      errorMessages: {
        name: "Enter a valid name.",
        expiration_date: "Enter a valid expiration date.",
        security_code: "Enter a valid CVV.",
        number: "Enter a valid card number.",
      },
      placeholders: {
        number: "Card Number (16 digits) ",
      },
      styles: {
        default: {
          color: theme === "dark" ? "#ffffff" : "#000",
          border: `1px solid 
            ${
              theme === "dark"
                ? "rgba(255, 255, 255, 0.0588235294)"
                : "rgba(0, 0, 0, 0.1215686275)"
            }`,
          borderRadius: "4px",
          padding: "8px 16px",
          height: "44px",
          fontSize: "16px",
          background:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.031372549)"
              : "rgba(0, 0, 0, 0.031372549)",
          marginBottom: "8px",
        },
      },
      onUpdate: function (
        _state: FormState,
        _binInformation: BinInformation,
        formHasErrors: boolean
      ) {
        setFormHasErrors(formHasErrors);
      },
      onLoad: function () {
        setFormLoaded(false);
      },
    });

    setForm(finixForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePayment = useCallback(
    async (amount: string, third_party_token: string) => {
      const payload = {
        mode: "card",
        transactionType: "deposit",
        amount: Number(amount),
        third_party_token: third_party_token,
      };
      const resp = await post(APIS.PAYMENT_INITIATION, payload);
      if (resp?.transactionId) {
        handleCloseDeposit?.(true);
        successNotification(
          "Your money will be successfully deposited into your account shortly."
      );
            getTransactions();
            getFortressAccountInfo();
            handleCloseDeposit?.(true);
      } else {
        errorNotification("Something went wrong !");
        handleCloseDeposit?.();
      }
    },
    [post]
  );

  const renderLoader = useMemo(() => {
    return (
      isformLoaded && (
        <div className="card-details__loader--wrapper">
          <Loader className="loader-blue" />
        </div>
      )
    );
  }, [isformLoaded]);

  const isPaybuttonDisable = useMemo(() => {
    return formHasErrors;
  }, [formHasErrors]);

  const handleSubmit = useCallback(() => {
    if (!form) return;
    setLoading(true);
    form.submit(
      FINIX_CARD_ENV_TYPE || "",
      applicationId,
      function (err: Error | null, res: TokenizationResponse | null) {
        if (err) {
          errorNotification("Something went wrong. Please try again.");
          setLoading(false);
        } else if (res) {
          const token = res.data.id;
          handlePayment(amount, token);
        }
      }
    );
  }, [form, applicationId, errorNotification, handlePayment, amount]);

  return (
    <div className="card-details__wrapper">
      {renderLoader}
      <div className="sdk-screen__form" id="finix-form">
        {/* Finix SDK will attach the form here */}
      </div>
      <div
        className={`wallet-modal-footer card-details__footer ${
          isformLoaded ? "card-details__footer--loading" : ""
        }`}
      >
        <button
          className="btn-secondary"
          onClick={() => handleCloseDeposit?.()}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          disabled={isPaybuttonDisable || loading}
          className={`btn-primary`}
          onClick={() => handleSubmit()}
        >
          {loading ? <Loader dimension={24} /> : "Proceed"}
        </button>
      </div>
    </div>
  );
};
