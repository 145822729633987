import React, { useCallback, useMemo, useState } from "react";
import "./wireDepositForm.scss";

import { CommaSeparatedInput } from "@storybook/comma-separated-input/comma-seprated-input";
import { useRecoilState } from "recoil";
import { progressStepState } from "@states/wallet";
import { WireTransferInfoCard } from "../WireTransferInfoCard";
import { NotificationModal } from "../wireNotification";
import classNames from "classnames";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import { Loader } from "@components/Loader2";

interface WireTransferState {
  amount: string;
  bankName: string;
  accountNumber: string;
  nickname: string;
  errors: {
    amount?: string;
    bankName?: string;
    accountNumber?: string;
    nickname?: string;
  };
}

interface WireDepositFormProps {
  onClose: () => void; // This prop will handle closing the modal from the parent component
}

export const WireDepositForm: React.FC<WireDepositFormProps> = ({
  onClose,
}) => {
  const [state, setState] = useState<WireTransferState>({
    amount: "0.0",
    bankName: "",
    accountNumber: "",
    nickname: "",
    errors: {},
  });

  const [depositAmount, setDepositAmount] = useState("");
  const [currentStep, setCurrentStep] = useRecoilState(progressStepState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState<string | null>(null);
  const [showWireInfo, setShowWireInfo] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { post: WireDepositData, loading } = useNetwork();
  const { errorNotification } = useNotification();
  const [confirmationId, setConfirmationId] = useState<string>("");

  const handleNextStep = () => {
    if (!showWireInfo) {
      if (!validateForm()) {
        return;
      }
      setCurrentStep(2);
      setShowWireInfo(true);
    } else {
      const payload = {
        amount: Number(state.amount),
        bankName: state.bankName,
        accountNumber: state.accountNumber,
        accountNickName: state.nickname,
      };
     
       // Call API to submit the wire deposit data
      WireDepositData(`${APIS.Wire_Deposit}`, payload, {
        apiResponse: true,
      }).then((res) => {
        setConfirmationId(res?.data?.confirmationId);
        // Handle success response
        if (res?.data?.success) {
          setCurrentStep(3);
          setShowNotificationModal(true);
        } else {
          errorNotification(res?.message);
        }
      });
    }
  };

  const handleBack = () => {
    if (showNotificationModal) {
      setShowNotificationModal(false);
      setCurrentStep(1);
      setShowWireInfo(false);
    } else if (showWireInfo) {
      setCurrentStep(1);
      setShowWireInfo(false);
    } else {
      onClose();
    }
  };

  const handleClose = () => {
    setCurrentStep(1);
    onClose();
  };

  const validateForm = (): boolean => {
    const errors: WireTransferState["errors"] = {};
    let { amount, bankName, accountNumber, nickname } = state;

    // Amount validation - must be greater than 0
    if (!amount || Number.parseFloat(amount) <= 0) {
      errors.amount = "Deposit amount is required";
    }

    // Bank name validation - min 2, max 50 characters
    if (!bankName || !/^[a-zA-Z0-9\s&.\-']{2,50}$/.test(bankName)) {
      errors.bankName =
        "This field is required";
    }

    // Clean account number - remove spaces and special characters
    accountNumber = accountNumber.replace(/\D/g, "");

    // Account number validation - Only check if the user types something
    if (!accountNumber.length || !/^\d{6,20}$/.test(accountNumber)) {
      errors.accountNumber =
        "Enter a valid account number (6-20 digits, number only)";
    }

    // Nickname validation - min 2, max 50 characters
    if (!nickname) {
      errors.nickname = "This field is required"; // Error for empty nickname
    } else if (!/^[A-Za-z0-9&.'-\s]{2,50}$/.test(nickname)) {
      errors.nickname = "Enter a valid nickname (2-50 characters, using letters, numbers, spaces, & . ' -)"; // Error for invalid format
    }

    setState((prevState) => ({
      ...prevState,
      errors,
      accountNumber, // Save the cleaned account number in state
    }));

    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/[^0-9.]/g, "");
      setDepositAmount(value);

      const errors: WireTransferState["errors"] = {};

      // Amount validation - must be greater than 0
      if (!value || Number.parseFloat(value) <= 0) {
        errors.amount = "Please enter a valid amount";
      }

      setState((prevState) => ({
        ...prevState,
        amount: value,
        errors,
      }));
    },
    []
  );

  const { errors } = state;

  const banks = useMemo(
    () => [
      {
        id: "bank1",
        nickname: "Account Nickname",
        name: "Sending Bank/Institution name",
        accountNumber: "1234",
      },
      {
        id: "bank2",
        nickname: "Personal BOA Checking",
        name: "Bank of America",
        accountNumber: "5678",
      },
    ],
    []
  );

  const renderDropdown = useMemo(() => {
    if (banks.length === 0) return null;

    // Set default selected bank to the first bank if none is selected
    if (!selectedBank && banks.length > 0) {
      setSelectedBank(banks[0].nickname); // Set the first bank's nickname as the default
    }

    const selectedBankDetails = banks.find(
      (bank) => bank.nickname === selectedBank
    );

    return (
      <>
        <div
          className="dropdown"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div>
            {selectedBankDetails ? (
              <>
                <strong>{selectedBankDetails.nickname}</strong>
                <p>{selectedBankDetails.name}</p>
                <span>•••• {selectedBankDetails.accountNumber}</span>
              </>
            ) : (
              "Select a bank"
            )}
          </div>
          <span className="arrow">
            <i
              className={`ri-3x ${
                isDropdownOpen
                  ? "ri-arrow-drop-up-line"
                  : "ri-arrow-drop-down-line"
              }`}
            ></i>
          </span>
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            {banks.map((bank) => (
              <div key={bank.id} className="dropdown-item">
                <input
                  type="radio"
                  name="bank"
                  id={bank.id}
                  checked={selectedBank === bank.nickname}
                  onChange={() => setSelectedBank(bank.nickname)}
                  className="dropdown-radio"
                />
                <label htmlFor={bank.id} className="dropdown-label">
                  <strong>{bank.nickname}</strong>
                  <p>{bank.name}</p>
                  <span>•••• {bank.accountNumber}</span>
                </label>
              </div>
            ))}
            <div
              className="dropdown-item"
              onClick={() => setSelectedBank("New Bank")}
            >
              <input
                type="radio"
                name="bank"
                checked={selectedBank === "New Bank"}
                onChange={() => setSelectedBank("New Bank")}
                id="bank-new"
                className="dropdown-radio"
              />
              <label htmlFor="bank-new" className="dropdown-label">
                <strong>Use New Bank/Institution</strong>
                <p>Provide details of sending bank/institution</p>
              </label>
            </div>
          </div>
        )}
      </>
    );
  }, [banks, isDropdownOpen, selectedBank]);

  const renderBankDetails = useMemo(() => {
    if (selectedBank !== "New Bank") {
      return (
        <>
          <div className="form-group">
            <label>Sending Bank/Institution (Required)</label>
            <input
              type="text"
              value={state.bankName}
              onChange={(e) => {
                let value = e.target.value;
                value = value.replace(/[^a-zA-Z0-9&,. \-']/g, "");
                if (value.length > 50) return;
                setState((prevState) => ({
                  ...prevState,
                  bankName: value,
                  errors: { ...prevState.errors, bankName: "" },
                }));
              }}
              className={errors.bankName ? "error" : ""}
              placeholder="Bank/Institution Name"
            />
            {errors.bankName && (
              <span className="error-message">{errors.bankName}</span>
            )}
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Account Number (Required)</label>
              <input
                type="text"
                value={state.accountNumber}
                onChange={(e) => {
                  let value = e.target.value.replace(/\D/g, ""); // Allow only numbers

                  if (value.length > 20) return;

                  setState((prevState) => ({
                    ...prevState,
                    accountNumber: value,
                    errors: { ...prevState.errors, accountNumber: "" }, // Clear error when typing
                  }));
                }}
                className={errors.accountNumber ? "error" : ""}
                placeholder="1234567890"
              />
              {errors.accountNumber && (
                <span className="error-message">{errors.accountNumber}</span>
              )}
            </div>

            <div className="form-group">
              <label>Account Nickname (Required)</label>
              <input
                type="text"
                value={state.nickname}
                onChange={(e) => {
                  let value = e.target.value;
                  value = value.replace(/[^a-zA-Z0-9&,. \-']/g, "");
                  if (value.length > 50) return;
                  setState((prevState) => ({
                    ...prevState,
                    nickname: value,
                    errors: { ...prevState.errors, nickname: "" },
                  }));
                }}
                className={errors.nickname ? "error" : ""}
                placeholder="Nickname"
              />
              {errors.nickname && (
                <span className="error-message">{errors.nickname}</span>
              )}
            </div>
          </div>
        </>
      );
    }
    return null;
  }, [selectedBank, state, errors]);

  return (
    <div className="wire-transfer-modal">
      <div className={classNames("modal-content", { "modal-conten-hide": showNotificationModal })}>
        {!showWireInfo ? (
          <>
            <div className="wire-transfer-container">
              <i className="ri-close-line close-button" onClick={onClose}></i>
              <div className="wire-transfer-modal__wire-title">
                Provide bank/institution details from which the wire transfer
                will be sent.
              </div>
              <div className="wire-transfer-modal__wire-subtitle">
                Please provide these detail this will help us match your deposit
                notification to the actual funds.
              </div>
            </div>
            <div style={{ padding: "16px 24px" }}>
              <div className={`withdrawAmount ${errors.amount ? "withdrawAmount--error" : ""}`}>
                <div className="withdrawAmount__amountContainer">
                  <span className="withdrawAmount__dollarSign">$</span>{" "}
                  <CommaSeparatedInput
                    type="text"
                    onChange={handleOnChange}
                    value={depositAmount}
                    placeholder="0.00"
                    autoComplete="off"
                    className="withdrawAmount__amountInput"
                    required
                    name="deposit"
                    autoFocus={true}
                    style={{
                      width: `${depositAmount?.toString()?.length + 1}ch`,
                      minWidth: "4ch",
                    }}
                  />
                </div>
                <div className="withdrawAmount__amounttodeposit">
                  {"Deposit Amount (Required)"}
                </div>
              </div>
              {errors.amount && (
                <span className="error-message">{errors.amount}</span>
              )}
              {/* This code might be used later, so it has been commented out for now. */}
              {/* <div className="bank-selection">
        <label>Select Sending Bank/Institution</label>
        {renderDropdown}
      </div> */}
              {renderBankDetails}
            </div>
          </>
        ) : (
          <WireTransferInfoCard  
          onClose={handleClose} 
          confirmationId={confirmationId}
          accountNickName={state?.nickname}/>
        )}
        <div className="footer-wired">
          <div className="progress-bar">
            <div className="progress-line"></div>
            <div
              className="progress-fill"
              style={{
                width: `${((currentStep - 1) / 2) * 100}%`,
              }}
            ></div>
            {["Sending Institution", "Wire Instructions", "Success"].map(
              (label, index) => {
                const stepNumber = index + 1;
                return (
                  <div
                    key={stepNumber}
                    className={`step ${
                      stepNumber < currentStep
                        ? "completedStep"
                        : stepNumber === currentStep
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="step-number">{stepNumber}</div>
                    <span>{label}</span>
                  </div>
                );
              }
            )}
          </div>

          <div className="btn-group">
            <button
              type="button"
              className="btn-group__cancel-button"
              onClick={handleBack}
            >
              {showWireInfo ? "Back" : "Cancel"}
            </button>
            <button
              type="button"
              className={`btn-group__submit-button ${showWireInfo ? "btn-min-width" : ""}`}
              onClick={handleNextStep}
              disabled={loading}
            >
              {loading ? (
                <Loader className="loader-blue" dimension={20} />
              ) : showWireInfo ? (
                "Proceed"
              ) : (
                "Get Wire Instructions"
              )}
            </button>
          </div>
        </div>
      </div>
      {showNotificationModal && (
        <NotificationModal
          onClose={handleClose}
          amount={Number(state.amount)}
          confirmationId={confirmationId}
        />
      )}
    </div>
  );
};
