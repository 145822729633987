import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useDarkMode from "use-dark-mode";
import { useRecoilState } from "recoil";

import { useNetwork } from "hooks";
import { ReactModal, SkeletonCard } from "components";
import { Image } from "@storybook";
import { AddCard } from "./add-card";
import { APIS } from "constant";
import { CardState } from "states";

import styles from "./AccountBalances.module.sass";
import { imageConfig } from "@utils/imageConfig";

const items = [
  {
    title: "Saved Cards",
    btnText: "Add New",
    type: "card",
  },
];

export const Cards = () => {
  const [addCard, setAddCard] = useState(false);
  const [showOption, setShowOption] = useState<boolean>(false);

  const [cards, setCards] = useRecoilState(CardState);

  const darkMode = useDarkMode(false);

  const { get: getCards, loading, data: cardsList } = useNetwork();

  useEffect(() => {
    if (cards.length === 0) getCards(APIS.Cards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cardsList?.data) {
      setCards(cardsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsList]);

  const cardImage = useCallback((brand: string) => {
    switch (brand) {
      case "Visa":
        return "visa-card.svg";
      case "MasterCard":
        return "master-card.svg";
      case "Discover":
        return "discover-card.svg";
      case "MaestroCard":
        return "maestro-card.svg";
      case "American Express":
        return "american-express-card.svg";
      default:
        return "default-card.svg";
    }
  }, []);

  const maskDots = useCallback((listsToRender: number) => {
    return Array(listsToRender)
      .fill(1)
      .map((index) => <span className={styles.maskDots}></span>);
  }, []);

  const handleAdd = useCallback(() => {
    setAddCard(true);
  }, []);

  const handleOptions = useCallback((e: any) => {
    e.stopPropagation();
    setShowOption((prev) => !prev);
  }, []);

  const handleDelete = useCallback((id: string) => {}, []);

  const renderCard = useMemo(() => {
    return (
      <>
        {loading ? (
          <SkeletonCard listsToRender={3} />
        ) : (
          <div>
            {cards.map((card) => (
              <div className={styles.accountDetail}>
                <div className={styles.accountName}>
                  <Image
                    fileName={`cards/${cardImage(card.brand)}`}
                    className="payment-save-card-list__card-icon"
                  />
                  <div>
                    <div className={styles.cardNo}>
                      <span className={styles.dots}>{maskDots(4)}</span>
                      {"  "}
                      <span className={styles.dots}>{maskDots(4)}</span>
                      {"  "}
                      <span className={styles.dots}>{maskDots(4)}</span>
                      {"  "}
                      {card.last4}{" "}
                    </div>
                    <div className={styles.cardExpire}>
                      Exp.{" "}
                      {card?.exp_month?.toString()?.length === 1
                        ? `0${card?.exp_month}`
                        : card?.exp_month}
                      /{card?.exp_year}{" "}
                    </div>
                  </div>
                </div>
                <div
                  className={styles.accountMore}
                  // onClick={() => handleDelete(card.id)}
                >
                  {/* <i className="ri-delete-bin-line" /> */}

                  {showOption && (
                    <div className={styles.optionBox}>
                      <button
                        onClick={() => handleOptions}
                        className="menu-item"
                      >
                        <i className="ri-delete-bin-line" />
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }, [cardImage, cards, handleOptions, loading, showOption, maskDots]);

  const { svg: {empty_img, empty_img_light}} = imageConfig;

  return (
    <>
      <div className={styles.list}>
        <>
          {cards.length || loading ? (
            <>
              {items.map((item, index) => (
                <div className={cn(styles.item)} key={index}>
                  <div className={styles.head}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.details}>
                      <button
                        className={cn(
                          "button",
                          styles.buttonAaddAccount,
                          styles.buttons
                        )}
                        onClick={() => handleAdd()}
                        disabled={true}
                      >
                        {item.btnText}
                      </button>
                    </div>
                  </div>
                  <div className={styles.body}>{renderCard}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.emptyState}>
              {/* <img src={darkMode.value ? noDataDark : noDataLight} alt="" /> */}
              {
                <Image
                  className={styles.brand_logo}
                  fileName={darkMode.value ? empty_img : empty_img_light}
                />
              }
              <div className={styles.heading}>Nothing Here</div>
              <div className={styles.noDataText}>Add bank account to trade</div>
              <button
                className={cn(
                  "button",
                  styles.buttonAaddAccountEmpty,
                  styles.disable
                )}
                onClick={handleAdd}
                disabled={true}
              >
                Add New Card
              </button>
            </div>
          )}
        </>
      </div>

      <ReactModal
        outerClassName={styles.addCard}
        visible={addCard}
        onClose={() => setAddCard(false)}
        closeBtn={true}
      >
        <AddCard />
      </ReactModal>
    </>
  );
};
