import React, { useMemo } from "react";
import "./layout.scss"
import { Header } from "@components/Header";
import { useCookie } from "@hooks/cookie";
import { Sidebar } from "@views/admin/sidebar";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { AdminHeader } from "@views/admin/components";
import { ADMIN_URLS_NAME, API_DESIGNER_URLS } from "@routes/constants";
import { useRecoilValue } from "recoil";
import { userPersonalDetails } from "@states/user";

interface ILayOut {
  children?: any;
  isShowHeader ?:boolean
}

export const Layout = ({ isShowHeader, children }: ILayOut) => {
  const location = useLocation();
  const navigator = useNavigate();
  const { get } = useCookie();
  const userDetails = get("userDetails") ?? {};
  const { isAdmin } = useRecoilValue(userPersonalDetails);

  const adminRouteName = useMemo(() => {
    let path = location.pathname;
    if (/admin*/.test(path)) {
      const keys = Object.keys(ADMIN_URLS_NAME);
      path = keys?.find((k) => path.includes(k)) || "";
      return ADMIN_URLS_NAME[path] || "Admin"
    }
    return ""
  }, [location.pathname]);

  const handleApiDesignerNavigation = (id: string, url: string) => {
    if (id) {
      document.getElementById(id)?.click();
      navigator(url, { replace: true });
    }
  };

  const activeTab = useMemo(() => {
    if (location?.pathname.includes(API_DESIGNER_URLS.documentation)) {
      return "Documentation";
    } else if (location?.pathname.includes(API_DESIGNER_URLS.home)) {
      return "Home";
    }
  }, [location?.pathname])

  if (adminRouteName && isAdmin)
    return (<div className="layout-admin">
      <Sidebar />
      <div className="admin-right-side">
        <AdminHeader title={adminRouteName}>
          {adminRouteName === "API Designer" ? <div className="api-designer-header-navigator">
          <span
            className={`nav ${activeTab === "Home" ? "active" : ""}`}
            onClick={() => handleApiDesignerNavigation("adf-home", API_DESIGNER_URLS.home)}>
            Home
          </span>
            <span
              className={`nav ${activeTab === "Documentation" ? "active" : ""}`}
              onClick={() => handleApiDesignerNavigation("adf-documentations", API_DESIGNER_URLS.documentation)}>
            Documentation
          </span>
          </div> : undefined}
        </AdminHeader>
        {children || <Outlet />}
      </div>
    </div>);
  else
    return (<>
      {(!!userDetails?.token || isShowHeader) && <Header />}
      {children || <Outlet />}
    </>)
};
