import { atom } from "recoil";

export type ILoginRoutes =
  | "phoneNumber"
  | "chooseMethod"
  | "otpVerify"
  | "mobilelinkVerify"
  | "biometriclinkVerify"
  | "newUserOtpVerify";
export type IAuthType = "signup" | "login";

export const LoginRoutesState = atom<ILoginRoutes>({
  key: "login-route-state",
  default: "phoneNumber",
});

export const AuthTypeState = atom<IAuthType>({
  key: "auth-type-state",
  default: "signup",
});

export const ApprovalStatusState = atom<string>({
  key: "approval-status-state-key",
  default: "",
});

export const OptionsResponseState = atom<any>({
  key: "options-response-state-key",
  default: null,
});

export const tenantSecretToken = atom<any>({
  key: "tenant-secret-token",
  default: null,
});
