export const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })
}

type IPixelCrop = {
  height: number
  width: number
  x: number
  y: number
}

type ICropOptions = {
  imageName: string,
  imageType: string
  cropSize: { width: number, height: number },
}

export const getCroppedImage = async (imageSrc: string, pixelCrop: IPixelCrop, options: ICropOptions) => {
  const image: any = await createImage(imageSrc);
  const croppedCanvas = document.createElement('canvas')
  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = options?.cropSize?.width;
  croppedCanvas.height = options?.cropSize?.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    croppedCanvas.width,
    croppedCanvas.height,
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      const newFile = new File([file as BlobPart], options?.imageName, { type: options?.imageType })
      resolve({ croppedFile: newFile, blobUrl: URL.createObjectURL(file as Blob) })
    }, options?.imageType)
  });
}
