import React from "react";
import { imageConfig } from "@utils/imageConfig";

import "./splash-screen.scss";

export const SplashScreen = () => {
  const {svg: {Time}} = imageConfig;
  
  return (
    <div className="splash-screen-container">
      <img src={Time} alt="time" />
      <p>
        After reviewing your wire transfer details your transaction will settle
        in your wallet
      </p>
      <button>Close</button>
    </div>
  );
};
