import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  ActiveAssetTab,
  HorsesState,
  ActiveCurrencyState,
  initialQueryEstateState,
  initialQueryHorsesState,
  initialQueryPrivateState,
  PrivatesState,
  RealEstateState, SecondaryMortgagesState
} from "states";
import { SkeletonWatchList } from "components";
import { APIS } from "constant";
import { PrivateItem } from "./private-item";

import styles from "../Currency/Currency.module.sass";

import { useNetwork } from "hooks";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import InfiniteScroll from "react-infinite-scroll-component";
import { getJsonToParams, getKeyValueObjectFromArray } from "@utils/common";
import { assetTabs } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";
import { useParams } from "react-router-dom";

export const Private = () => {
  const activeTab = useRecoilValue(ActiveAssetTab);
  const isRealEstate = activeTab.key === assetTabs.REALESTATE
  const isHorses = activeTab.key === assetTabs.HORSES
  const isSM = activeTab.key === assetTabs.SM

  const [filters, setFilters] = useRecoilState(isRealEstate ? initialQueryEstateState : isHorses ? initialQueryHorsesState : initialQueryPrivateState);
  const [privates, setPrivates] = useRecoilState(PrivatesState);
  const [realEstate, setRealEstate] = useRecoilState(RealEstateState);
  const [secondaryMortgagesState, setSecondaryMortgagesState] = useRecoilState(SecondaryMortgagesState);
  const [horsesState, setHorsesState] = useRecoilState(HorsesState);
 const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const darkMode = useDarkMode(false);
  const { get: getPrivatesData, loading: loadingISPrivate } = useNetwork();
  const { get: getHorsesData, loading: loadingISHorses } = useNetwork();
  const { get: getRealEstateData, loading: loadingISRealEstate } = useNetwork();
  const { get: getSMData, loading: loadingISSM } = useNetwork();
  const { get: getPrivates } = useNetwork();
  const { get: getHorses } = useNetwork();
  const { get: getRealEstate } = useNetwork();
  const { get: getSM } = useNetwork();

  const refInterval = useRef<any>(null);
 const params = useParams();
  const mappedData = useMemo(() => {
    return {
      fetchMoreData: isRealEstate ? getRealEstateData : isHorses ? getHorsesData : isSM ? getSMData : getPrivatesData,
      loading: isRealEstate ? loadingISRealEstate : isHorses ? loadingISHorses : isSM ? loadingISSM : loadingISPrivate,
      setAssetData: isRealEstate ? setRealEstate : isHorses ? setHorsesState : isSM ? setSecondaryMortgagesState : setPrivates,
      assets: isRealEstate ? realEstate : isHorses ? horsesState : isSM ? secondaryMortgagesState : privates,
      intervalFetch: isRealEstate ? getRealEstate : isHorses ? getHorses : isSM ? getSM : getPrivates,
    }
  }, [getHorses, getHorsesData, getPrivates, getPrivatesData, getRealEstate, getRealEstateData, horsesState, isHorses, isRealEstate, loadingISHorses, loadingISPrivate, loadingISRealEstate, privates, realEstate, setHorsesState, setPrivates, setRealEstate, isSM, loadingISSM, secondaryMortgagesState])
  
 
  // This code is for exchange explorer api polling
  const NewObjectWithNewIdAdded = useCallback(
    (assets: any, currentArray: any) => {
      let newData = { ...assets };
      currentArray?.map((obj: any) => {
        if (!assets?.[obj?.id]) {
          newData = { [obj.id]: obj, ...newData };
        } else if (assets?.[obj?.id]) {
          newData[obj.id] = obj;
        }

        const id = params.id;
        if (activeCurrency?.id === obj?.id && activeCurrency?.symbol === id) {
          setActiveCurrency((prev) => ({
            ...prev,
            status: obj.status,
            symbol: obj.symbol,
            name: obj.name,
          }));
        }
      });
      return newData;
    },
    [activeCurrency, params]
  );

  useEffect(() => {
    if (isSM) return;
    refInterval.current = setInterval(() => {
      mappedData
        ?.intervalFetch(
          `${APIS.Explorers}?include=${
            isRealEstate
              ? "real_estate"
              : isHorses
              ? "horse_trade"
              : isSM
              ? "secondary_mortgages"
              : "privates"
          }`
        )
        .then((res: any) => {
          if (res?.message === "ok") {
            const newAsset = NewObjectWithNewIdAdded(
              mappedData.assets,
              isRealEstate
                ? res?.data?.real_estate || []
                : isHorses
                ? res?.data?.horse_trade
                : isSM
                ? res?.data?.secondary_mortgages
                : res?.data?.privates
            );
            if (newAsset) {
              mappedData?.setAssetData((prev) => {
                return { ...newAsset };
              });
            }
          }
        });
    }, 5000);
    return () => clearInterval(refInterval.current);
  }, [
    mappedData.assets,
    isRealEstate,
    isHorses,
    isSM,
    NewObjectWithNewIdAdded,
  ]);

  const handleFetchMore = useCallback(() => {
    const queryParams = { ...filters, offset: filters.offset + 1 };
    mappedData.fetchMoreData(`${APIS.Explorers}` + getJsonToParams(queryParams)).then((res: any) => {
      if (res) {
        mappedData?.setAssetData((prev) => {
          const newAssets = getKeyValueObjectFromArray("id", (isRealEstate ? res?.data?.real_estate || [] : isHorses ? res?.data?.horse_trade || [] : isSM ? res?.data?.secondary_mortgages || [] : res?.data?.privates))
          return {...prev, ...newAssets};
        });
      }
    });
    setFilters((prev) => ({ ...queryParams }));
  }, [filters, mappedData, setFilters, isRealEstate, isHorses, isSM]);

  const assets = useMemo(() => {
    return Object?.values?.(mappedData?.assets || {});
  }, [mappedData?.assets]);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;

  return (
    <>
      {assets?.length === 0 ? (
        <div className={styles.noData}>
          <div className={styles.noDataContent}>
            <Image fileName={darkMode.value ? no_data_dark : no_data_light} />
            <div>No Data Found</div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={assets?.length || 0}
          next={handleFetchMore}
          hasMore={
            assets?.length >= filters.offset * filters.limit + filters.limit
          }
          loader={""}
          scrollableTarget="assets-scrollableDiv"
          style={{ minHeight: "80px" }}
        >
          {assets?.map((data, index) => {
            return (
              <PrivateItem
                lastTradedPrice={0}
                amount={0}
                key={data?.id}
                index={index}
                {...(data ?? {})}
                type={isRealEstate ? "real_estate" : "privates"}
              />
            );
          })}
        </InfiniteScroll>
      )}
      {mappedData?.loading &&<SkeletonWatchList listsToRender={4} />}
    </>
  );
};
