// @ts-ignore
import { Json } from "@types/common";

export const items = [
  {
    title: "Open",
    icon: "clock",
    color: "#00C076",
    key: "openingPrice",
  },
  {
    title: "52W High",
    icon: "arrow-top",
    key: "high",
  },
  {
    title: "52W Low",
    icon: "arrow-bottom",
    key: "low",
  },

  {
    title: "1M Volume",
    icon: "chart",
    key: "tradedVolume",
  },
];

export const CRYPTO_ITEMS = [
  {
    title: "Open",
    icon: "clock",
    color: "#00C076",
    key: "openingPrice",
  },
  {
    title: "24H High",
    icon: "arrow-top",
    key: "high",
  },
  {
    title: "24H Low",
    icon: "arrow-bottom",
    key: "low",
  },

  {
    title: "Total Volume",
    icon: "chart",
    key: "total_volume",
  },
];

export const realEstateItems = [
  {
    title: "Total IRR",
    icon:"",
    key: "Total IRR",
  },
  {
    title: "Min. investment",
    icon:"",
    key: "Min. investment",
  },
  {
    title: "Property type",
    icon:"",
    key: "Property type",
  },

  {
    title: "Property class",
    icon:"",
    key: "Property class",
  },
];

export const itemsMusic = [
  {
    title: "Open",
    icon: "clock",
    color: "#00C076",
    key: "open",
  },
  {
    title: "24h high",
    icon: "arrow-top",
    key: "dayHigh",
  },
  {
    title: "24h low",
    icon: "arrow-bottom",
    key: "dayLow",
  },

  {
    title: "Prev. Close",
    icon: "clock",
    key: "previousClose",
  },
];

export const assetsTobeRemoved = [
  "stocks",
  "privates",
  "forex",
  "crypto",
  "commodities",
  "marketIndexes",
];

export const newWatchlistKey: any = {
  stocks: "stock",
  music: "music",
  crypto: "crypto",
  commodities: "commodity",
  privates: "privates",
  realEstate: "real_estate",
  horses: "horse_trade",
  forex: "forex",
  marketIndexes: "marketIndex",
  sba7: "sba7",
  SM: "secondary_mortgages",
};

export const LOAN_NAME: any = {
  "whole_loans" : "Whole Loans",
  "current_guaranteed_loan_balance": "Gov. Guaranteed Portions"
}

export const ACCREDITATION_TYPES: Json = {
  RETAIL: "Retail",
  B506: "506b",
  C506: "506c",
}

export const ACCREDITATION_LABEL: Json = {
  [ACCREDITATION_TYPES.B506]: true,
  [ACCREDITATION_TYPES.C506]: true,
}

export const ACCREDITATION_ALLOWED: Json = {
  [ACCREDITATION_TYPES.C506]: {
    [ACCREDITATION_TYPES.RETAIL]: true,
    [ACCREDITATION_TYPES.B506]: true,
    [ACCREDITATION_TYPES.C506]: true
  },
  [ACCREDITATION_TYPES.B506]: { [ACCREDITATION_TYPES.RETAIL]: true, [ACCREDITATION_TYPES.B506]: true },
  [ACCREDITATION_TYPES.RETAIL]: { [ACCREDITATION_TYPES.RETAIL]: true },
}

export const ACCREDITATION_TEXT : Json = {
  [ACCREDITATION_TYPES.RETAIL]: "(Non accredited/Retail)",
  [ACCREDITATION_TYPES.B506]: "506(b)",
  [ACCREDITATION_TYPES.C506]: "506(c)",
}

