import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { useCookie, useNetwork, useNotification, useWehAuthn } from "hooks";
import {
  LoginPhoneNumberState,
  LoginUserState,
  MobileVerificationState,
} from "states";
import { APIS, loginToasterMessages } from "constant";
import { ROUTES } from "routes";
import {
  ApprovalStatusState,
  OptionsResponseState,
} from "views/SignIn/stores";
import { Waiting } from "../waiting";
import style from "./choose-method.module.sass";
import {
  useMobileAuthentication,
  useBiometricAuthentication,
} from "@views/SignIn/hooks";
import { Loader } from "@components/Loader2";
import { Confirmation } from "@storybook";
import { ReactModal } from "@components/react-modal";
import { useLocation } from "@hooks/location";
import { Nosupported } from "@components/no-supported";
// @ts-ignore
import { useSwitchWorkSpace } from "@views/SignIn/hooks/useSwitchWorkSpace";
import { useHandleSignInFireBase } from "libs/signInwithTokenFirebase";




export const ChooseMethod = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const {
    verifyRegistration,
    authenticateOptions,
    registrationApproval,
    registrationOptions,
    verifyAuthentication,
  } = useBiometricAuthentication();
  const { registerNewCredential, authenticateDevice, getWebAuthnSupported } =
    useWehAuthn();

  const navigate = useNavigate();
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const setLoginUser = useSetRecoilState(LoginUserState);
  const { post } = useNetwork();
  const { errorNotification, successNotification } = useNotification();
  const { set: setCookieToken } = useCookie();
  const { EXCHANGE, SWITCH_ACCOUNT } = ROUTES;
  const { LOGIN_SUCCESS } = loginToasterMessages;
  const { post: sendOtp } = useNetwork();
  const setMovileVerificationState = useSetRecoilState(MobileVerificationState);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  const verficationStatus = useRecoilValue(ApprovalStatusState);
  const optionsResponse = useRecoilValue(OptionsResponseState);
  const resetOptionResponseState = useResetRecoilState(OptionsResponseState)
  const [isNotSupportedModalVisible, setIsNotSupportedModalVisible] =
    useState(false);
  const { generateVerificationLink, loading: mobileVerificationLinkLoading } =
    useMobileAuthentication();

  const verificationID = useRecoilValue(MobileVerificationState);
  const { locationInfo } = useLocation();
  const [ipAddress, setIPAddress] = useState("");
  const { fetchWorkSpaceUser } = useSwitchWorkSpace();
  const { handleSigninFireBase } = useHandleSignInFireBase();

  const { MOBILELINKSENT, OTPVERIFY, LOGIN, BIOMETRICAPPROVALSENT } = ROUTES

  useEffect(() => {
    fetch("https://api.ipify.org?format=json").then((response) => response.json()).then((data) => setIPAddress(data.ip)).catch((error) => console.log(error));
  }, []);

  const handleSuccess = useCallback(async () => {
    try {
      const payload = {
        countryCode: loginPhoneNumber?.countryCode ?? "+1",
        phone: loginPhoneNumber?.phone ?? "",
        data: { a: "a" },
      };
      const resp = await post(APIS.WebAuthLogin, payload);
      if (resp) {
        const { token } = resp;
        const loginDetails = {
          token,
          isLoggedIn: true,
        };
        if (token) {
          setCookieToken("userDetails", loginDetails);
          successNotification(LOGIN_SUCCESS);
          setLoginUser((prevState) => {
            const newState = {
              ...prevState,
              isLoggedIn: true,
              token,
            };
            return newState;
          });
          navigate(EXCHANGE);
        }
      }
    } catch {
      errorNotification("Something went wrong.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNotification, navigate, successNotification]);

  const handleFail = useCallback(() => {
    errorNotification("Authentication failed");
  }, [errorNotification]);

  const handleAuthenticationSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payload = {
          type: "verifyAuthentication",
          authenticateOptions: res.authenticateOptions ?? {},
          id: res.id ?? "",
        };
        const resp = await verifyAuthentication(payload);
        const { isAuthenticated, token } = resp ?? {};
        if (token && isAuthenticated) {
          loginUser(token, "Logged In Successfully");
          handleSigninFireBase(token)

        } else {
          setIsConfirmationOpen(true);
        }
        return;
      }
    },
    [verifyAuthentication]
  );

  const openWebAuthenticationModal = useCallback(() => {
    setIsConfirmationOpen(true);
  }, [isConfirmationOpen]);

  const handleWebAuth = useCallback(async () => {
    if (isbiometricLoading) return;
    if (getWebAuthnSupported()) {
      setIsbiometricLoading(true);

      const { countryCode, phone } = loginPhoneNumber;
      let payloadForAuthenticateCredentials = {};

      payloadForAuthenticateCredentials = {
        type: "authenticateOpts",
        countryCode,
        phone,
      };

      const resAuthenticateCredentials = await authenticateOptions(
        payloadForAuthenticateCredentials
      );

      const { id } = resAuthenticateCredentials ?? {};

      if (id) {
        authenticateDevice(
          handleAuthenticationSuccess,
          resAuthenticateCredentials ?? {},
          openWebAuthenticationModal
        );
      } else {
        openWebAuthenticationModal()
      }
      setIsbiometricLoading(false);
    } else {
      // open modal that web auth not supported
      setIsNotSupportedModalVisible(true);
    }
  }, [authenticateDevice, authenticateOptions, getWebAuthnSupported, handleAuthenticationSuccess, isbiometricLoading, loginPhoneNumber, openWebAuthenticationModal]);

  const handleMobileApp = useCallback(async () => {
    const { countryCode, phone } = loginPhoneNumber;
    const verificationId = await generateVerificationLink({
      type: "phone",
      countryCode,
      phone,
    });
    if (verificationId) {
      setMovileVerificationState(verificationId);
      navigate(MOBILELINKSENT);
    }
  }, []);

  const handleOtp = useCallback(() => {
    const { countryCode, phone } = loginPhoneNumber;
    navigate(OTPVERIFY);
    sendOtp(APIS.PhoneCodes, {
      phone,
      countryCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginPhoneNumber, sendOtp]);

  const handleGoBack = useCallback(() => {
    navigate(LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verficationStatus === "approved") {
      startRegisterDevice();
    }
  }, [verficationStatus]);

  const startRegisterDevice = useCallback(async () => {
    if (verficationStatus === "approved") {
      // if user already approved mobile link
      await registrationOptions({ authenticationType: "login" });
    } else {
      const { countryCode, phone } = loginPhoneNumber;

      const approvalPayload = {
        type: "registrationApproval",
        phone,
        countryCode,
        deviceInfo: navigator.userAgent ?? {},
      };
      const response = await registrationApproval(approvalPayload);
      if (response) {
        const { message, success, verificationId } = response;
        if (success && verificationId) {
          navigate(BIOMETRICAPPROVALSENT);
          successNotification(message);
          return;
        }
        errorNotification(message);
        return;
      }
    }
  }, [BIOMETRICAPPROVALSENT, errorNotification, loginPhoneNumber, navigate, registrationApproval, registrationOptions, successNotification, verficationStatus]);

  const handleRegistrastionSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payloadSaveCredential = {
          type: "verifyRegistration",
          registrationOptResponse: res,
          id: optionsResponse?.id,
          verificationId: verificationID,
          deviceInfo: {
            location: locationInfo,
            ip: ipAddress,
          },
        };
        const resp = await verifyRegistration(payloadSaveCredential);
        const { isRegistered, token } = resp ?? {};

        if (isRegistered) {
          if (token) {
            resetOptionResponseState();
            loginUser(token, "Device registered and logged in successfully.");
            handleSigninFireBase(token);
          }
        } else {
          errorNotification("Login failed");
        }
      }
    },
    [
      optionsResponse?.id,
      verificationID,
      locationInfo,
      verifyRegistration,
      successNotification,
    ]
  );

  const renderContent = () => {
    if (!loginPhoneNumber.phone) {
      navigate(LOGIN);
      return 'Redirecting...';
    }
    return loginPhoneNumber.phone;
  };

  const loginUser = async (token: string, message: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      const res = await fetchWorkSpaceUser();
      if (res?.message !== "ok") return;
      if (res?.data?.length > 1) {
        navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
        return;
      }

      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      successNotification(message);
      navigate(EXCHANGE);
    }
  };

  useEffect(() => {
    if (
      verficationStatus === "approved" &&
      optionsResponse?.registrationOptions
    ) {
      registerNewCredential(
        handleRegistrastionSuccess,
        optionsResponse?.registrationOptions ?? {}
      );
    }
    // eslint-disable-next-line
  }, [optionsResponse]);

  const handalConfimation = useCallback(
    async (isOpen: boolean, value: boolean) => {
      setIsConfirmationOpen(isOpen);
      if (value) {
        await startRegisterDevice();
      }
    },
    [setIsConfirmationOpen, startRegisterDevice]
  );

  const renderItem = useMemo(() => {
    return (
      <>
        {isWaiting ? (
          <Waiting setIsWaiting={setIsWaiting} />
        ) : (
          <div className={style.wrapper}>
            <div className={style.inner_wrapper}>
              <div className={style.inner}>
                <div>
                  <div className={style.title}>Choose Method</div>
                  <div className={style.sub_title}>
                    Select the method through which you want to sign in.
                  </div>
                </div>
                <div className={style.edit_text}>
                  We will send you a confirmation code on:{" "}
                  {loginPhoneNumber.countryCode} {renderContent()}
                </div>
                <div className={style.list_item} onClick={handleWebAuth}>
                  <div className={style.left_icon}>
                    <i className="ri-profile-line"></i>
                  </div>
                  <div className={style.method_title}>Device Biometric</div>
                  <div className={style.right_icon}>
                    {isbiometricLoading ? (
                      <Loader className="loader-blue" dimension={30} />
                    ) : (
                      <i className="ri-arrow-right-s-line"></i>
                    )}
                  </div>
                </div>
                <div className={style.lists}>
                  <div className={style.list_item} onClick={handleMobileApp}>
                    <div className={style.left_icon}>
                      <i className="ri-smartphone-line"></i>
                    </div>
                    <div className={style.method_title}>
                      Approval From Mobile
                    </div>
                    <div className={style.right_icon}>
                      {mobileVerificationLinkLoading ? (
                        <Loader className="loader-blue" dimension={30} />
                      ) : (
                        <i className="ri-arrow-right-s-line"></i>
                      )}
                    </div>
                  </div>


                  <div className={style.list_item} onClick={handleOtp}>
                    <div className={style.left_icon}>
                      <i className="ri-message-line"></i>
                    </div>
                    <div className={style.method_title}>
                      Mobile Text: "One-time password"
                    </div>
                    <div className={style.right_icon}>
                      <i className="ri-arrow-right-s-line"></i>
                    </div>
                  </div>

                  <div className={style.back_btn} onClick={handleGoBack}>
                    <div className={style.method_title}>Back</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }, [
    handleGoBack,
    handleMobileApp,
    handleOtp,
    handleWebAuth,
    isWaiting,
    isbiometricLoading,
    loginPhoneNumber.countryCode,
    loginPhoneNumber.phone,
    mobileVerificationLinkLoading,
  ]);

  const renderConfirmationModal = useMemo(() => {
    return (
      <ReactModal
        visible={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
      >
        <Confirmation
          title={"Look like your browser is not Register Passkey!"}
          visible={isConfirmationOpen}
          description={
            "Please register this browser for WebAuthn before using device biometric login."
          }
          handleModal={() => handalConfimation(false, true)}
          handleClose={() => setIsConfirmationOpen(false)}
          boldDescription=""
          label="Register Passkey"
          cancelLabel="Close"
          type="buy"
          confirmationHeaderStyle={{ borderRadius: "20px" }}
        />
      </ReactModal>
    );
  }, [isConfirmationOpen, handalConfimation]);

  const renderNotSupportedModal = useMemo(() => {
    return (
      <ReactModal
        visible={isNotSupportedModalVisible}
        onClose={() => setIsNotSupportedModalVisible(false)}
      >
        <Nosupported
          title={"Look like your browser doesn’t support WebAuthn"}
          visible={isNotSupportedModalVisible}
          description={
            "Your browser does not support WebAuthn. Please try other available method."
          }
          handleModal={() => setIsNotSupportedModalVisible(false)}
          boldDescription=""
          label={"Close"}
          type="Secondary"
        />
      </ReactModal>
    );
  }, [isNotSupportedModalVisible]);

  return (
    <>
      {renderItem}
      <div className="position-top">
        {renderNotSupportedModal}
        {renderConfirmationModal}
      </div>
    </>
  );
};
export default ChooseMethod
