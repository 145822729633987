import { FC } from "react";

import { Image } from "@storybook/image";
import { imageConfig } from "@utils/imageConfig";

// import useDarkMode from "use-dark-mode";

interface ITableEmptyMessage {
  height: string;
}

export const TableEmptyMessage: FC<ITableEmptyMessage> = ({ height }) => {
  // const darkmode = useDarkMode();
  const {svg: {empty_table}} = imageConfig;
  // const imgSrc = darkmode.value ? "dark" : "light";
  return (
    <div className="empty-table" style={{ height }}>
      <Image fileName={empty_table} className="empty-table__image" />
      <div className="empty-table__message">!No Data Available</div>
    </div>
  );
};
