import React, { useCallback, useState } from "react";

import { Header } from "../../../components";
import { Image } from "@storybook";

import { useSearchParams } from "react-router-dom";
import { USER_BID_LIST_TABS } from "../constant";
import { UserBidList, UserWishListAuctions } from "../components";

export const UserBidAuctions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "myAuction"
  );

  const handleChangeTab = useCallback((key: string) => {
    setActiveTab(key);
    setSearchParams({ tab: key })
  }, [searchParams]);

  return (
    <>
      <div className="auction-bid-list-container">
        <div className="auction-bid-list-tabs">
          {USER_BID_LIST_TABS.map((tab) => (
            <div key={tab.type}
                 className={`auction-bid-list-tabs_item ${activeTab === tab.type ? "active" : ""}`}
                 onClick={() => handleChangeTab(tab.type)}>
              {tab.title}
            </div>
          ))}
        </div>

        {activeTab === "myAuction" && <UserBidList />}
        {activeTab === "wishlist" && <UserWishListAuctions />}
        {activeTab === "buy" && <div className="auction-bid-buy-tab">
          <Image fileName="icon/emptyCart.svg" />
          <p>Coming Soon...</p>
        </div>}
      </div>
    </>
  );
};
export default UserBidAuctions;
