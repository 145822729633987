import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { Json } from "types";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { fileFormat } from "./constant";
import { APIS } from "constant";
import axios from "axios";
import { format } from "date-fns";

type TDataRoom = {
  dataRoomFilesRes: any;
  editId: string;
  editFolderid: string;
  dataRoomApi?: string;
  payloadForSignUrl?: Json;
};

export const useDataRoom = ({
  dataRoomFilesRes,
  editId,
  editFolderid,
  dataRoomApi,
  payloadForSignUrl
}: TDataRoom): any => {
  const [dataRoom, setDataRoom] = useState<any>({
    folderId: editFolderid || uuidv4(),
    files: [],
    deletedFiles: [],
    updatedFileNames: [],
    filesOrder: [],
    filesStatus: [],
  });
 
  const [fileDeletedId, setFileDeletedId] = useState<any>([]);
  let todoItemDrag: any = useRef();
  let todoItemDragOver: any = useRef();

  const { post: getSignedUrl } = useNetwork();
  const [dataRoomEditState, setDataRoomEditState] = useState({
    modalVisible: false,
    fileId: "",
    fileName: "",
  });

  const { errorNotification } = useNotification();
  const fileInputRef: any = useRef();

  useEffect(() => {
    if (editId && editFolderid && dataRoomFilesRes?.length) {
      setDataRoom((prev: any) => ({
        ...prev,
        files: [...dataRoomFilesRes],
      }));
    }
  }, [editId, dataRoomFilesRes, editFolderid]);

  const handleOnDrop = async (event: any) => {
    event.preventDefault();
    let IsFilesValid: boolean | undefined = true;
    const files = event.dataTransfer.files;
    IsFilesValid = checkIsFilesFormatValid(files);
    let fileData = getNotAlreadyExistedFile(files);
    if (IsFilesValid) {
      await uploadFile(fileData);
    } else {
      fileInputRef.current.value = null;
    }
  };

  const handleOnChange = async (event: any) => {
    //publishtnDisabled(true);
    const { files } = event.target;
    let IsFilesValid: boolean = checkIsFilesFormatValid(files);
    let fileData = getNotAlreadyExistedFile(files);
    if (IsFilesValid) {
      await uploadFile(fileData);
    } else {
      fileInputRef.current.value = null;
      // publishtnDisabled(false);
    }
  };

  const checkIsFilesFormatValid = (files: Json): boolean => {
    for (let index = 0; index < files.length; index++) {
      if (!fileFormat[files[index].type]) {
        errorNotification(`${files[index].name} is not a valid file format`);
        return false;
      }
    }

    return true;
  };

  const getNotAlreadyExistedFile = (files: Json[]) => {
    let file: Json = [];
    for (let index = 0; index < files.length; index++) {
      let findFile = dataRoomFilesFiltered.findIndex(
        (items: any) => items.fileName === files[index].name
      );
      if (findFile < 0) {
        file.push(files[index]);
      } else {
        errorNotification(`${files[index].name} already exist. Please check`);
      }
    }

    return file;
  };

  const getFilesList = (files: any) => {
    const fileList = [...files]?.map((file, index) => ({
      fileId: uuidv4(),
      fileName: file.name,
      fileSize: file.size,
      extension: file.type,
      downLoadingStatus: 0,
      isCancelled: false,
      abortController: null,
      isfileuploading: false,
      isSuccessfullyUploaded: false,
      loadingStatus: "uploading",
      status: "pending",
      file: file,
    }));

    return fileList;
  };

  const uploadFile = async (files: Json) => {
    const fileList = getFilesList(files);
    const { folderId } = dataRoom;
    const { gcpResponse, GcpFileStatus } = await uploadToGcp(fileList);
    if (!gcpResponse?.length) return

    fileInputRef.current.value = null;
    Promise.all(gcpResponse)
      .then((response) => {
        updateFileStatusToDb(GcpFileStatus, folderId);
      })
      .catch((err) => {
        errorNotification(err?.message);
      });
  };

  // function tp get signed url and upload it to GCP
  const uploadToGcp = async (fileList: any) => {
    const { folderId } = dataRoom;

    const signedurlPayload = {
      ...(payloadForSignUrl ? payloadForSignUrl : {}),
      folderId,
      files: fileList?.map(({ fileId, fileName, fileSize, extension }: any) => {
        return {
          fileId,
          fileName,
          fileSize,
          extension,
        };
      }),
    };

    const gcpSuuccessStatus = getGcpuploadStatus(signedurlPayload, fileList);
    return gcpSuuccessStatus;
  };

  const getGcpuploadStatus = async (signedurlPayload: Json, fileList: Json) => {
    let gcpResponse;
    const GcpFileStatus: any = [];
    try {
      const signedurlResponse = await getSignedUrl(
        dataRoomApi || APIS.AuctionDataRoom,
        signedurlPayload,
        { apiResponse: true }
      );
      if (signedurlResponse.message === "ok") {
        // GCP upload
        // based on the signedurl get file mapped by fileid
        const {
          data: signedUrlResponseFile,
        } = signedurlResponse;

        gcpResponse = await signedUrlResponseFile?.map(async (files: any) => {
          const controller = new AbortController();
          const signal = controller.signal;
          let found = fileList?.find((e: any) => e.fileId === files.fileId);

          // setDataRoomFiles((prev: any) => [...prev, found]);
          setDataRoom((prevState: any) => ({
            ...prevState,
            files: [
              ...prevState?.files,
              { ...found, abortController: controller, id: files.id },
            ],
          }));

          const res = axios
            .put(files.signedUrl, found?.file, {
              headers: {
                "Content-Type": found?.extension,
              },
              onUploadProgress: (progressEvent) => {
                if (
                  typeof progressEvent.total === "number" &&
                  !isNaN(progressEvent.total)
                ) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );

                  setDataRoom((prev: any) => ({
                    ...prev,
                    files: [
                      ...prev?.files?.map((item: any) => {
                        if (item.fileId === files.fileId) {
                          item.isfileuploading = true;
                          item.downLoadingStatus = percentCompleted;
                        }
                        return item;
                      }),
                    ],
                  }));
                }
              },
              signal: signal,
            })
            .then((response) => {
              const obj = {
                id: files.id,
                status: response.status === 200 ? "SUCCESS" : "FAILED",
                time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
              };

              GcpFileStatus.push(obj);
              setDataRoom((prevState: any) => ({
                ...prevState,
                filesStatus: [...prevState?.filesStatus, obj],
              }));
              setDataRoom((prev: any) => ({
                ...prev,
                files: [
                  ...prev?.files.map((item: any) => {
                    if (item.id === files.id) {
                      item.loadingStatus =
                        response?.status === 200 ? "completed" : "failed";
                      item.abortController = null;
                      item.isSuccessfullyUploaded =
                        response?.status === 200 ? true : false;
                      item.status =
                        response?.status === 200 ? "SUCCESS" : "FAILED";
                    }
                    return item;
                  }),
                ],
              }));
            })
            .catch((err) => {
              const obj = {
                id: files.id,
                status: "FAILED",
                time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
              };
              GcpFileStatus.push(obj);
              setDataRoom((prevState: any) => ({
                ...prevState,
                filesStatus: [...prevState.filesStatus, obj],
              }));
              errorNotification("File uploading cancelled");
              setDataRoom((prev: any) => ({
                ...prev,
                files: [
                  ...prev?.files?.map((item: any) => {
                    if (item.id === files.id) {
                      item.loadingStatus = "cancelled";
                      item.abortController = null;
                      item.isSuccessfullyUploaded = false;
                      item.status = "FAILED";
                    }
                    return item;
                  }),
                ],
              }));
            });

          return res;
        });
      } else {
        errorNotification(signedurlResponse?.message);
      }
    } catch (err: any) {
      errorNotification(err?.message);
    }

    return { gcpResponse, GcpFileStatus };
  };

  const updateFileStatusToDb = async (
    GcpFileStatus: Json[],
    folderId: string
  ) => {
    // publishtnDisabled(false);
    //setGcpFileUploadingStatus(GcpFileStatus);
    //updateFileFolderId(folderId);
  };

  const cancelFileUpload = (abortController: any, id: string, delay = 1000) => {
    abortController?.abort();
    removeCancelledFiles(id, delay);
  };

  const removeCancelledFiles = (id: string, delay = 1000) => {
    setTimeout(() => {
      setDataRoom((prev: any) => ({
        ...prev,
        files: [...prev.files.filter((files: Json) => files.id !== id)],
      }));
    }, delay);
  };

  const openEditDatafileModal = (name: string, id: string) => {
    setDataRoomEditState({
      modalVisible: true,
      fileId: id,
      fileName: name,
    });
  };

  const resetDataRoomModalState = () => {
    setDataRoomEditState({
      modalVisible: false,
      fileId: "",
      fileName: "",
    });
  };

  const updateDataRoomFile = (id: string, value: string) => {
    setDataRoom((prev: any) => ({
      ...prev,
      files: [
        ...prev?.files?.map((file: Json) => {
          if (file.id === dataRoomEditState.fileId) {
            file.fileName = value;
          }
          return file;
        }),
      ],
      updatedFileNames: !prev?.updatedFileNames.length
        ? [
            {
              id: id,
              fileName: value,
              time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
            },
          ]
        : [
            ...prev?.updatedFileNames,
            ...prev?.updatedFileNames?.map((file: Json) => {
              if (file.id !== dataRoomEditState.fileId) {
                let arr;
                arr = {
                  id: id,
                  fileName: value,
                  time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
                };
                return arr;
              } else {
                file.fileName = value;
                file.time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
              }
            }),
          ],
    }));

    // reset the file edit state
    resetDataRoomModalState();
  };

  const deleteFile = (id: string, name: string) => {
    setFileDeletedId((prev: any) => [...prev, id]);
    setDataRoom((prev: any) => ({
      ...prev,
      deletedFiles: [
        ...prev.deletedFiles,
        {
          id: id,
          time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
        },
      ],
    }));
  };

  const getStringAccordingCount = (count: number, str: string) => {
    if (count > 1) return count + " " + str + "s";
    return count + " " + str;
  };

  const onDragStart = (e: any, index: any): any => {
    todoItemDrag.current = index;
  };

  const onDragEnter = (e: any, index: any): any => {
    todoItemDragOver.current = index;
  };

  const onDragEnd = (e: any, index: any): any => {
    const arr1 = dataRoom?.files?.filter(
      (file: any) => !dataRoom.deletedFiles?.includes(file.id)
    );

    const data_room_main: any = arr1[todoItemDrag?.current];

    arr1.splice(todoItemDrag?.current, 1);
    arr1.splice(todoItemDragOver?.current, 0, data_room_main);

    todoItemDrag.current = null;
    todoItemDragOver.current = null;

    setDataRoom((prevState: any) => ({
      ...prevState,
      files: [...arr1],
    }));
    setFilesOrder();
  };

  useEffect(() => {
    if (fileDeletedId.length || dataRoom?.files.length) {
      setFilesOrder();
      removeFileFromSuccessStatus();
      removeFileFromUpdatedFileNames();
    }
  }, [fileDeletedId, dataRoom?.files]);

  const setFilesOrder = () => {
    const fileOrderArray: any = [];
    dataRoom?.files
      ?.filter((file: any) => !fileDeletedId?.includes(file.id))
      .forEach((item: any, index: number) => {
        const FileObject = {
          id: item.id ?? null,
          fileIndex: index + 1,
        };
        fileOrderArray.push(FileObject);
      });

    setDataRoom((prevState: any) => ({
      ...prevState,
      filesOrder: [...fileOrderArray],
    }));
  };

  const removeFileFromSuccessStatus = () => {
    const filteredFiles = dataRoom?.filesStatus?.filter(
      (file: any) => !fileDeletedId?.includes(file.id)
    );
    setDataRoom((prevState: any) => ({
      ...prevState,
      filesStatus: [...filteredFiles],
    }));
  };

  const removeFileFromUpdatedFileNames = () => {
    const filteredFiles = dataRoom?.updatedFileNames?.filter(
      (file: any) => !fileDeletedId?.includes(file.id)
    );

    setDataRoom((prevState: any) => ({
      ...prevState,
      updatedFileNames: [...filteredFiles],
    }));
  };

  const dataRoomFilesFiltered = useMemo(
    () =>
      dataRoom?.files?.filter(
        (file: any) =>
          !dataRoom?.deletedFiles?.find((files: any) => files.id === file.id)
      ),
    [dataRoom?.deletedFiles, dataRoom?.files]
  );

  return {
    handleOnDrop,
    handleOnChange,
    cancelFileUpload,
    openEditDatafileModal,
    updateDataRoomFile,
    deleteFile,
    getStringAccordingCount,
    onDragStart,
    onDragEnter,
    onDragEnd,
    dataRoomFilesFiltered,
    dataRoomEditState,
    fileInputRef,
    dataRoom,
    resetDataRoomModalState,
  };
};
