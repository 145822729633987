import { ReactNode, useEffect, useMemo, useState } from "react";
import styles from "./dvp-models.module.sass";
import { Button } from "@components/button";
import classNames from "classnames";
import { ReactModal } from "@components/react-modal";
// import { DataRoom } from "@components/dataRoom";
// import { Dataroom } from "@views/MyWallet/conponent/deposit/component/DataRoom.tsx";
import { Confirmation } from "@storybook";
import { SuccessIcon } from "@views/SignIn/components/status";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { DvpIframe } from "./dvpIframe";
import { useNotification } from "@hooks/notification";
import { useRecoilValue } from "recoil";
import { userPersonalDetails } from "@states/user";
import { DvpEmailVerify } from "./dvp-email-verify";

interface IDvpModal {
  openDvpModal: boolean;
  setOpenDvpModal: (value: boolean) => void;
}

export const DvpModals = ({ openDvpModal, setOpenDvpModal }: IDvpModal) => {
  const [dvpStep, setDvpSteps] = useState(1);
  const [dvpAmt, setDvpAmt] = useState("");
  const [url, seturl] = useState("");
  const { post: postDvpAmt, loading } = useNetwork();
  const { USER_DVP } = APIS;
  const isRequestAccepted = false;
  const { errorNotification } = useNotification();
  const personalData = useRecoilValue(userPersonalDetails);

  useEffect(() => {
    setDvpSteps(1);
  }, []);

  const nextDvpStep = () => {
    setDvpSteps((prev: any) => prev + 1)
  }

  const handleDvpInput = (e: any) => {
    const { value, pattern } = e?.target ?? {};
    const inputRegex = new RegExp(pattern)
    if (!inputRegex.test(value)) return;
    if (value <= 100000000) setDvpAmt(value);
  };

  const renderDvpNameModal = useMemo(
    () => (
      <>
        <div className={styles.DvpNameModal__top}>
          <div className={styles.DvpNameModal__top__heading}>
            DVP Limit Request
          </div>
          <div className={styles.DvpNameModal__top__subHeading}>
            Enter the desired limit and send a request to change your DVP limit.
          </div>
        </div>
        {isRequestAccepted && (
          <div className={styles.DvpNameModal__middle}>
            <div className={styles.currLimit}>
              <div className={styles.limitHeading}>Current DVP limit</div>
              <div className={styles.limitValue}>$1,00,000.00</div>
            </div>
          </div>
        )}
        <div className={styles.DvpNameModal__middle}>
          <p className={styles.DvpNameModal__middle__label}>DVP limit (USD)</p>
          <div className={styles.DvpNameModal__middle__input}>
            <input
              type="text"
              placeholder="0.00"
              value={dvpAmt}
              onChange={handleDvpInput}
              pattern="^(10000000000(\.\d{0,2})?|(\d{0,11})(\.\d{0,2})?)$"
              className={styles.DvpNameModal__middle__input__field}
            />
          </div>
        </div>
        {/* <div className={styles.DvpNameModal__middle}>
          <p className={styles.DvpNameModal__middle__label}>Upload documents</p>
          <div className={styles.DvpNameModal__middle__input}>
            <DataRoom dataRoomFilesRes={[]}
              allowFilesRearrange= {false}
              allowFilesRename= {false}
              editId={""}
              editFolderid={""}
              onSuccess={()=>{}}
              className={styles.dataRoom}/>
          </div>
        </div> */}
        <div className={styles.DvpNameModaBtnContainer}>
          <Button
            label="Cancel"
            buttonType="submit"
            handleClick={() => {
              setOpenDvpModal(false);
            }}
            type={classNames(styles.DvpNameModaBtnContainer__cancel)}
          />
          <Button
            label={isRequestAccepted ? "Send Request" : "Next"}
            disabled={!dvpAmt}
            handleClick={() => nextDvpStep()}
            type={classNames(styles.DvpNameModaBtnContainer__save)}
          />
        </div>
      </>
    ),
    [dvpAmt]
  );

  const handleSubmit = () => {
    if (dvpAmt) {
      const payload = { requestedLimit: dvpAmt };
      postDvpAmt(USER_DVP, payload, { apiResponse: true }).then((resp) => {
        if (resp?.data) {
          seturl(resp?.data);
          nextDvpStep();
        }
        else {
          setOpenDvpModal(false);
          errorNotification(resp?.message || "something went wrong.")
        }
      })
        .catch(() => {
          setOpenDvpModal(false);
        });
    }
  };

  const renderSuccessModal: any = useMemo(() => {
    return (
      <div className={styles.dvpSuccessModal}>
        <SuccessIcon />
        <div className={styles.heading}>
          DVP approval request has been successfully sent
        </div>
        <p className={styles.para}>
          We will review your request and notify you of the outcome within 15
          business days. You can track the status of your request in your
          profile section.
        </p>
      </div>
    );
  }, []);

  const getDvpStepItems = useMemo(() => {
    switch (dvpStep) {
      case 1:
        return renderDvpNameModal;
      case 2:
        return (
          <Confirmation
            title={"DVP Request"}
            visible={true}
            description={
              "To request for DVP, you need to sign a DVP terms and agreement. Do you want continue?"
            }
            handleModal={handleSubmit}
            handleClose={() => {
              setOpenDvpModal(false);
            }}
            boldDescription=""
            label="Continue"
            cancelLabel="Cancel"
            type="buy"
            cancelLoading={loading}
            confirmationHeaderStyle={{ borderRadius: "20px" }}
          />
        );
      case 3:
        return (
          <DvpIframe
            onSuccess={nextDvpStep}
            url={url}
          />
        );
      default:
        return renderSuccessModal;
    }
  }, [dvpStep, renderDvpNameModal, renderSuccessModal, setOpenDvpModal, url, loading])

  return (
    <ReactModal
      visible={openDvpModal}
      onClose={() => {
        setOpenDvpModal(false);
      }}
      closeBtn={true}
      maskClosable={false}
      outerClassName={classNames(styles.DvpNameModal, (dvpStep === 3 && styles.iframeModal), (!personalData?.isVerifiedEmail && styles.emailModal))}
    >
     {personalData?.isVerifiedEmail ? getDvpStepItems as ReactNode : <DvpEmailVerify />}
    </ReactModal>
  );
};

export default DvpModals;
