import { useEffect,  } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useNetwork } from "@hooks/network";
import { useCookie } from "@hooks/cookie";
import { APIS } from "constant";
import { Loader } from "components";
import { oauthService } from "./OAuthService";
import { useNotification } from "@hooks/notification";


import "./social-callback.scss";
import { useRecoilValue } from "recoil";
import { TennetTokenState } from "@states/user";
import { useLogin } from "@views/SignIn/hooks/login";
import { ROUTES } from "@routes/constants";


export const CallBackComponent = () => {

  const { post: loginViaSocialSignin } = useNetwork();
  const tennetValue = useRecoilValue(TennetTokenState)
  const {loginUser} = useLogin()
  const path = useParams();
  const navigate = useNavigate();
   
  const {errorNotification} = useNotification()
  const login = async () => {
    let idToken = ''
    if (path.type === 'onyxplus') {
      const code = new URLSearchParams(window.location.search).get('code');
      const resp = await oauthService.exchangeCodeForToken(code ?? "");
      idToken = resp?.access_token ?? ""
    } else {
     idToken =  new URLSearchParams(
        path.type === "google"
          ? window.location.hash.substring(1)
          : window.location.search
      ).get(path.type === "google" ? "id_token" : "access_token") ?? ""
  
    }
    const payload = {
      provider: path.type,
      token: idToken,
    };

    const resp = await loginViaSocialSignin(
      APIS.social_login,
      payload,
      undefined,
      {
        "tenant-authorization":tennetValue
      }
    );

    if (!resp.token) {
      navigate(ROUTES.LOGIN)
      errorNotification(resp.message || "Something went wrong")
    } else {
      loginUser(resp.token)
    }
  
  };
  useEffect(() => {
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="auth-loader">
      <div className="loader">
        <Loader className="loader-white" />
      </div>

      <p>We are authenticating you...</p>
    </div>
  );
};
