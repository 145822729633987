import { imageConfig } from "@utils/imageConfig";

import "./continueInMobile.scss";

const ContinueInMobile = () => {
  const {svg: {LiqLogo}} = imageConfig;

  return (
    <div className="ContinueInMobile">
      <div className="ContinueInMobile__logo">
        <img src={LiqLogo} alt="liquidity_logo" />
      </div>
      <div className="ContinueInMobile__article">
        <div className="ContinueInMobile__article__subheading">
          How would you like to continue with liquidity?
        </div>
        <a href="liquidity://">
          <button className="ContinueInMobile__article__continueMobile">
            Continue in mobile application
          </button>
        </a>
        {/* <button
          className="ContinueInMobile__article__continueWeb"
          onClick={handleContinueOnWeb}
        >
          Continue on this browser
        </button> */}
      </div>
    </div>
  );
};

export default ContinueInMobile;
