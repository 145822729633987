import React from "react";
import { imageConfig } from "@utils/imageConfig";

import "./Noasset.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const Noasset = () => {
  const {svg: {noAssetImage}} = imageConfig;
  const {config} = usePermissionContext();
  
  return (
    <div className="noasset-container">
      <img className="no-data" src={noAssetImage} alt="No Asset" />

      <div className="asset-details">
        <div className="text-url">Invalid asset URL</div>
        <div className="asset-url-invalid">
          The entered URL for the asset is invalid or does not exist. Please
          check the URL or contact our support for assistance.
        </div>
        <div className="contact-email">
        Contact : <a className="email-color" href={`https://mail.google.com/mail/?view=cm&fs=1&to=${config?.supportEmail}`} target="_blank" rel="noopener noreferrer">{config?.supportEmail}</a>
           
        </div>
      </div>
    </div>
  );
};
