import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useSetRecoilState } from "recoil";

import { Image } from "@storybook";
import { getLogos } from "../../../account/constants";
import { Loader } from "components";
import { useNetwork, useNotification } from "hooks";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { APIS } from "constant";
import { AccountState } from "states";

import styles from "./addAccount.module.sass";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { imageConfig } from "@utils/imageConfig";

export const AddAccount = () => {
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState("");

  const setAccounts = useSetRecoilState(AccountState);

  const { successNotification, errorNotification } = useNotification();

  const { get: getAccounts, data: accountsList } = useNetwork();
  const { post: generateToken, data: tokenResponse } = useNetwork();
  const { post: submitExchangeToken } = useNetwork();
  const { trackEvent } = useFullStoryTrackEvent();

  //sending institution name and public token to backend once user will cliked on Success screen
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      let payload = {
        bankName: metadata.institution?.name,
        linkToken: token,
        token: publicToken,
      };

      const response = await submitExchangeToken(APIS.TokenExchange, payload);
      if (response.id) {
        trackEvent("NEW_BANK_ADDED", {
          ...payload
        });
        successNotification("Account linked successfully");
      } else {
        trackEvent("NEW_BANK_ADD_ERROR", {
          ...response
        });
        errorNotification(response?.message ?? "Something went wrong");
      }
      setToken("");
      getAccounts(APIS.Accounts);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  //not required for now keep it for future use
  const onEvent = useCallback<PlaidLinkOnEvent>(() => {}, []);

  //not required for now keep it for future use
  const onExit = useCallback<PlaidLinkOnExit>(() => {
    setToken("");
  }, []);

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const { open } = usePlaidLink(config);
  const { logo } = imageConfig;

  useEffect(() => {
    if (tokenResponse?.token) {
      setToken(tokenResponse?.token);
      open();
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tokenResponse?.token]);

  useEffect(() => {
    if (accountsList?.data) {
      setAccounts(accountsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);

  const handleAdd = useCallback(() => {
    setLoader(true);
    const payload = {
      language: "en",
      countryCodes: ["US"],
    };
    trackEvent("ADD_NEW_BANK", {
      ...payload
    });
    generateToken(APIS.GenerateLinkToken, payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.addAccount}>
      <Image
        className={styles.bankLogo}
        fileName={logo["default"]}
      />
      <div className={styles.NoHead}>No bank account available</div>
      <button
        className={cn("button", styles.buttonAaddAccount, styles.button)}
        onClick={handleAdd}
      >
        {loader ? <Loader dimension={24} /> : "Add Account"}
      </button>
    </div>
  );
};
