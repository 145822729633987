import React from 'react'
import './contact-us.scss';
import { Image } from '@storybook'
import { imageConfig } from '@utils/imageConfig';
import { usePermissionContext } from '@hooks/authentication/permissionsContext';
import classNames from 'classnames';
import useDarkMode from 'use-dark-mode';
import { LoaderLine } from '@components/LoaderLine';

type Props = {}

export const ContactUs = (props: Props) => {
  const darkMode = useDarkMode(false);
  const {logo: {Liquid}} = imageConfig;
  const { config ,loading} = usePermissionContext()
  
  return (
    <div className="contacy-us-container">
      <div className="contact_header">
        <div className="container_logo">
         { loading ? <LoaderLine /> :<Image className={classNames("brand_logo", { "MlcLogo": config?.name !== "Liquidity" })} fileName={config?.name !== "Liquidity" ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.light : Liquid} />}
        </div>
        <div className="container_greeting">
          <h2>Contact Us</h2>
          <p>
            We're excited to connect with you! Whether you have inquiries, need
            assistance, or want to discuss potential collaborations, our team is
            here and ready to help.
          </p>
        </div>
      </div>

      <div className="container_info">
        <div className='info-panel'>
          <span className='info-icon'>
             <i className='ri-mail-line' />
          </span>
          <p className='info-type'>
            Email
          </p>
          <p className='info-link'>
            <a href={`mailto:${config?.supportEmail || ""}`}>
              {config?.supportEmail || ""}
            </a>
          </p>
        </div>


        <div className='info-panel'>
          <span className='info-icon'>
             <i className='ri-phone-line' />
          </span>
          <p className='info-type'>
           Call us
          </p>
          <p className='info-link'>
            +1 310-968-7900
          </p>
        </div>
      </div>
    </div>
  );
}