import { useState, useEffect, useCallback } from "react";
import { CommaSeparatedInput } from "@storybook/comma-separated-input/comma-seprated-input";
import { Loader } from "@components/Loader2";
import "../wireDepositForm/wireDepositForm.scss";

interface AmountScreenProps {
  defaultValue?: number;
  loading?: boolean;
  onAmountChange?: (value: string, isError: boolean) => void;
  methodType?: string;
}

export const AmountScreen = ({
  defaultValue = 0,
  loading = false,
  onAmountChange,
  methodType = "apple_pay",
}: AmountScreenProps) => {
  const [amount, setAmount] = useState<number>(defaultValue);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setAmount(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.replace(/,/g, "");
      const numericValue = Number(value);

      setAmount(numericValue);

      // Set the limit based on methodType directly
      const limit = methodType === "google_pay" ? 2000 : 10000;

      onAmountChange?.(value, numericValue > limit);

      if (numericValue > limit) {
        setError(
          `The maximum allowable amount for ${methodType.replace(
            "_",
            " "
          )} is $${limit} per transaction. Please enter a smaller amount.`
        );
      } else {
        setError("");
      }
    },
    [methodType, onAmountChange] // Dependency array
  );

  return (
    <div>
      {loading ? (
        <div className="amount-screen__loading--wraper">
          <Loader dimension={50} />
          <div className="amount-screen__loading-message">
            We are processing your deposit, and the funds will be credited to
            your account shortly.
          </div>
        </div>
      ) : (
        <div style={{ padding: "16px 24px" }}>
          <div
            className={`withdrawAmount ${error ? "withdrawAmount--error" : ""}`}
          >
            <div className="withdrawAmount__amountContainer">
              <span className="withdrawAmount__dollarSign">$</span>
              <CommaSeparatedInput
                type="text"
                onChange={handleChange}
                value={amount.toLocaleString()}
                placeholder="0.00"
                autoComplete="off"
                className="withdrawAmount__amountInput"
                required
                name="deposit"
                autoFocus={true}
                style={{
                  width: `${amount?.toString()?.length + 1}ch`,
                  minWidth: "4ch",
                }}
              />
            </div>
            <div className="withdrawAmount__amounttodeposit">
              {"Amount (Required)"}
            </div>
          </div>
          {error && <span className="error-message">{error}</span>}
        </div>
      )}
    </div>
  );
};
