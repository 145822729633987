import React from "react";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  tabName: string;
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ tabName, children }) => {
  const { hasRouteAccess} = usePermissionContext();
  
  // Redirect to Access Denied page if no access
  if (!hasRouteAccess(tabName)) {
    return <Navigate to="/access-denied" replace state={{ access: true }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
