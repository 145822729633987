export const AUCTION_TABLE_HEADER = [
  {
    label: "",
    key: "arrow",
    format: "jsx",
    width: "4%",
    className: "arrow",
  },
  {
    label: "All",
    key: "checkbox",
    format: "jsx",
    width: "5%",
    className: "fund_name",
  },
  {
    label: "Name",
    key: "auctionName",
    format: "string",
    className: "",
    width: "20%",
  },
  {
    label: "Issuer",
    key: "createdBy",
    format: "string",
    className: "",
    width: "15%",
  },
  {
    label: "Type",
    key: "supportingDoc",
    format: "jsx",
    width: "10%",
  },

  {
    label: "Date & Time",
    key: "startDateAndTime",
    format: "jsx",
    width: "15%",
  },
  {
    label: "Amount",
    key: "startPrice",
    format: "string",
    width: "10%",
  },
  {
    label: "Auction starts - End",
    key: "startDateAndTime",
    format: "jsx",
    width: "15%",
  },
  {
    label: "Type",
    key: "supportingDoc",
    format: "jsx",
    width: "16%",
  },
  {
    label: "",
    key: "actions",
    format: "jsx",
    width: "10%",
  },
];
