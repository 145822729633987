import { useMemo, useState } from "react";
import { useRecoilState } from "recoil";

import { ReactResponsiveTable } from "@storybook";
import { SkeletonSearch } from "components";
import { SbaLoanState } from "states";
import { DOCUMENTS_HEADER_SBA } from "../constant";
import { ReactModal } from "components";
import "./sbaloan.scss";
import { auctionDetails } from "./constant";
import { SBA7A } from "@views/exchange/constants";

export const SbaLoan = () => {
  const [AuctionData, setAuctionDataList] = useRecoilState(SbaLoanState);
  const [detailModal, setDetailModal] = useState(false);
  const { loading } = AuctionData;

  const tableRows = useMemo(() => {
    if (!AuctionData?.data) return [];
    const rows: any = [];
    AuctionData?.data?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        DOCUMENTS_HEADER_SBA.Header.forEach(({ format, key }) => {
          row.id = items.id;

          if (format === "jsx" && key === "action") {
            const value = () => (
              <button onClick={() => setDetailModal(true)}>
                {" "}
                View Details
              </button>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }
          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [AuctionData?.data]);

  return (
    <div className="sba-stats-container">
      <h2 className="auction-details-heading">{SBA7A} Loan pool</h2>
      {loading && (
        <SkeletonSearch className="data-room-container" listsToRender={2} />
      )}
      <ReactResponsiveTable
        className="sbaLoan__container-body"
        rows={tableRows}
        column={DOCUMENTS_HEADER_SBA.Header}
        isLoading={loading}
        numberOfList={10}
      />
      <ReactModal
        outerClassName="sba-loan-modal"
        visible={detailModal}
        onClose={() => setDetailModal(false)}
        closeBtn={true}
      >
        <div className="sba-detail-modal">
          <h2>LION TECHNOLOGIES, LLC</h2>
          <div className="detail-items">
            {Object.keys(auctionDetails as any).map((key, index): any => {
              return (
                <div className="detail-item">
                  <p>{(auctionDetails as any)[key]}</p>
                  <h3>4196379100</h3>
                </div>
              );
            })}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
