import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import { useNetwork, useNotification } from "hooks";
import { ActiveCurrencyState } from "states";
import { newWatchlistKey } from "../../Main";
import { APIS } from "constant";
import { Json } from "types/common";
import { assestType, PROVIDER } from "@views/exchange/constants";

export const useCurrencyHook = () => {
  //globle states
  const setActiveCurrency = useSetRecoilState(ActiveCurrencyState);
  const { get: getAssetData } = useNetwork();

  //hooks
  const { get: getSummaries, loading: loadingAsset } = useNetwork();
  const { errorNotification } = useNotification();

  const openSearchAsset = useCallback(
    async (
      id: string,
      symbol: string,
      name: string,
      type: string,
      image: string,
      imageFrom: string,
      imageTo: string,
    ) => {
      const { privates, music } = newWatchlistKey;
      let endPoint = "";
      if (type === privates) {
        if (!id) return errorNotification("Not Found");
        const resp = await getAssetData(`${APIS.ExchangeAssets}/${id}`);
        if (resp?.data)
          setActiveCurrency({
            ...resp.data,
            marketPrice: resp.data?.amount,
            type: privates,
          });
        localStorage.setItem("activeCurrency", id);
        localStorage.setItem("activeCurrencyTab", "privates");
        localStorage.setItem("activeCurrencyIndex", JSON.stringify(0));
        endPoint = id;
      } else if (type === music) {
        const defaultActiveCurrency = {
          id: "",
          name: "",
          symbol: "",
          marketPrice: 0,
          change: 0,
          changesPercentage: 0,
          image: "",
          imageFrom: "",
          imageTo: "",
          perDay: [],
          type: "music",
          address: "",
        };
        setActiveCurrency(defaultActiveCurrency);
        const resp = await getAssetData(`${APIS.ExchangeAssets}/${id}`);
        if (resp?.data) {
          const missingData: any = {};
          setActiveCurrency({ ...resp?.data, ...missingData, type: "music" });
          localStorage.setItem("activeCurrency", id);
          localStorage.setItem("activeCurrencyTab", "music");

          localStorage.setItem("activeCurrencyIndex", JSON.stringify(0));
        }
      } else {
        endPoint = symbol;
        let newActiveCurrncy = {
          id,
          name,
          symbol,
          image,
          imageFrom,
          imageTo,
          marketPrice: 0,
          change: 0,
          changesPercentage: 0,
          perDay: [],
          type,
        };
        setActiveCurrency(newActiveCurrncy);
        getSummaries(`/${type}-summaries/${endPoint}`).then((res) => {
          const { data } = res ?? {};
          if (data) {
            const { detail, perDay } = data;
            if (detail && perDay) {
              const { change, changesPercentage, price } = detail;
              newActiveCurrncy = {
                ...newActiveCurrncy,
                change,
                changesPercentage,
                marketPrice: price,
                perDay,
              };
              setActiveCurrency(newActiveCurrncy);
              localStorage.setItem("activeCurrency", id);
              localStorage.setItem("activeCurrencyTab", type);

              localStorage.setItem("activeCurrencyIndex", JSON.stringify(0));
            }
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

   const getAssetType = useCallback(
     (assetData: Json) =>
       assetData?.type === assestType.crypto &&
       assetData?.provider === PROVIDER.LQDTY
         ? assestType.privates
         : assetData?.type,
     []
   );
  return { openSearchAsset, loadingAsset, getAssetType };
};
