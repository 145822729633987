export const defaultPermissionCnnfig = {
  data: {
    _id: "6762afc6585c94217018d79b",
    name: "Liquidity",
    configuration: {
      branding: {
        logo: null,
        color: {
          primary: null,
          secondary: null,
        },
      },
      features: [
        {
          _id: "6762afc4585c94217018d78c",
          name: "assets",
          parentFeatureId: null,
          permissions: {
            view: true,
            edit: true,
            create: true,
            delete: true,
          },
          subFeatures: [
            {
              _id: "6762afc9585c94217018d7a2",
              name: "sba7",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6762afc9585c94217018d7a3",
              name: "private stock",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6762afc9585c94217018d7a4",
              name: "manufactured home loans",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6762afc9585c94217018d7a5",
              name: "watchlist",
              parentFeatureId: "assets",
              permissions: {
                view: true,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6762afc9585c94217018d7v5",
              name: "horses",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6763f46b03bd4f97d6bbbcd7",
              name: "equipment finance",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6763f46b03bd4f97d6bbbcd8",
              name: "real estate",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
            {
              _id: "6763f46b03bd4f97d6bbbcd8",
              name: "crypto",
              parentFeatureId: "assets",
              permissions: {
                view: false,
                edit: true,
                create: true,
                delete: true,
              },
            },
          ],
        },
        {
          _id: "6762afc4585c94217018d78d",
          name: "orders",
          parentFeatureId: null,
          permissions: {
            view: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
        {
          _id: "6762afc4585c94217018d78e",
          name: "reports",
          parentFeatureId: null,
          permissions: {
            view: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
        {
          _id: "6762afc4585c94217018d78f",
          name: "wallet",
          parentFeatureId: null,
          permissions: {
            view: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
        {
          _id: "6762afcc585c94217018d7b0",
          name: "marketplace",
          parentFeatureId: null,
          permissions: {
            view: true,
            edit: true,
            create: true,
            delete: true,
          },
        },
      ],
    },
  },
};
