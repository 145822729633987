import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./auction-loan-form.scss";
import { ReactResponsiveTable } from "@storybook";
import { SBA_TYPE, UPLOAD_TYPE } from "./constant";
// @ts-ignore
import { Json } from "@types/common";
import classNames from "classnames";
import { useNotification } from "@hooks/notification";
import { APIS, DOC_TYPES, FILE_EXTENSION_URL } from "constant";
import { useNetwork } from "@hooks/network";
import axios from "axios";
import { formatFileSize } from "@utils/common";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@components/button";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Loader } from "@components/Loader2";
import { API_HOST_2 } from "../../../../../envs";
import { SBA7A } from "@views/exchange/constants";

type IAuctionLoanForm = {
  setAuctionFormData: (value: any) => void;
  auctionFormData: Json;
  setLoanFormDataRoomFiles: (value: any) => void;
  loanFormDataRoomFiles: Json[];
  setLoanFormDeletedFiles: (value: any) => void;
  setFileCheckLoading: (value: boolean) => void;
};

const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  xlsx: "xlsx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
};

export const AuctionLoanForm = ({
  setAuctionFormData, auctionFormData, setLoanFormDataRoomFiles,
  loanFormDataRoomFiles, setLoanFormDeletedFiles, setFileCheckLoading
}: IAuctionLoanForm) => {
  const { errorNotification, successNotification } = useNotification();
  const { post: getSignedUrl, loading: signedUrlLoading } = useNetwork();
  const [fileMatchedData, setFileMatchedData] = useState<Json>({});

  useEffect(() => {
    if (loanFormDataRoomFiles[0] && loanFormDataRoomFiles[1]) {
      fileDetailUploading(loanFormDataRoomFiles as any);
    }
  }, [auctionFormData.loanType])

  const fileValidations = useCallback(
    (files: any) => {
      if (!fileFormat[files.type]) {
        errorNotification(`${files.name} is not a valid file format`);
        return false;
      }

      return true;
    },
    [errorNotification]
  );

  const renderBidSheetTable = useMemo(() => {
    const { jsonified_marked_sheets, loading, marked_sheets_links } = fileMatchedData || {};
    if (!loanFormDataRoomFiles[0] || !loanFormDataRoomFiles[1]) return "";
    if (!auctionFormData?.bidSheetJSON?.length && !jsonified_marked_sheets?.bidsheet?.length) return ""
    const keys = jsonified_marked_sheets?.bidsheet?.[0] || auctionFormData?.bidSheetJSON?.[0];
    const rows = !!loading ? [] : jsonified_marked_sheets?.bidsheet || auctionFormData?.bidSheetJSON;
    const column = Object.keys(keys || {}).map((key) => ({
      label: key, key: key, format: "string", width: "10%",
    }))
    return (
      <div className="auction-loan-table__container">
        <div className="auction-loan-table__desc">
          <div>
            <p>Bid sheet</p>
            <span>The table below displays the processed bid sheet.</span>
          </div>
          {!!marked_sheets_links?.bidsheet &&
            <Button label="Download" type="btn-light-blue btn-h-auto" buttonType="button"
                    handleClick={() => window.open(marked_sheets_links?.bidsheet, "_blank")} />}
        </div>
        <ReactResponsiveTable rows={rows || []} column={column} numberOfList={4} showHeader={!!column?.length}
                              isLoading={loading} numberOfColumn={10} />
      </div>
    );
  }, [auctionFormData?.bidSheetJSON, fileMatchedData, loanFormDataRoomFiles]);

  const renderSBAFormTable = useMemo(() => {
    const { jsonified_marked_sheets, loading, marked_sheets_links } = fileMatchedData || {};
    if (!loanFormDataRoomFiles[0] || !loanFormDataRoomFiles[1]) return ""
    if (!auctionFormData?.sba1502JSON?.length && !jsonified_marked_sheets?.sba7_1502?.length) return ""
    const keys = jsonified_marked_sheets?.sba7_1502?.[0] || auctionFormData?.sba1502JSON?.[0];
    const rows = !!loading ? [] : jsonified_marked_sheets?.sba7_1502 || auctionFormData?.sba1502JSON;
    const column = Object.keys(keys || {}).map((key) => ({
      label: key, key: key, format: "string", width: "10%",
    }))
    return (
      <div className="auction-loan-table__container">
        <div className="auction-loan-table__desc">
          <div>
            <p>SBA form 1502</p>
            <span>The table below displays the processed SBA form 1502.</span>
          </div>
          {!!marked_sheets_links?.sba7_1502 &&
            <Button label="Download" type="btn-light-blue btn-h-auto" buttonType="button"
                    handleClick={() => window.open(marked_sheets_links?.sba7_1502, "_blank")} />}
        </div>
        <ReactResponsiveTable rows={rows || []} column={column} numberOfList={4} showHeader={!!column?.length}
                              isLoading={loading} numberOfColumn={10} />
      </div>
    );
  }, [auctionFormData?.sba1502JSON, fileMatchedData, loanFormDataRoomFiles]);

  const handleBrowse = useCallback((event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    let IsFilesValid: boolean | undefined = true;
    const file = event.target.files?.[0];
    event.target.value = "";
    IsFilesValid = fileValidations(file);
    if (IsFilesValid) {
      setLoanFormDataRoomFiles((prevFiles: any) => {
        const newIndex = fileType === UPLOAD_TYPE.BIDSHEET ? 0 : 1;
        const updatedFiles = [...prevFiles];
        updatedFiles[newIndex] = file;
        if (updatedFiles?.[0]?.name === updatedFiles?.[1]?.name) {
          errorNotification(`${file?.name} already exist. Please check`);
          return prevFiles;
        }
        if (updatedFiles[0] && updatedFiles[1]) {
          fileDetailUploading(updatedFiles as any);
        }
        return updatedFiles;
      });
    }
  }, [fileValidations, auctionFormData, loanFormDataRoomFiles]);

  const fileDetailUploading = useCallback(async (files: FileList | any) => {
    setFileMatchedData({ loading: true });
    setFileCheckLoading(true);
    const formData = new FormData();
    formData.append("sba_type", auctionFormData?.sbaType);
    formData.append("loan_type", auctionFormData?.loanType);
    if (!files?.[0]?.fileUrl) {
      formData.append("bidsheet", files?.[0]?.fileId ? files?.[0]?.file : files?.[0]);
    }
    if (!files?.[1]?.fileUrl) {
      formData.append("sba7_1502", files?.[1]?.fileId ? files?.[1]?.file : files?.[1]);
    }
    formData.append("metadata", JSON.stringify({
      bidsheet: files?.[0]?.fileUrl || "", sba7_1502: files?.[1]?.fileUrl || ""
    }));
    try {
      const response = await fetch(`${API_HOST_2}/auctionsba7file/api/sba7-filescheck`, {
        method: "POST",
        body: formData,
      });
      const res = await response.json();
      if (res?.data) {
        setFileMatchedData({ loading: false, ...(res?.data || {}) });
        if (res?.data?.mismatched === false) {
          setAuctionFormData((prev: Json) => ({
            ...prev,
            currentLoanBalance: res?.data?.total_current_loan_balance || 0,
            currentYield: res?.data?.current_yield || 0,
            totalWeightedLoanBalance: res?.data?.total_weighted_loan_balance || 0,
          }));
          await uploadToGcp(files);
        }
      } else {
        setFileMatchedData({ loading: false });
        setAuctionFormData((prev: Json) => ({
          ...prev,
          currentLoanBalance: 0,
          currentYield: 0,
          totalWeightedLoanBalance: 0,
        }));
        errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      setFileMatchedData({ loading: false });
      setAuctionFormData((prev: Json) => ({
        ...prev,
        currentLoanBalance: 0,
        currentYield: 0,
        totalWeightedLoanBalance: 0,
      }));
      errorNotification(e.message);
    } finally {
      setFileCheckLoading(false);
    }
  }, [auctionFormData]);

  const uploadToGcp = useCallback(async (files: any) => {
    const fileList: any = [...files]?.map((file: any, index) => {
      if (!!file?.fileId || !!file.fileUrl) return file;
      return {
        fileId: uuidv4(),
        name: file.name,
        size: file.size,
        type: file.type || DOC_TYPES[file.extension],
        extension: file.type || DOC_TYPES[file.extension],
        downLoadingStatus: 0,
        isCancelled: false,
        abortController: null,
        isfileuploading: false,
        isSuccessfullyUploaded: false,
        loadingStatus: "uploading",
        status: "pending",
        file: file,
        fileLink: file,
        fileUrl: file?.fileUrl || "",
        uploadType: index === 0 ? UPLOAD_TYPE.BIDSHEET : UPLOAD_TYPE.SBA1502
      }
    });

    let folderId;
    if (!!auctionFormData?.folderId) {
      folderId = auctionFormData?.folderId;
    } else {
      const uniqueId = uuidv4();
      setAuctionFormData((prev: Json) => ({ ...prev, folderId: uniqueId }));
      folderId = uniqueId;
    }
    // post for getting signed URL
    const signedurlPayload = {
      folderId,
      files: fileList.filter(({ status }: Json) => status === "pending")?.map(({
        name, size, extension, fileId, uploadType
      }: any) => {
        return {
          fileId,
          fileName: name,
          fileSize: size,
          extension,
          uploadType,
        };
      }),
    };

    if (!signedurlPayload?.files?.length) return

    try {
      const signedurlResponse = await getSignedUrl(
        APIS.AuctionDataRoom,
        signedurlPayload,
        { apiResponse: true }
      );
      if (signedurlResponse.message === "ok") {
        const { data: signedUrlData } = signedurlResponse;

        let apiCallCount = 0;
        let apiSuccessCount = 0;
        await signedUrlData?.file?.map(async (files: any) => {
          const controller = new AbortController();
          const signal = controller.signal;
          let found = fileList?.find((e: any) => e.fileId === files.fileId);

          found = {
            ...found,
            id: files.id,
            abortController: controller,
          };

          setLoanFormDataRoomFiles((prev: any) => {
            const temp = prev?.map((item: any) => {
              if (item?.name === files?.fileName) {
                item = found
              }
              return item
            });
            return [...temp];
          });

          const res = axios.put(files.signedUrl, found?.file, {
            headers: {
              "Content-Type": found?.extension,
            },
            onUploadProgress: (progressEvent) => {
              if (
                typeof progressEvent.total === "number" &&
                !isNaN(progressEvent.total)
              ) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setLoanFormDataRoomFiles((prev: any) => {
                  const temp = prev?.map((item: any) => {
                    if (item?.id === files?.id) {
                      item.isfileuploading = true;
                      item.downLoadingStatus = percentCompleted;
                    }
                    return item;
                  });
                  return [...temp];
                });
              }
            },
            signal: signal,
          }).then((response) => {
            setLoanFormDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item?.id === files?.id) {
                  item.loadingStatus = response?.status === 200 ? "" : "failed";
                  item.abortController = null;
                  item.isSuccessfullyUploaded = response?.status === 200 ? true : false;
                  item.status = response?.status === 200 ? "SUCCESS" : "FAILED";
                  item.time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
                }
                return item;
              });
              return [...temp];
            });
            ++apiCallCount
            if (response?.status === 200) ++apiSuccessCount;
            if (signedUrlData?.file?.length === apiCallCount) {
              successNotification(`${apiSuccessCount} file successfully uploaded`);
            }
          }).catch((err) => {
            setLoanFormDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item?.id === files?.id) {
                  item.loadingStatus = "Cancelled";
                  item.abortController = null;
                  item.isSuccessfullyUploaded = false;
                  item.status = "FAILED";
                  item.time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
                }
                return item;
              });
              return [...temp];
            });
            setTimeout(() => {
              setLoanFormDataRoomFiles((prev: any) => {
                const temp = prev?.map((item: any) => {
                  if (item?.id === files?.id) return null;
                  return item;
                });
                return [...temp];
              });
            }, 1000);
          });
          return res;
        });
      } else {
        errorNotification(signedurlResponse?.message);
      }
    } catch (err: any) {
      errorNotification(err?.message);
    }
  }, [getSignedUrl, auctionFormData, loanFormDataRoomFiles]);

  const deleteFile = (key: string) => {
    let tempData: any = loanFormDataRoomFiles;
    let deletedData: Json;
    if (key === UPLOAD_TYPE.BIDSHEET) {
      deletedData = tempData[0];
      tempData[0] = null;
    } else {
      deletedData = tempData[1];
      tempData[1] = null;
    }

    if (!!deletedData?.id) {
      setLoanFormDeletedFiles((prev: any) => {
        return [...prev, {
          id: deletedData.id,
          time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
        }]
      })
    }
    setLoanFormDataRoomFiles([...tempData]);
    setAuctionFormData((prev: Json) => ({
      ...prev,
      currentLoanBalance: 0,
      currentYield: 0,
      totalWeightedLoanBalance: 0,
    }));
    setFileMatchedData(() => ({}));
  };

  const cancelFileUpload = (index: number) => {
    const abortController = loanFormDataRoomFiles[index]?.abortController;
    abortController.abort();
    setAuctionFormData((prev: Json) => ({
      ...prev,
      currentLoanBalance: 0,
      currentYield: 0,
      totalWeightedLoanBalance: 0,
    }));
    setFileMatchedData(() => ({}));
  };

  const handleChangeSbaType = useCallback((type: string) => {
    if (!!auctionFormData?.isPublished) return;
    const deleteFiles = loanFormDataRoomFiles.filter((file) => !!file && !!file?.id).map((file) => ({
      id: file.id,
      time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
    }));
    if (deleteFiles?.length) {
      setLoanFormDeletedFiles((prev: any) => {
        return [...prev, ...deleteFiles]
      })
    }
    setLoanFormDataRoomFiles([]);
    setFileMatchedData({});
    setAuctionFormData((prev: Json) => ({
      ...prev,
      sbaType: type,
      currentLoanBalance: 0,
      currentYield: 0,
      totalWeightedLoanBalance: 0,
    }));
  }, [auctionFormData, loanFormDataRoomFiles]);


  return (
    <>
      <div className="auction-loan-form">
        <div className="auction-loan-form__heading">
          Listing type
          <div className={`auction-loan-form__container ${!!auctionFormData?.isPublished ? "disabled-input" : ""}`}>
            <div
              className="auction-loan-form__radio"
              onClick={() => handleChangeSbaType(SBA_TYPE.INDIVIDUAL)}>
              <input
                type="radio"
                className="auction-loan-form__radio-btn"
                checked={auctionFormData?.sbaType === SBA_TYPE.INDIVIDUAL} />
              <div className="auction-loan-form__details">
                <p>Individual</p>
                <span className="auction-loan-form__span">
                  Choose this option if you want to list each {SBA7A} loan
                  separately.
                </span>
              </div>
            </div>
            <div style={{ borderLeft: "1px solid #E6E8EC" }}></div>
            <div
              className="auction-loan-form__radio"
              onClick={() => handleChangeSbaType(SBA_TYPE.POOL)}>
              <input
                type="radio"
                className="auction-loan-form__radio-btn"
                checked={auctionFormData?.sbaType === SBA_TYPE.POOL} />
              <div className="auction-loan-form__details">
                <p>Pool</p>
                <span className="auction-loan-form__span">
                  Choose this option if you want to list multiple {SBA7A} loans as
                  a pool or group.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="auction-loan-form__note">
          <span className="auction-loan-form__note__bellIcon">
            <i className={classNames("ri-alarm-warning-fill")} />
          </span>
          <p className="auction-loan-form__note__text">
            Note: Please ensure that the loan numbers in your 'Bid sheet' and '{SBA7A} Form 1502' files are consistent
            before uploading and loan numbers are unique and not listed in any auctions before attempting to list them.
            This will help prevent any discrepancies and ensure smooth processing.
          </p>
        </div>
        <div className="auction-loan-file-section">
          <div className="auction-loan-file-section__FileBrowse">
            <p className="auction-loan-file-section__heading">
              Bid sheet (Required)
            </p>
            <div className="auction-loan-file-section__container">
              <div className="auction-loan-files">
                <div className="auction-loan-main">
                  <div className="auction-loan-details">
                    {!!loanFormDataRoomFiles[0]?.name && <div className="fileLogo">
                      <img alt={loanFormDataRoomFiles[0]?.name}
                           src={FILE_EXTENSION_URL[loanFormDataRoomFiles[0]?.name?.split(".").pop().toLowerCase()]} />
                    </div>}
                    <div className="auction-loan-files-description-container">
                      <div className="auction-loan-files-description">
                        <div
                          className={classNames(
                            "auction-loan-files-description__upper",
                            {
                              "auction-loan-files-description__upper__uploading":
                                !loanFormDataRoomFiles[0]?.isSuccessfullyUploaded,
                            }
                          )}>
                          <div className="auction-loan-file-names">
                            <p className="fileName">{loanFormDataRoomFiles[0]?.name || "Upload bid sheet"}</p>
                            {!!loanFormDataRoomFiles[0]?.isSuccessfullyUploaded && <small className="fileSize">
                              {loanFormDataRoomFiles[0] ? formatFileSize(loanFormDataRoomFiles[0]?.size) : ""}
                            </small>}
                          </div>
                          {!!loanFormDataRoomFiles[0]?.loadingStatus && loanFormDataRoomFiles[0]?.loadingStatus !== "completed" &&
                            <span className="auction-loan-file-loading-status">
                                {loanFormDataRoomFiles[0]?.loadingStatus}
                              </span>}
                        </div>
                        {loanFormDataRoomFiles[0]?.isSuccessfullyUploaded === false && (
                          <div className="auction-loan-loading-placeholder">
                            <div
                              style={{
                                width:
                                  loanFormDataRoomFiles[0]?.downLoadingStatus + "%",
                              }}
                              className="auction-loan-loading-percentage"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="auction-loan-right">
                    {loanFormDataRoomFiles[0]?.abortController ? (
                      <i
                        onClick={() => cancelFileUpload(0)}
                        className="ri ri-close-circle-line auction-loan-file"
                      />
                    ) : (
                      <>
                        {loanFormDataRoomFiles[0] && !fileMatchedData?.loading && !signedUrlLoading && <i
                          onClick={() => deleteFile(UPLOAD_TYPE.BIDSHEET)}
                          className="ri ri-delete-bin-6-line auction-loan-file auction-loan-file__bin"
                        />}
                        <label className="auction-loan-file-section__browse" data-tooltip-id={UPLOAD_TYPE.BIDSHEET}>
                          <input
                            required={!loanFormDataRoomFiles[0]}
                            type="file"
                            className="loanFileInput"
                            placeholder="select File"
                            accept=".xlsx,.xls"
                            onChange={(e) => handleBrowse(e, UPLOAD_TYPE.BIDSHEET)}
                            disabled={!!loanFormDataRoomFiles[0]}
                          />
                          {!!fileMatchedData?.loading ? <Loader className="loader-blue" dimension={15} /> : "Browse"}
                        </label>
                        {!!loanFormDataRoomFiles[0] &&
                          <ReactTooltip id={UPLOAD_TYPE.BIDSHEET} place="top" content="Please delete and browse" />}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="auction-loan-file-section__FileBrowse">
            <p className="auction-loan-file-section__heading">
              SBA form 1502 (Required)
            </p>
            <div className="auction-loan-file-section__container">
              <div className="auction-loan-files">
                <div className="auction-loan-main">
                  <div className="auction-loan-details">
                    {!!loanFormDataRoomFiles[1]?.name && <div className="fileLogo">
                      <img alt={loanFormDataRoomFiles[1]?.name}
                           src={FILE_EXTENSION_URL[loanFormDataRoomFiles[1]?.name?.split(".").pop().toLowerCase()]} />
                    </div>}
                    <div className="auction-loan-files-description-container">
                      <div className="auction-loan-files-description">
                        <div
                          className={classNames(
                            "auction-loan-files-description__upper",
                            {
                              "auction-loan-files-description__upper__uploading":
                                !loanFormDataRoomFiles[1]?.isSuccessfullyUploaded,
                            }
                          )}>
                          <div className="auction-loan-file-names">
                            <p className="fileName">{loanFormDataRoomFiles[1]?.name || "Upload SBA form 1502"}</p>
                            {!!loanFormDataRoomFiles[1]?.isSuccessfullyUploaded && <small className="fileSize">
                              {loanFormDataRoomFiles[1] ? formatFileSize(loanFormDataRoomFiles[1]?.size) : ""}
                            </small>}
                          </div>
                          {!!loanFormDataRoomFiles[1]?.loadingStatus && loanFormDataRoomFiles[1]?.loadingStatus !== "completed" &&
                            <span className="auction-loan-file-loading-status">
                                {loanFormDataRoomFiles[1]?.loadingStatus}
                              </span>}
                        </div>
                        {loanFormDataRoomFiles[1]?.isSuccessfullyUploaded === false && (
                          <div className="auction-loan-loading-placeholder">
                            <div
                              style={{
                                width:
                                  loanFormDataRoomFiles[1]?.downLoadingStatus + "%",
                              }}
                              className="auction-loan-loading-percentage"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="auction-loan-right">
                    {loanFormDataRoomFiles[1]?.abortController ? (
                      <i
                        onClick={() => cancelFileUpload(1)}
                        className="ri ri-close-circle-line auction-loan-file"
                      />
                    ) : (
                      <>
                        {loanFormDataRoomFiles[1] && !fileMatchedData?.loading && !signedUrlLoading && <i
                          onClick={() => deleteFile(UPLOAD_TYPE.SBA1502)}
                          className="ri ri-delete-bin-6-line auction-loan-file auction-loan-file__bin"
                        />}
                        <label className="auction-loan-file-section__browse" data-tooltip-id={UPLOAD_TYPE.SBA1502}>
                          <input
                            required={!loanFormDataRoomFiles[1]}
                            type="file"
                            className="loanFileInput"
                            placeholder="select File"
                            accept=".xlsx,.xls"
                            onChange={(e) => handleBrowse(e, UPLOAD_TYPE.SBA1502)}
                            disabled={!!loanFormDataRoomFiles[1]}
                          />
                          {!!fileMatchedData?.loading ? <Loader className="loader-blue" dimension={15} /> : "Browse"}
                        </label>
                        {!!loanFormDataRoomFiles[1] &&
                          <ReactTooltip id={UPLOAD_TYPE.SBA1502} place="top" content="Please delete and browse" />}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!fileMatchedData?.mismatched && <div className="auction-loan-form__note red pt-10">
          <span className="auction-loan-form__note__bellIcon">
            <i className="ri-alert-fill" />
          </span>
          <p className="auction-loan-form__note__text">
            <strong>Loan number errors</strong> <br />
            Rows with inconsistent loan numbers and that already present in an auction are highlighted in red. You can
            download the processed sheet, remove the rows with inconsistent loan numbers, and then re-upload the
            corrected file.
          </p>
        </div>}
      </div>
      {renderBidSheetTable}
      {renderSBAFormTable}
    </>
  );
};
