import { Button } from "@components/button"
import "./error-page.scss"
import { imageConfig } from "@utils/imageConfig"

export const ErrorPage = () => {
    const {svg:{errorImageLight}} = imageConfig;

    return (
        <div className="error-page-container">
            <div className="error-page-container__iamge">
            <img  src={errorImageLight} alt="error"/>
            </div>
			<div className="error-page-container__details">
				<div className="error-page-container__details__desc">
                We're sorry, but it seems like something went wrong
				</div>
				<div className="error-page-container__details__subDesc">
				We apologize for any inconvenience this may cause and appreciate your patience. Our team is already on it and working to resolve the problem.
				</div>
					<button onClick={()=>window.location.reload()} className="error-page-container__details__btn">Try Again</button>
                </div>
        </div>
    )
}   