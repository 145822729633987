import { FC, useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import cn from "classnames";

import { AddWatchlist } from "components";
import { ActiveCurrencyState } from "states";
import { Price } from "../Price";

import styles from "./../Currency/Currency.module.sass";
import { assestType } from "../../constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface ICryptoItem {
  index: number;
  symbol: string;
  id: string;
  name: string;
  marketPrice: number;
  change: number;
  changesPercentage: number;
  image: string;
  issuerDetails?: any
  accreditationType?: string
  type?: string
  amount: number;
  lastTradedPrice?: number;
}

interface ILogo {
  symbol: string;
  image: string;
}

export const CryptoItem: FC<ICryptoItem> = (props) => {
  const {
    index,
    symbol,
    id,
    name,
    marketPrice,
    change,
    changesPercentage,
    image,
    issuerDetails,
    amount,
    lastTradedPrice,
  } = props;
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { issuerName } = issuerDetails || {}

  const handleRowClick = useCallback(async () => {
    navigate(`/exchange/${id}?type=${assestType?.crypto}`);
  }, [navigate]);


  const getLogo = useCallback(
    ({ symbol, image }: ILogo) => {
      return image && !isError ? (
        <img
          src={image}
          alt=""
          onError={() => setIsError(true)}
          className={styles.assetImage}
        />
      ) : (
        <div className={styles.assetLetter}>
          {symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    },
    [isError]
  );

  return (
    <div
      onClick={handleRowClick}
      className={cn(styles.row, styles.cryptoRow, {
        [styles.active]: id === activeCurrency?.id && symbol === activeCurrency?.symbol,
      })}>
      <div className={`${styles.left}`}>
        <div className={styles.assetImageContain}>
          {getLogo({ symbol, image })}
        </div>
        <div className={styles.symbol} data-tooltip-id={id}>
          {limitFirstPart(mergeStringWithSymbol("", symbol?.toUpperCase(), "."), 12, ".")}
        </div>
        <ReactTooltip
          className="theme-tooltip"
          id={id}
          place="bottom"
          content={mergeStringWithSymbol("", symbol?.toUpperCase(), ".")}
        />
        <div className={styles.compony_name}>{mergeStringWithSymbol("", name, " ") ?? "NA"} </div>
      </div>
      <Price
        marketPrice={lastTradedPrice || amount}
        change={change}
        changesPercentage={changesPercentage}
        symbol={symbol}
        type={props?.type}
      />
      <AddWatchlist item={props} assetType={assestType.crypto} />
    </div>
  );
};
