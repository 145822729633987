import type React from "react"
import type { ChangeEvent } from "react"
import { NumericFormat } from "react-number-format"

interface CommaSeparatedInputProps {
  value: string | number
  onChange: (e: ChangeEvent<HTMLInputElement>, rawValue: string | number) => void
  type?: "text" | "tel" | "password" | undefined
  name?: string
  className?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  maxLength?: number
  disabled?: boolean
  style?: React.CSSProperties
  min?: number
  autoComplete?: "on" | "off"
  onWheel?: React.WheelEventHandler<HTMLElement>
  placeholder?: string
  required?: boolean
  autoFocus?: boolean
  pattern?: string
  decimalPlaces?: number
  maxDigits?: number
  onFocus?: () => void
}

export const CommaSeparatedInput: React.FC<CommaSeparatedInputProps> = ({
  value,
  onChange,
  type = "text",
  name,
  className,
  onKeyPress,
  maxLength,
  disabled,
  style,
  min,
  autoComplete,
  onWheel,
  placeholder,
  required,
  autoFocus,
  pattern,
  decimalPlaces = 2,
  maxDigits = 10,
  onFocus = () => {},
}) => {
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, "")
    onChange(e, rawValue)
  }

  return (
    <NumericFormat
      value={value}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={decimalPlaces}
      allowNegative={false}
      isAllowed={(values) => {
        const { floatValue, formattedValue } = values
        if (floatValue === undefined) return true

        const parts = formattedValue.split(".")
        const integerPart = parts[0].replace(/,/g, "")
        const decimalPart = parts[1] || ""

        return integerPart.length <= maxDigits && decimalPart.length <= decimalPlaces
      }}
      onChange={handleValueChange}
      onKeyPress={onKeyPress}
      disabled={disabled}
      className={className}
      style={style}
      name={name}
      autoComplete={autoComplete}
      onWheel={onWheel}
      placeholder={placeholder}
      required={required}
      autoFocus={autoFocus}
      onFocus={onFocus}
      maxLength={maxLength}
      type={type}
    />
  )
}

