import { LoginUserState } from "@states/user";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useSwitchWorkSpace } from "./useSwitchWorkSpace";
import { ROUTES } from "@routes/constants";
import { useCookie } from "@hooks/cookie";
import { useNotification } from "@hooks/notification";
import { useCallback } from "react";
import { APIS } from "../../../constant";
import { useNetwork } from "@hooks/network";
import { ILoginWithSocialParams } from "@views/SignIn/type";

export const useLogin = () => {
  const navigate = useNavigate();
  const { post: postSocialLogin } = useNetwork({ updateState: false });
  const setLoginUser = useSetRecoilState(LoginUserState);
  const { fetchWorkSpaceUser } = useSwitchWorkSpace();
  const { set: setCookieToken } = useCookie();
  const { NO_ROUTE, SWITCH_ACCOUNT } = ROUTES;
  const { successNotification, errorNotification } = useNotification();

  const loginUser = async (token: string, message?: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      const res = await fetchWorkSpaceUser();
      if (res?.message !== "ok") return;
      if (res?.data?.length > 1) {
        navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
        return;
      }

      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      if (message) {
        successNotification(message);
      }
      navigate(NO_ROUTE);
    }
  };

  const loginWithSocial = useCallback(async (params: ILoginWithSocialParams) => {
      try {
        const res = await postSocialLogin(`${APIS.social_login}`, params, {apiResponse: true})
        if (res?.message === "ok") {
          return res?.data
        } else {
          errorNotification(res?.message || "Something went wrong!");
        }
      } catch (e: any) {
        errorNotification(e?.message || "Something went wrong!");
      }
    }, []);

  return { loginUser, loginWithSocial };
};
