import { FC, useState } from "react";
import { Image } from "@storybook";
import { Loader } from "components";
import "./social-login-options.scss";

interface ISocialLoginOptions {
  options?: any[]; // Array of options to display, each option should have `key` and `image` properties
  handleSelect?: (e: any) => any; //functino of option select
}

/**
 * SocialLoginOptions component displays a list of social login options with loading indicators.
 *
 * @param {ISocialLoginOptions} props - The properties object for the SocialLoginOptions component.
 * @param {any[]} [props.options] - The options for social login, where each option includes `key` and `image`.
 * @param {any[]} [props.handleSelect] - The handleSelect for social where it will trigger when option will be select
 *
 * @returns {JSX.Element} The rendered SocialLoginOptions component.
 */
export const SocialLoginOptions: FC<ISocialLoginOptions> = ({
  options = [], // Default to an empty array if no options are provided
  handleSelect,
}) => {

  // State to manage the loading status of each social login option
  const [loader, setLoader] = useState({
    index: 0, // Index of the currently loading option
    loading: false, // Boolean indicating if any option is currently loading
  });

  /**
   * Handle click event for a social login option.
   * Sets the loading state and simulates an asynchronous operation.
   *
   * @param {number} index - The index of the clicked social login option.
   *
   * @returns {Promise<string>} A promise that resolves after a simulated delay.
   */
  const handleClick = async (index: number, el?: string) => {
    setLoader({
      index, // Set the index of the loading option
      loading: true, // Set loading state to true
    });
    await handleSelect?.(el);
  };

  return (
    <div className="social-login-options-wrapper">
      <div className="social-login-options-wrapper__options">
        {/* Map through the options and render each one */}
        {options.map((el: any, index: number) => (
          <div
            key={el.key} // Use the option's key as the unique key for each item
            className={`social-login-options-wrapper__options__item ${
             el.disabled
                ? "social-login-options-wrapper__options__item--disabled" // Add disabled class if loading
                : ""
            }`}
            onClick={() =>
              !el.disabled ? handleClick(index, el) : {}
            } // Handle click event if not loading
          >
            {loader.index === index && loader.loading ? (
              <Loader className="loader-white" dimension={20} /> // Show loader if this option is loading
            ) : (
              <Image
                fileName={el.image} // Render image based on the option's image property
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
