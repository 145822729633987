import React, {  useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import {
  ActiveCurrencyState,
  ActiveAssetTab,
  perDayState,
  AssetDetailsState, 
  userSelectedCurrencyState,
  IsOpenAssetLoadingState,
  AssetAllDetailsState,
  AssetLoaderState, userPersonalDetails,
  PrivatesState,
  RealEstateState,
  isPrivateModalState,
  refAtomCaptable,
  isUserLoginPopupVisibleState,
} from "states";
import { convertToCurrencySystem, mergeStringWithSymbol, useFormatNumber } from "utils";
import {
  AddWatchlist,
  Icon, Loader, ReactModal,
  Skeleton,
  SkeletonHeader,
  SkeletonLogo,
} from "components";
import { useCaptable, useNetwork, useCookie } from "hooks";
import { APIS, AssetStatus } from "constant";
import { Price } from "../Price";
import { LOAN_NAME, items, itemsMusic, newWatchlistKey, ACCREDITATION_LABEL, realEstateItems, CRYPTO_ITEMS } from "./constants";
import { useCurrency } from "hooks/currency";

import styles from "./Main.module.sass";
import { Json } from "types";
import {
  ASSETS_CONFIG,
  HIDE_ISSUER_NAME,
  LOAN_ASSETS,
  MORNING_LINE,
  ORDERBOOK_SERIES,
} from "@views/exchange/constants";
import UseChatroomApi from "@views/Chat/store/chatroomApi";
import { assestType } from "@views/exchange/constants";
import classNames from "classnames";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { useNavigate } from "react-router-dom";

export const Main = ({isCaptable= false}) => {
  const navigate=useNavigate();
  //global state
  const setUserLoginPopupVisible = useSetRecoilState(isUserLoginPopupVisibleState);
  const refCaptable = useRecoilValue(refAtomCaptable);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const personalData = useRecoilValue(userPersonalDetails);
  const [assetDetails, setAssetDetails] = useRecoilState(AssetDetailsState);
  const setAssestAlldetails = useSetRecoilState(AssetAllDetailsState);
  const setChartPerDay = useSetRecoilState(perDayState);
  const [activeTab] = useRecoilState(ActiveAssetTab);
  const userSelectedCurrency = useRecoilValue(userSelectedCurrencyState);
  const isOpenAssetLoading = useRecoilValue(IsOpenAssetLoadingState);
  const setAssestLoaderState = useSetRecoilState(AssetLoaderState);
  const { getChatInitiated, chatInitiatedLoading } = UseChatroomApi();
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const setEditPrivate = useSetRecoilState(isPrivateModalState);
  const isRealEstate = activeCurrency.type === "real_estate"
  const isHorses = activeCurrency.type === "horse_trade"
  //custom hook
  const {
    get: getSummaries,
    data: summaries,
    loading: loadingSummaries,
    isLoaded: loadedSummaries,
  } = useNetwork();

  const {
    formatCurrency,
    amountToBondPoints,
    formatCurrencyNumber,
    formatCurrencyWithBillion,
  } = useCurrency();
  const { config } = usePermissionContext();
  const { openCaptableWindow } = useCaptable();
  const { get: getCookieData } = useCookie();
    
  const isUserLogedIn = useMemo(() => {
    return getCookieData("userDetails");
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const isMLC = config?.name === MORNING_LINE;
  const {
    symbol,
    name,
    marketPrice,
    change,
    changesPercentage,
    image,
    imageFrom,
    imageTo,
    type: assetType,
    id: assetID,
    rawSymbol,
    amount,
    releaseDate,
    duration,
    previewUrls,
    artist,
    currentLoanBalance,
    currentYield,
    sbaType,
    loanType,
    issuerDetails,
    numberOfLoans,
    averageLoanBalance,
    accreditationType,
    lastTradedPrice,
    realStatAddress,
    status,
  } = activeCurrency || {};

  const { issuerName } = issuerDetails || {}
  const address= realStatAddress

  const {
    stocks,
    music,
    crypto,
    commodities,
    privates,
    forex,
    marketIndexes,
    sba7,
    realEstate,
    horses,
    SM
  } = newWatchlistKey ?? {};

  useEffect(() => {
    setAssestLoaderState({ IsLoading: true });
    if (symbol && assetType) {
      switch (assetType) {
        case music:
          setAssestLoaderState({ IsLoading: false });
          //   // getMusicSummaries(`${APIS.MusicSummaries}/${musicId}`);
          break;
        case stocks:
          getSummaries(`${APIS.StockSummaries}/${symbol}`);
          break;
        case crypto:
          getSummaries(`${APIS.PrivateSummaries}/${assetID}`);
          break;
        case commodities:
          getSummaries(`${APIS.CommoditySummaries}/${symbol}`);
          break;
        case forex:
          getSummaries(`${APIS.ForexSummaries}/${symbol}`);
          break;
        case marketIndexes:
          getSummaries(`${APIS.MarketIndexSummaries}/${symbol}`);
          break;
        case privates:
          getSummaries(`${APIS.PrivateSummaries}/${assetID}`);
          break;
        case realEstate:
          getSummaries(`${APIS.PrivateSummaries}/${assetID}`);
          break;
        case horses:
          getSummaries(`${APIS.PrivateSummaries}/${assetID}`);
          break;
      }
    }
  }, [symbol, assetType, setAssestLoaderState, stocks, horses, getSummaries, crypto, commodities, forex, marketIndexes, privates, assetID, music, realEstate]);

  useEffect(() => {
    setAssestLoaderState({ IsLoading: false });
    if (summaries && summaries.message === "ok") {
      setAssetDetails(summaries.data?.detail);
      setAssestAlldetails(summaries.data);
    }
    setAssestLoaderState({ IsLoading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaries]);

  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(false);
  }, [activeCurrency]);

  const { NumberWithCommas } = useFormatNumber();
  const getLogo = useMemo(() => {
    if (assetType === forex) {
      if (imageFrom && imageTo && !isError) {
        return (
          <div className={styles.imagewrapper}>
            <div className={styles.imagefromcontiner}>
              <img
                className={styles.imagefrom}
                src={imageFrom}
                alt=""
                onError={() => setIsError(true)}
              />
            </div>
            <div className={styles.imagetocontiner}>
              <img
                className={styles.imageto}
                src={imageTo}
                alt=""
                onError={() => setIsError(true)}
              />
            </div>
          </div>
        );
      } else {
        return <div>{symbol?.charAt(0).toUpperCase() ?? "?"}</div>;
      }
    } else {
      return image && !isError ? (
        <img src={image} alt="" onError={() => setIsError(true)} />
      ) : (
        <div>
          {LOAN_ASSETS[assetType] || assetType === music
            ? name?.charAt(0).toUpperCase()
            : symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    }
  }, [
    assetType,
    forex,
    image,
    imageFrom,
    imageTo,
    isError,
    name,
    sba7,
    symbol,
  ]);

  const musicArtistLogo = useMemo(() => {
    return artist?.image_url ? (
      <img src={artist?.image_url} alt="" />
    ) : (
      <div> {artist?.name?.charAt(0).toUpperCase()}</div>
    );
  }, [artist?.image_url, artist?.name])
  
  const renderPerDayValue = useCallback(
    (key: string) => {
      const details: any = activeCurrency?.assetValuation; 
        // assetType === music ? activeCurrency : assetDetails;
      return Object.keys(details ?? {}).length > 0 && details?.[key] !== 0
        ? (key !== "volume" && key !== "tradedVolume" && key !== "total_volume")
          ? formatCurrency(details[key], ASSETS_CONFIG[activeCurrency?.type]?.decimal)
          : NumberWithCommas(details[key] || details["tradedVolume"])
        : "0.00";
    },
    [
      assetType,
      music,
      activeCurrency,
      assetDetails,
      formatCurrency,
      NumberWithCommas,
    ]
  );

  const titleMapping: { [key: string]: string } = {
    "Total IRR": "Total IRR",
    "Minimum Investment": "Min. investment",
    "Property Type": "Property type",
    "Property Class": "Property class",
  };

  const renderGeneralDetails = useCallback(
    (key: string, index: number) => {
      const details = activeCurrency?.generalDetails ?? [];
  
      const originalTitle = Object.keys(titleMapping).find(
        (mappedTitle) => titleMapping[mappedTitle] === key
      );
  
      const detail = details.find(
        (item: { title: string; value: string }) => item.title === originalTitle
      );
  
      if (key === "Min. investment" && detail?.value) {
        const valueWithoutDollarSign = detail.value.replace(/[^0-9.,]/g, ""); 
        const isDollarPresent = detail.value.includes("$");
  
        const formattedValue = NumberWithCommas(valueWithoutDollarSign);
  
        return isDollarPresent ? `$${formattedValue}` : formattedValue;
      }
  
      return detail?.value ?? "--";
    },
    [activeCurrency?.generalDetails, NumberWithCommas]
  );
  
  const isLoading = useMemo(
    () =>
        ![music, SM].includes(assetType)
        ? !loadingSummaries && loadedSummaries
        : activeCurrency?.musicId || activeCurrency?.id,
    [
      activeCurrency?.musicId,
      assetType,
      loadedSummaries,
      loadingSummaries,
      music,
    ]
  );

  //TODO: usage not clear, so commenting out the code. We were not using this anywhere
  // const timeFormate = useCallback((time: string) => {
  //   const timeArr = time.split(":");
  //   return `${AddZeroInSingleNumbers(timeArr[0] ?? 0)}:${timeArr[1] ?? "00"}`;
  // }, []);

  //TODO: can be a util fn
  function formatDuration(durationStr: string) {
    const duration = parseInt(durationStr) ?? 0;
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursDisplay =
      hours > 0 ? hours.toString().padStart(2, "0") + ":" : "";
    const minutesDisplay = minutes.toString().padStart(2, "0");
    const secondsDisplay = seconds.toString().padStart(2, "0");

    return hoursDisplay + minutesDisplay + ":" + secondsDisplay;
  }

  const handleGoDown = useCallback(() => {
    const element = document?.getElementById('assetDetails');
    if (element) {
      element.scrollIntoView();
    }
  }, []);

  const renderPerDay = useMemo(() => {
    let daysItem = {
      [assestType.realEstate]: realEstateItems,
      [assestType.crypto]:  CRYPTO_ITEMS,
    }

    const allowItemMusic: Json = { music, stock: stocks };
    return (
      allowItemMusic[assetType]
        ? itemsMusic
        : daysItem[ activeCurrency?.category] || items
    ).map((item: { key: string; icon: string; title: string }, index) => {
      return (
        <div
          className={classNames(styles.item, {
            [styles.itemBorder]: activeCurrency?.type !== "real_estate",
          })}
          key={index}
        >
          <div className={styles.subtitle}>
            {activeCurrency?.type !== "real_estate" && (
              <Icon name={item.icon} size="16" />
            )}
            {item.title}
          </div>
          <div
            className={styles.content}
            style={{
              color:
                item.key === "open" || item.key === "openPrice"
                  ? assetDetails?.change >= 0
                    ? "#58BD7D"
                    : "#FF6838"
                  : "unset",
            }}
          >
            {isLoading ? renderPerDayValue(item.key) : <Skeleton />}
          </div>
        </div>
      );
    });
  }, [activeCurrency?.category, activeCurrency?.type, assetDetails?.change, assetType, isLoading, music, renderPerDayValue, stocks]);

  const renderGeneralDetailTitle = useMemo(() => {

    return   realEstateItems.map(
      (item: { key: string; icon: string; title: string }, index) => {
        return (
          <div className={classNames(styles.item, { [styles.itemBorder]: activeCurrency?.type !== "real_estate" })} key={index}>
            <div className={styles.subtitle}>
              {item.title}
            </div>
            <div
              className={styles.content}
              style={{
                color:
                  item.key === "open" || item.key === "openPrice"
                    ? assetDetails?.change >= 0
                      ? "#58BD7D"
                      : "#FF6838"
                    : "unset",
              }}
            >
              {isLoading ?  renderGeneralDetails(item.title,index) : <Skeleton />}
            </div>
          </div>
        );
      }
    );
  }, [activeCurrency?.type, assetDetails?.change, isLoading, renderGeneralDetails]);

  

  function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const handleChatClick = useCallback(async () => {
    if (chatInitiatedLoading) return;
    getChatInitiated({
      assetId: activeCurrency?.id || "",
      receiverId: activeCurrency?.customerId || "",
      senderId: personalData?.customerId || "",
    })
  }, [activeCurrency, personalData, chatInitiatedLoading]);

  const issuerNameString = HIDE_ISSUER_NAME[assetType] ? "" : issuerName;
  const assetInfo = isMLC ? name : isRealEstate
    ? ( status !== AssetStatus.DRAFT ? name : "YourAsset")
    : isHorses
    ? symbol
    : mergeStringWithSymbol(
        issuerNameString,
        symbol?.split("-")[0]?.toUpperCase(),
        "."
      ) ?? "--";
  const assetStockName = isMLC ? symbol : isHorses
    ? name
    : mergeStringWithSymbol(
        issuerNameString,
        status !== AssetStatus.DRAFT ? name : "YourAsset",
        " "
      );
  const isOrderBook = sbaType === ORDERBOOK_SERIES;

  const { svg } = imageConfig;
  return (
    <div
      className={`${styles.main} ${assetType === music ? styles.music : ""} ${
        styles[assetType]
      } ${isCaptable && styles.captableOpen}`}
    >
      {LOAN_ASSETS[assetType] ? (
        <>
          <div className={cn(styles.details, styles.sbaDetails)}>
            <div className={styles.box}>
              {symbol === "" ? (
                <div className={styles.line}>
                  <div className={styles.img}>
                    <SkeletonLogo />
                  </div>
                  <div className={styles.skeleton_asset}>
                    <SkeletonHeader />
                  </div>
                </div>
              ) : (
                <div className={styles.sbaLine}>
                  <div
                    className={`${styles.img} ${
                      assetType === music ? styles.music_img : ""
                    }`}
                  >
                    {getLogo}
                    {!!ACCREDITATION_LABEL[accreditationType || ""] && (
                      <img
                        className={styles.accreditationLabel}
                        src={svg[accreditationType]}
                        alt="Accreditation"
                      />
                    )}
                  </div>
                  <div>
                    <div data-tooltip-id={assetInfo} className={styles.info}>
                      {assetInfo}
                    </div>
                    <div
                      className={styles.stock_name}
                      data-tooltip-id={assetStockName}
                    >
                      {assetStockName}
                    </div>
                  </div>
                </div>
              )}
              {!personalData?.isBlockChat &&
                !!activeCurrency?.customerId &&
                activeCurrency?.customerId !== personalData?.customerId && 
                isUserLogedIn && (
                  <div>
                    <button
                      className={styles.chat_btn}
                      onClick={handleChatClick}
                      disabled={chatInitiatedLoading}
                    >
                      {chatInitiatedLoading ? (
                        <Loader dimension={20} />
                      ) : (
                        <>
                          <i className="ri-chat-voice-fill"></i>Chat
                        </>
                      )}
                    </button>
                  </div>
                )}
            </div>
            <div className={cn(styles.sbacolumn)}>
              <div className={styles.box}>
                {/* {marketPrice !== 0 ? ( */}
                {sbaType === "ORDERBOOK_SERIES" ? (
                  <>
                    <div className={styles.subtitlesba}>Number of Loans</div>
                    <div className={styles.sbacontent}>{numberOfLoans}</div>
                  </>
                ) : (
                  <>
                    <div className={styles.subtitlesba}>
                      Price in Bond Points
                    </div>
                    <div className={styles.sbacontent}>
                      {Number(
                        convertToCurrencySystem(
                          amountToBondPoints(currentLoanBalance, marketPrice),
                          4
                        )
                      ).toFixed(6)}
                    </div>
                  </>
                )}
                {/* ) : (
                  <SkeletonHeader />
                )} */}
              </div>
              <div className={styles.box}>
                {
                  userSelectedCurrency?.code && !isOpenAssetLoading && (
                    // marketPrice !== 0 ? (

                    <>
                      {sbaType === "ORDERBOOK_SERIES" ? (
                        <>
                          {" "}
                          <div className={styles.subtitlesba}>
                            Average Loan Balance
                          </div>
                          <div className={styles.content}>
                            {formatCurrencyNumber(averageLoanBalance, 2)}
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className={styles.subtitlesba}>Price (USD)</div>
                          <div className={styles.content}>
                            {formatCurrencyNumber(marketPrice, 2)}
                          </div>
                        </>
                      )}
                    </>
                  )

                  // ) : (
                  //   <SkeletonHeader />
                  // )
                }
              </div>
              <div className={styles.box}>
                {currentYield ? (
                  <>
                    <div className={styles.subtitlesba}>
                      Weighted Average Coupon
                    </div>
                    <div className={styles.content}>
                      {(currentYield || 0)?.toFixed(6)}%
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              <div className={styles.box}>
                {!isOpenAssetLoading ? (
                  <>
                    <div className={styles.subtitlesba}>
                      Total Current Loan Balance (USD)
                    </div>
                    <div className={styles.content}>
                      {isOrderBook
                        ? "$" + Number(currentLoanBalance).toLocaleString()
                        : formatCurrencyWithBillion(currentLoanBalance, 2)}
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              <div className={styles.box}>
                {currentYield ? (
                  <>
                    <div className={styles.subtitlesba}>Listing Type</div>
                    <div className={styles.content}>
                      {isOrderBook
                        ? "Orderbook series"
                        : capitalizeFirstLetter(sbaType) || "NA"}
                    </div>
                  </>
                ) : (
                  <SkeletonHeader />
                )}
              </div>
              {activeCurrency?.type === assestType.SBA7 && (
                <div className={styles.box}>
                  {currentYield ? (
                    <>
                      <div className={styles.subtitlesba}>Loan Type</div>
                      <div className={styles.content}>
                        {LOAN_NAME[loanType] || "NA"}
                      </div>
                    </>
                  ) : (
                    <SkeletonHeader />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* <div className={styles.list}>{renderPerDay}</div> */}
        </>
      ) : (
        <>
          <div
            className={cn(styles.details, {
              [styles.musicDetails]: assetType === "music",
            })}
          >
            <div className={styles.box}>
              {symbol === "" ? (
                <div className={styles.line}>
                  <div className={styles.img}>
                    <SkeletonLogo />
                  </div>
                  <div className={styles.skeleton_asset}>
                    <SkeletonHeader />
                  </div>
                </div>
              ) : (
                <div className={styles.line}>
                  {!isCaptable && (
                    <div
                      className={`${styles.img} ${
                        assetType === music ? styles.music_img : ""
                      }`}
                    >
                      {getLogo}
                      {!!ACCREDITATION_LABEL[accreditationType || ""] && (
                        <img
                          className={styles.accreditationLabel}
                          src={svg[accreditationType]}
                          alt="Accreditation"
                        />
                      )}
                    </div>
                  )}

                  <div>
                    <div className={styles.info} data-tooltip-id={assetInfo}>
                      {assetType === music ? symbol ?? "--" : assetInfo}
                    </div>
                    {/* {assetType !== music && <ReactTooltip className="theme-tooltip" id={assetInfo} place="right" content={assetInfo} />} */}
                    {assetType === music ? (
                      <>
                        <div className={styles.songName} data-tooltip-id={name}>
                          {name}
                        </div>
                        {/* <ReactTooltip className="theme-tooltip" id={name} place="right" content={name} /> */}
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      className={styles.stock_name}
                      data-tooltip-id={assetStockName}
                    >
                      {assetType === music ? (
                        <div className={styles.music_release}>
                          <span>{formatDuration(duration ?? "0")}</span>
                          <i className="ri-checkbox-blank-circle-fill" />
                          {releaseDate?.split("-")[0] ?? "0000"}
                        </div>
                      ) : isRealEstate ? (
                        address?.city || address?.state || address?.country ? (
                          `${address?.city ?? ""}, ${address?.state ?? ""}, ${
                            address?.country ?? ""
                          }`.replace(/(, )+$/, "")
                        ) : (
                          "--"
                        )
                      ) : (
                        assetStockName
                      )}
                    </div>
                    {/* {assetType !== music && <ReactTooltip className="theme-tooltip" id={assetStockName} place="right" content={assetStockName} />} */}
                  </div>
                </div>
              )}
            </div>
            <div className={styles.box}>
              {userSelectedCurrency?.code &&
              !isOpenAssetLoading &&
              marketPrice !== 0 ? (
                <div className={styles.line1}>
                  <Price
                    className={`${styles[assetType + "_price"]}`}
                    marketPrice={
                      assetType === music ? amount : lastTradedPrice || amount
                    }
                    change={change || 0}
                    changesPercentage={changesPercentage || 0}
                    symbol={rawSymbol ?? symbol}
                    type={activeCurrency?.type}
                  />
                  {assetType === music && (
                    <div className={styles.music_devide}></div>
                  )}
                </div>
              ) : (
                <div className={styles.Lheader}>
                  <SkeletonHeader />
                </div>
              )}
            </div>
            {assetType !== music &&
              !LOAN_ASSETS[assetType] &&
              status === AssetStatus.APPROVED && (
                <div>
                  {assetType !== music && !LOAN_ASSETS[assetType] && (
                    <AddWatchlist item={activeCurrency} />
                  )}
                </div>
              )}
            {(activeCurrency?.type === "real_estate" ||
              activeCurrency?.type === "crypto") && (
              <span>
                <i
                  onClick={handleGoDown}
                  className={classNames(
                    `ri-arrow-down-circle-fill`,
                    styles.gotodownIcon
                  )}
                />
              </span>
            )}
          </div>
          {activeCurrency?.status === AssetStatus.APPROVED && (
            <div className={styles.list}>
              {activeCurrency?.type === "real_estate"
                ? renderGeneralDetailTitle
                : renderPerDay}
            </div>
          )}
          <div className={styles.publishNowSection}>
           
            {activeCurrency?.status === "PENDING" ? (
              <div className={styles.publishNow_pendingConatiner}>
                <span className={styles.publishNow_pendingConatiner__pending}>
                  Approval Pending
                </span>
              </div>
            ) : (
              (activeCurrency?.status === AssetStatus.DRAFT ||
                activeCurrency?.status === AssetStatus.REJECTED) && (
                <div className={styles.publishBtnContainer}>
                  {activeCurrency?.status === AssetStatus.REJECTED && (
                    <div className={styles.publishNow_rejectedContainer}>
                      <span
                        className={
                          styles.publishNow_rejectedContainer__rejected
                        }
                      >
                        Rejected
                      </span>
                    </div>
                  )}
                  <div className={styles.publishNow__btnContainer}>
                    <button
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (isUserLogedIn){
                          if (isCaptable) {
                            const privateAssetsParent = (
                              event.target as HTMLElement
                            ).closest(".Modal__wrapper");
                            if (privateAssetsParent) {
                              // Find the #saveAsDraft button inside private-assets
                              const saveAsDraftBtn =
                                privateAssetsParent.querySelector<HTMLButtonElement>(
                                  "#saveAsDraft"
                                );

                              saveAsDraftBtn?.click(); // Click the button if found

                              setTimeout(() => {
                                setEditPrivate(false);
                              }, 500);
                              refCaptable?.contentWindow?.postMessage(
                                {
                                  data: { success: true },
                                  type: "editAssetStatus",
                                  key: "liquidity",
                                },
                                "*"
                              );
                            }
                          } else {
                            const ele: any =
                              document.querySelector("#saveAsDraft");
                            ele?.click();
                          }
                        }
                        else{
                          setUserLoginPopupVisible(true)
                        }
                      }}
                      className={`${styles.publishNow__btnContainer__saveToDraft}`}
                    >
                      Save As Draft
                    </button>
                    {!isCaptable && (
                      <button
                        className={`${styles.publishNow__btnContainer__publishNow} `}
                        onClick={() => {
                            if (isUserLogedIn){
                          openCaptableWindow({
                            sessionID: personalData?.sessionId,
                            assetID:activeCurrency?.id,
                          });
                        }
                        else{
                            setUserLoginPopupVisible(true);
                        }
                        }}
                      >
                        Publish Now
                      </button>
                    )}
                  </div>
                </div>
              )
            )}
          </div>

          {assetType === music && (
            <div className={styles.music_other_details}>
              <div className={styles.music_other_details__left}>
                <div className={`${styles.img} ${styles.albom_img}`}>
                  {musicArtistLogo}
                </div>
                <div className={styles.album}>{artist?.name}</div>
              </div>
              <div className={styles.music_other_details__right}>
                {/* TODO: svgs can be extracted as global svg and we can use them here as well */}
                {Object.keys(previewUrls || {}).map((platform: any) => {
                  let label = platform.split("_").join("").split("url")[0];
                  if (label === "itunes") label = "apple";
                  if (label === "amazonmusic") {
                    return (
                      <div
                        key={label}
                        onClick={() =>
                          window.open(previewUrls[platform], "_blank")
                        }
                        style={{ width: "24px", height: "24px" }}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_6382_17110)">
                            <path d="M24 0H0V24H24V0Z" fill="#25D1DA" />
                            <path
                              d="M8.625 10.125H3.375V12H8.625V10.125Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 12.75H3.375V14.625H8.625V12.75Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 15.375H3.375V17.25H8.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M8.625 18H3.375V19.875H8.625V18Z"
                              fill="#14191A"
                            />
                            <path
                              d="M14.625 15.375H9.375V17.25H14.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M14.625 18H9.375V19.875H14.625V18Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 12.75H15.375V14.625H20.625V12.75Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 15.375H15.375V17.25H20.625V15.375Z"
                              fill="#14191A"
                            />
                            <path
                              d="M20.625 18H15.375V19.875H20.625V18Z"
                              fill="#14191A"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_6382_17110">
                              <rect
                                width="24"
                                height="24"
                                rx="2"
                                fill="white"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    );
                  }
                  return (
                    previewUrls[platform] && (
                      <i
                        key={label}
                        onClick={() =>
                          window.open(previewUrls[platform], "_blank")
                        }
                        className={`ri-${label}-fill ${styles[label]}`}
                      ></i>
                    )
                  );
                })}
              </div>
            </div>
          )}

        </>
      )}
    </div>
  );
};
