import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { LoginPhoneNumberState, LoginUserState } from "states";
import { ROUTES } from "routes";
import "./new-user-otp-verify.scss";
import { useNotification } from "@hooks/notification";
import { loginToasterMessages } from "constant";
import { useBiometricAuthentication } from "@views/SignIn/hooks";
import { OptionsResponseState } from "@views/SignIn/stores";
import { useWehAuthn } from "@hooks/webAuthn";
import { useLocation } from "@hooks/location";
import { useCookie } from "@hooks/cookie";
import { ALLOW_MPC_ENV } from "../../../../envs";
import BiometricSetup from "../biometric-setup/biometric-setup";

const { EXCHANGE,NO_ROUTE, MPC_QR } = ROUTES;
const { LOGIN_SUCCESS } = loginToasterMessages;
export const NewUserOtpVerify = () => {
  const setLoginUser = useSetRecoilState(LoginUserState);
  const tempLoginUser = useRecoilValue(LoginUserState);
  const optionsResponse = useRecoilValue(OptionsResponseState);
  const { registerNewCredential, getWebAuthnSupported } = useWehAuthn();
  const { registrationOptions, verifyRegistration } =
    useBiometricAuthentication();
  const { locationInfo } = useLocation();
  const [ipAddress, setIPAddress] = useState("");
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginPhoneNumber;

  const { set: setCookieToken } = useCookie();
  const navigate = useNavigate();

  const { successNotification, errorNotification } = useNotification();

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  const handleStartWebAuthn = useCallback(async () => {
    if (getWebAuthnSupported()) {
      await startRegisterDevice();
    } else {
      errorNotification("Device biometrics not supported on your device");
    }
  }, []);

  const startRegisterDevice = async () => {
    await registrationOptions({ authenticationType: "signup" });
  };

  const handleRegistrastionSuccess = useCallback(
    async (res: any) => {
      if (res) {
        const payloadSaveCredential = {
          type: "verifyRegistrationUID",
          registrationOptResponse: res,
          id: optionsResponse?.id,
          phone,
          countryCode,
          deviceInfo: {
            location: locationInfo,
            ip: ipAddress,
          },
        };
        const resp = await verifyRegistration(payloadSaveCredential);
        const { isRegistered, token } = resp ?? {};

        if (isRegistered) {
          if (token) {
            loginUser(
              token,
              ALLOW_MPC_ENV
                ? "Device registered"
                : "Device registered and logged in successfully."
            );
          }
        } else {
          errorNotification("There are some unexpected error");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optionsResponse?.id, locationInfo, verifyRegistration, successNotification]
  );

  const loginUser = (token: string, message: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      successNotification(message);
      if (ALLOW_MPC_ENV) {
        navigate(MPC_QR);
      } else {
        setCookieToken("userDetails", loginDetails);
        navigate(NO_ROUTE);
      }
    }
  };

  useEffect(() => {
    if (optionsResponse?.registrationOptions) {
      registerNewCredential(
        handleRegistrastionSuccess,
        optionsResponse?.registrationOptions ?? {}
      );
    }
    // eslint-disable-next-line
  }, [optionsResponse]);

  const handleLogin = useCallback(() => {
    if (ALLOW_MPC_ENV) {
      navigate(MPC_QR);
    } else {
      setCookieToken("userDetails", tempLoginUser);
      navigate(NO_ROUTE);
      successNotification(LOGIN_SUCCESS);
    }
  }, [navigate, setCookieToken, successNotification, tempLoginUser]);





  useEffect(() => {
    if (!tempLoginUser.phone) {
      navigate("/login")
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Fragment>
      <BiometricSetup
        handleWebAuthn={handleStartWebAuthn}
        handleLogin={handleLogin}
      />
    </Fragment>
  );
};
export default NewUserOtpVerify;
