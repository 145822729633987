import { useEffect, useState, FC, useCallback, MouseEvent, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { useCookie, useWatchlist } from "hooks";
import { WatchlistState } from "states";

import styles from "./addWatchlist.module.sass";

type IPorps = {
  item: any;
  assetType?: string;
  from?: string;
};

export const AddWatchlist: FC<IPorps> = ({ item, assetType, from }) => {
  //globle state
  const watchlistData = useRecoilValue(WatchlistState);

  //local State
  const [isAdded, setAdded] = useState(false);

  //hook
  const { addToWatchlist, removeFromWatchlist } = useWatchlist();
  const { get: getCookieData } = useCookie();

  const isUserLogedIn = useMemo(() => {
    return getCookieData("userDetails");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const { symbol, id } = item ?? {};

  useEffect(() => {
    if (watchlistData) {
      if (watchlistData[id]) {
        return setAdded(true);
      }
      return setAdded(false);
    }
  }, [watchlistData, symbol]);

  const [asset_id, setAsset_id] = useState("");

  useEffect(() => {
    if (watchlistData[id]) {
      setAsset_id(watchlistData?.[id]._id || "");
    }
  }, [symbol, watchlistData]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!isAdded) {
        addToWatchlist(item, assetType, setAdded, from);
      } else {
        removeFromWatchlist(asset_id, setAdded, id);
      }
    },
    [
      addToWatchlist,
      assetType,
      asset_id,
      from,
      isAdded,
      item,
      removeFromWatchlist,
    ]
  );

  if(!isUserLogedIn) return <></>;

  return (
    <>
      <div className={styles.addWatchlistIcon} onClick={handleClick}>
        <i
          className={`ri-star-s-fill ${styles.icon} ${
            isAdded ? styles.iconAdded : styles.iconRemoved
          }`}
        />
      </div>
    </>
  );
};
