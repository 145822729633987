import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./bid-card.scss";
import { Button, CountdownTimer, Loader, RadialSeparators } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { Json } from "../../../../types";
import { getDateWithTime } from "../../../../utils";
import {
  useCurrency,
  useNetwork,
  useNotification,
  usePrevious,
  useRemainingSecPercentage,
} from "../../../../hooks";
import { CircularProgressbarWithChildren, CircularProgressbar } from "react-circular-progressbar";
import { PlaceBidModal } from "../PlaceBidModal";
import { APIS } from "../../../../constant";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AuctionBidPostState, AuctionListState } from "../../../../states";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BuyNowBidModal } from "../BuyNowBidModal";
import { addMinutes, intervalToDuration, subMinutes } from "date-fns";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { LOAN_NAME } from "../../constant";
import { imageConfig } from "@utils/imageConfig";

interface IBidCard {
  detail: Json;
  handleOnWishlist?: (id: string) => void;
}

export const BidCard = ({ detail, handleOnWishlist }: IBidCard) => {
  const { formatCurrencyWithBillion } = useCurrency();
  const navigator = useNavigate();
  const { errorNotification, successNotification } = useNotification();
  const setRecoilAuctionList = useSetRecoilState(AuctionListState);
  const { data: bidPostData = {}, loading } = useRecoilValue(AuctionBidPostState);
  const prevLoading = usePrevious(loading);
  const {
    post: postAuctionWishList,
    data: wishListData,
    loading: wishListLoading,
  } = useNetwork();
  const prevWishListLoading = usePrevious(wishListLoading);
  const [bidNow, setBidNow] = useState<null | Json>(null);
  const [buyBidNow, setBuyBidNow] = useState<null | Json>(null);

  const secPercentage = useRemainingSecPercentage(detail?.startTime, detail?.endTime, detail?.status === "live");
  const prevDutchStepTime = useMemo(() => {
    return subMinutes(new Date(detail?.dutchPriceUpdateTime), ((detail?.timeStepHours * 60) + detail?.timeStepMinutes))
  }, [detail]);
  const isDutchLive = detail?.tradeType === "dutch" && detail?.status === "live";
  const dutchStepTimePer = useRemainingSecPercentage(prevDutchStepTime, detail?.dutchPriceUpdateTime, isDutchLive);
  const { sbaAndNormalPrice, isSbaLoan } = useAuctionHook(detail);

  const handleAuctionWishlist = useCallback(() => {
    postAuctionWishList(
      APIS.AuctionWatchlist,
      {
        auctionId: detail?.id,
        isWatchlist: !detail?.isWatchlist,
      },
      { apiResponse: true }
    );
  }, [detail]);

  useEffect(() => {
    if (bidPostData?.message === "ok" && !loading && prevLoading && bidPostData?.data?.id === detail?.id) {
      setBidNow(null);
      successNotification(
        bidPostData?.data?.status === "completed"
          ? "You have successfully bought this auction"
          : "Bid placed successfully"
      );
    }
  }, [bidPostData, detail]);

  useEffect(() => {
    if (
      wishListData?.message !== "ok" &&
      !wishListLoading &&
      prevWishListLoading
    ) {
      errorNotification(wishListData?.message);
    }
    if (
      wishListData?.message === "ok" &&
      !wishListLoading &&
      prevWishListLoading
    ) {
      !!handleOnWishlist && handleOnWishlist(detail?.id);
    }
  }, [wishListData, detail]);

  const handleClickViewDetail = () => {
    if (detail.status === "completed") {
      navigator(`/user-bid-details/${detail.id}`);
    } else {
      navigator(`/auction-details/${detail.id}`);
    }
  };

  const renderDate = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
      if (completed && detail?.status === "live") {
        setRecoilAuctionList((prev) => {
          let temp = JSON.parse(JSON.stringify(prev?.data?.data));
          const idx = temp.findIndex((_data: Json) => _data?.id === detail?.id);
          temp[idx] = { ...temp[idx], status: "completed" }
          return { ...prev, data: { ...prev.data, data: [...temp] } }
        });
      }

      const classText = (secPercentage || 0) < 10 ? "time-red" : "time-color";
      const time = `${!!days ? days + "D " : ""}${
        (hours < 10 ? "0" : "") + hours
      }:${(minutes < 10 ? "0" : "") + minutes}:${(seconds < 10 ? "0" : "") + seconds}`;

      const stepTime = intervalToDuration({ start: new Date(), end: new Date(detail?.dutchPriceUpdateTime) });
      let stepTimeText = `${((stepTime?.hours || 0) < 10 ? "0" : "") + stepTime?.hours}:${((stepTime?.minutes || 0) < 10 ? "0" : "") + stepTime?.minutes}:${((stepTime?.seconds || 0) < 10 ? "0" : "") + stepTime?.seconds}`
      if (((new Date(detail?.dutchPriceUpdateTime).getTime() - new Date().getTime()) < 0) || detail?.status === "completed")
        stepTimeText = "00:00:00"

      return (
        <div className="time-count">
          <CircularProgressbarWithChildren
            value={detail?.status === "completed" ? 0 : detail?.status === "live" ? secPercentage || 0 : 100}
            maxValue={100} strokeWidth={10} counterClockwise
            className={`count-circle ${classText} ${completed && "time-over"}`}>
            <RadialSeparators count={50} />
            {detail?.tradeType === "dutch" &&
              <CircularProgressbar className="inner-time-circle" counterClockwise
                                   value={detail?.status === "completed" ? 0 : dutchStepTimePer || 0}
                                   maxValue={100} strokeWidth={6} text={stepTimeText} />}
          </CircularProgressbarWithChildren>
          <span className={`${classText} mt-10`}>{time}</span>
          Time to end
        </div>
      );
    },
    [detail, secPercentage, dutchStepTimePer]
  );

  const renderStartDate = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    if (completed) {
      setRecoilAuctionList((prev) => {
        let temp = JSON.parse(JSON.stringify(prev?.data?.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === detail?.id);
        temp[idx] = { ...temp[idx], status: "live" }
        return { ...prev, data: { ...prev.data, data: [...temp] } }
      });
    }
    const time = `${!!days ? days + "D " : ""}${
      (hours < 10 ? "0" : "") + hours
    }:${(minutes < 10 ? "0" : "") + minutes}:${(seconds < 10 ? "0" : "") + seconds}`;
    return (
      <div className="time-count">
        <CircularProgressbarWithChildren
          value={100} maxValue={100} className="count-circle" strokeWidth={10}>
          <RadialSeparators count={50} />
        </CircularProgressbarWithChildren>
        <span className="time-color mt-10">{time}</span>
        Yet to start
      </div>
    );
  }, []);

  const renderDutchStepTime = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    if (completed && (detail?.currentBidPrice > detail?.reservePrice)) {
      setRecoilAuctionList((prev) => {
        let temp = JSON.parse(JSON.stringify(prev?.data?.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === detail?.id);
        const {
          currentBidPrice, stepPrice, maxAuctionBidPrice, userMaxBidPrice, reservePrice,
          timeStepMinutes, timeStepHours, dutchPriceUpdateTime
        } = temp[idx] || {};
        const price = Math.max((currentBidPrice - stepPrice), maxAuctionBidPrice, reservePrice);

        if (price === maxAuctionBidPrice) {
          temp[idx] = {
            ...temp[idx], currentBidPrice: price, userBidPrice: userMaxBidPrice, status: "completed"
          }
          return { ...prev, data: { ...prev.data, data: [...temp] } }
        }

        const totalMinutes = price === reservePrice ? 0 : (timeStepHours * 60) + timeStepMinutes;
        temp[idx] = {
          ...temp[idx],
          currentBidPrice: price,
          dutchPriceUpdateTime: addMinutes(new Date(dutchPriceUpdateTime), totalMinutes),
        }
        return { ...prev, data: { ...prev.data, data: [...temp] } }
      });
    }
  }, [detail]);

  const {svg: {ArrowTail}} = imageConfig;
  
  return (
    <div className="bid-card-container">
      {!!bidNow && (
        <PlaceBidModal open={!!bidNow} details={bidNow} onClose={() => setBidNow(null)} />)}

      {!!buyBidNow && (
        <BuyNowBidModal open={!!buyBidNow} details={buyBidNow} onClose={() => setBuyBidNow(null)} />)}

      <div className={`bid-card-image ${isSbaLoan ? "sba-image" : ""}`}>
        <span className="label">{detail?.tradeType}</span>
        {!!detail?.assetImage?.[0] && (
          <img className="image" src={detail?.assetImage?.[0]} alt="asset" />
        )}
        {!detail?.assetImage?.[0] && <i className="ri-image-add-line" />}
        {!wishListLoading && (
          <i
            className={`h-icon ${
              detail?.isWatchlist ? "ri-heart-3-fill" : "ri-heart-3-line"
            }`}
            onClick={handleAuctionWishlist}
          />
        )}
        {wishListLoading && (
          <i className="h-icon h-icon-reload">
            <Loader className="loader-white" dimension={15} />
          </i>
        )}
        <div className="arrow-label">
          {detail?.category}
          <img
            className="arrow-label-img"
            src={ArrowTail}
            alt="Assets"
          />
        </div>
      </div>
      <div className="bid-card-details">
        <div>
          <div className="logo-container">
            <div className={`logo-img ${!detail?.assetIcon ? "with-bg" : ""}`}>
              {!!detail?.assetIcon && (
                <img src={detail?.assetIcon} alt="logo" />
              )}
              {!detail?.assetIcon && <i className="ri-file-upload-line" />}
            </div>
            <div className="title-heading-symbol">
              <h2 className="title-heading" data-tooltip-id={detail.id}>{detail?.name}</h2>
              <span className="title-symbol">{detail?.symbol}.<span>{detail?.symbolValue}</span></span>
            </div>
            {detail?.name?.length >= 25 &&
              <ReactTooltip className="theme-tooltip" id={detail.id} place="top" content={detail?.name} />}
          </div>

          <div className="date-and-buttons">
            <div className="price-bids-box">
              <div className="price-bits">
                <label className="price-label">Current Bid Price</label>
                <h2 className="price">
                  {sbaAndNormalPrice(detail, {
                    price: detail?.currentBidPrice
                  })}
                </h2>
                {isSbaLoan && <label className="price-label">
                  {formatCurrencyWithBillion(detail?.currentBidPrice, 2)}
                </label>}
              </div>
              {detail?.tradeType === "classic" && (
                <div className="price-bits">
                  <label className="price-label">Total Bids</label>
                  <h2 className="price">{detail?.totalBid || 0}</h2>
                </div>
              )}
              {detail?.tradeType === "classic" && (
                <div className="price-bits">
                  <label className="price-label">Buy Now</label>
                  <h2 className="price">
                    {sbaAndNormalPrice(detail, {
                      price: detail?.buynowPrice
                    })}
                  </h2>
                  {isSbaLoan && <label className="price-label">
                    {formatCurrencyWithBillion(detail?.buynowPrice, 2)}
                  </label>}
                </div>
              )}
              {detail?.tradeType === "dutch" && (
                <div className="price-bits">
                  <label className="price-label">Starting Bid</label>
                  <h2 className="price">
                    {sbaAndNormalPrice(detail, {
                      price: detail?.startPrice
                    })}
                  </h2>
                  {isSbaLoan && <label className="price-label">
                    {formatCurrencyWithBillion(detail?.startPrice, 2)}
                  </label>}
                </div>
              )}
              {detail?.tradeType == "dutch" && (
                <div className="price-bits">
                  <label className="price-label">Price Step</label>
                  <h2 className="price">
                    {sbaAndNormalPrice(detail, {
                      price: detail?.stepPrice
                    })}
                  </h2>
                  {isSbaLoan && <label className="price-label">
                    {formatCurrencyWithBillion(detail?.stepPrice, 2)}
                  </label>}
                </div>
              )}
              <div className="price-bits">
                <label className="price-label">Auction Ending</label>
                <h2 className="price">
                  {detail?.endTime
                    ? getDateWithTime(detail?.endTime, "EEE, MMM dd, hh:mm a")
                    : "--"}
                </h2>
              </div>

              {isSbaLoan && <div className="price-bits">
                <label className="price-label">Weighted Average Coupon</label>
                <h2 className="price">
                  {(detail?.metadata?.currentYield || 0)?.toFixed(6)}%
                </h2>
              </div>}

              {isSbaLoan && <div className="price-bits">
                <label className="price-label">Listing Type</label>
                <h2 className="price">
                  {detail?.sbaType || "--"}
                </h2>
              </div>}

              {isSbaLoan && <div className="price-bits">
                <label className="price-label">Loan Type</label>
                <h2 className="price">
                  {LOAN_NAME?.[detail?.metadata?.loanType] || "--"}
                </h2>
              </div>}
            </div>

            <div className="bid-buttons">
              <div className="bid-buttons-grp">
                <Button label="Bid Now" type="button-green bid-btn"
                        disabled={detail?.status !== "live"}
                        handleClick={() => setBidNow(detail)} />
                {detail?.tradeType === "classic" && (
                  <Button type="btn-outline-green bid-btn" label="Buy Now"
                          handleClick={() => setBuyBidNow(detail)}
                          disabled={detail?.status !== "live" || !detail?.buynowPrice} />)}
              </div>
              <Button
                label="View Details"
                type="btn-grey-1 bid-btn"
                handleClick={handleClickViewDetail}
              />
            </div>
          </div>
        </div>
        <div className="time-container">
          {detail?.status === "yet-to-start" && (
            <CountdownTimer dateTime={detail?.startTime} renderer={renderStartDate} />
          )}
          {detail?.status === "live" && (
            <CountdownTimer dateTime={detail?.endTime} renderer={renderDate} />
          )}
          {detail?.tradeType === "dutch" && detail?.status === "live" && (
            <CountdownTimer dateTime={detail?.dutchPriceUpdateTime} renderer={renderDutchStepTime} />
          )}
          {detail?.status === "completed" &&
            renderDate({
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
              completed: true,
            })}
        </div>
      </div>
    </div>
  );
};
