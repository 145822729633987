// import styles from "./compliance.module.sass";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  userPersonalDetails,
  openComplianceState,
  TriggerUserApi,
  reOpenKycSignupModalState, reinitiateKycState, isStartVerificationNewState,
  OpenKycReviewModalState,
  OpenMintAppState,
  OpenPublishNowReviewState,
  ActiveCurrencyState
} from "states";
import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
// @ts-ignore
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import styles from "./publish-now-review.module.sass"
import { UserPublishNowApi } from "./store/publishNowApi";
import { SkeletonHeader } from "@components/skeleton-loader";
import classNames from "classnames";
import { useCaptable } from "@hooks/use-Captable";

interface IkycReview {
  onLogout?: () => void;
  setReinitiateKyc?: (value: boolean) => void;
  handleClose?: () => void
}

export const PublishNowReview = ({ onLogout, handleClose }: IkycReview,) => {
  const [isSelectedLinked, setIsSelectedLinked] = useState(false);
  const [selectedShareClass, setSelectedShareClass] = useState<string>('');
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');

  const personalData = useRecoilValue(userPersonalDetails);
  const activeCurrency= useRecoilValue(ActiveCurrencyState);

  const setOpenCompliance = useSetRecoilState(openComplianceState);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const setReinitiateKyc = useSetRecoilState(reinitiateKycState);
  const setIsStartVerification = useSetRecoilState(isStartVerificationNewState);
  const setOpenKycReviewModal = useSetRecoilState(OpenKycReviewModalState);
  const  setOpenMintApp = useSetRecoilState(OpenMintAppState)
  const setOpenPublishNowReview = useSetRecoilState(OpenPublishNowReviewState);
  
  
  
  const { config } = usePermissionContext()
  const { openCaptableWindow } = useCaptable();
  const { errorNotification } = useNotification();
  const { GetListedComapanyData, ListedComapny, ListedCompanyLoading, LinkCompany,LinkCompanyLoading } = UserPublishNowApi();

  const {
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    signDocStatus,
    kybDocStatus,
  } = personalData.onboardingData;

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "pending");

  const isAnyStatusProcessing = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "processing");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "rejected");

  const isAnyCompleted = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    kybDocStatus,
    signDocStatus
  ].some((status) => status === "completed");

  const noOneIsPendingAndRejected = !isAnyStatusPending && !isAnyRejected;

  const isAllInPending = isAnyStatusPending && !isAnyStatusProcessing && !isAnyRejected && !isAnyCompleted


  const handleCloseModal = useCallback(() => {
    setTriggerUserApi((prev) => !prev);
    //  setIsSuccessModal(false);
    setOpenCompliance(false);
    setReOpenKycSignupModal(false);
    if (handleClose) {
      handleClose();
    }
  }, []);

  
  useEffect(() => {
    // Find first object with isLinked: true
    const linkedCompany:any= ListedComapny.find((item:any) => item.isLinked);
  
    // If found, set it as selected
    if (linkedCompany) {
      setSelectedCompanyName(linkedCompany?.companyName);
      setSelectedShareClass(linkedCompany?.shareClassId);
      setIsSelectedLinked(true)
    }else{
      setIsSelectedLinked(false)
    }
  }, [ListedComapny]);

  const handleOnCompleteKyc = useCallback(() => {
    if (noOneIsPendingAndRejected) {
      handleCloseModal()
      return
    }

    !!handleClose && handleClose();
    setReinitiateKyc(true);
    setOpenKycReviewModal(false)
    setIsStartVerification(true);
    setOpenCompliance(true);
    setReOpenKycSignupModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOneIsPendingAndRejected]);

  const handleShareClassChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;

    // Find the selected share class in the company list
    const selectedShare: any = ListedComapny.find((share: any) => share.shareClassId === selectedId);

    if (selectedShare) {
      setSelectedShareClass(selectedId);
      setSelectedCompanyName(selectedShare.companyName); // Update the selected company name
      setIsSelectedLinked(selectedShare?.isLinked ? true : false);
      
    }
  }, [ListedComapny]);

  const handleLinkAsset = useCallback(() => {
    if (!selectedShareClass || !selectedCompanyName) {
      errorNotification("Please select a share class before linking the asset.");
      return;
    }

    const selectedShare: any = ListedComapny.find((share: any) => share.shareClassId === selectedShareClass);

    if (selectedShare) {
      LinkCompany({
        assetId: activeCurrency.id,
        companyId: selectedShare.companyId,
        shareClassType: selectedShare.shareClassType ? "common" : "preferred",
        companyName: selectedCompanyName,
        shareClassId: selectedShareClass,
        action: selectedShare.isLinked ? "unlink" : "link", 
        setIsSelectedLinked
      });
    }
  }, [selectedShareClass, selectedCompanyName, ListedComapny, errorNotification, LinkCompany, activeCurrency.id]);


  const renderKycStatus = useMemo(() => {
    return (
      <div className={styles.kyc_review}>
        <h2 className={styles.kyc_heading_review}>Publish Now</h2>
        <p className={styles.kyc_para_review}>
          We'll review your information. Once your account get verified you can start using the platform.
        </p>

        {(isAnyRejected && !isAnyStatusPending) && <p className={styles.kyc_para_rejected}>
          <i className={`ri-alert-fill ${styles.kyc_para_rejected_icon}`} />
          Your verification was not approved. Reinitiate the process by
          selecting "Reinitiate Verification" below.
        </p>}

        <div className={styles.kyc__review}>
          <div className={styles.kyc_review_progress}>
            <h3 className={styles.heading}>Verification Timeline</h3>
            <div className={styles.progressContainer}>
              <div className={`${styles.progress_bar} ${isAllInPending ? styles.notDone : isAnyStatusPending ? styles.inProgress : styles.complte
                }`}>
                <div>
                  <div className={`${styles.kyc_dot}`}></div>
                  <div className={`${styles.progressline}`}></div>
                </div>
                <div className={styles.timeLineDetails}>
                  {" "}
                  <h3>Completed verification</h3>
                  {/* <p>{isAllInPending ? "--" : "Submitted for review"}</p> */}
                  {!noOneIsPendingAndRejected && <button
                    className={`${styles.proceed_btn_review_1}`}
                    onClick={handleOnCompleteKyc}
                  >
                    {personalData?.sessionId && !isAnyStatusPending ? "Reinitiate Verification" : "Complete Verification"}
                  </button>}
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${isAnyStatusPending ? styles.notDone : !isSelectedLinked ? styles.inProgress : styles.complte
                  }`}
              >
                <div>
                  <div className={`${styles.kyc_dot}`}></div>
                  <div className={styles.progressline}></div>
                </div>
                <div className={styles.timeLineDetails}>
                  <div className={styles.captable_heading_container}>
                    <h3>Captable</h3>

                  {ListedComapny?.length !== 0 && <button onClick={() => { openCaptableWindow() }} className={styles.go_to_cap_tableBtn}>Go to Captable</button>}
                  </div>
                  {ListedCompanyLoading ? <span className={styles.publishNow_captable_skeleton}><SkeletonHeader/> </span>: ListedComapny?.length === 0 ?
                    <button
                      className={`${styles.proceed_btn_review_1} ${!noOneIsPendingAndRejected && styles.publishReviewBtnDisbaled}`}
                      onClick={() => { openCaptableWindow() }}
                      disabled={isAnyStatusPending || !noOneIsPendingAndRejected}
                    >
                      Setup Captable
                    </button> :
                    <div className={styles.timelineItem}>
                      <div className={styles.captable_share_class}>Share class</div>
                      <div className={styles.capTableActions}>
                        <div className={styles.dropdownWrapper}>
                          {/* <select value={selectedShareClass} onChange={handleShareClassChange} className={styles.shareClassSelect}>
                          <option value="">Select share class</option>
                          {ListedComapny.map((shareClass: ShareClass, index: number) => (
                            <option key={index} value={shareClass.shareClassId}>
                              {shareClass.companyName} - {shareClass.shareClassType}
                            </option>
                          ))}
                        </select>
                        <button onClick={handleLinkAsset} className={styles.linkAssetButton}>Link Asset</button> */}
                          <select value={selectedShareClass} onChange={handleShareClassChange} className={styles.shareClassSelect}>
                            <option value="">Select share class</option>
                            {ListedComapny.map((shareClass: any, index) => (
                              <option key={index} value={shareClass.shareClassId}>
                                {shareClass.companyName} - {shareClass.shareClassType}
                              </option>
                            ))}
                          </select>
                          <i className="ri-arrow-down-s-line"></i>
                        </div>
                        <button onClick={handleLinkAsset} className={`${isSelectedLinked ? styles.unLinkAssetBtn : styles.linkAssetButton}`} disabled={!selectedShareClass}>
                          {isSelectedLinked ? "Unlink" : "Link Asset"}
                        </button>
                        {/* <button onClick={handleLinkAsset} className={styles.unLinkAssetBtn}>
                        Unlink
                        </button> */}

                      </div>
                    </div>

                  }
                </div>
              </div>
              <div
                className={`${styles.progress_bar} ${activeCurrency?.status === "PENDING" ? styles.inProgress  : styles.notDone
                  }`}
              >
                <div>
                  <div className={styles.kyc_dot}></div>
                </div>
                <div className={styles.timeLineDetails}>
                  {" "}
                  <h3>List your asset</h3>
                  {/* <p>
                    {isAnyRejected && !isAnyStatusPending ? "Verification was unsuccessful. Reinitiate the verification process to access trading and other features." : "You can trade, deposit, withdraw and use all other features."}
                  </p> */}

                  {
                    activeCurrency?.status === "PENDING" ?  <span className={classNames(styles.approval_status, styles.approval_status__pending)}>
                    <i className="ri-time-fill" />
                    &nbsp;Pending
                  </span> :
                    <button
                    className={`${styles.proceed_btn_review_1} ${(!noOneIsPendingAndRejected  || ListedComapny?.length === 0 || !isSelectedLinked || LinkCompanyLoading || ListedCompanyLoading  ) && styles.publishReviewBtnDisbaled}`}
                    onClick={() => {
                      setOpenMintApp(true)
                      setOpenPublishNowReview(false)
                    }}
                    disabled={isAnyStatusPending || !noOneIsPendingAndRejected || ListedComapny?.length === 0 || !isSelectedLinked || LinkCompanyLoading || ListedCompanyLoading}
                  >
                    {"List your asset"}
                  </button>}
                </div>
              </div>
            </div>
            <h3>Have any questions?</h3>
            <p>Our support team can help you.</p>
            <div className={styles.mail}>
              <i className="ri-mail-line"></i>
              <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${config?.supportEmail}`} target="_blank" rel="noopener noreferrer">{config?.supportEmail}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }, [LinkCompanyLoading, ListedComapny, ListedCompanyLoading, activeCurrency?.status, config?.supportEmail, handleLinkAsset, handleOnCompleteKyc, handleShareClassChange, isAllInPending, isAnyRejected, isAnyStatusPending, isSelectedLinked, noOneIsPendingAndRejected, openCaptableWindow, personalData?.sessionId, selectedShareClass, setOpenMintApp, setOpenPublishNowReview]);

  return <>
    {renderKycStatus}
  </>;
};
