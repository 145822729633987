import { Loader } from "@components/Loader2";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { Image } from "@storybook";
import { useEffect } from "react";

export const TermsOfService = () => {
  const { config, loading } = usePermissionContext();

  useEffect(() => {
    if (!loading) {
      const OneTrust = (window as any)?.OneTrust;
      OneTrust?.NoticeApi.Initialized.then(function () {
        OneTrust?.NoticeApi?.LoadNotices([
          config?.disclaimer?.url || "",
        ]);
      });
    }

  }, [config?.disclaimer?.url, loading]);
  return (
    <>
      {loading ? <div className="loadingTerms"> < Loader /></div > :
        <div className="terms-of-service">
      <div
        id={config?.disclaimer?.id || ""}
        className="otnotice"
      ></div>
        </div>}
    </>

  );
};
