import { mobileApps } from "constant";
import { SuccessIcon } from "./SignIn/components/status";
import styles from "./mobile-email-verify.module.sass";
import { Image } from "@storybook";
import MpcMobileRedirect from "./SignIn/components/mpc-wallet/mpc-mobile-redirect";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";


const MobileEmailVerification = () => {
    const { ANDROID_APP, IOS_APP } = mobileApps;
    const { config } = usePermissionContext();
    const {svg: {appstore, android_appstore, Originalwordmark}} = imageConfig;
 
    return (
       <>
        <MpcMobileRedirect />
        <div className={styles.page}>
            <div className={styles.main}>
                <SuccessIcon />
                <div className={styles.heading}>Email successfully verified!</div>
                <div className={styles.note}>
                    <i className={`ri-lightbulb-flash-fill ${styles.icon}`} />
                    <p className={styles.para}>
                        Please refresh your profile page if you are using a web browser, or restart the app if you are on a mobile device to see the changes.
                    </p>
                </div>
                <div className={styles.text}>
                        {config?.name} mobile app is available for both iOS and Android platform
                </div>
                <div className={styles.app_buttons}>
                    <a href={IOS_APP}>
                        <Image fileName={appstore} />
                    </a>
                    <a href={ANDROID_APP}>
                        <Image fileName={android_appstore} />
                    </a>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footer_item}>
                    <Image fileName={Originalwordmark} />
                </div>
            </div>
        </div>
       </>
    );
};
export default MobileEmailVerification;