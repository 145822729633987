import { useCallback } from "react";
import { useNetwork } from "@hooks/network";
import { Json } from "../../../../../types/common";
import { APIS } from "constant";
import { TriggerUserApi, userPersonalDetails } from "@states/user";
import {  useRecoilValue, useSetRecoilState } from "recoil";

const useIssuerNameApi = () => {
  const { patch ,loading:IssuerNameUpdateLoading} = useNetwork();
  const personalData = useRecoilValue(userPersonalDetails);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);

  const UpdateIssuerName = useCallback(async(payload: Json,closeIssuerNameModal:any, setIsIssuerNameInvalid:any,setIssuerNameError:any) => {
    try {
      await patch(`${APIS.Users}/${personalData?.id}`, payload, { apiResponse: true }).then((res):any=>{
        if(res){
          if(res?.message==="ok"){
            setTriggerUserApi((prev) => !prev);
            closeIssuerNameModal();
          }else if(res?.message==="Already name in allocated"){
            setIsIssuerNameInvalid(true)
            setIssuerNameError("This issuer name is already taken. Please choose a different one.")
          }
        }
      });
    } catch (error) {
    }
  }, [patch, personalData?.id, setTriggerUserApi]);

  return { UpdateIssuerName ,IssuerNameUpdateLoading};
};

export default useIssuerNameApi;
