import { FC, MouseEvent, createRef, useCallback, useState } from 'react';
import { useOutsideClick } from 'hooks';
import cn from 'classnames';

import './drop.scss';

export interface IDropOptions {
	handleDelete: () => void;
	handleMangeDelete: () => void;
	isVisible: boolean
	status: string
}

export const DropOptions: FC<IDropOptions> = ({
	handleDelete, isVisible, status, handleMangeDelete
}) => {

	// local state
	const [showOption, setShowOption] = useState<boolean>(false);

	// ref
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleClickMangeDelete = useCallback(() => {
		handleMangeDelete()
		setShowOption(false);
	}, [handleMangeDelete])

	const handleclickOnDelete = useCallback(() => {
		setShowOption(false);
		handleDelete();
	}, [handleDelete]);

	return (
		<div ref={ref} className={'options-wrapper'}>
			<div className='icon-container '>
				<i className={cn("ri-more-2-fill  extra", { iconWithBackground: showOption })} onClick={handleOptions} />
			</div>
			{showOption && (
				<div className={status === "removal pending" ? "option-manage" : "options-box"}>
					{status === "removal pending" ?
						<button className='manage-removal-request' onClick={handleClickMangeDelete}>
							<i className='ri-settings-4-line' />
							Manage Removal Request
						</button> :
						<button className="menu-item" onClick={handleclickOnDelete}>
							<i className='ri-indeterminate-circle-line space' />
							Remove
						</button>}
				</div>
			)
			}
		</div >
	);
};
