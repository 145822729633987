import { ReactResponsiveTable } from "@storybook/react-responsive-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { AuctionListState } from "states";
import { Json } from "types";
import { useNetwork, useNotification, usePrevious } from "hooks";
import { AUCTION_DRAFTS_HEADER } from "views/auction/constant";
import { APIS } from "constant";
import { ReactModal } from "components";
import { useNavigate } from "react-router-dom";
import { getFormattedDate, getFormattedTime } from "utils";
import { Confirmation } from "@storybook";

export const DraftList = () => {
  const navigator = useNavigate();
  const [deleteAuctionId, setDeleteAuctionId] = useState<string | null>(null);
  const [AuctionDataList, setAuctionDataList] =
    useRecoilState(AuctionListState);

  const { remove: deleteAssetData, loading: deleteloading } = useNetwork();
  const { data: AuctionData, loading } = AuctionDataList;
  const prevLoading = usePrevious(loading);
  const { errorNotification,successNotification } = useNotification();

  useEffect(() => {
    setDeleteAuctionId(null);
  }, []);

  const deleteAsset = async (Id: string) => {
    setDeleteAuctionId(Id);
  };

  const editAuctions = useCallback((auctionId: string) => {
    navigator(`/admin/auction-edit/${auctionId}`);
  }, []);

  useEffect(() => {
    if (AuctionData?.message !== "ok" && !loading && prevLoading) {
      errorNotification(AuctionData?.message);
    }
  }, [AuctionData]);

  const tableRows = useMemo(() => {
    if (!AuctionData?.data) return [];
    const rows: any = [];
    AuctionData.data?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        AUCTION_DRAFTS_HEADER.Header.forEach(({ format, key }) => {
          row.tradeType = items.tradeType ?? "--";
          row.id = items.id;

          if (format === "jsx" && key === "product") {
            const value = () => (
              <span
                className="auction-name"
                data-tooltip-id={"name" + items.id}
              >
                {items.name}
              </span>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && key === "date") {
            const value = () => (
              <div className="auction__table-date-container">
                <p className="auction__table-date">
                  {getFormattedDate(items.createdAt)}
                </p>
                <p className="auction__table-time">
                  {getFormattedTime(items.createdAt)}
                </p>
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && key === "action") {
            const value = () => (
              <div className="asset-action-icons">
                <span
                  className="ri ri-edit-box-line"
                  onClick={() => editAuctions(items.id)}
                ></span>
                <div>
                  <span
                    onClick={() => deleteAsset(items.id)}
                    className="ri ri-delete-bin-line"
                  ></span>
                </div>
              </div>
            );
            return (row[key] = value);
          }
        });
        rows.push(row);
      }
    });

    return rows;
  }, [AuctionData.data, deleteAsset, editAuctions]);

  const handlePatchDeleteAuction = async () => {
    try {
      const response = await deleteAssetData(
        `${APIS.Auction}/${deleteAuctionId}`
      );
      if (response) {
        successNotification("Successfully deleted!")
        const currentAssetAdterDeletion = AuctionData?.data?.filter(
          (items: Json) => items.id !== deleteAuctionId
        );
        setAuctionDataList((prev) => ({
          ...prev,
          data: { message: "ok", data: [...currentAssetAdterDeletion] },
        }));
      }
    } catch (err: any) {
      errorNotification(err);
    } finally {
      setDeleteAuctionId(null);
    }
  };

  return (
    <>
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={AUCTION_DRAFTS_HEADER.Header}
        isLoading={loading}
        numberOfList={10}
      />
      {!!deleteAuctionId && (
        <ReactModal
          visible={!!deleteAuctionId}
          onClose={() => setDeleteAuctionId(null)}
        >
          <Confirmation
            title="Are you sure?"
            visible={!!deleteAuctionId}
            handleModal={handlePatchDeleteAuction}
            handleClose={() => setDeleteAuctionId(null)}
            description="Deleting draft will remove it from your draft list. Do you want to delete?"
            label="Delete"
            cancelLabel="Cancel"
            type="danger"
            cancelLoading={deleteloading}
          />
        </ReactModal>
      )}
    </>
  );
};
