import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useCallback } from "react";

export const useApplePayCalls = () => {
  const { post } = useNetwork();

  const handleValidMerchantCall = useCallback(
    (validationURL: string) => {
      const payload = {
        mode: "applePaySession",
        validation_url: validationURL,
      };
      const resp = post(APIS.PAYMENT_INITIATION, payload);
      return resp;
    },
    [post]
  );

  const handlePayment = useCallback(
    (amount: string, third_party_token: string) => {
      const payload = {
        mode: "apple-pay",
        transactionType: "deposit",
        amount: amount,
        third_party_token: third_party_token,
      };
      const resp = post(APIS.PAYMENT_INITIATION, payload);
      return resp;
    },
    [post]
  );

  return {
    handleValidMerchantCall,
    handlePayment,
  };
};
