import { ReactModal } from "@components/react-modal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LoginPhoneNumberState, MobileVerificationState, userPersonalDetails } from "@states/user";
import { APIS } from "constant";
import { useNetwork } from "@hooks/network";
import { ApprovalStatusState } from "@views/SignIn/stores";
import { useBiometricAuthentication } from "@views/SignIn/hooks";
import { useNotification } from "@hooks/notification";
import { Loader } from "@components/Loader2";
import { useIsTabActive } from "@hooks/common";
import { allCoOwnerState, coOwnerApiCall } from "@states/authorised-user";
import { useLocalStorage } from "@hooks/local-storage";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";

import "./waiting-for-approval.scss";

export const WaitingForApproval = (props: any) => {
  const {onSuccess, onFailure} = props;
  const isTabActive = useIsTabActive();
  const verificationId = useRecoilValue(MobileVerificationState);
  const loginDetails = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginDetails;
  const { successNotification, errorNotification } = useNotification();
  const [isSmsSending, setIsSmsSending] = useState(false);
  const [waittingForApprovalSuccess, setWaitingForApprovalSuccess] = useState(false); 
  const coOwnerList = useRecoilValue(allCoOwnerState)
  const { get: getLocalStorage } = useLocalStorage();
  const iscoOwnerApiCall = useRecoilValue(coOwnerApiCall);
  const { config } = usePermissionContext()
  const { gif: { liquidity, success, morningLineGif } } = imageConfig;

  const PrimaryUser=coOwnerList.find((user)=>user.isPrimary===true);

  const isOwner = useMemo(() => {
    const primaryUser: any =
    getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    if (iscoOwnerApiCall) {
      if (coOwnerList?.length > 0 && !primaryUser) {
        return false;
      }
      return true;
    }
  }, [coOwnerList, iscoOwnerApiCall]);

  let mobileLinkVerifyInterval: any = useRef(null);

  const setVerificationStatus = useSetRecoilState(ApprovalStatusState);

  const { registrationApproval } = useBiometricAuthentication();

  const { get: checkLinkStatus } = useNetwork();
  const checkBiometricMobileVerificationStatus = async (id: string) => {
    try {
      const response = await checkLinkStatus(`${APIS.VerifyPhone}/${id}`);
      return response;
    } catch (err: any) {
      console.log(err?.message);
      clearInterval(mobileLinkVerifyInterval.current);
    }
  };

  const checkStatus = async () => {
    const res = await checkBiometricMobileVerificationStatus(verificationId);
    if (res) {
      const { status } = res?.data;
      if (status === "approved") {
        setWaitingForApprovalSuccess(true);
        clearInterval(mobileLinkVerifyInterval.current);
        setVerificationStatus(res?.data?.status);
        showWaitingSuccessScreen(status);
        successNotification("Mobile verified successfully");
      }
      if (status === "rejected") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        onFailure();
        errorNotification("Registration Declined: Please try again if you'd like to proceed");
      }
    }
  };

  const showWaitingSuccessScreen = (status: string) => {
      setTimeout(() => {
        setWaitingForApprovalSuccess(false)
        onSuccess(status);
      }, 3000)
  }
 
  useEffect(() => {
    if (verificationId) {
      mobileLinkVerifyInterval.current = setInterval(() => {
        if (isTabActive) checkStatus();
       
      }, 5000);
    }
    return () => clearInterval(mobileLinkVerifyInterval.current);
  }, [verificationId, isTabActive]);

  const cancelApproval = () => {
    clearInterval(mobileLinkVerifyInterval.current);
    setWaitingForApprovalSuccess(false);
    onFailure();
  };

  const resendSms = useCallback(async () => {
    if (isSmsSending) return;
    if (phone && countryCode) {
      setIsSmsSending(true);
      const approvalPayload = {
        type: "registrationApproval",
        phone,
        countryCode,
        deviceInfo: navigator.userAgent ?? {},
      };
      const response = await registrationApproval(approvalPayload);
      if (response) {
        const { message, success, verificationId } = response;
        if (success && verificationId) {
          successNotification(message);
          return;
        } else {
          errorNotification(message);
        }
        setIsSmsSending(false);
      }
    }
  }, [
    countryCode,
    errorNotification,
    isSmsSending,
    phone,
    registrationApproval,
    successNotification,
  ]);

  const renderWaitingScreen = useMemo(() => {
      return (
        <div className="biometric-approval__wrapper">
        <div className="biometric-approval__row">
          <p className="biometric-approval__title">Waiting For Approval</p>
          <p className="biometric-approval__description">
            We have sent you a text message to &nbsp;
            <strong className="biometric-approval__number">
              {coOwnerList?.length && !isOwner ? `${PrimaryUser?.countryCode}  ${PrimaryUser?.phone}.` : `${countryCode} ${phone}.`}
            </strong>
            &nbsp; Open the link provided in the text message and approve from there.
          </p>
        </div>
        <p className="biometric-approval__row align-center">
            <img src={config?.name === MORNING_LINE ? morningLineGif : liquidity} alt="Logo" />
        </p>
        <div className="biometric-approval__row">
          <button onClick={cancelApproval}>Back</button>
        </div>
        <div className="biometric-approval__row">
          <span className="resent-link">
              Didn’t receive text message? <i onClick={resendSms}> Resend </i>
          </span>
        </div>
      </div>
      )
  }, [])

  const renderSuccessScreen = useMemo(() => {
    return (
      <div className="biometric-approval__wrapper success_screen">
        <div className="biometric-approval__row">
          <img
            src={success}
            className="biometric-approval__success-icon"
            alt="success screen"
          />
          <div className="biometric-approval__title text-center">
            Approved successfully
          </div>
          <div className="biometric-approval__description">
            Approval successful to register this device for webAuthn.
          </div>
          <div className="biometric-approval__description flex-row">
            <i>Redirecting</i>
            <Loader className="loader-white" dimension={22} />
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <ReactModal visible={true} onClose={() => {}}>
        <div className="biometric-approval-exchange">
          {waittingForApprovalSuccess
            ? renderSuccessScreen
            : renderWaitingScreen}
        </div>
      </ReactModal>
    </>
  );
};
