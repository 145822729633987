import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container } from "../container";
import { TransactionCard } from "../TransactionCard";
import { Json } from "types";
import { useRecoilValue } from "recoil";
import { AllTransactionsData } from "views/MyWallet/store/state";
import { UseTransaction } from "views/MyWallet/store/hooks";
import { SkeletonWallet } from "components";
import InfiniteScroll from "react-infinite-scroll-component";

import "./transactions.scss";
import { TRANSACTION_PAGE_TABS } from "./constant";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

export const Transactions = () => {
  const allTranstions = useRecoilValue(AllTransactionsData);
  const { getTransactions } = UseTransaction();
  const [activeTab, setActiveTab] = useState(TRANSACTION_PAGE_TABS[0].type)
  const darkMode = useDarkMode(false);

  const offsetValue = useMemo(() => {
    return allTranstions?.data?.length === 0
      ? 0
      : Math.ceil(allTranstions?.data?.length / 20) - 1;
  }, [allTranstions?.data?.length]);

  const initialQuery: any = useMemo(() => {
    return {
      limit: 20,
      offset: offsetValue,
      filterType: activeTab,
    };
  }, []);

  const [filters, setFilters] = useState(initialQuery);

  useEffect(() => {
    setFilters(initialQuery);
  }, [initialQuery]);

  const abortControllerRef = useRef<any>(null);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;
    const options = { updateNewData: false, signal };
    const query = {
      limit: 20,
      offset: 0,
      filterType: activeTab,
    };
    getTransactions(query, options);
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

 
    const ordersLength = (allTranstions)?.data?.length;

    const handleFetchMore = useCallback(async () => {
      let query: Json = {
        ...filters,
        offset: offsetValue + 1,
        filterType: activeTab,
      };
      const options = { updateNewData: true };
      setFilters((prev:any) => ({ ...prev, offset: offsetValue + 1, }));
      getTransactions(query, options);
    }, [filters, getTransactions, offsetValue, activeTab]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const {svg: {no_data_light, no_data_dark}} = imageConfig;
 
  return (
    <Container>
      <div className="transactions">
        <header>
          <div className="transaction-header">
            <div className="header-left">
              <span onClick={() => window.history.back()}>
                <i className="ri ri-arrow-left-s-line"></i>
              </span>
              <p>Transactions</p>
            </div>
            {/* <DataFilter
              position="right"
              data={filterOptions}
              selectedValue={filters}
              onChangeFilter={handleFilter}
              onClickReset={onReset}
            /> */}
          </div>
        </header>
        <main>
          <div className="transaction-tabs">
            {TRANSACTION_PAGE_TABS.map((tab, index) => (
              <span
                key={tab.type}
                className={activeTab === tab.type ? "active-tab" : "disable"}
                onClick={() => handleTabChange(tab.type)}
              >
                {tab.title}
              </span>
            ))}
          </div>
          <div className="transaction-container" id="scrollableDiv">
            {
              <InfiniteScroll
                dataLength={ordersLength || 0}
                next={handleFetchMore}
                hasMore={
                  allTranstions?.data?.length >=
                  offsetValue * filters.limit + filters.limit
                }
                loader={<SkeletonWallet listsToRender={1} />}
                scrollableTarget="scrollableDiv"
              >
                {allTranstions?.data?.map((transaction: any, index: number) => (
                  <TransactionCard
                    key={transaction?.transactionId}
                    transactionType={transaction?.type}
                    transactiomnStatus={transaction?.status}
                    amount={transaction?.amount}
                    bankName={transaction?.bankName}
                    transactionDate={transaction?.createdAt}
                    flow={transaction?.flow}
                    transactionId={transaction?.transactionId}
                    id={transaction?.id}
                    accountNumber={transaction?.accountNumber}
                    intendedTransaction ={transaction?.intendedTransaction}
                  />
                ))}
              </InfiniteScroll>
            }
            {allTranstions?.loading && <SkeletonWallet listsToRender={3} />}
            {!allTranstions?.loading && !allTranstions?.data?.length && (
              <div className="transac-empty-container">
                <Image
                  className="no-data-image"
                  fileName={darkMode.value ? no_data_dark : no_data_light}
                />
                <span className="transac-empty-text">No transactions!</span>
              </div>
            )}
          </div>
        </main>
      </div>
    </Container>
  );
};
