import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";
import useDarkMode from "use-dark-mode";

import {
  complianceDataState,
  imageUplaodingState,
  signUpInfoState,
  TriggerUserApi,
  userPersonalDetails,
  userProfileState,
  UsersLoadingState,
  allCoOwnerState, reOpenKycSignupModalState,
  isStartVerificationNewState,
  openComplianceState,
  reinitiateKycState,
  showEmailNotificationState,
  userDetailsState,
  showUserEditModalState,
} from "states";

import styles from "./MyProfile.module.sass";
import "./MyProfile.scss";
import { Confirmation, Image } from "@storybook";
import { APIS, VALID_IMAGE_FILE_FORMAT } from "../../../../constant";
import {
  useLocalStorage,
  useNetwork,
  useNotification,
} from "../../../../hooks";
import { Button, Loader, ReactModal, SkeletonLogo } from "../../../../components";
import { Compliance } from "../../../exchange/components/compliance";
import NewModal from "../../../../components/new-modal/new-modal";
import { useNavigate } from "react-router-dom";
import { convertFileToBase64, formatPhoneNumber, maskString } from "utils";
import { FortressAccountInfo} from "views/MyWallet/store/state";
import IssuerNameModal from "../IssuerNameModal/IssuerNameModal";
// @ts-ignore
import { Json } from "@types/common";
import { UpgradeAccreditation } from "@views/exchange/components/Actions/Form/UpgradeAccreditation";
import { ACCREDITATION_TYPES } from "@views/exchange/components/Main/constants";
import DvpModals from "../DvpModals/dvp-modals";
import classNames from "classnames";
import ProgressBar from "@components/progress-bar/progressbar";
import { ALLOW_MPC_ENV } from "../../../../envs";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const Myprofile = () => {
  const personalData = useRecoilValue(userPersonalDetails);
  const darkMode = useDarkMode(false);
  const { post: resendVerifyMail, loading } = useNetwork();
  const { post: changeSettings, loading: picUploading } = useNetwork();
  const { remove: removePic, loading: picRemoving } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const [showUserEditModal, setShowUserEditModal] = useRecoilState(
    showUserEditModalState
  );
  const setEditFormData = useSetRecoilState(signUpInfoState);
  const setComplianceData = useSetRecoilState(complianceDataState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const [profilePic, setProfilePic] = useRecoilState(userProfileState);
  const setUplaoding = useSetRecoilState(imageUplaodingState);
  const profileRefresh = useRecoilValue(UsersLoadingState);

  const userDetails = useRecoilValue(userDetailsState);
  const [isOpenDVPRequest, setIsOpenDVPRequest] = useState(false);
  const [OpenDVPRequestModal, setOpenDVPRequestModal] = useState(false);
  const [openIssuerNameModal, setOpenIssuerNameModal] = useState(false);
  const [openAccreditation, setOpenAccreditation] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [showKYCReview, setShowKYCReview] = useState(false);
  const uploadRef = useRef<any>(null);

  const {images: {galleryImg}} = imageConfig;
 
  const setIsStartVerification = useSetRecoilState(
    isStartVerificationNewState
  );
  const setOpenCompliance = useSetRecoilState(openComplianceState);
  const { get: getLocalStorage } = useLocalStorage();
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const { isOnboardingComplete = true, accreditationType, kybStatus = false, } = personalData?.onboardingData || {};
  const { actualLimit, requestedLimit, status, id } = personalData?.dvp || {};
  const setReinitiateKyc = useSetRecoilState(reinitiateKycState);
  const [openDvpModal, setOpenDvpModal] = useState(false);
  const [isCancelDvpRequest, setIsCancelDvpRequest] = useState(false);
  const buttonContainerRef = useRef<any>(null);
  const { remove: deleteDvpReq, loading: deleteLoading } = useNetwork();
  const { config } = usePermissionContext()

  const {
    firstName,
    lastName,
    email,
    accountName,
    countryCode,
    phone,
    kyc,
    kyb,
    amlStatus,
    customerId,
    accreditationStatus,
    address,
    issuerName,
    isVerifiedEmail,
    polygonId,
    accreditationDetails,
    onboardingData
  } = personalData;

  const accreStatus = new Set(["rejected", "processing"]);
  const accreType = accreStatus.has(accreditationStatus || "") ? accreditationDetails?.previousType : accreditationType
  const allowMpcCheck = ALLOW_MPC_ENV ? userDetails?.data?.isMPCWallet : true;

  useLayoutEffect(() => {
    setTriggerUserApi((prev) => !prev);
  }, [])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        buttonContainerRef.current &&
        !buttonContainerRef.current.contains(event.target as Node)
      ) {
        setShowButtons(false);
      }
    }
    if(uploadRef.current){
      setShowButtons(false);
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonContainerRef]); 
 

  const primaryUser = useMemo(() => {
    const isPrimary: any =
      getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    return isPrimary;
  }, [getLocalStorage]);

  const handleClickAccreditation = useCallback(() => {
    if (!isVerifiedEmail) {
      errorNotification("Please verify your email first");
      return;
    }
    setOpenAccreditation(true)
  }, [isVerifiedEmail])

  const { kycStatus, questionnaireStatus, signDocStatus } = onboardingData;

  const isAnyStatusPending = [
    kycStatus,
    kybStatus,
    amlStatus,
    accreditationStatus,
    questionnaireStatus,
    status,
    signDocStatus
  ].some((status) => status === "pending");

  const isAnyRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
    questionnaireStatus,
    accreditationStatus,
    signDocStatus,
  ].some((status) => status === "rejected");

  const handleProfileChange = useCallback(
    async (e: any) => {
      setShowButtons(false)
      const file = e.target.files?.[0];
      
      if (file?.type && !VALID_IMAGE_FILE_FORMAT[file.type])
        return errorNotification(
          "Invalid Image. Please upload png, jpg, jpeg file only"
        );

      if (file) {
        if (file.size <= 4 * 1024 * 1024) {
          const fileName: any = await convertFileToBase64(file);
          const logo = fileName.toString();

          const payload = {
            type: file.type,
            image: logo,
          };
          setUplaoding(true);

          changeSettings(`${APIS.UserProfile}`, payload, { apiResponse: true }).then((res) => {
            if (res?.message === "ok") {
              setProfilePic(res?.data);
              successNotification("Image Uploaded");
              setTriggerUserApi((prev) => !prev);
              setUplaoding(false);
            } else {
              errorNotification(res?.message);
            }
          }).catch((error) => {
            errorNotification("Something went wrong");
          });
        } else {
          errorNotification("Image size exceeds 4MB limit");
        }
            }
    },
    [changeSettings, errorNotification, setProfilePic, setTriggerUserApi, setUplaoding, successNotification]
  );

  const renderKyc = useMemo(
    () => (
      <>
        {kyc ? (
          <div
            className={cn(styles.dotColor, {
              [styles.approved]: kyc === "completed" || "approved",
              [styles.rejected]: kyc === "rejected",
              [styles.pending]: kyc === "pending",
              [styles.processing]: kyc === "processing",
            })}
          ></div>
        ) : (
          ""
        )}

        <span className={styles.kyc}> {kyc ? kyc : "N/A"}</span>
      </>
    ),
    [kyc]
  );

  const renderKyb = useMemo(
    () => (
      <>
        {kyb ? (
          <div
            className={cn(styles.dotColor, {
              [styles.approved]: kyb === "completed" || "approved",
              [styles.rejected]: kyb === "rejected",
              [styles.pending]: kyb === "pending",
              [styles.processing]: kyb === "processing",
            })}
          ></div>
        ) : (
          ""
        )}

        <span className={styles.kyc}> {kyb ? kyb : "N/A"}</span>
      </>
    ),
    [kyb]
  );

  const renderAmlStatus = useMemo(
    () => (
      <>
        {amlStatus ? (
          <div
            className={cn(styles.dotColor, {
              [styles.approved]: amlStatus === "completed" || "approved",
              [styles.rejected]: amlStatus === "rejected",
              [styles.pending]: amlStatus === "pending",
              [styles.processing]: amlStatus === "processing",
            })}
          ></div>
        ) : (
          ""
        )}

        <span className={styles.kyc}> {amlStatus ? amlStatus : "N/A"}</span>
      </>
    ),
    [amlStatus]
  );

  const renderPlygonIDStatus = useMemo(
    () => (
      <>
        {polygonId ? (
          <div
            className={cn(styles.dotColor, {
              [styles.approved]: polygonId === "completed" || "approved",
              [styles.rejected]: polygonId === "rejected",
              [styles.pending]: polygonId === "pending",
            })}
          ></div>
        ) : (
          ""
        )}

        <span className={styles.kyc}>
          {polygonId ? maskString(polygonId) : "N/A"}
        </span>
      </>
    ),
    [polygonId]
  );

  const renderAccreditationLabel = useMemo<Json>(() => {
    return {
      [ACCREDITATION_TYPES.RETAIL]: "Non accredited/Retail",
      [ACCREDITATION_TYPES.B506]: "506(b)",
      [ACCREDITATION_TYPES.C506]: "506(c)",
    }
  }, [accreditationType]);


  const renderAccreditationStatus = useMemo(() => {
    const status = accreStatus.has(accreditationStatus || "") ? "completed" : accreditationStatus;

    return <>
                  <div className={styles.detail}>
                    <div className={styles.accditationLeft}>
                    {renderAccreditationLabel[accreType || ""] || ""}
                    </div>

      {accreditationStatus ? (
        <div
          className={cn(styles.dotColor, {
            [styles.approved]: status === "completed" || "approved",
            [styles.rejected]: status === "rejected",
            [styles.pending]: status === "processing" || status === "pending",
          })}
        ></div>
      ) : (
        <>
          <div className={styles.dotColor}></div>
          <span className={styles.kyc}>N/A</span>
        </>
      )}
      <span className={styles.kyc}>
          {" "}
        {status ? status : "N/A"}
        </span>
        </div>
      {!!accreType && accreType !== ACCREDITATION_TYPES.C506 && accreditationStatus !== "processing" && config?.isAccountStepUp &&
        <div className={styles.accreditationUpgrade} onClick={handleClickAccreditation}>
          Get Accreditation 506(c)</div>
          }
    </>
  }, [accreStatus, accreditationStatus, renderAccreditationLabel, accreType, config?.isAccountStepUp, handleClickAccreditation]);


  const onClickResend = useCallback(async () => {
    if (personalData?.signDocStatus === "processing") {
      setShowKYCReview(true)
      return;
    }
    if (loading) return;
    const res = await resendVerifyMail(APIS.ResendVerificationEmail, {});
    if (res?.success) return successNotification(res?.message);
    else
      return errorNotification(
        res?.message || "Something went wrong. Please try again"
      );
  }, [loading, personalData]);

  const handleClickEditUser = useCallback((action: string) => {
    if (personalData?.signDocStatus === "processing") {
      setShowKYCReview(true)
      return;
    }
    const { firstName = "", lastName = "", email = "", id, accountName = "" } = personalData;
    setEditFormData({ firstName, lastName, email, accountName });
    setComplianceData({ id: id as string, onboardingData: {} });
    setShowUserEditModal(action);
  }, [personalData]);

  const handleAfterEdit = useCallback(() => {
    setTriggerUserApi((prev) => !prev);
    setShowUserEditModal("");
  }, []);

  const handleOpenKycModal = useCallback(() => {
    setReinitiateKyc(true);
    setIsStartVerification(true);
    setOpenCompliance(true);
    setReOpenKycSignupModal(true);
  }, [])

  const handleCancelDvp = () => {
    deleteDvpReq(`${APIS.USER_DVP}/${id}`).then(() => {
      setTriggerUserApi((prev) => !prev)
      setIsCancelDvpRequest(false)
    })
  }

  const renderCancelModal = useMemo(() => {
    return (
      <ReactModal
        visible={isCancelDvpRequest}
        onClose={() => setIsCancelDvpRequest(false)}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(styles.DvpNameModal)}
      >
        <Confirmation
          title={"Cancel DVP request"}
          visible={true}
          description={
            "Are you sure you want to cancel your DVP request? You can submit a new request any time."
          }
          handleModal={handleCancelDvp}
          handleClose={() => setIsCancelDvpRequest(false)}
          boldDescription=""
          label="Continue"
          cancelLabel="Cancel"
          type="sell"
          cancelLoading={deleteLoading}
          confirmationHeaderStyle={{ borderRadius: "20px" }}
        />
      </ReactModal>
    );
  }, [isCancelDvpRequest, deleteLoading]);

  const renderEditUserDetailsModal = useMemo(() => {
    return (
      <NewModal
        isOpen={!!showUserEditModal}
        closeModal={() => setShowUserEditModal("")}
        modalName={showUserEditModal === "email" ? "Update email address" : "Update profile name"}
        title={showUserEditModal === "email" ? "Update email address" : "Update profile name"}
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        className="Modal__wrapper exchange-modal-wrapper update-email-address-modal"
      >
        <Compliance
          isEditingUserDetails
          showUserEditModal = {showUserEditModal}
          handleSuccess={handleAfterEdit} handleClose={() => setShowUserEditModal("")}
          disabledData={{ firstName: true, lastName: true, email: showUserEditModal !== "email", accountName: showUserEditModal !== "accountName" }}
        />
      </NewModal>
    );
  }, [handleAfterEdit, showUserEditModal]);

  const renderAddress = useMemo(() => {
    return (
      <div>
        {`${address ? `${address}` : ""}
        `}
      </div>
    );
  }, [address]);


  function getDvpStatus(status: string) {
    switch (status) {
      case 'PENDING':
        return (<>
          <i className={`ri-time-fill ${styles.dvpIcon}`}></i>
          Requested (Limit : ${requestedLimit})
          <span className={styles.dvpCancel} onClick={() => setIsCancelDvpRequest(true)}
          >Cancel</span>
        </>)
      case 'ACCEPTED':
        return <p>
          <i className={`ri-checkbox-circle-fill ${styles.approvIcon}`}></i>
          Approved</p>
      default:
        return <Button type={`button ${styles.custom}`} label="Request"
                       handleClick={() => setOpenDvpModal(true)}
        />
    }
  }

  const handleDeletePic = useCallback(() => {
    setShowButtons(false)
    removePic(`${APIS.Users}/${personalData?.id}`).then((res) => {
      setTriggerUserApi((prev) => !prev)
      if (res?.success) {
        successNotification("Image Removed");
      }
    })
  }, [personalData?.id]);

  const {svg: {avatar_dark, avatar_light}} = imageConfig;

  return (
    <div className={styles.mainContainer}>
      <ReactModal
        visible={showKYCReview}
        onClose={() => setShowKYCReview(false)}
        closeBtn={true}
        outerClassName={classNames(styles.confirmationModalOuter, styles.KYCReviewModal)}
      >
        <KycReview 
          handleClose={()=>setShowKYCReview(false)}
        />
      </ReactModal>
      {openIssuerNameModal && (
        <IssuerNameModal
          openIssuerNameModal={openIssuerNameModal}
          setOpenIssuerNameModal={setOpenIssuerNameModal}
        />
      )}
      {openAccreditation && <NewModal
        isOpen={openAccreditation}
        closeModal={() => setOpenAccreditation(false)}
        modalName={""}
        showCloseBtn={true}
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        className={`new-react-modal accreditation-upgrade-modal`}>
        <UpgradeAccreditation isProfile onClose={() => setOpenAccreditation(false)} />
      </NewModal>}

      {/* {showUserEditModal && renderEditUserDetailsModal} */}
      {openDvpModal && <DvpModals openDvpModal setOpenDvpModal={setOpenDvpModal} />}
      {isCancelDvpRequest && renderCancelModal}
      <div className={styles.firstContainer}>
        <div className={styles.profilePic}>
          {/* <img src={darkMode.value ? avtarDark : avtarLight} alt="avatar" /> */}
          {picUploading || profileRefresh || picRemoving ? (
            <SkeletonLogo />
          ) : profilePic ? (
            <Image className={styles.brand_logo} url={profilePic} />
          ) : (
            <Image
              className={styles.brand_logo}
              fileName={
                darkMode.value
                  ? avatar_dark
                  : avatar_light
              }
            />
          )}
          {/*
          // Todo Pratham
           This will be implement later */}
        <div ref={buttonContainerRef}>
            <label className={styles.gallary}>
              <img
                className={styles.gallaryImage}
                src={galleryImg}
                alt="gallary"
                onClick={() => setShowButtons(!showButtons)}
              />
            </label>
            {showButtons && (
              <div className={styles.buttonsContainer}>
                <button className={styles.picButtons} onClick={() => {uploadRef.current.click()
                  setShowButtons(false)
                }}>
                  <i className={`ri-image-add-fill ${styles.picIcons}`}></i>
                  Upload Image
                </button>
                {!personalData?.isEmptyProfileImage && <button className={styles.picButtons} onClick={handleDeletePic}>
                  <i className={`ri-delete-bin-6-line ${styles.picIcons}`}></i>
                  Remove Image
                </button>}

              </div>
            )}
             <input
                  type="file"
                  id="upload-img"
                  accept="image/png, image/jpeg , image/jpg"
                  onChange={handleProfileChange}
                  ref={uploadRef}
                  hidden
                />
          </div>
        </div>
        <div>
          <h3 className={styles.name}>
            {!!onboardingData?.companyName
              ? onboardingData?.companyName
              :!!firstName ? firstName + " " + lastName
                : `${config?.name} User`}
          </h3>
          <div className={styles.accid}>
            Account ID : {personalData?.customerId}
          </div>
          <div className={styles.accid}>
            Account verification :{" "}
            {isOnboardingComplete ? (
              <span className={styles.accountVerified}>Verified</span>
            ) : (
              <>
                <span
                  className={styles.accountPending}>{!isAnyStatusPending ? "Processing" : "Pending"}</span>
                {(isAnyStatusPending || isAnyRejected) && <div
                  className={styles.accountInitiate}
                  onClick={handleOpenKycModal}
                >
                  {userDetails?.data?.sessionId ? "Reinitiate verification" : "Initiate Verification"}
                </div>}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.secondaryContainer}>
        <div className={styles.details}>
          <div className={styles.detailTitle}>Profile name</div>
          <div className={styles.detail}>
            {accountName || email || customerId}
            <i
              title="Edit Account Name"
              className={`ri-edit-line ${styles.editIcon}`}
              onClick={() => handleClickEditUser("accountName")}
            />
          </div>
        </div>
        {/* <div className={styles.details}>
          <div className={styles.detailTitle}>Email</div>
          <div className={styles.detail}>
            {email ? email : "N/A"}
            <i
              title="Edit Email"
              className={`ri-edit-line ${styles.editIcon}`}
              onClick={() => handleClickEditUser("email")}
            />
            {email ? (
              <>
                |
                {!!email && isVerifiedEmail ? (
                  <span className={styles.emailVerified}>Verified</span>
                ) : (
                  <span className={styles.emailResend} onClick={onClickResend}>
                    {loading ? (
                      <Loader className="loader-white" dimension={12} />
                    ) : (
                      "Resend verify"
                    )}
                  </span>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div> */}
         <div className={styles.details}>
      <div className={styles.detailTitle}>Email</div>
      <div className={styles.detail}>
        {/* Email Value with Tooltip */}
        <span
          id="emailTooltip" // Unique ID for the tooltip
          className={styles.emailValue}
        >
          {email ? email : "N/A"}
        </span>

        <ReactTooltip
          anchorId="emailTooltip"
          className={classNames("theme-tooltip",styles.emailTooltip)}
          place="bottom"
          content={email}
          style={{
            whiteSpace: "pre-wrap", 
            wordWrap: "break-word", 
          }}
        />

        {/* Edit Email Icon */}
        <i
          title="Edit Email"
          className={`ri-edit-line ${styles.editIcon}`}
          onClick={() => handleClickEditUser("email")}
        />

        {email && (
          <>
            |
            {!!email && isVerifiedEmail ? (
              <span className={styles.emailVerified}>Verified</span>
            ) : (
              <span className={styles.emailResend} onClick={onClickResend}>
                {loading ? (
                  <Loader className="loader-white" dimension={12} />
                ) : (
                  "Resend verify"
                )}
              </span>
            )}
          </>
        )}
      </div>
    </div>
        <div className={styles.details}>
          <div className={styles.detailTitle}>Phone</div>
          <div className={styles.detail}>
            {!!phone ? "(" + countryCode + ")" + " " + formatPhoneNumber(countryCode + phone) : "N/A"}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detailTitle}>KYC</div>
          <div className={styles.detail}>{renderKyc}</div>
        </div>
        {kyb && (
          <div className={styles.details}>
            <div className={styles.detailTitle}>KYB</div>
            <div className={styles.detail}>{renderKyb}</div>
          </div>
        )}
        <div className={styles.details}>
          <div className={styles.detailTitle}>AML</div>
          <div className={styles.detail}>{renderAmlStatus}</div>
        </div>
        <div className={styles.details}>
          <div
            className={styles.detailTitle}>
            Accreditation
            {accreditationStatus === "processing" &&
              <p className="mt-10">Accreditation {renderAccreditationLabel[accreditationType || ""] || ""}</p>}
          </div>
          <div>
            <div>{renderAccreditationStatus}</div>
            {accreditationStatus === "processing" && <div className={`mt-10 ${styles.detail}`}>
              <div className={cn(styles.dotColor, styles.pending)} />
              Under Review
            </div>}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detailTitle}>Issuer name</div>
          <div className={styles.detail}>
            {issuerName ? (
              <span className={styles.IssuerName}>
                <span className={styles.IssuerName__name}>{issuerName}</span>
              </span>
            ) : (
              <div className={styles.IssuerName}>
                N/A
                <span
                  className={styles.CreateIssuerName}
                  onClick={() => setOpenIssuerNameModal(true)}
                >
                  Create issuer name
                </span>
              </div>
            )}
          </div>
        </div>
        {isOnboardingComplete && kybStatus && personalData?.email && allowMpcCheck && <>
          <div className={styles.details}>
            <div
              className={styles.detailTitle}>DVP (Trade with no balance)

              {status === "REJECTED" &&
                <div className={styles.detailTitle_dvpRejected}>
                  <i className={`ri-error-warning-fill ${styles.rejectedIcon}`}></i>
                  <span>Your last DVP request has been rejected. You may submit a new request at any time.</span>
                </div>
              }
            </div>
            <div className={styles.detail}>
              {getDvpStatus(status)}
            </div>
          </div>
          {status === "ACCEPTED" && <div className={styles.dvpOuterdetails}>
            <div className={styles.dvpdetails}>
              <div className={styles.detailTitle}>DVP limit</div>
            </div>
            <div className={styles.dvpBar_wrapper}>
              {false && <div className={styles.dvpBar_container}>
                <ProgressBar currentAmount={90} totalAmount={100} />
              </div>}
              <div className={styles.dvpBar_Price}>
                <div className={styles.priceheading}>Total Limit</div>
                <div className={styles.priceValue}>${actualLimit}</div>
              </div>
            </div>
          </div>}
        </>}
        <div className={styles.details}>
          <div className={styles.detailTitle}>Wallet Address</div>
          <div className={styles.detail}>
            {personalData?.walletId ? personalData?.walletId : "N/A"}
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.detailTitle}>Address</div>
          <div className={styles.detail}>{address ? renderAddress : "N/A"}</div>
        </div>
      </div>
    </div>
  );
};
export default Myprofile;
