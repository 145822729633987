import React, { useEffect, useMemo, useState } from "react";
import styles from "./MpcSetupModal.module.sass";
import classNames from "classnames";
import { Button } from "@components/button";
import MpcQr from "@views/SignIn/components/mpc-wallet/mpc-qr";
import MpcSetupStatus from "@views/SignIn/components/mpc-wallet/mpc-setup-status";
import { useWebSocket } from "@hooks/web-socket";

import { REACT_APP_API_HOST as API_HOST } from "envs";

import { Image } from "@storybook";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { userDetailsState } from "@states/user";
import { useSetRecoilState } from "recoil";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

interface CustomWindow extends Window {
  __ENV?: {
    REACT_APP_API_HOST?: string;
  };
}
enum MpcModalType {
  // BENIFITS,
  QRCODE,
  SUCCESS,
  FAILURE,
}

const MpcSetupModal = ({ handleCancelMpcSetup }: any) => {
  const [mpcSetupState, setMpcSetupState] = useState<MpcModalType>(
    MpcModalType.QRCODE
  );
  const [mpcSocketStatus, setMpcSocketStatus] = useState<any>();
  const { socket } = useWebSocket();
  const { get: getUser } = useNetwork();
  const setUserDetail = useSetRecoilState(userDetailsState);
  const { config } = usePermissionContext();

  const {svg: {line, cross, app_qr_stage, app_qr_pp, app_qr_beta, app_qr_prod}} = imageConfig;

  useEffect(() => {
    socket?.on("mpc", (data: any) => {
      if (data?.type === "MESSAGE") {
        setMpcSocketStatus(data);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (mpcSocketStatus) {
      if (mpcSocketStatus?.isSuccess) {
        setMpcSetupState(MpcModalType.SUCCESS);
        getUser(APIS.Users).then((res: any) => {
          if (res) {
            setUserDetail(res);
          }
        });
        setTimeout(() => handleCancelMpcSetup(), 5000);
      } else {
        setMpcSetupState(MpcModalType.FAILURE);
      }
    }
  }, [mpcSocketStatus]);

  const customWindow = window as CustomWindow;
  const  API = typeof API_HOST !== "undefined" && API_HOST 
      ? API_HOST 
      : customWindow.__ENV?.REACT_APP_API_HOST;

  const isPP = /pp/gi.test(API || "");
  const isBeta = /beta/gi.test(API_HOST || "");
  const isProd = /api.satschel.com/gi.test(API_HOST || "");
  
  const hostQR = () => {
      if (isPP) {
          return app_qr_pp;
      } else if (isBeta) {
          return app_qr_beta;
      } else if (isProd) {
          return app_qr_prod;
      } else {
          return app_qr_stage;
      }
  };

  const renderMpcSetup = useMemo(() => {
    return (
      <>
        <div className={styles.MpcSetupHeader}>
          <h2 className={styles.MpcSetupHeader__heading}>Setup MPC wallet</h2>
          <p className={styles.MpcSetupHeader__subHeading}>
            You need to setup MPC wallet before placing order.
          </p>
        </div>
        <div className={styles.MpcSetupDescription}>
          <div className={styles.MpcSetupDescription__text}>
            Multi-Party Computation (MPC) Wallet secures assets by distributing
            private keys across multiple locations.
          </div>
          <div className={styles.MpcSetupBenifits}>
            <div className={styles.MpcSetupBenifits__title}>Benefits:</div>
            <div className={styles.MpcSetupBenifits__points}>
              <div className={styles.MpcSetupBenifits__points__Icon}>
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.mpcSetupcheckboxIcon
                  )}
                />
              </div>
              <div className={styles.MpcSetupBenifits__points__desc}>
                <p className={styles.MpcSetupBenifits__points__desc__heading}>
                  Enhanced Security
                </p>
                <p className={styles.MpcSetupDescription__text}>
                  Your private key is securely stored on your mobile device.
                </p>
              </div>
            </div>
            <div className={styles.MpcSetupBenifits__points}>
              <div className={styles.MpcSetupBenifits__points__Icon}>
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.mpcSetupcheckboxIcon
                  )}
                />
              </div>
              <div className={styles.MpcSetupBenifits__points__desc}>
                <p className={styles.MpcSetupBenifits__points__desc__heading}>
                  Recovery Options
                </p>
                <p className={styles.MpcSetupDescription__text}>
                  We provide recovery options in case your device is lost, or
                  you <br /> uninstall the application, ensuring you can always
                  access your assets.
                </p>
              </div>
            </div>
            <div className={styles.MpcSetupBenifits__points}>
              <div className={styles.MpcSetupBenifits__points__Icon}>
                <i
                  className={classNames(
                    `ri-checkbox-circle-fill`,
                    styles.mpcSetupcheckboxIcon
                  )}
                />
              </div>
              <div className={styles.MpcSetupBenifits__points__desc}>
                <p className={styles.MpcSetupBenifits__points__desc__heading}>
                  Convenience
                </p>
                <p className={styles.MpcSetupDescription__text}>
                  It's easy to set up—just perform face authentication on your
                  <br />
                  phone, and your key will be saved on the device.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.MpcSetupBtnContainer}>
          <Button
            label="Cancel"
            handleClick={handleCancelMpcSetup}
            type={classNames(styles.MpcSetupBtnContainer__cancel)}
          />
          {
            <Button
              label={"Next"}
              handleClick={() => setMpcSetupState(MpcModalType.QRCODE)}
              type={classNames(styles.MpcSetupBtnContainer__next)}
            />
          }
        </div>
      </>
    );
  }, []);

  const renderQrCode = useMemo(() => {
    return (
      <div className={styles.QrcodeContainer}>
        <div className={styles.QrcodeContainer__details}>
          <div className={styles.QrcodeContainer__details__heading}>
          Initiate the wallet setup
          </div>
          <div className={styles.QrcodeContainer__details__desc}>
            Scan the QR code to install the {config?.name} app and secure your assets
            on the go
          </div>
        </div>
        <div className={styles.QrcodeContainer__Qrcode}>
          <Image
            className={styles.QrcodeContainer__Qrcode__image}
            fileName={hostQR()}
          />
        </div>
      </div>
    );
  }, []);

  const renderSetupError = useMemo(() => {
    return (
      <div className={styles.mpc_status_container}>
        <div className={styles.mpc_status_container__DeclineIcon}>
          <img className={styles.icon} src={cross} alt="" />
        </div>
        <div>
          <h2 className={styles.mpc_status_container__heading}>
            Setup unsuccessful
          </h2>
          <p className={styles.mpc_status_container__para}>
            We encountered an issue with configuring and storing your private
            key. Please try again or contact us at{" "}
            <a className={styles.contactus} href="mailto:contact@liquidity.io">
              contact@liquidity.io
            </a>{" "}
            for any assistance.
          </p>
        </div>
      </div>
    );
  }, []);

  const renderSetupSuccess = useMemo(() => {
    return (
      <div className={styles.mpc_status_container}>
        <div className={styles.mpc_status_container__approvedIcon}>
          <img className={styles.icon} src={line} alt="" />
        </div>
        <div>
          <h2 className={styles.mpc_status_container__heading}>
            Setup successful
          </h2>
          <p className={styles.mpc_status_container__para}>
            Your MPC Wallet has been successfully setup. Your private key is now
            securely distributed across multiple locations.
          </p>
        </div>
      </div>
    );
  }, []);

  return (
    // <ReactModal
    //   visible={true}
    //   onClose={() => {}}
    //   closeBtn={true}
    //   maskClosable={false}
    //   outerClassName={classNames(styles.verfiyOrderModal)}
    // >
    <>
      {/* {mpcSetupState === MpcModalType.BENIFITS && renderMpcSetup} */}
      {mpcSetupState === MpcModalType.QRCODE && renderQrCode}
      {mpcSetupState === MpcModalType.SUCCESS && renderSetupSuccess}
      {mpcSetupState === MpcModalType.FAILURE && renderSetupError}
    </>
    // </ReactModal>
  );
};

export default MpcSetupModal;
