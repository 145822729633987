import { Button } from "components"
import cn from "classnames";
import { Image } from "@storybook";
import { useCallback } from "react";

import styles from "./removalRequest.module.sass"
import { useNavigate } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import { ROUTES } from "@routes/constants";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { LIQUIDITY } from "@views/exchange/constants";



const RequestCancel = () => {
    const navigate = useNavigate()
    const darkMode = useDarkMode()
    const { EXCHANGE } = ROUTES
    const { config } = usePermissionContext();
    const {logo: {Liquidity_Blue_Logo}} = imageConfig;
  

    const handleApprove = useCallback(() => {
        navigate(EXCHANGE)
    }, [EXCHANGE, navigate])
    
    const handleLogo = useCallback(()=>{
        navigate("/exchange")
    },[navigate])

    return (
        <div className={styles.requestDeniedSection}>
            <div >
                <Image
                    className={styles.logoContainer}
                    fileName={config?.name !== LIQUIDITY ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.dark : Liquidity_Blue_Logo}
                    props={{onClick:handleLogo}}
                />
            </div>
            <div className={styles.requestDenied__main}>
                <div className={styles.requestDenied__main__details}>
                    <div className={styles.requestDenied__main__heading}>Removal request denied</div>
                    <div>
                        <p className={styles.requestDenied__main__text}>You have denied owner’s request to remove you as a co-owner. Your reason for denying has sent to the owner.</p>
                    </div>

                </div >
                <div className={styles.request_cancel} >
                    <Button
                        label={"Close "}
                        handleClick={handleApprove}
                        type={cn(styles.deny)}
                    />
                </div>
            </div>
            <div className={styles.cancelRequest_Footer}>
                <div className={styles.invitaionSocial}>
                    <span className={styles.invitaionSocial__twitterLogo}>
                        <a href="https://www.linkedin.com/company/liquidity-io/" ><img src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/linkedIn.svg" alt="LinkedIn" /></a>
                    </span>
                    <span className={styles.invitaionSocial__twitterLogo}>
                        <a href="https://twitter.com/LiquidityIO" >  <img className={styles.invitaionSocial__twitterImg} src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/twitter-logo.png" alt="Twiiter" /></a></span>
                </div>
                <p className={styles.footerDisclaimer}>© 2024 Satschel Inc. All rights reserved.</p>
            </div>
        </div>

    )
}
export default RequestCancel