import { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";

import { useRecoilState, useRecoilValue } from "recoil";

import { profilTabState, userDropDownState } from "states";

import styles from "./ProfileNav.module.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { url } from "inspector";
import { NAVIGATION, COOWNER_NAVIGATION } from "../constant"


export const ProfileNav = () => {
  const [profileTab, setProfileTab] = useRecoilState(profilTabState);
  const navigate = useNavigate();
  const location = useLocation();
  const [visibleMenu, setVisibleMenu] = useState(false);
  const isActiveUser = useRecoilValue(userDropDownState);

  const activeItem = NAVIGATION.find((item) => profileTab === item.type);

  const handleProfileTab = useCallback(
    (title: string) => {
      // if (title === "profile") return;
      setProfileTab(title);
      setVisibleMenu(!visibleMenu);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visibleMenu]
  );

  const navigateUrl = (url: string) => {
    navigate(url);
  };

  const navMenu = useMemo(() => {
    return !isActiveUser.isPrimary
      ? COOWNER_NAVIGATION
      : NAVIGATION;
  }, [isActiveUser.isPrimary]);


  return (
    <div className={cn(styles.wallet)}>
      <div className={styles.sidebar}>
        <div className={cn(styles.group, { [styles.active]: visibleMenu })}>
          <div
            className={styles.top}
            onClick={() => setVisibleMenu(!visibleMenu)}
          >
            <div className={styles.bg}></div>
            {activeItem?.title}
          </div>

          <div className={styles.menu}>
            {navMenu.map((item, index) => (
              <div
                className={cn(
                  styles.item, item.title === "My Wallet" && { [styles.active]: location.pathname.startsWith("/profile/accounts") || location.pathname.startsWith("/profile/transactions") },
                  location.pathname.endsWith(item.url) ? styles.active : ""
                )}
                key={index}
                onClick={() => {
                  navigateUrl(item.url);
                  handleProfileTab(item.type);
                }}
              >
                <div className={styles.bg}>
                  <i
                    className={
                      location.pathname.startsWith(item.url)
                        ? item.activeIcon
                        : item.icon
                    }
                  ></i>
                </div>

                {item.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
