import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { ActiveCurrencyState } from "@states/exchange";
import { APIS } from "constant";
import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";

export const UserPublishNowApi =()=> {
    const {get: getPublishNow,loading:ListedCompanyLoading} = useNetwork()
    const {post: linkAComapny, loading:LinkCompanyLoading} = useNetwork()
    const [ListedComapny,setListtedComapany]=useState([])
    const {successNotification,errorNotification}=useNotification()
    const [isLinkedAsset,setIsLinkedAsset]=useState(false)
    const activeCurrency = useRecoilValue(ActiveCurrencyState);

    const GetListedComapanyData = useCallback(()=>{
        try {
            getPublishNow(`${APIS.LIST_COMAPANIES}?assetId=${activeCurrency?.id}`).then((res)=>{
                    if(res){
                        setListtedComapany(res?.data || []);
                    }
            })
        } catch (error) {
            console.error(error);
        }
    },[activeCurrency?.id, getPublishNow])
    
    const LinkCompany = useCallback(({assetId, companyId,shareClassType,companyName,shareClassId,action,setIsSelectedLinked}: { assetId: string, companyId: string ,shareClassType:string, companyName: string, shareClassId: string, action: string, setIsSelectedLinked: (value: boolean) => void })=>{
        try {
            const payload = {
                assetId,
                companyId,
                companyName,
                shareClassId,
                shareClassType,
                action,
            };
            linkAComapny(APIS.LINK_COMAPANY,payload).then((res)=>{
                    if(res?.assetId ){
                        successNotification(res?.message)
                        setIsLinkedAsset(true)
                    }else if(res?.message.includes("Company details already linked for assetId")){
                        errorNotification("Company already listed for this asset")
                        setIsLinkedAsset(true)
                    }else{
                        setIsLinkedAsset(false)
                    }
                    GetListedComapanyData()
            })
        } catch (error) {
            console.error(error);
        }
    },[GetListedComapanyData, errorNotification, linkAComapny, successNotification])


    return{
        GetListedComapanyData,ListedComapny,ListedCompanyLoading,LinkCompanyLoading,LinkCompany,isLinkedAsset
    }
}