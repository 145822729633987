import { ReactResponsiveTable } from "@storybook/react-responsive-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { AuctionListState } from "states";
import { Json } from "types";
import {
  useNetwork,
  useRemainingSecPercentage,
  useNotification,
  usePrevious,
  useCurrency,
} from "hooks";
import { DOCUMENTS_HEADER } from "views/auction/constant";
import { APIS } from "constant";
import { CountdownTimer } from "components/counter";
import { ReactModal } from "components";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import { Confirmation } from "@storybook";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { differenceInHours } from "date-fns";
import { getFormattedDate, getFormattedTime } from "@utils/getTime";
import BidSheetModal from "../bidSheetModal/bidSheetModal";
import useAuctionHook from "@views/AuctionConsumer/hook";

const RenderProgresBar = ({ startTime, endTime, status }: Json) => {
  const secPercentage = useRemainingSecPercentage(startTime, endTime);

  return (
    <CircularProgressbar
      counterClockwise={true}
      className="counter-circle"
      value={
        status === "yet-to-start"
          ? 100
          : status === "completed"
            ? 0
            : secPercentage || 0
      }
      maxValue={100}
      strokeWidth={10}
    />
  );
};

export const AuctionList = () => {
  const navigator = useNavigate();
  const [deleteAuctionId, setDeleteAuctionId] = useState<string | null>(null);
  const [AuctionDataList, setAuctionDataList] =
    useRecoilState(AuctionListState);
  const { remove: deleteAssetData, loading: deleteloading } = useNetwork();
  const { data: AuctionData, loading } = AuctionDataList;
  const prevLoading = usePrevious(loading);
  const { errorNotification, successNotification } = useNotification();
  const { formatCurrencyWithBillion } = useCurrency();

  const [auctionFormData, setAuctionFormData] = useState<any>(null);
  const { sbaAndNormalPrice } = useAuctionHook();

  useEffect(() => {
    setDeleteAuctionId(null);
  }, []);

  const deleteAuction = async (e: any, Id: string) => {
    e.stopPropagation();
    setDeleteAuctionId(Id);
  };
  const handleBidSheetModal = async (e: any, item: Json) => {
    e.stopPropagation();
    setAuctionFormData(item);
  };

  const formatString = (val: string) => {
    if (val === "yet-to-start") {
      return "--";
    }
    if (val.includes("-")) {
      return val.replaceAll("-", " ");
    }
    return val;
  };

  const rendererTimeLeft = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, id: string) => {
      if (completed) {
        setAuctionDataList((prev) => {
          let temp = JSON.parse(JSON.stringify(prev?.data?.data));
          const idx = temp.findIndex((_data: Json) => _data?.id === id);
          temp[idx] = { ...temp[idx], status: "completed" };
          return { ...prev, data: { ...prev.data, data: [...temp] } };
        });
      }
      return (
        <span className="auction__table-status">
          <i className="circle-green" />
          Live
          <br />
          {!!days ? days + "d " : ""}
          {!!hours ? hours + "h " : ""}
          {minutes}m {seconds}s
        </span>
      );
    },
    []
  );

  const rendererTimeToStart = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, id: string) => {
      if (completed) {
        setAuctionDataList((prev) => {
          let temp = JSON.parse(JSON.stringify(prev?.data?.data));
          const idx = temp.findIndex((_data: Json) => _data?.id === id);
          temp[idx] = { ...temp[idx], status: "live" };
          return { ...prev, data: { ...prev.data, data: [...temp] } };
        });
      }

      return (
        <span className="auction__table-status">
          <i className="circle-dark" />
          Yet to start
          <br />
          {!!days ? days + "d " : ""}
          {!!hours ? hours + "h " : ""}
          {minutes}m {seconds}s
        </span>
      );
    },
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentPrice: Json = {
    "yet-to-start": "startPrice",
    live: "currentBidPrice",
    completed: "currentBidPrice",
  };

  const editAuctions = useCallback((event: any, auctionId: string) => {
    event.stopPropagation();
    navigator(`/admin/auction-edit/${auctionId}`);
  }, []);

  const isButtonEnabled = (status: string, totalBid: number, dateTime: any) => {
    if (status === "completed" || (status === "live" && totalBid >= 1))
      return false;
    if (status === "yet-to-start") return true;
    else {
      const currentDateTime = new Date();
      const totalHours = differenceInHours(currentDateTime, new Date(dateTime));
      if (totalHours <= 12 && totalBid === 0) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (AuctionData?.message !== "ok" && !loading && prevLoading) {
      errorNotification(AuctionData?.message);
    }
  }, [AuctionData]);

  const tableRows = useMemo(() => {
    if (!AuctionData?.data) return [];
    const rows: any = [];
    AuctionData?.data?.forEach((items: any) => {
      if (items) {
        let row: any = {};
        DOCUMENTS_HEADER.Header.forEach(({ format, key }) => {
          row.tradeType = items.tradeType ?? "--";
          row.id = items.id;
          row.auctionStatus = items.status;
          const { status } = items;

          if (format === "jsx" && key === "price") {
            const value = () => (
              <div className="auction-price">
                <p className="auction-main-price">
                  {sbaAndNormalPrice(items, {
                    price: items[currentPrice[status]]
                  })}
                </p>

                {items.product === "SBA-7" && items.category === "Loans" && (
                  <p className="auction-sub-price">
                    {formatCurrencyWithBillion(
                      items[currentPrice[status]],
                      2)}
                  </p>
                )}
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && (key === "startTime" || key === "endTime")) {
            const value = () => (
              <div className="auction__table-date-container">
                <p className="auction__table-date">
                  {!!items?.[key] && getFormattedDate(items?.[key])}
                </p>
                <p className="auction__table-time">
                  {!!items?.[key] && getFormattedTime(items?.[key])}
                </p>
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && key === "status") {
            const value = () => (
              <div className="flex-center">
                <div className="auction__table-date-container auction-date-flex-center">
                  <RenderProgresBar
                    startTime={items.startTime}
                    endTime={items.endTime}
                    status={status}
                  />

                  {status === "completed" && (
                    <span className="auction__table-status">
                      <i className="circle-red" />
                      Ended
                      <br />
                      00h 00m 00s
                    </span>
                  )}
                  {status === "yet-to-start" && (
                    <CountdownTimer
                      dateTime={items.startTime}
                      renderer={(timeValue) =>
                        rendererTimeToStart(timeValue, items.id)
                      }
                    />
                  )}
                  {status === "live" && (
                    <CountdownTimer
                      dateTime={items.endTime}
                      renderer={(timeValue) =>
                        rendererTimeLeft(timeValue, items.id)
                      }
                    />
                  )}
                </div>
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && key === "product") {
            const assetIcon =
              items.assetIcon === ""
                ? "https://upload.wikimedia.org/wikipedia/commons/5/5f/Grey.PNG"
                : items.assetIcon;
            const assetName = items.name ?? "--";
            const description = items.description ?? "--";
            const value = () => (
              <div className="auction__table-product">
                <img src={assetIcon} alt="logo"></img>
                <div className="auction__table-product-desc">
                  <span
                    className="product-name"
                    data-tooltip-id={"name" + items.id}
                  >
                    {assetName}
                  </span>
                  {assetName?.length >= 25 && (
                    <ReactTooltip
                      className="theme-tooltip"
                      id={"name" + items.id}
                      place="bottom"
                      content={assetName}
                    />
                  )}
                  <p className="product-desc">{description}</p>
                </div>
              </div>
            );
            return (row[key] = value);
          }

          if (format === "jsx" && key === "action") {
            const value = () => (
              <div className="asset-action-icons">
                <div
                  style={{
                    cursor:
                      isButtonEnabled(
                        status,
                        items.totalBid,
                        items.startTime
                      ) &&
                      status !== "live"
                        ? "auto"
                        : "not-allowed",
                  }}
                >
                  <span
                    style={{
                      pointerEvents:
                        isButtonEnabled(
                          status,
                          items.totalBid,
                          items.startTime
                        ) &&
                        status !== "live"
                          ? "auto"
                          : "none",
                    }}
                    className="ri ri-edit-box-line"
                    onClick={(event) => editAuctions(event, items.id)}
                  ></span>
                </div>
                {items?.product === "SBA-7" && items?.category === "Loans" && (
                  <span
                    className="ri ri-file-upload-line"
                    onClick={(event) => handleBidSheetModal(event, items)}
                  />
                )}
                <div
                  style={{
                    cursor:
                      isButtonEnabled(status, items.totalBid, items.startTime)
                        ? "auto"
                        : "not-allowed",
                  }}
                >
                  <span
                    style={{
                      pointerEvents:
                        isButtonEnabled(status, items.totalBid, items.startTime)
                          ? "auto"
                          : "none",
                    }}
                    onClick={(event) => {
                      deleteAuction(event, items.id);
                    }}
                    className="ri ri-delete-bin-line"
                  ></span>
                </div>
              </div>
            );
            return (row[key] = value);
          }
        });
        rows.push(row);
      }
    });

    return rows;
  }, [
    AuctionData.data,
    currentPrice,
    editAuctions,
    rendererTimeLeft,
  ]);

  const AuctionDetails = (data: any) => {
    const { id: auctionId, auctionStatus } = data;
    if (auctionStatus === "completed") {
      navigator(`/user-bid-details/${auctionId}`);
    } else {
      navigator(`/admin/auction-details/${auctionId}`);
    }
  };

  const handlePatchDeleteAuction = async () => {
    try {
      const response = await deleteAssetData(
        `${APIS.Auction}/${deleteAuctionId}`, {}, { apiResponse: true }
      );
      if (response?.message === "ok") {
        successNotification("Auction Deleted Successfully");
        const currentAssetAdterDeletion = AuctionData?.data?.filter(
          (items: Json) => items.id !== deleteAuctionId
        );
        setAuctionDataList((prev) => ({
          ...prev,
          data: { message: "ok", data: [...currentAssetAdterDeletion] },
        }));
      } else {
        errorNotification(response?.message);
      }
    } catch (err: any) {
      errorNotification(err);
    } finally {
      setDeleteAuctionId(null);
    }
  };

  const handleUpdateFiles = useCallback((filesObj: Json) => {
    setAuctionDataList((prev) => {
      let temp = JSON.parse(JSON.stringify(prev?.data?.data));
      const idx = temp.findIndex((_data: Json) => _data?.id === auctionFormData?.id);
      temp[idx]?.files?.forEach((file: Json, index: number) => {
        if (file?.uploadType) temp[idx].files[index] = filesObj?.[file?.uploadType] || file;
      })
      temp[idx] = { ...temp[idx] };
      return { ...prev, data: { ...prev.data, data: [...temp] } };
    });
  }, [auctionFormData])

  return (
    <>
      <ReactResponsiveTable
        className="auction__container-body"
        rows={tableRows}
        column={DOCUMENTS_HEADER.Header}
        isLoading={loading}
        numberOfList={10}
        handelRowClick={(data: any) => AuctionDetails(data)}
      />
      {!!deleteAuctionId && (
        <ReactModal
          visible={!!deleteAuctionId}
          onClose={() => setDeleteAuctionId(null)}
        >
          <Confirmation
            title="Are you sure?"
            visible={!!deleteAuctionId}
            description="Deleting auction will remove it from the auction list. Do you want to delete?"
            handleModal={handlePatchDeleteAuction}
            handleClose={() => setDeleteAuctionId(null)}
            label="Delete"
            cancelLabel="Cancel"
            type="danger"
            cancelLoading={deleteloading}
          />
        </ReactModal>
      )}
      {!!auctionFormData && (
        <BidSheetModal
          open={!!auctionFormData}
          details={auctionFormData}
          onCallBack={handleUpdateFiles}
          onClose={() => setAuctionFormData(null)}
        />
      )}
    </>
  );
};
