import { IWaiting } from "views/SignIn/type";

import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useCookie, useNetwork, useNotification } from "hooks";
import { APIS, loginToasterMessages } from "constant";
import { ROUTES } from "routes";
import { LoginPhoneNumberState, LoginUserState } from "states";
import { imageConfig } from "@utils/imageConfig";

import style from "./waiting.module.sass";

let isEventCalled = false;
export const Waiting: FC <IWaiting> = ({setIsWaiting}) => {
  const loginNumber = useRecoilValue(LoginPhoneNumberState);
  const [checkStatus, setCheckStatus] = useState<boolean>(false);
  const setLoginUser = useSetRecoilState(LoginUserState);

  const { LOGIN_SUCCESS } = loginToasterMessages;
  const { successNotification } = useNotification();
  const navigate = useNavigate();
  const { set: setCookieToken } = useCookie();
  const { post } = useNetwork();
  const { post: loginStatus } = useNetwork();
  const { EXCHANGE ,NO_ROUTE} = ROUTES;

  const {svg: {clock}} = imageConfig;

  const sendConfirmation = useCallback(async () => {
    const payload = {
      channelName: "users",
      data: {
        phone: loginNumber?.phone,
        countryCode: loginNumber?.countryCode,
      },
      eventName: `connect:${loginNumber?.phone}`,
    };

    if (loginNumber?.phone?.length > 0) {
      post(APIS.WaitingLogin, payload)
        .then((resp) => {
          if (resp?.success) {
            isEventCalled = true;
          }
        })
        .catch((err) => {});
    }
  }, [loginNumber?.countryCode, loginNumber?.phone, post]);

  useEffect(() => {
    sendConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginNumber]);

  const getStatus = useCallback(async () => {
    const payload = {
      channelName: "webUser",
      data: {
        phone: loginNumber?.phone,
        countryCode: loginNumber?.countryCode,
      },
      eventName: `permission`,
    };
    loginStatus(APIS.LoginStatus, payload)
      .then((resp) => {
        const { token, status } = resp ?? {};
        if (status === "CONNECTED") {
          const loginDetails = {
            token,
            isLoggedIn: true,
          };
          if (token) {
            setCookieToken("userDetails", loginDetails);
            successNotification(LOGIN_SUCCESS);
            setLoginUser((prevState) => {
              const newState = { ...prevState, isLoggedIn: true, token };
              return newState;
            });
            navigate(NO_ROUTE);
          }
        }
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginNumber, navigate]);

  useEffect(() => {
    if (isEventCalled) {
      getStatus();
    }
    const timeoutId = setTimeout(() => {
      setCheckStatus((pre) => !pre);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return()=>{
      clearTimeout(timeoutId);
    }
  }, [checkStatus, isEventCalled]);

  const handleBack = useCallback(()=>{
   setIsWaiting(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className={style.wrapper}>
      <div className={style.inner_wrapper}>
        <div className={style.inner}>
          <img src={clock} alt="waiting" className={style.render_image} />
          <div className={style.title}>Waiting For Approval</div>
          <div className={style.sub_title}>
            Open Liquid app and give access to login the liquid exchange app.
          </div>
          <div className={style.back_btn} onClick={handleBack}>
            <div className={style.method_title}>Back</div>
          </div>
        </div>
      </div>
    </div>
  );
};
