import React from "react";
import "./wallet-header.scss";
import { useNavigate } from "react-router-dom";

export const WalletHeader = (props: any) => {
  const navigate = useNavigate();
  const { title } = props;
  return (
    <header>
      <div className="transaction-header">
        <div className="header-left">
          <span onClick={() => navigate(`/wallet`)}>
            <i className="ri ri-arrow-left-s-line"></i>
          </span>
          <p>{title}</p>
        </div>
      </div>
    </header>
  );
};
