import { useCallback, useState } from "react";
import { useNotification } from "@hooks/notification";
import { UseTransaction } from "@views/MyWallet/store/hooks";
import { ApplePayPayment, ApplePayPaymentRequest } from "./type";
import { useApplePayCalls } from "./hook";

const useApplePay = () => {
  const [hasApplePayTransactionError, setApplePayTransactionError] =
    useState(false);
  const { handleValidMerchantCall, handlePayment } = useApplePayCalls();
  const { getTransactions, getFortressAccountInfo } = UseTransaction();

  const updateApplePayTransactionError = (
    hasError: boolean | ((prevState: boolean) => boolean)
  ) => {
    setApplePayTransactionError(hasError);
  };

  const { errorNotification, successNotification } = useNotification();

  const handleApplePayClick = useCallback(
    (amount: string, handleClose: (value?: boolean) => void) => {
      setApplePayTransactionError(false);
      if (!window.ApplePaySession) {
        errorNotification("Apple Pay is not supported on this device.");
        return;
      }

      const paymentRequest: ApplePayPaymentRequest = {
        countryCode: "US",
        currencyCode: "USD",
        merchantCapabilities: ["supports3DS"],
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        total: { label: "your payment", amount: amount },
        requiredBillingContactFields: ["postalAddress"],
      };

      const session = new ApplePaySession(6, paymentRequest);
      session.oncancel = () => {
        setApplePayTransactionError(true);
        errorNotification("Transaction Failed.");
      };
      session.onvalidatemerchant = async (event: { validationURL: string }) => {
        const resp = await handleValidMerchantCall(event.validationURL);
        if (resp) {
          // For now debug purpose for stage only
          console.log("Response for validate ", resp);
          if(resp?.session_details){
          session.completeMerchantValidation(JSON.parse(resp?.session_details));
          }
        }
      };

      session.onpaymentauthorized = async (event: {
        payment: ApplePayPayment;
      }) => {
        const paymentToken = event?.payment;

        if (paymentToken) {
          const paymentTokenString = JSON.stringify(paymentToken);
          const resp = await handlePayment(amount, paymentTokenString);
          if (resp?.transactionId) {
            session.completePayment(0);
            getTransactions();
            getFortressAccountInfo();
            successNotification(
                "Your money will be successfully deposited into your account shortly."
            );
            handleClose?.(true);
          } else {
              session.completePayment(1);
              errorNotification("Transction Failed. Please try another method.");
              handleClose();
            }
        }
      };

      session.begin();
    },
    [
      errorNotification,
      getFortressAccountInfo,
      getTransactions,
      handlePayment,
      handleValidMerchantCall,
      successNotification,
    ]
  );

  return {
    handleApplePayClick,
    hasApplePayTransactionError,
    updateApplePayTransactionError,
  };
};

export default useApplePay;
