import Modal from "components/Modal";
import { useMemo, useState } from "react";
import { useNotification } from "hooks";
import { Button, Input } from "components";
import "./edit-data-room-files.scss";

export const EditDataRoomFileNameModal = ({
  showModal,
  hideAuctionModalOnClick,
  data,
  updateDataRoomFile,
  files,
}: any) => {
  const editedFileName = useMemo(() => {
    const editedName = data?.fileName?.split(".");
    editedName.pop();
    return editedName.join(".");
  }, [data.fileName]);

  const { errorNotification } = useNotification();
  const [fileName, setFileName] = useState<string | number>(
    editedFileName ?? ""
  );

  const closeModal = () => {
    hideAuctionModalOnClick();
  };

  const updateFile = () => {
    const extension = data?.fileName?.split(".").pop();
    const fileIndex = files?.findIndex(
      (file: any) => file.fileName === fileName
    );
    if (fileIndex === -1) {
      updateDataRoomFile(data.fileId, `${fileName}.${extension} `);
    } else {
      errorNotification("A file with the same name already exist");
    }
  };

  return (
    <Modal
      title=""
      outerClassName="asset-modal"
      visible={showModal}
      onClose={closeModal}
    >
      <div className="asset-modal-container">
        <div className="asset-modal-header">
          <p>Edit name</p>
        </div>
        <div className="asset-Modal-form">
          <Input
            inputType="text"
            label="Product Name"
            placeholder=""
            value={fileName}
            handleChange={(e) => setFileName(e.target.value)}
            inputName="name"
            required={true}
            maxLength={50}
          />
        </div>
        <div className="create-asset-btn">
          <Button
            label="Cancel"
            type="btn-grey btn-h-auto"
            buttonType="button"
            handleClick={hideAuctionModalOnClick}
          />
          <Button
            disabled={!fileName}
            label="Save"
            type="button-green asset-btn"
            buttonType="button"
            handleClick={updateFile}
          />
        </div>
      </div>
    </Modal>
  );
};
