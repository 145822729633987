import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import UseOrdersApi from "@views/exchange/components/Orders/store/hook";
import "./my-orders.scss";
import { ORDERS_PAGE_TABS } from "./constants";
import { useSearchParams } from "react-router-dom";
import OrdersList from "./components/OrdersList";
import { useResetRecoilState } from "recoil";
import { ActiveCurrencyState } from "@states/exchange";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

const MyOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const resetCurrencyData = useResetRecoilState(ActiveCurrencyState);
  const ordersTabactive = searchParams.get("tab") || "PENDING";
  const { fetchOrders } = UseOrdersApi();
  const [activeTab, setActiveTab] = useState(ordersTabactive);
  const [ordersData, setOrdersData] = useState({});
  const { trackPageEvent } = useFullStoryTrackEvent();

  useEffect(() => {
    trackPageEvent({
      pageName: 'Orders',
    });
  }, []);

  useLayoutEffect(() => {
    const validTabsArray = ["PENDING", "EXECUTED", "CANCELLED"];
    if (!validTabsArray.includes(ordersTabactive.toString())) {
      setActiveTab(validTabsArray[0]);
    } else {
      setActiveTab(ordersTabactive as string);
    }
  }, [ordersTabactive]);

  const handleTabChange = useCallback(
    (key: string) => {
      setActiveTab(key);
      setSearchParams(`?tab=${key}`);
    },
    [setSearchParams]
  );

  useEffect(() => {
    resetCurrencyData();
    const options = { updateNewData: false };
    const query = {
      limit: 20,
      offset: 0,
      type: activeTab,
    };
    fetchOrders(query, activeTab, options,setOrdersData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <div className="orders-container">
      <div className="orders-header">
        <h1 className="heading">Orders</h1>
        {/* <div className="order-filter">
          <i className="ri-filter-2-line" />
          Filters
        </div> */}
      </div>
      <div className="orders-tabs">
        {ORDERS_PAGE_TABS.map((tab, index) => (
          <div
            key={tab.type}
            className={`orders-tabs_item ${
              activeTab === tab.type ? "active" : ""
            }`}
            onClick={() => handleTabChange(tab.type)}
          >
            {tab.title}
          </div>
        ))}
      </div>

      <OrdersList activeTab={activeTab} ordersData={ordersData} setOrdersData={setOrdersData}/>
    </div>
  );
};

export default MyOrders;
