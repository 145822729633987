import env from "@beam-australia/react-env";

declare global {
  interface Window {
	__ENV?: {
	  REACT_APP_API_HOST: string;
	  REACT_APP_API_HOST_2: string;
	  REACT_APP_HOST_URL: string;
	  REACT_APP_ENVIRONMENT: string;
	  REACT_APP_FIREBASE: string;
	  REACT_APP_ALLOW_MPC: string;
	  REACT_APP_SOCKET_URL: string;
	  REACT_APP_ONYXPLUS_CLIENT_SECRET: string;
	  REACT_APP_ONYXPLUS_OIDC_TOKEN_URL: string;
	  REACT_APP_GOOGLE_SSO_URL: string;
	  REACT_APP_ONYXPLUS_SSO_URL: string;
	  REACT_APP_CAPTABLE_URL: string;
	  REACT_APP_GOOGLE_PAY_ENV_TYPE: string;
	  REACT_APP_FINIX_CARD_ENV_TYPE: string;
	  REACT_APP_CLIENT_ID: string;
	};
  }
}

export const REACT_APP_API_HOST = env("API_HOST") || window?.__ENV?.REACT_APP_API_HOST || "https://api.satschel.com/v1";
export const API_HOST_2 = env("API_HOST_2") || window?.__ENV?.REACT_APP_API_HOST_2 || "https://api.satschel.com/v2";
export const REACT_APP_HOST_URL = env("HOST_URL") || window?.__ENV?.REACT_APP_HOST_URL || "https://liquidity.io"; 
export const ENVIRONMENT = env("ENVIRONMENT") || window?.__ENV?.REACT_APP_ENVIRONMENT || "prod";
export const REACT_APP_FIREBASE = env("FIREBASE") || window?.__ENV?.REACT_APP_FIREBASE || "{\"apiKey\":\"AIzaSyC3OT7qYZDnw2nxhhhf59SGe34Kg6k-Ihw\",\"authDomain\":\"liquidity-pp.firebaseapp.com\",\"projectId\":\"liquidity-pp\",\"storageBucket\":\"liquidity-pp.appspot.com\",\"messagingSenderId\":\"30392363559\",\"appId\":\"1:30392363559:web:b74d73a6632ac4af5abc02\",\"measurementId\":\"G-VHHZN2EV4T\",\"databaseURL\":\"https://liquidity-pp-default-rtdb.firebaseio.com/\"}";
export const SOCKET_URL = env("SOCKET_URL") || window?.__ENV?.REACT_APP_SOCKET_URL || "https://socket.satschel.com";
export const ALLOW_MPC_ENV = JSON.parse(env("ALLOW_MPC") || window?.__ENV?.REACT_APP_ALLOW_MPC || "false");
export const ONYXPLUS_CLIENT_SECRET = env("ONYXPLUS_CLIENT_SECRET") || window?.__ENV?.REACT_APP_ONYXPLUS_CLIENT_SECRET;
export const ONYXPLUS_OIDC_TOKEN_URL = env("ONYXPLUS_OIDC_TOKEN_URL") || window?.__ENV?.REACT_APP_ONYXPLUS_OIDC_TOKEN_URL;
export const GOOGLE_SSO_URL = env("GOOGLE_SSO_URL")  || window?.__ENV?.REACT_APP_GOOGLE_SSO_URL;
export const ONYXPLUS_SSO_URL = env("ONYXPLUS_SSO_URL") || window?.__ENV?.REACT_APP_ONYXPLUS_SSO_URL;
export const CAPTABLE_URL =
  env("CAPTABLE_URL") ||
  window?.__ENV?.REACT_APP_CAPTABLE_URL ||
  "http://localhost:3000/";
export const GOOGLE_PAY_ENV_TYPE = env("GOOGLE_PAY_ENV_TYPE") || window?.__ENV?.REACT_APP_GOOGLE_PAY_ENV_TYPE || "PRODUCTION";
export const FINIX_CARD_ENV_TYPE = env("FINIX_CARD_ENV_TYPE") || window?.__ENV?.REACT_APP_FINIX_CARD_ENV_TYPE || "live";
export const CLIENT_ID = env("CLIENT_ID") || window?.__ENV?.REACT_APP_CLIENT_ID;

