import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { SOCKET_URL } from "../../envs";
import { useCookie } from "@hooks/cookie";
import { useRecoilValue } from "recoil";
import { LoginUserState } from "@states/user";

interface IData {
  [key: string]: string;
}

// Singleton instance for the socket connection
let globalSocketConnection: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;

export const useWebSocket = () => {
  const { get: getTokenFromCookie } = useCookie();
  const [messages, setMessages] = useState<IData[]>([]);
  const loggedinUser = useRecoilValue(LoginUserState);
  const [socketConnection, setSocketConnection] = useState<Socket<
    DefaultEventsMap,
    DefaultEventsMap
  > | null>(null);

  useEffect(() => {
    if (!globalSocketConnection) {
      const user = getTokenFromCookie("userDetails");

      if (SOCKET_URL) {
        globalSocketConnection = io(SOCKET_URL, {
          reconnectionDelayMax: 10000,
          transports: ["websocket"],
          withCredentials: true,
          auth: user || loggedinUser,
        });
      } else {
        console.error("SOCKET_URL is not defined");
      }

      globalSocketConnection?.on("connect", () => {
        console.log("WebSocket connected");
        setSocketConnection(globalSocketConnection); // Set the state after connection is established
      });

      globalSocketConnection?.on("message", (data: IData) => {
        setMessages((prevMessages) => [...prevMessages, data]);
      });

      globalSocketConnection?.on("disconnect", (reason) => {
        console.log("WebSocket disconnected:", reason);
      });
    } else {
      setSocketConnection(globalSocketConnection); // Set the state immediately if connection already exists
    }

    return () => {
      // Optionally handle disconnection here if needed
    };
  }, [getTokenFromCookie, loggedinUser]);

  const subscribeToSymbol = (symbol: string) => {
    if (socketConnection) {
      socketConnection.emit("subscribe", { symbol });
    }
  };

  const receiveMessagesForSymbol = (
    symbol: string,
    callback: (data: IData) => void
  ) => {
    if (socketConnection) {
      socketConnection.on(symbol, (data: IData) => {
        callback(data);
      });
    }
  };

  const unsubscribeFromSymbol = (symbol: string) => {
    if (socketConnection) {
      socketConnection.emit("unsubscribe", { symbol });
    }
  };

  return {
    subscribeToSymbol,
    unsubscribeFromSymbol,
    messages,
    receiveMessagesForSymbol,
    socket: socketConnection,
  };
};
