export const DOCUMENTS_HEADER = {
  Header: [
    {
      label: "Product ID & Name",
      key: "product",
      width: "30%",
      format: "jsx",
    },
    {
      label: "Auction Type",
      key: "tradeType",
      width: "10%",
      format: "string",
    },
    {
      label: "Total Price",
      key: "price",
      width: "10%",
      format: "jsx",
    },
    {
      label: "Auction Start",
      key: "startTime",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Auction End",
      key: "endTime",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Auction Status",
      key: "status",
      width: "17%",
      format: "jsx",
    },
    {
      label: "Actions",
      key: "action",
      width: "20%",
      format: "jsx",
    },
  ],
};

export const AUCTION_DRAFTS_HEADER = {
  Header: [
    {
      label: "Type",
      key: "tradeType",
      width: "20%",
      format: "string",
    },
    {
      label: "Product Name",
      key: "product",
      width: "50%",
      format: "jsx",
    },
    {
      label: "Date & time",
      key: "date",
      width: "20%",
      format: "jsx",
    },
    {
      label: "Actions",
      key: "action",
      width: "10%",
      format: "jsx",
    },
  ],
};
export type statusType = {
  live: string;
  completed: string;
  "yet-to-start": string;
};

export const DOCUMENTS_HEADER_SBA = {
  Header: [
    {
      label: "Lender loan number",
      key: "loan_number",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Borrower  name",
      key: "borrower_name",
      width: "22%",
      format: "string",
    },
    {
      label: "First payment date",
      key: "first_payment_date",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Maturity date",
      key: "maturity_date",
      width: "10%",
      format: "jsx",
    },
    {
      label: "Original loan amount",
      key: "original_loan_amount",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Current loan balance",
      key: "current_loan_balance",
      width: "15%",
      format: "jsx",
    },
    {
      label: "Actions",
      key: "action",
      width: "20%",
      format: "jsx",
    },
  ],
};
