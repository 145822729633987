import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./wire-transfer-info-card.scss";
import { useNetwork } from "hooks/network";
import { Button, Skeleton } from "components";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { useRecoilValue } from "recoil";
import { IPersonalDetails, userPersonalDetails } from "@states/user";
import { UseTransaction } from "@views/MyWallet/store/hooks";

type WireTransferInfoCardProps = {
  onClose: () => void;
  confirmationId: string;
  accountNickName: string
};

export const WireTransferInfoCard: React.FC<WireTransferInfoCardProps> = ({ onClose, confirmationId, accountNickName }) => {
  const { get: fetchWireTransferInfo, data: wireTransferInfo } = useNetwork();
  const { errorNotification } = useNotification();
  const { trackEvent } = useFullStoryTrackEvent();
  const personalData = useRecoilValue(userPersonalDetails);
  const [copyAlert, setCopyAlert] = useState(false);
  const [isCopyAll, setIsCopyAll] = useState(false);
  const { getTransactions, getFortressAccountInfo } = UseTransaction();

  useEffect(() => {
    (async () => {
      const response = await fetchWireTransferInfo(
        `${APIS?.PAYMENT_INITIATION}/123`
      );
      trackEvent("WIRE_TRANSFER_INFO", { ...response });
      if (response?.message !== "ok") {
        errorNotification(`${response?.message}`);
      }
    })();
  }, [errorNotification, fetchWireTransferInfo]);

  const { address }: IPersonalDetails = personalData;

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyAlert(true);
    setTimeout(() => setCopyAlert(false), 1000);
  };

  const handleCopyAll = () => {
    const allDetails = `
      Wire Account Number: ${wireTransferInfo?.data?.wireAccountNumber}
      Domestic(USA) Wire Routing Number: ${wireTransferInfo?.data?.wireRoutingNumber}
      International Wire SWIFT/BIC code: ${wireTransferInfo?.data?.internationalWireInfo?.swiftOrBicCode}
      Wire Memo: ${wireTransferInfo?.data?.wireMemo}
      Bank Name: ${wireTransferInfo?.data?.beneficiaryBankName}
      Bank Address: ${wireTransferInfo?.data?.beneficiaryBankAddress}
      Payment Reference: ${confirmationId}
  `;
    navigator.clipboard.writeText(allDetails.trim());
    setIsCopyAll(true);
    setCopyAlert(true);
    setTimeout(() => {
      setCopyAlert(false);
      setIsCopyAll(false);
    }, 1000);
  };

  const handleWireClose = () => {
    getTransactions(); 
    getFortressAccountInfo(); 
    onClose(); 
  };

  const buttonLabel = useMemo(() => {
    return isCopyAll ? (
      <span className="transfer-details__copied-all">
        <i className="ri-checkbox-circle-line ri-xl transfer-details__copied"></i>
        Copied all to clipboard
      </span>
    ) : (
      "Copy All"
    );
  }, [isCopyAll]);

  const renderBankDetails = useMemo(() => {
    return (
      <><div className="wallet-modal-container-header">
        <p className="heading">Bank Wire Instructions</p>
        <p className="sub-heading">
        Provide the below information to your bank to initiate the transfer.
        </p>
          <i className="ri-close-line wire-transfer-details-container__close-button" onClick={handleWireClose}></i>
      </div><div className="wire-transfer-details-container">
          <div className="transfer-details-wrapper">
            <div className="transfer-details">
              <p className="heading">Wire Account Number</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100}  height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.wireAccountNumber || "NA"}{" "}
                  {<span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.wireAccountNumber)}
                    ></i>
                  </span>}
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Domestic(USA) Wire Routing Number</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.wireRoutingNumber || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.wireRoutingNumber)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">International Wire SWIFT/BIC code</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.internationalWireInfo?.swiftOrBicCode || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.internationalWireInfo?.swiftOrBicCode)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Wire Memo</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.wireMemo || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.wireMemo)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Bank Name</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.beneficiaryBankName || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.beneficiaryBankName)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Bank Address</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.beneficiaryBankAddress || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(wireTransferInfo?.data?.beneficiaryBankAddress)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Payment Reference</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {/* {`${confirmationId} / ${accountNickName}` || "NA"}{" "} */}
                  {confirmationId || "NA"}{" "}
                  <span>
                    <i
                      className="ri-file-copy-line ri-xl transfer-details__copy"
                      onClick={() => handleCopy(confirmationId)}
                    ></i>
                  </span>
                </p>
              )}
            </div>
            <Button
              disabled={!wireTransferInfo}
              label={buttonLabel}
              handleClick={handleCopyAll}
              type="transfer-details__copy-btn" />
            <span className="transfer-details__border-bottom"></span>
            <div className="transfer-details">
              <p className="heading">Recipient Name</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">
                  {wireTransferInfo?.data?.beneficiaryName || "NA"}
                </p>
              )}
            </div>
            <div className="transfer-details">
              <p className="heading">Recipient Address</p>
              <p>:</p>
              {!wireTransferInfo ? (
                <Skeleton width={100} height={12}/>
              ) : (
                <p className="details">{address ?? "NA"}</p>
              )}
            </div>
          </div>
          {/* this code use in future */}
          {/* <div className="transfer-detail-note">
      <i className="ri ri-information-line"></i>
      <p>Note: $15 will be charged for every transaction done using wire transfer.</p>
    </div> */}
          {copyAlert && !isCopyAll && (
            <div className="copy-alert">
              <i className="ri-checkbox-circle-line ri-xl"></i>Copied to clipboard
            </div>
          )}
        </div></>
    );
  }, [wireTransferInfo, address, copyAlert]);

  return <Fragment>{renderBankDetails}</Fragment>;
};
