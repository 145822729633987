import { useRecoilValue } from "recoil";

import { ActiveCurrencyState, showEmailNotificationState } from "states";

import "./mint-app.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "mint-app": any;
    }
  }
}
interface IMintApp {
  openMintApp?: string;
  setOpenMintApp?: any;
  dark?:string;
}

export const MintApp = ({openMintApp,setOpenMintApp,dark}:IMintApp) => {
  const emailNotificationValue = useRecoilValue(showEmailNotificationState);
  const activeCurrency= useRecoilValue(ActiveCurrencyState);
  const { config } = usePermissionContext();

  const assetDetails={
    maxSupply:activeCurrency?.maxSupply,
    id:activeCurrency?.id,
    symbol:activeCurrency?.symbol.includes("YOURTOKEN") ? "" : activeCurrency?.symbol.split("-")[0],
    price:activeCurrency?.amount,
    folderid:activeCurrency?.folderId,
    offeringType: activeCurrency?.offeringType,
    logo:activeCurrency?.image,
    raiseDetails:{
      targetRaise:activeCurrency?.raiseDetails?.targetRaise,
      minimumRaise:activeCurrency?.raiseDetails?.minimumRaise,
    }
  }


  return (
    <div className="minted-dashboard-body">
      <div
        className={emailNotificationValue ? "mintContainer" : ""}
        dangerouslySetInnerHTML={{
          __html: `<mint-app
            dark="${dark}"
            menu="false"
            openmintapp="${openMintApp || "false"}"
            activecurrency='${JSON.stringify(assetDetails) || ""}'
            asset_status="${activeCurrency?.status || ""}"
            blockchain="${config?.blockchain}"
            url_prefix="admin/minted"
            >
            <div class="mint-circle-loader">
            <div
              class="loader loader-blue"
                />
              </div>
            </mint-app>`,
        }}
      />
    </div>
  );
};
export default MintApp;