import React, { useEffect, useMemo, useRef } from "react";

import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

import { firebaseIsFeatureEnableState } from "@states/common";

import { GOOGLE_PAY_ENV_TYPE } from "envs";

import "./wallet-selection-cards.scss";

export const WalletSelectionCards = (props: any) => {
  const { setWithdrawType, selected, type = "withdraw" } = props;
  const { config } = usePermissionContext();
  const { trackEvent } = useFullStoryTrackEvent();
  const darkMode = useDarkMode(true);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);
  const { applePay, googlePay, debitCreditCard } =
    firebaseIsFeatureEnable ?? {};

  const theme = useMemo(() => {
    return darkMode.value ? "dark" : "light";
  }, [darkMode]);

  const googlePayClient = new google.payments.api.PaymentsClient(
    GOOGLE_PAY_ENV_TYPE === "PRODUCTION"
      ? { environment: GOOGLE_PAY_ENV_TYPE }
      : {}
  );

  useEffect(() => {
    if (buttonContainerRef.current) {
      const button = googlePayClient.createButton({
        buttonColor: theme !== "dark" ? "black" : "white",
        buttonType: "plain",
        buttonRadius: 4,
        buttonSizeMode: "fill",
        onClick: () => handleClick?.("google_pay"),
      });

      buttonContainerRef.current.innerHTML = "";
      buttonContainerRef.current.appendChild(button);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googlePay]);

  const handleClick = (type: string) => {
    trackEvent("WITHDRAW_METHOD", {
      method: type,
    });
    setWithdrawType(type);
  };

  return (
    <div className="wallet-select-card--wapper">
      <div className="wallet-select-card">
        {type === "deposit" && config?.name?.includes("Morning Line") && (
          <>
            {debitCreditCard && (
              <>
                <div
                  className={`card-manual ${
                    selected === "credit_card" ? "active-card" : ""
                  } `}
                  onClick={() => handleClick("credit_card")}
                >
                  <span>
                    <i className="ri ri-bank-card-fill"></i>
                  </span>
                  <p>Credit card</p>
                </div>
                <div
                  className={`card-manual ${
                    selected === "debit_card" ? "active-card" : ""
                  } `}
                  onClick={() => handleClick("debit_card")}
                >
                  <span>
                    <i className="ri ri-bank-card-fill"></i>
                  </span>
                  <p>Debit card</p>
                </div>
              </>
            )}
            {applePay && (
              <div
                className={`card ${
                  selected === "apple_pay" ? "active-card" : ""
                } `}
                onClick={() => handleClick("apple_pay")}
              >
                <span>
                  <i className="ri ri-apple-fill"></i>
                </span>
                <p>Pay </p>
              </div>
            )}
            {googlePay && (
              <div
                className="wallet-select-card__google-pay-button-wrapper"
                ref={buttonContainerRef}
              ></div>
            )}
          </>
        )}
        <div
          className={`card-manual ${selected === "bank" ? "active-card" : ""} `}
          onClick={() => handleClick("bank")}
        >
          <span>
            <i className="ri ri-bank-fill"></i>
          </span>
          <p>ACH</p>
        </div>
        <div
          className={`card-manual ${selected === "wire" ? "active-card" : ""} `}
          onClick={() => handleClick("wire")}
        >
          <span>
            <i className="ri ri-global-fill"></i>
          </span>
          <p>Wire</p>
        </div>
      </div>
    </div>
  );
};
