import React, { useCallback, useEffect, useState } from "react";
import { Filter } from "./components/filter";
import { useAuctionApis, useDebounce } from "hooks";
import { DraftList } from "./components/draftList";
import { Json } from "types";
import { Pagination } from "@components/Pagination";
import { useRecoilValue } from "recoil";
import { AuctionListState } from "@states/auction";
import "../auction.scss";

export const Drafts = () => {
  const initialFilter = {
    limit: 20,
    offset: 0,
    isPublished: false,
    isAdmin: true,
  };
  const { data: auctions } = useRecoilValue(AuctionListState);
  const { fetchAuctionList } = useAuctionApis();
  const [filters, setFilters] = useState<Json>(initialFilter);
  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    250
  );

  useEffect(() => {
    handleCallAuction();
  }, [debouncedFilters]);

  const handleCallAuction = () => {
    const { immediate, ..._filters } = filters;
    fetchAuctionList(_filters);
  };

  const handleFilter = (filterObj: Json, debounce = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  return (
    <div className="auction__container">
      <Filter
        inputValue={filters.searchText || ""}
        handleInputChange={(value: string) =>
          handleFilter({ searchText: value, offset: 0 })
        }
      />
      <DraftList />
      <Pagination
        showCount={false}
        listCount={auctions?.data?.length}
        page={parseInt(filters.offset)}
        perPage={parseInt(filters.limit)}
        onChangePage={(page) => handlePagination(page, "page")}
        onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
      />
    </div>
  );
};
