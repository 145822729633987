import { useCallback } from "react";

export const useCookie = () => {
  const getCookie = useCallback((key: string): any => {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let decodeCookie = decodedCookie.split(";");
    for (let i = 0; i < decodeCookie.length; i++) {
      let decodeKey = decodeCookie[i];
      while (decodeKey.charAt(0) === " ") {
        decodeKey = decodeKey.substring(1);
      }
      if (decodeKey.indexOf(name) === 0) {
        return decodeKey.substring(name.length, decodeKey.length);
      }
    }
    return "";
  }, []);

  const get = useCallback(
    (key: string) => {
      try {
        let localData = getCookie(key);
        if(!localData && key === "userDetails"){
          localData = localStorage.getItem(key);
        }
        if (localData) {
          try {
            localData = JSON.parse(localData);
          } catch (err) {
            // do nothing
          }
        }
        return localData;
      } catch (err) {
        return null;
      }
    },
    [getCookie]
  );

  const set = useCallback(
    (key: string, value: any) => {
      if( key === "userDetails"){
        localStorage.setItem(key, JSON.stringify(value));
      }
      try {
        const localData: any = get(key);
        let localValue = {
          ...localData,
          ...value,
        };
        if (typeof value === "object") {
          localValue = JSON.stringify(localValue);
        }

        const exdays = 30;
        const newDate = new Date();
        newDate.setTime(newDate.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + newDate.toUTCString();
        document.cookie = key + "=" + localValue + ";" + expires + ";path=/";
        // localStorage.setItem(key, localValue);
      } catch (err) {
        // do nothing
      }
    },
    [get]
  );

  const deleteCookie = useCallback(
    (key: string) => {
      try {
        if( key === "userDetails"){
          localStorage.removeItem(key);
        }
        let localData = getCookie(key);
        if (localData) {
          try {
            document.cookie =
              key + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          } catch (err) {
            // do nothing
          }
        }
      } catch (err) {}
    },
    [getCookie]
  );
  return { get, set, deleteCookie };
};
