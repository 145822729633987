import React, { useState, useEffect, useRef } from 'react';
import NewModal from '@components/new-modal/new-modal';
import { Loader } from '@components/Loader2';
// @ts-ignore
import { Json } from "@types/common";

type IOrderDetailKyc = {
  open: boolean;
  details: Json;
  onClose: () => void;
  onSuccess: () => void;
};

export const OrderDetailKyc = ({ open, details, onClose, onSuccess }: IOrderDetailKyc) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const iframeRef = useRef<any>();

  useEffect(() => {
    const handleIframeReady = (event: MessageEvent) => {
      const receivedData = event.data;
      if (receivedData?.type === "submit_esign") {
        onSuccess()
      }
    };

    window.addEventListener("message", handleIframeReady);
    return () => window.removeEventListener("message", handleIframeReady);
  }, [onSuccess]);


  return (
    <NewModal
      isOpen={open}
      showCloseBtn={true}
      closeModal={onClose}
      modalName={"Order detail"}
      title=""
      isStopOutsideClick={false}
      closeOnEscBtn={false}
      className="exchange-modal-wrapper order-detail-kyc-modal-container"
    >
      <iframe
        key={`${isIframeLoading}`}
        ref={iframeRef}
        src={details?.signUrl}
        title="Order detail"
        className={`order-detail-modal-iframe ${isIframeLoading ? "is-loading" : ""}`}
        allow="camera; microphone; geolocation"
        onLoad={() => setIsIframeLoading(false)}
      />
      {isIframeLoading && <Loader className="loader-blue" />}
    </NewModal>
  );
};

export default OrderDetailKyc;
