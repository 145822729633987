import NewModal from "@components/new-modal/new-modal";
import styles from "./dvpIframe.module.sass"
import { useEffect } from "react";
import { Json } from "../../../../types";
import { useSetRecoilState } from "recoil";
import { TriggerUserApi } from "@states/user";

interface IDvpIframe{
    onSuccess: () => void;
    url: string
}

export const DvpIframe = ({onSuccess, url}:IDvpIframe) => {
    const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  

    useEffect(() => {
        window.addEventListener('message', event => {
            const receivedData: {
                type: 'submit_esign';
                data: Record<string, never>;
            } = event.data;
            if (receivedData.type === 'submit_esign') {
                setTimeout(()=>{
                    onSuccess()
                    setTriggerUserApi((prev) => !prev)},2000);
                ;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return( 
        <div>
            <iframe
                src={url}
                title="Compliance"
                width={"100%"}
                height={630}
                className={styles.dvp_iframe}
                allow="camera; microphone"
            />
        </div>
    );
};