import React from 'react'
import "./accountApprovalBanner.scss"

const AccountApprovalBanner = ({approve = false }) => {
  return (
    <>
      <div className="account-approval-banner">
        {approve ? (
          <>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1752 0.454406C8.54008 -0.0749222 6.76252 0.661361 5.98056 2.19191L5.04048 4.03194C4.92878 4.25058 4.75097 4.42839 4.53234 4.54008L2.69231 5.48016C1.16176 6.26212 0.425475 8.03969 0.954803 9.67484L1.59117 11.6407C1.66678 11.8742 1.66678 12.1258 1.59117 12.3593L0.954803 14.3252C0.425475 15.9604 1.16176 17.7379 2.69231 18.5199L4.53234 19.4599C4.75097 19.5717 4.92878 19.7495 5.04048 19.9681L5.98056 21.8082C6.76252 23.3387 8.54008 24.075 10.1752 23.5457L12.1411 22.9093C12.3746 22.8337 12.6262 22.8337 12.8597 22.9093L14.8256 23.5457C16.4608 24.075 18.2383 23.3387 19.0203 21.8082L19.9603 19.9681C20.0721 19.7495 20.2499 19.5717 20.4685 19.4599L22.3086 18.5199C23.8391 17.7379 24.5754 15.9604 24.0461 14.3252L23.4097 12.3593C23.3341 12.1258 23.3341 11.8742 23.4097 11.6407L24.0461 9.67484C24.5754 8.03969 23.8391 6.26212 22.3086 5.48016L20.4685 4.54008C20.2499 4.42839 20.0721 4.25058 19.9603 4.03194L19.0203 2.19191C18.2383 0.661361 16.4608 -0.0749222 14.8256 0.454406L12.8597 1.09078C12.6262 1.16638 12.3746 1.16639 12.1411 1.09078L10.1752 0.454406ZM6.3868 11.7169L8.03673 10.0668L11.3365 13.3668L17.9363 6.7671L19.5862 8.41701L11.3365 16.6666L6.3868 11.7169Z"
                fill="#50C17D"
              />
            </svg>
            Congratulations, your account has been verified!
          </>
        ) : (
          <>
            <i className="ri-information-fill informationIcon" /> Hang Tight!
            Your account is under review and may take up to 2 business days for
            approval. We’ll notify you once you're all set!
          </>
        )}
      </div>
    </>
  );
}

export default AccountApprovalBanner