import React, { useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useDarkMode from "use-dark-mode";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { Loader, SkeletonWallet, ReactModal } from "components";
import { Image, Confirmation } from "@storybook";
import { useNetwork, useNotification, useWebAuthentication } from "hooks";
import { APIS } from "constant";
import { getLogos } from "../../constants";
import {
  AccountState,
  DisplayUserState,
  LoginPhoneNumberState,
  PlaidTokenState,
  SelectedFundRecipientBank,
  SelectedFundRecipientBankAccount,
  allCoOwnerState,
  userDetailsState,
  userPersonalDetails
} from "states";
import { useNavigate } from "react-router-dom";
import styles from "./AccountBalances.module.sass";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import MpcIncomplete from "@views/exchange/components/Actions/Form/MpcIncomplete/MpcIncomplete";
import MpcSetupModal from "@views/exchange/components/Actions/Form/MpcSetupModal/MpcSetupModal";
import classNames from "classnames";
import { ALLOW_MPC_ENV } from "../../../../envs";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { Json } from ".././../../../types/common";
import { useAppPlaidLink } from "./plaidLinkHook";
import { imageConfig } from "@utils/imageConfig";

const items = [
  {
    title: "Linked Bank Accounts",
    color: "#9757D7",
    btnText: "Add New",
    type: "account",
  },
];
export const Accounts = () => {
  const setToken = useSetRecoilState(PlaidTokenState);
  const [loader, setLoader] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [showKYCReview, setShowKYCReview] = useState(false);
  const [visibleDeleteConfirmation, setVisibleDeleteConfirmation] = useState(false);
  const [deleteBankId, setDeleteBankId] = useState<Json>({})

  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [accounts, setAccounts] = useRecoilState(AccountState);
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const displayUser = useRecoilValue<any>(DisplayUserState);
  const navigate = useNavigate();
  const { trackEvent, trackPageEvent } = useFullStoryTrackEvent();
  const personalDetails = useRecoilValue(userPersonalDetails)
  const userDetails = useRecoilValue(userDetailsState);
  const resestFundBank = useResetRecoilState(SelectedFundRecipientBank)
  const resestFundBankAccount = useResetRecoilState(SelectedFundRecipientBankAccount)

  const [mpcIncomplete, setMpcIncomplete] = useState(false);
  const [setupMpcWallet, setSetupMpcWallet] = useState(false);

  const darkMode = useDarkMode(false);

  const { registerNewCredentials, authenticate, getRegistrations } = useWebAuthentication();

  const { post: generateToken, data: tokenResponse, error: tokenError } = useNetwork();
  const { get: getAccounts, loading, data: accountsList } = useNetwork();
  const { remove: removeBankAccount, loading: loadingDelete } = useNetwork();
  const {
    remove: deleteAccount,
    data: deleteData,
    error: deleteError,
  } = useNetwork();
  const { post } = useNetwork();
  const { open, acccountGetLoading: loadingAfterAccountAdd, bankProceedLoader } = useAppPlaidLink();

  const { logo, svg: {empty_img, empty_img_light}} = imageConfig;
  
  const { successNotification, errorNotification } = useNotification();

  const {
    firstName,
    lastName,
  } = personalDetails;

  useEffect(() => {
    trackPageEvent({
      pageName: 'SAVED_BANK_ACCOUNTS',
    });
  }, []);

  useEffect(() => {
    if (accountsList) {
      setAccounts(accountsList);
    }
  }, [accountsList, setAccounts]);

  useEffect(() => {
    if (tokenResponse?.token) {
      setToken(tokenResponse?.token);
      open();
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tokenResponse?.token]);

  useEffect(() => {
    if (accounts.length === 0) getAccounts(APIS.Accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountsList?.data) {
      setAccounts(accountsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);


  const handleClose = useCallback(() => {
    setVisibleCancel(false);
  }, []);

  const handleFail = useCallback(() => {
    errorNotification("Authentication failed");
  }, [errorNotification]);

  const handleSuccess = useCallback(async () => {
    try {
      const payload = {
        countryCode: loginPhoneNumber?.countryCode ?? "+1",
        phone: loginPhoneNumber?.phone ?? "",
        data: { a: "a" },
      };
      const resp = await post(APIS.WebAuthLogin, payload);
      if (resp) {
        const { token } = resp;
        if (token) {
          // successNotification("Authentication");
          deleteAccount(`${APIS.Accounts}/${selectedAccountId}`);
        }
      }
    } catch {
      errorNotification("Something went wrong.");
    }
  }, [
    deleteAccount,
    errorNotification,
    loginPhoneNumber?.countryCode,
    loginPhoneNumber?.phone,
    post,
    selectedAccountId,
  ]);

  const handleWebAuth = useCallback(() => {
    const isAlreadyExist = getRegistrations().find(
      (item: any) => item.name === loginPhoneNumber?.phone || "",
    );
    const registerPayload = {
      email: loginPhoneNumber?.phone ?? "",
      id: loginPhoneNumber?.phone ?? "",
      displayName: loginPhoneNumber?.phone ?? "",
    };

    trackEvent("BANK_ACCOUNT_DELETED", {
      ...registerPayload
    });

    if (!isAlreadyExist) registerNewCredentials(handleSuccess, registerPayload);
    else authenticate(handleSuccess, handleFail);
  }, [
    authenticate,
    getRegistrations,
    handleFail,
    handleSuccess,
    loginPhoneNumber?.phone,
    registerNewCredentials,
  ]);
  const handleDeleteAction = useCallback(() => {
    handleWebAuth();
    setVisibleCancel(false);
  }, [handleWebAuth]);

  const maskDots = useCallback((listsToRender: number) => {
    return Array(listsToRender).fill(1).map((index) => <span className={styles.accountDots}></span>);
  }, []);

  useEffect(() => {
    if (deleteData) {
      successNotification("Deleted successfully !");
      setVisibleCancel(false);
      const restAccounts = accounts?.filter((item) => item?._id !== selectedAccountId);
      setAccounts(restAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);

  useEffect(() => {
    if (deleteError) {
      errorNotification(deleteError?.message ?? "Something went wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError]);

  useEffect(() => {
    if (tokenError) {
      errorNotification("Something went wrong");
      setLoader(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenError]);

  const handleOpenMpcSetup = useCallback(() => {
    setSetupMpcWallet(true);
    setMpcIncomplete(false);
  }, []);

  const handleCancelMpcSetup = useCallback(() => {
    setSetupMpcWallet(false)
  }, []);


  const handleClickDelete = useCallback(() => {
    const localSelectedItem = JSON.parse(localStorage.getItem("selectedBankSubItem") as any);

    removeBankAccount(`${APIS.DELETE_BANK_ACCOUNT}/${deleteBankId?.id}?accountNumber=${deleteBankId?.accountNumber}`, {}, { apiResponse: true, }).then(async (res: any) => {
      if (res) {
        if (res?.data?.success) {
          if (localSelectedItem?.accountId === deleteBankId?.accountId) {
            localStorage.removeItem("selectedBank")
            localStorage.removeItem("selectedBankSubItem")
            resestFundBank()
            resestFundBankAccount()
        }
          successNotification(res?.data?.message || "Bank account removed successfully")
          await getAccounts(APIS.Accounts);
          setVisibleDeleteConfirmation(false)
      } else {
          if (res?.message === "Failed to remove account: Bank Account cannot be deleted. Please ensure no SCHEDULED or PENDING transaction(s).") {
            errorNotification("Action Failed: A transaction is in process on this account. Please try again later");
            setVisibleDeleteConfirmation(false)
        }
      }
      }
    }).catch((error) => {
      console.error(error?.message)
    })
  }, [deleteBankId, errorNotification, getAccounts, removeBankAccount, resestFundBank, resestFundBankAccount, successNotification])
  
  const handleDelete = useCallback((obj:Json) => {
    setDeleteBankId(obj)
    setVisibleDeleteConfirmation(true)
  }, [])

  const renderConfirmation = useMemo(() => {
    return (
      <Confirmation
        title={"Remove Account"}
        visible={visibleDeleteConfirmation}
        description={"Are you sure you want to remove this bank account? This action cannot be undone."}
        handleModal={handleClickDelete}
        handleClose={() => setVisibleDeleteConfirmation(false)}
        boldDescription=""
        label={"Yes"}
        cancelLabel={"Cancel"}
        type="primary"
        cancelLoading={loadingDelete || loading}
      />
    );
  }, [handleClickDelete, loadingDelete, visibleDeleteConfirmation]);
  

  const renderAccount = useMemo(() => {
    return (
      <>
        {loadingAfterAccountAdd || loading || bankProceedLoader ? (
          <SkeletonWallet listsToRender={3} />
        ) : (
          <div>
            {accounts?.map((items) => (
              <>
                <div className={styles.accountDetail}>
                  <div className={styles.accountName}>
                    <Image
                      className="bank-logo"
                      fileName={logo[items.bankName] ?? logo["default"]}
                    />
                    <div className={styles.accountData}>
                      <div
                        className={styles.userName}> {items?.accounts[0]?.accountHolderName || ""}</div>
                      <div className={styles.bankName}>{items.bankName}</div>
                      <div className={styles.subAccounts}>
                        {(items?.accounts as any[])?.map((account) => (
                          <div className={styles.subAccounts__account}>
                            <div className={styles.subAccounts__account__left}>
                              <span className={styles.accType}>
                                <span className={styles.primaryAccContainer}>
                                  {account.subtype}
                                  {/* {true && (
                                    <span className={styles.primary}>
                                      Primary
                                    </span>
                                  )} */}
                                </span>
                              </span>
                              <span className={styles.cardNo}>
                                <span className={styles.dots}>
                                  {maskDots(3)}
                                </span>
                                {"  "} {account.mask}
                              </span>
                            </div>
                            {/* <div className={styles.subAccRight}> */}
                            {/* TODO: unhide this for bank delete feature */}
                             <i
                              className={`ri-delete-bin-6-line ${styles.deleteAccountIcon}`}
                              onClick={() => handleDelete({ id: account?._id, accountNumber: account?.accountNumber, accountId: account?.accountId })}
                            />
                            {/* <div className={styles.makePrimaryContainer}>
                                <i className="ri-vip-crown-line" />
                                Make Primary
                              </div> */}
                            {/* </div> */}
                          </div>
                        ))}
                      </div>

                      <div></div>
                    </div>
                  </div>
                  {/* <div className={styles.accountMore} onClick={() => handleDelete(items?._id)}>
                    <i className="ri-delete-bin-line" />
                  </div> */}
                </div>
              </>
            ))}
          </div>
        )}
      </>
    );
  }, [accounts, bankProceedLoader, handleDelete, loading, loadingAfterAccountAdd, maskDots]);

  const handleAdd = useCallback(() => {
    if (displayUser?.type === "Authorised User") {
      errorNotification("As an authorised user, you are not allowed to add new bank account")
    } else if (!personalDetails?.onboardingData?.isOnboardingComplete) {
      setShowKYCReview(true);
    } else if (ALLOW_MPC_ENV && !userDetails?.data?.isMPCWallet) {
      setMpcIncomplete(true);
      return;
    } else if (!personalDetails?.accounts?.length) {
      errorNotification("Once your account verification is approved, you can add a bank account")
    } else {
      setLoader(true);
      const payload = {
        language: "en",
        countryCodes: ["US"],
      }
      generateToken(APIS.GenerateLinkToken, payload);
    }
    ;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = useCallback(() => {
    navigate("/wallet");
  }, [navigate]);

  return (
    <>
      <div className={styles.list}>
        <>
          {accounts.length || loading || loadingAfterAccountAdd ? (
            <>
              {items.map((item, index) => (
                <div className={cn(styles.item)} key={index}>
                  <div className={styles.head}>
                    <span className={styles.arrowBack} onClick={handleGoBack}>
                      <i className="ri ri-arrow-left-s-line"></i>
                    </span>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.details}>
                      <button
                        className={cn(
                          "button",
                          styles.buttonAaddAccount,
                          styles.buttons
                        )}
                        onClick={handleAdd}
                      >
                        {loader ? <Loader dimension={16} /> : item.btnText}
                      </button>
                    </div>
                  </div>
                  <div className={styles.body}>{renderAccount}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={cn(styles.item)}>
              <span className={styles.arrowBack} onClick={handleGoBack}>
                <i className="ri ri-arrow-left-s-line"></i>
              </span>
              <div className={styles.emptyState}>
                {/* <img src={darkMode.value ? noDataDark : noDataLight} alt="" /> */}
                {
                  <Image
                    className={styles.brand_logo}
                    fileName={darkMode.value ? empty_img : empty_img_light}
                  />
                }
                <div className={styles.heading}>Nothing Here</div>
                <div className={styles.noDataText}>
                  Add bank account to trade
                </div>
                <button
                  className={cn("button", styles.buttonAaddAccountEmpty)}
                  onClick={handleAdd}
                    disabled={loadingAfterAccountAdd || bankProceedLoader}
                >
                  {loader || bankProceedLoader ? (
                    <Loader dimension={30} />
                  ) : (
                    "Add New Bank Account"
                  )}
                </button>
              </div>
            </div>
          )}
        </>
      </div>
      <ReactModal
        visible={visibleDeleteConfirmation}
        onClose={() => setVisibleCancel(false)}
        outerClassName={styles.confirmationModalOuter}
      >
        {renderConfirmation}
      </ReactModal>
      <ReactModal
        visible={showKYCReview}
        onClose={() => setShowKYCReview(false)}
        closeBtn={true}
        outerClassName={classNames(
          styles.confirmationModalOuter,
          styles.KYCReviewModal
        )}
      >
        <KycReview 
          handleClose={()=>setShowKYCReview(false)}
        />
      </ReactModal>
      <ReactModal
        visible={mpcIncomplete}
        onClose={() => {
          setMpcIncomplete(false);
        }}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(
          styles.confirmationModalOuter,
          styles.kycStatusModal
        )}
      >
        {mpcIncomplete && (
          <MpcIncomplete
            handleOpenMpcSetup={handleOpenMpcSetup}
            showZeroBalance={false}
          />
        )}
      </ReactModal>
      <ReactModal
        visible={setupMpcWallet}
        onClose={handleCancelMpcSetup}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(styles.MpcSetupStatusModal)}
      >
        <MpcSetupModal handleCancelMpcSetup={handleCancelMpcSetup} />
      </ReactModal>
    </>
  );
};
