import {  PieChart } from "./music-pie-chart";

import styles from "./music-chart.module.sass";
import {BarChartMusic } from "components/Charts";

export const MusicCharts = () => {


  const series = [
    {
      id:  "idData",
      name: "Song Played",
      data: [
        { name: "Spotify", y: 195.75 },
        { name: "Youtube", y: 153.89 },
        { name: "Apple Music", y: 59.42 },
        { name: "Amazon Music", y: 16.49 },
      ],

      states: {
        hover: {
          enabled: false,
        },
      },
    },
  ];
  const seriesbardata= [
    {
      name: "2021",
      data: [1.55, 1.35, 1.3, 1.1],
    },
    {
      name: "2022",
      data: [1.1, 0.8, 0.95, 0.75],
    },
  ];
  return (
    <div className={styles.musicChart}>
      <div className={styles.musicChart_technical}>
        <div className={styles.musicChart_line} />
        <div>Technical Charts</div>
      </div>
      <div className={styles.musicChart_chart}>
        <BarChartMusic title={"Dividend"} subtitle={"Over each quarter"} seriesbardata={seriesbardata}/>
        <PieChart title={"Song Played"} subtitle={"On Popular plateform"} series={series}/>
      </div>
    </div>
  );
};
