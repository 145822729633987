import React, { useCallback, useEffect, useState } from "react";
import { Filter } from "./components/filter";
import { useAuctionApis, useDebounce } from "hooks";
import { AuctionList } from "./components/auctionList";
import { Json } from "types";
import { useNavigate, useLocation } from "react-router-dom";
import { filterOptions } from "./components/filter/constant";
import "./auction.scss";
import { getJsonToParams, getJsonFromParams } from "utils";
import { Pagination } from "@components/Pagination";
import { useRecoilValue } from "recoil";
import { AuctionListState } from "@states/auction";

export const Auction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: auctions } = useRecoilValue(AuctionListState);

  const initialFilter = {
    offset: 0,
    limit: 20,
  };

  const { fetchAuctionList } = useAuctionApis();
  const [filters, setFilters] = useState<Json>({
    ...initialFilter,
    ...getJsonFromParams(location.search),
  });
  const debouncedFilters = useDebounce(
    filters,
    { immediate: filters.immediate },
    800
  );

  useEffect(() => {
    const { immediate, ..._filters } = filters;
    navigate(`/admin/auction${getJsonToParams(_filters)}`);
    handleCallAuction();
  }, [debouncedFilters]);

  const handleCallAuction = () => {
    const { immediate, ..._filters } = filters;

    fetchAuctionList({
      ..._filters,
      isAdmin: true,
      isPublished: true,
    });
  };

  const onReset = () => {
    setFilters({ ...initialFilter, immediate: true });
  };

  const handleFilter = (filterObj: Json, debounce = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const showAuctionModalOnClick = useCallback(() => {
    navigate("/admin/auction-create");
  }, []);

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {};
    if (action === "perPage") {
      pageChange.limit = val;
      pageChange.offset = 0;
    }
    if (action === "page") pageChange.offset = val;
    handleFilter(pageChange);
  }, []);

  return (
    <div className="auction__container">
      <Filter
        inputValue={filters.searchText || ""}
        handleInputChange={(value: string) =>
          handleFilter({ searchText: value, offset: 0 })
        }
        data={filterOptions}
        filters={filters}
        handleFilter={(obj: any) => handleFilter({ ...obj, offset: 0 })}
        onReset={onReset}
        showAuctionModalOnClick={showAuctionModalOnClick}
      />
      <AuctionList />
      <Pagination
        showCount={false}
        listCount={auctions?.data?.length}
        page={parseInt(filters.offset)}
        perPage={parseInt(filters.limit)}
        onChangePage={(page) => handlePagination(page, "page")}
        onChangePerPage={(perPage) => handlePagination(perPage, "perPage")}
      />
    </div>
  )
};
