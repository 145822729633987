import { useRecoilValue } from "recoil";

import { showEmailNotificationState } from "states";

import "./mint-app.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "mint-app": any;
    }
  }
}

export const MintApp = () => {
  const emailNotificationValue = useRecoilValue(showEmailNotificationState);
  const { config } = usePermissionContext();


  return (
    <div className="minted-dashboard-body">
      <div
        className={emailNotificationValue ? "mintContainer" : ""}
        dangerouslySetInnerHTML={{
          __html: `<mint-app
            menu="false"
            blockchain=${config?.blockchain}
            url_prefix="admin/minted"
            >
            <div class="mint-circle-loader">
            <div
              class="loader loader-blue"
                />
              </div>
            </mint-app>`,
              }}
      />
    </div>
  );
};
export default MintApp;