import { Button, Input } from "components";
import { DataFilter } from "components/data-filter";
import "./filter.scss";
import { useRecoilValue } from "recoil";
import { AuctionListState } from "states";

export const Filter = ({
  showAuctionModalOnClick,
  inputValue,
  handleInputChange,
  data,
  filters,
  handleFilter,
  onReset,
}: any) => {
  const change = (value: string) => {
    handleInputChange(value);
  };

  const { loading } = useRecoilValue(AuctionListState);

  return (
    <div className="filter__container">
      <div className="filter__container-search">
        <Input
          placeholder="Search"
          label=""
          inputType="text"
          value={inputValue}
          handleChange={(e) => change(e.target.value)}
          suffixIcon="ri ri-search-line"
        />
        <DataFilter
          selectedValue={filters}
          data={data}
          position="left"
          onChangeFilter={handleFilter}
          onClickReset={onReset}
          isLoading={loading}
        />
      </div>
      <div className="filter__container-create">
        <Button
          label="New Auction Listing "
          type="button-green asset-btn"
          buttonType="button"
          handleClick={showAuctionModalOnClick}
        />
      </div>
    </div>
  );
};
