import { useState, useRef } from "react";
import { Json } from "types";
import { formatFileSize } from "utils";
import { v4 as uuidv4 } from "uuid";
import { useNetwork, useNotification } from "hooks";
import { APIS, FILE_EXTENSION_URL } from "constant";
import axios from "axios";
import { EditDataRoomFileNameModal } from "views/auction/create-auction/components/auctionDataRoom/components/createAssetModal";
import { imageConfig } from "@utils/imageConfig";

import "./dataroom.scss";

const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/pdf": "pdf",
  pdf: "pdf",
  xlsx: "xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  docx: "docx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
  "application/msword": "doc",
  doc: "doc",
  "text/csv": "csv",
  csv: "csv",
  pptx: "ppt",
};

export const Dataroom = ({
  updateFileFolderId,
  publishtnDisabled,
  auctionFormData,
  updatedDataRoomfiles,
}: //updateDataRoomFileOrder
  any) => {
  const [dataRoomFiles, setDataRoomFiles] = useState<Json | any>([]);
  const { post: getSignedUrl } = useNetwork();
  const [dataRoomFolderId, setDataRoomFolderId] = useState<string>("");
  const [dataRoomEditState, setDataRoomEditState] = useState({
    modalVisible: false,
    fileId: "",
    fileName: "",
  });
  const [dataRoomUpdatedFile, setDataRoomUpdatedFile] = useState<any>({});
  const [dataRoomDeleteFileId, setDataRoomDeleteFileId] = useState<any>([]);
  const { remove: deleteDataRoomFile, loading: fileDeleteLoading } = useNetwork();

  const { successNotification, errorNotification } = useNotification();

  const {svg: {draggableIcon}} = imageConfig;

  // function tp get signed url and upload it to GCP
  const uploadToGcp = async (fileList: any) => {
    let gcpResponse;
    const GcpFileStatus: any = [];
    let folderId;
    if (dataRoomFolderId) {
      folderId = dataRoomFolderId;
    } else {
      const uniqueId = uuidv4();
      setDataRoomFolderId(uniqueId);
      folderId = uniqueId;
    }

    // post for getting signed URL
    const signedurlPayload = {
      folderId,
      files: fileList?.map(({ fileId, fileName, fileSize, extension }: any) => {
        return {
          fileId,
          fileName,
          fileSize,
          extension,
        };
      }),
    };

    try {
      const signedurlResponse = await getSignedUrl(APIS.AuctionDataRoom, signedurlPayload, {
        apiResponse: true,
      });
      if (signedurlResponse.message === "ok") {
        // GCP upload
        // based on the signedurl get file mapped by fileid
        const {
          data: { file: signedUrlResponseFile },
        } = signedurlResponse;

        gcpResponse = await signedUrlResponseFile?.map(async (files: any) => {
          const controller = new AbortController();
          const signal = controller.signal;
          let found = fileList?.find((e: any) => e.fileId === files.fileId);

          found = {
            ...found,
            id: files.id,
            abortController: controller,
          };

          setDataRoomFiles((prev: any) => [...prev, found]);

          const res = axios
            .put(files.signedUrl, found?.file, {
              headers: {
                "Content-Type": found?.extension,
              },
              onUploadProgress: (progressEvent) => {
                if (typeof progressEvent.total === "number" && !isNaN(progressEvent.total)) {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  );
                  setDataRoomFiles((prev: any) => {
                    const temp = prev?.map((item: any) => {
                      if (item.fileId === files.fileId) {
                        item.isfileuploading = true;
                        item.downLoadingStatus = percentCompleted;
                      }
                      return item;
                    });
                    return [...temp];
                  });
                }
              },
              signal: signal,
            })
            .then((response) => {
              const obj = {
                id: files.id,
                status: response.status === 200 ? "SUCCESS" : "FAILED",
              };
              GcpFileStatus.push(obj);
              setDataRoomFiles((prev: any) => {
                const temp = prev?.map((item: any) => {
                  if (item.id === files.id) {
                    item.loadingStatus = response?.status === 200 ? "completed" : "failed";
                    item.abortController = null;
                    item.isSuccessfullyUploaded = response?.status === 200 ? true : false;
                    item.status = response?.status === 200 ? "SUCCESS" : "FAILED";
                  }
                  return item;
                });
                return [...temp];
              });
            })
            .catch((err) => {
              const obj = {
                id: files.id,
                status: "FAILED",
              };
              GcpFileStatus.push(obj);
              // publishtnDisabled(false);
              errorNotification("File uploading cancelled");
              setDataRoomFiles((prev: any) => {
                const temp = prev?.map((item: any) => {
                  if (item.id === files.id) {
                    item.loadingStatus = "cancelled";
                    item.abortController = null;
                    item.isSuccessfullyUploaded = false;
                    item.status = "FAILED";
                  }
                  return item;
                });
                return [...temp];
              });
            });

          return res;
        });
      } else {
        //publishtnDisabled(false);
        errorNotification(signedurlResponse?.message);
      }
    } catch (err: any) {
      // publishtnDisabled(false);
      errorNotification(err?.message);
    }

    return { gcpResponse, GcpFileStatus, folderId };
  };

  const { put: updateDataRoomFileStatus } = useNetwork();

  const updateFileStatusToDb = async (GcpFileStatus: Json[], folderId: string) => {
    const payload = {
      files: [...GcpFileStatus],
    };

    updateDataRoomFileStatus(`${APIS.AuctionDataRoom}/${folderId}`, payload)
      .then((response) => {
        updateFileFolderId(folderId);
        // publishtnDisabled(false);
      })
      .catch((err) => errorNotification(err?.message));
  };

  const fileValidations = (files: Json[]) => {
    for (let index = 0; index < files.length; index++) {
      if (!fileFormat[files[index].type]) {
        errorNotification(`${files[index].name} is not a valid file format`);
        return false;
      }
    }

    return true;
  };

  const fileInputRef: any = useRef();
  const handleOnChange = async (event: any) => {
    //  publishtnDisabled(true);
    let IsFilesValid: boolean | undefined = true;
    const { files } = event.target;
    IsFilesValid = fileValidations(files);
    if (IsFilesValid) {
      const fileList = [...files]?.map((file, index) => ({
        fileId: uuidv4(),
        fileName: file.name,
        fileSize: file.size,
        extension: file.type,
        downLoadingStatus: 0,
        isCancelled: false,
        abortController: null,
        isfileuploading: false,
        isSuccessfullyUploaded: false,
        loadingStatus: "uploading",
        status: "pending",
        file: file,
        order: index + 1,
      }));

      fileInputRef.current.value = null;
      const { gcpResponse, GcpFileStatus, folderId } = await uploadToGcp(fileList);

      Promise.all(gcpResponse)
        .then((response) => {
          updateFileStatusToDb(GcpFileStatus, folderId);
        })
        .catch((err) => {
          errorNotification(err?.message);
        });
    } else {
      fileInputRef.current.value = null;
      // publishtnDisabled(false);
    }
  };

  //   useEffect(() => {
  //     updateDataRoomFileOrder(dataRoomFiles);
  //   }, [dataRoomFiles]);

  const cancelFileUpload = (abortController: any, id: string) => {
    abortController.abort();
    //removeCancelledFiles(id);
  };

  // const removeCancelledFiles = (id: string) => {
  //   setTimeout(() => {
  //     setDataRoomFiles((prev: any) =>
  //       prev?.filter((files: Json) => files.id !== id)
  //     );
  //   }, 1000);
  // };

  const openEditDatafileModal = (name: string, id: string) => {
    setDataRoomEditState({
      modalVisible: true,
      fileId: id,
      fileName: name,
    });
  };

  const resetDataRoomModalState = () => {
    setDataRoomEditState({
      modalVisible: false,
      fileId: "",
      fileName: "",
    });
  };

  const updateDataRoomFile = (id: string, value: string) => {
    // updating the existing dataroom state
    setDataRoomFiles((prev: any) => {
      const temp = prev?.map((file: Json) => {
        if (file.id === dataRoomEditState.fileId) {
          file.fileName = value;
        }
        return file;
      });
      return [...temp];
    });

    // maintain a state to keep track of updated files
    const updatedFile = { ...dataRoomUpdatedFile };
    if (updatedFile.hasOwnProperty(id)) {
      updatedFile[`${id}`] = value;
    } else {
      updatedFile[`${id}`] = value;
    }
    setDataRoomUpdatedFile({ ...updatedFile });
    updatedDataRoomfiles(updatedFile);
    // reset the file edit state
    resetDataRoomModalState();
  };

  const deleteFile = (id: string, name: string) => {
    setDataRoomDeleteFileId((prev: any) => [...prev, id]);
    deleteDataRoomFile(`${APIS.AuctionDataRoom}/${id}`, {
      apiResponse: true,
    }).then((response) => {
      if (response === 1) {
        successNotification("File successfully deleted");
        const filteredfiles = dataRoomFiles?.filter((items: any) => items.id !== id);
        setDataRoomFiles([...filteredfiles]);

        setDataRoomDeleteFileId((prev: any) => prev.filter((items: any) => items.id !== id));
        // remove from the file name object state
        const tempFile = { ...dataRoomUpdatedFile };
        if (tempFile.hasOwnProperty(id)) {
          delete tempFile[`${id}`];
          setDataRoomUpdatedFile(tempFile);
        }
      } else {
        errorNotification("Failed to delete");
      }
    });
  };

  const getStringAccordingCount = (count: number, str: string) => {
    if (count > 1) return count + " " + str + "s";
    return count + " " + str;
  };

  const getButtonByActions = ({ id, abortController, loadingStatus, fileName }: any) => {
    if (abortController) {
      return (
        <i
          onClick={() => cancelFileUpload(abortController, id)}
          className="ri ri-close-circle-line"
        />
      );
    } else if (dataRoomDeleteFileId.includes(id)) {
      return <i className="ri ri-loader-3-line"></i>;
    } else if (loadingStatus === "cancelled") {
      return <i className="ri ri-close-line"></i>;
    } else {
      return (
        <i
          onClick={() => deleteFile(id, fileName)}
          className="ri ri-delete-bin-6-line delete-file"></i>
      );
    }
  };

  let todoItemDrag: any = useRef();
  let todoItemDragOver: any = useRef();

  const onDragStart = (e: any, index: any): any => {
    todoItemDrag.current = index;

    // const dataRoomFilesList = [...dataRoomFiles];

    // let finalArray: any = [];
    // dataRoomFilesList?.forEach((items, index) => {
    //   finalArray.push({
    //     ...items,
    //     order: index + 1,
    //   });
    // });

    // setDataRoomFiles(finalArray);
  };

  const onDragEnter = (e: any, index: any): any => {
    todoItemDragOver.current = index;
  };

  const onDragEnd = (e: any, index: any): any => {
    const arr1 = [...dataRoomFiles];

    const data_room_main: any = arr1[todoItemDrag?.current];

    arr1.splice(todoItemDrag?.current, 1);
    arr1.splice(todoItemDragOver?.current, 0, data_room_main);

    todoItemDrag.current = null;
    todoItemDragOver.current = null;

    let finalArray: any = [];
    arr1?.forEach((items, index) => {
      finalArray.push({
        ...items,
        order: index + 1,
      });
    });

    setDataRoomFiles(finalArray);
    //updateDataRoomFileOrder(finalArray);
  };

  const {svg: { user }} = imageConfig;

  return (
    <>
      <div className="wallet-form-data-room">
        <div className="data-room-file-container">
          <div className="data-room-content">
            <span className="ri ri-file-upload-line"></span>
            <p>Drag and Drop files or browse</p>
            <small>Supported file format: pdf, csv, xsl, docx, and doc</small>
            <input
              id={"dataroom-file-browse"}
              ref={fileInputRef}
              type="file"
              placeholder="select logo"
              className={"image_browse"}
              accept=".pdf, .docx, .CSV, .xsl, .doc, .xlsx, .xls"
              onChange={handleOnChange}
              multiple={true}
            />
          </div>
        </div>
        {dataRoomFiles.length > 0 && (
          <div className="data-room-files-container">
            <div className="data-room-files-header">
              <p> Files </p>
              <p className="file-count">{getStringAccordingCount(dataRoomFiles.length, "file")}</p>
            </div>

            <div className="date-room-files">
              {dataRoomFiles?.map(
                (
                  {
                    fileName,
                    fileSize,
                    extension,
                    abortController,
                    id,
                    loadingStatus,
                    downLoadingStatus,
                    isSuccessfullyUploaded,
                    isDragging,
                  }: any,
                  index: number,
                ) => {
                  return (
                    <>
                      <div
                        key={id}
                        className="file-details"
                        draggable="true"
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragEnter={(e) => onDragEnter(e, index)}
                        onDragEnd={(e) => onDragEnd(e, index)}
                        onDragOver={(e) => e.preventDefault()}>
                        <img src={draggableIcon} alt={"icon"} className="pointer-evenet-none" />
                        <div className="file-icon-container">
                          <img
                            src={
                              FILE_EXTENSION_URL[fileFormat[extension]] || user
                            }
                            alt={fileName}
                            className="pointer-evenet-none"
                          />
                        </div>
                        <div className="files-description-container">
                          <div className="files-description">
                            <div className="file-names">
                              <p>{fileName}</p> -<small>{formatFileSize(fileSize)}</small>
                            </div>
                            {loadingStatus !== "completed" ? (
                              <span className="file-loading-status">{loadingStatus}</span>
                            ) : (
                              <span
                                role="button"
                                className="edit-file-btn"
                                onClick={() => openEditDatafileModal(fileName, id)}>
                                Edit Name
                              </span>
                            )}
                          </div>
                          {!isSuccessfullyUploaded && (
                            <div className="loading-placeholder">
                              <div
                                style={{ width: downLoadingStatus + "%" }}
                                className="loading-percentage"></div>
                            </div>
                          )}
                        </div>
                        {getButtonByActions({
                          id,
                          abortController,
                          loadingStatus,
                          fileName,
                        })}
                      </div>
                    </>
                  );
                },
              )}
            </div>
          </div>
        )}
      </div>
      {dataRoomEditState.modalVisible && (
        <EditDataRoomFileNameModal
          showModal={dataRoomEditState.modalVisible}
          data={dataRoomEditState}
          updateDataRoomFile={updateDataRoomFile}
          hideAuctionModalOnClick={resetDataRoomModalState}
        />
      )}
    </>
  );
};
