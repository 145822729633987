import { useCallback } from "react";
import { useCookie } from "@hooks/cookie";
import { CAPTABLE_URL, CLIENT_ID } from "../../envs";

import { getJsonToParams } from "@utils/common";
import { useRecoilValue } from "recoil";
import { ActiveCurrencyState } from "@states/exchange";
import { useParams } from "react-router-dom";

export const useCaptable = () => {
  const activeCurrency = useRecoilValue(ActiveCurrencyState)
  const { get: getCookieData } = useCookie();
  const params = useParams();

  const openCaptableWindow = useCallback((props?: Record<string, any>) => {
    const userDetail = getCookieData("userDetails");
    const urlParams = getJsonToParams({
      token: userDetail?.token,
      type: "signupToken",
      key: "liquidity",
      ...(props || {}),
      tanentToken: CLIENT_ID,
      assetSymbol: activeCurrency?.symbol || params?.id || params?.['*'] || '',
    });
    window.open(`${CAPTABLE_URL}/login${urlParams}`, "_self");
  }, [activeCurrency, params, getCookieData]);

  return { openCaptableWindow };
};
