import React, { useCallback, useEffect, useState } from "react";
// import { Check, Mail, UserCheck, Building, Award } from "lucide-react";
import "./verifictionStep.scss";
import {
  complianceDataState,
  isStartVerificationNewState,
  openComplianceState,
  reinitiateKycState,
  reOpenKycSignupModalState,
  showUserEditModalState,
  signUpInfoState,
  userPersonalDetails,
} from "@states/user";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import { MORNING_LINE } from "@views/exchange/constants";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

type StepStatus = "completed" | "processing" | "pending" | "rejected";

interface Step {
  id: string;
  icon?: React.ReactNode;
  label: string;
  status: StepStatus;
  key?: string;
}

export const VerificationSteps: React.FC = () => {
  const { post: resendVerifyMail, loading } = useNetwork();

  const { successNotification, errorNotification } = useNotification();

  const personalData = useRecoilValue(userPersonalDetails);
  const setEditFormData = useSetRecoilState(signUpInfoState);
  const setComplianceData = useSetRecoilState(complianceDataState);
  const setShowUserEditModal = useSetRecoilState(showUserEditModalState);
  const setReinitiateKyc = useSetRecoilState(reinitiateKycState);
  const setIsStartVerification = useSetRecoilState(isStartVerificationNewState);
  const setReOpenKycSignupModal = useSetRecoilState(reOpenKycSignupModalState);
  const setOpenCompliance = useSetRecoilState(openComplianceState);
  const { config } = usePermissionContext();
const isMLC = config?.name === MORNING_LINE;

  const { kycStatus, kybStatus, accreditationStatus } =
    personalData?.onboardingData ?? {};

  const [steps, setSteps] = useState<Step[]>([
    {
      id: "email",
      label: "Add & verify email",
      status: "pending",
    },
    {
      id: "kyc",
      label: "KYC",
      status: "pending",
    },
    {
      id: "kyb",
      label: "KYB",
      status: "pending",
    },
    {
      id: "accreditation",
      label: "Accreditation",
      status: "pending",
    },
  ]);

  useEffect(() => {
    setSteps([
      {
        id: "email",
        label: "Add & verify email",
        status: personalData?.isVerifiedEmail
          ? "completed"
          : personalData?.email
          ? "processing"
          : "pending",
        key: "email",
      },
      {
        id: "kyc",
        label: "KYC",
        status: kycStatus,
        key: "kycStatus",
      },
      {
        id: "kyb",
        label: "KYB",
        status: kybStatus,
        key: "kybStatus",
      },
      {
        id: "accreditation",
        label: "Accreditation",
        status: accreditationStatus,
        key: "accreditationStatus",
      },
    ]);
  }, [accreditationStatus, kybStatus, kycStatus, personalData]);

  const getStatusText = (status: StepStatus) => {
    switch (status) {
      case "completed":
        return "Completed";
      case "processing":
        return "Under review";
      case "rejected":
        return "Rejected";
      default:
        return "Pending";
    }
  };

  const handleClickEditUser = useCallback(
    (action: string) => {
      const {
        firstName = "",
        lastName = "",
        email = "",
        id,
        accountName = "",
      } = personalData;
      setEditFormData({ firstName, lastName, email, accountName });
      setComplianceData({ id: id as string, onboardingData: {} });
      setShowUserEditModal(action);
    },
    [personalData]
  );

  const onClickResend = useCallback(async () => {
    if (loading) return;
    const res = await resendVerifyMail(APIS.ResendVerificationEmail, {});
    if (res?.success) return successNotification(res?.message);
    else
      return errorNotification(
        res?.message || "Something went wrong. Please try again"
      );
  }, [loading, personalData]);

  const handleOpenKycModal = useCallback(() => {
    setReinitiateKyc(true);
    setIsStartVerification(true);
    setOpenCompliance(true);
    setReOpenKycSignupModal(true);
  }, []);

  const verificationStepMobileIndicator = () => {
    const currentStep = steps.find(
      (step) => step.status === "pending" || step.status === "rejected"
    );

    if (!currentStep) return null;

    const filteredSteps = steps.filter((step) => step.status !== undefined);

    const stepIndex =
      filteredSteps.findIndex((s) => s.id === currentStep.id) + 1;
    const totalSteps = steps.filter((item) => item.status !== undefined).length;
    const progressPercentage = (stepIndex / totalSteps) * 100;
    return (
      <>
        <div className="mlc_progress-container">
          <div
            className="progress-circle"
            style={{
              background: `conic-gradient(#4ade80 ${progressPercentage}%, #e5e7eb ${progressPercentage}%)`,
            }}
          >
            <span>
              {stepIndex} of {totalSteps}
            </span>
          </div>
          <div className="progress-content">
            <p>{currentStep.label === 'KYC' ? 'Identity verification' : currentStep.label }</p>
            <div>
            <button
              className="verify-btn"
              onClick={() => {
                if (currentStep.id === "email") {
                  personalData?.email
                    ? onClickResend()
                    : handleClickEditUser("email");
                } else {
                  handleOpenKycModal();
                }
              }}
            >
              {currentStep.id === "email"
                ? personalData?.email
                  ? "Verify"
                  : "Add & Verify"
                : personalData?.sessionId
                ? "Reinitiate"
                : "Start Verification"}
            </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="verificationStep">
      <div className={"verification-steps"}>
        <div className="steps-container">
          {steps.map((step, index) => (
            <>
              {(step.status ||
                personalData?.onboardingData?.hasOwnProperty(step.key)) && (
                <div
                  key={step.id}
                  className={`step ${step.status} ${
                    personalData?.sessionId ||
                    (step.id === "kyc" && personalData?.isVerifiedEmail)
                      ? ""
                      : "sessionInt"
                  }`}
                >
                  <div
                    className="step-icon"
                    title="Click to change status"
                  ></div>
                  <div className="step-label">
                    {step.status === "pending" &&
                    step.label === "KYC" &&
                    !personalData?.sessionId ? (
                      "Identity verification"
                    ) : step.id === "email" ? (
                      <>
                        {step.status === "completed" ? (
                          <>Email Verification</>
                        ) : step.status === "processing" ? (
                          <>
                            {personalData?.email}{" "}
                            <i
                              className="ri-edit-line"
                              onClick={() => handleClickEditUser("email")}
                            ></i>
                          </>
                        ) : (
                          step.label
                        )}
                      </>
                    ) : (
                      step.label
                    )}{" "}
                    {step.id === "email" && !personalData?.isVerifiedEmail && (
                      <>
                        {personalData?.email ? (
                          <button
                            className="verification-action-btn"
                            onClick={() => onClickResend()}
                          >
                            verify
                          </button>
                        ) : (
                          <button
                            className="verification-action-btn"
                            onClick={() => handleClickEditUser("email")}
                          >
                            Add & verify
                          </button>
                        )}
                      </>
                    )}
                    {step.id === "kyc" &&
                      (personalData?.isVerifiedEmail ||
                        personalData?.sessionId) &&
                      (step.status === "pending" ||
                        step.status === "rejected") && (
                        <>
                          {" "}
                          {personalData?.sessionId ? (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Reinitiate
                            </button>
                          ) : (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Start verification
                            </button>
                          )}
                        </>
                      )}
                    {step.id === "kyb" &&
                      (step.status === null ||
                        step.status === "pending" ||
                        step.status === "rejected") &&
                      (kycStatus === "completed" ||
                        kycStatus === "processing") && (
                        <>
                          {" "}
                          {personalData?.sessionId ? (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Reinitiate
                            </button>
                          ) : (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Start verification
                            </button>
                          )}
                        </>
                      )}
                    {step.id === "accreditation" &&
                      personalData?.sessionId &&
                      (step.status === "pending" ||
                        step.status === "rejected") &&
                      ((personalData?.onboardingData?.hasOwnProperty(
                        "kybStatus"
                      ) &&
                        kybStatus !== null &&
                        kybStatus !== "pending") ||
                        !personalData?.onboardingData?.hasOwnProperty(
                          "kybStatus"
                        )) &&
                      kycStatus !== "pending" &&
                      kycStatus !== "rejected" &&
                      kybStatus !== "rejected" && (
                        <>
                          {" "}
                          {personalData?.sessionId ? (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Reinitiate
                            </button>
                          ) : (
                            <button
                              className="verification-action-btn"
                              onClick={handleOpenKycModal}
                            >
                              Start verification
                            </button>
                          )}
                        </>
                      )}
                    {step.status !== "pending" && step.status && (
                      <>
                        {step.id === "email" ? (
                          <>
                            {step.status !== "processing" && (
                              <div className={`step-status ${step.status}`}>
                                {getStatusText(step.status)}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={`step-status ${step.status}`}>
                            {getStatusText(step.status)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      {verificationStepMobileIndicator()}
    </div>
  );
};
