import { ReactResponsiveTable } from "@storybook";
import React, { useMemo } from "react";
import { AUCTION_TABLE_HEADER } from "./constant";

const AuctionData = {
  message: "ok",
  data: [
    {
      id: "80b40179-1d3e-4e5b-a7ef-f83444fa6564",
      assetId: "d5afaeb8-83cf-4b61-8720-b8cbf0238131",
      name: "pp testing",
      auctionName: "Item name",
      createdBy: "Vaibhav Arora",
      description: "",
      assetImage: [
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/pp testing-1697724674000.image/jpeg",
      ],
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/pp testing-1697724675000.image/png",
      specification: {},
      tradeType: "classic",
      status: "completed",
      startPrice: 100,
      endPrice: 0,
      timeStepHours: 0,
      timeStepMinutes: 0,
      dutchStepQuantity: 0,
      dutchDuration: 0,
      stepPrice: 50,
      buynowPrice: 1000,
      reservePrice: 0,
      antiSnipTimeStep: 60,
      isBidAgentEnabled: false,
      startDateAndTime: "Sept 15, 2023",
      endTime: "2023-10-20T14:09:00.000Z",
      autoRestart: false,
      restartSize: 0,
      auctionVisibility: false,
      createdAt: "2023-10-19T14:11:15.369Z",
      isPublished: true,
      antiSnipTimeToAdd: 120,
      symbol: "Pankaj.Jasoria",
      symbolValue: "",
      folderId: "a62e5536-8d9d-4665-b40b-984484f183e0",
      currentBidPrice: 1000,
      finalPrice: 1000,
      totalBid: 4,
      userBidPrice: 0,
      highestBidPrice: 1000,
      isWatchlist: false,
      antiSnipTimestamp: "2023-10-19T14:17:28.1728Z",
    },
    {
      id: "28490ef2-d921-4bbd-bdf3-1c3a750fd0e8",
      assetId: "49235e35-2cb2-427e-8d5f-0d8907d7e0ac",
      name: "testing007",
      auctionName: "Item name",
      createdBy: "Prateek Singh",
      description: "description",
      assetImage: [
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing007-1697718474000.image/jpeg",
      ],
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/testing007-1697712622000.image/jpeg",
      specification: {},
      tradeType: "classic",
      status: "live",
      startPrice: 200,
      endPrice: 0,
      timeStepHours: 0,
      timeStepMinutes: 0,
      dutchStepQuantity: 0,
      dutchDuration: 0,
      stepPrice: 100,
      buynowPrice: 20000,
      reservePrice: 0,
      antiSnipTimeStep: 0,
      isBidAgentEnabled: false,
      startDateAndTime: "Sept 15, 2023",
      endTime: "2023-10-27T07:20:00.000Z",
      autoRestart: false,
      restartSize: 0,
      auctionVisibility: false,
      createdAt: "2023-10-19T10:50:22.591Z",
      isPublished: true,
      antiSnipTimeToAdd: 0,
      symbol: "Pankaj.Jasoria",
      symbolValue: "",
      folderId: "",
      currentBidPrice: 200,
      finalPrice: 0,
      totalBid: 0,
      userBidPrice: 0,
      highestBidPrice: null,
      isWatchlist: false,
      antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
    },
    {
      id: "4100fd17-34e7-414b-85a6-141996e803f0",
      assetId: "386039da-c783-453f-843b-1b04078025b4",
      name: "dutch test 01",
      auctionName: "Item name",
      createdBy: "Chandan Tiwari",
      description: "this is testing",
      assetImage: [
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/dutch test 01-1697712337000.image/jpeg",
      ],
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/dutch test 01-1697712291000.image/jpeg",
      specification: {},
      tradeType: "dutch",
      status: "live",
      startPrice: 10000,
      endPrice: 500,
      timeStepHours: 2,
      timeStepMinutes: 30,
      dutchStepQuantity: 20,
      dutchDuration: 28,
      stepPrice: 100,
      buynowPrice: 0,
      reservePrice: 0,
      antiSnipTimeStep: 0,
      isBidAgentEnabled: false,
      startDateAndTime: "Sept 15, 2023",
      endTime: "2023-10-24T05:18:00.000Z",
      autoRestart: false,
      restartSize: 0,
      auctionVisibility: false,
      createdAt: "2023-10-19T10:44:51.827Z",
      isPublished: true,
      antiSnipTimeToAdd: 0,
      symbol: "Pankaj.Jasoria",
      symbolValue: "JKRF",
      folderId: "68b487f5-dc02-481d-bcfe-d5b5be858bd1",
      currentBidPrice: 10000,
      finalPrice: 0,
      totalBid: 0,
      userBidPrice: 0,
      highestBidPrice: null,
      isWatchlist: false,
      antiSnipTimestamp: "2023-10-21T14:48:00.480Z",
    },
    {
      id: "b5f1cdfa-3c48-4e1f-bb8c-840edf88b105",
      assetId: "c33de39b-9e66-4676-a017-fe0a9f19e93a",
      name: "file testing",
      auctionName: "Item name",
      createdBy: "bcd",
      description: "",
      assetImage: [
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/file testing-1697706119000.image/jpeg",
      ],
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/file testing-1697706120000.image/jpeg",
      specification: {},
      tradeType: "classic",
      status: "completed",
      startPrice: 600,
      endPrice: 0,
      timeStepHours: 0,
      timeStepMinutes: 0,
      dutchStepQuantity: 0,
      dutchDuration: 0,
      stepPrice: 100,
      buynowPrice: 10000,
      reservePrice: 0,
      antiSnipTimeStep: 180,
      isBidAgentEnabled: false,
      startDateAndTime: "Sept 15, 2023",
      endTime: "2023-10-27T03:30:00.000Z",
      autoRestart: false,
      restartSize: 0,
      auctionVisibility: false,
      createdAt: "2023-10-19T09:02:00.386Z",
      isPublished: true,
      antiSnipTimeToAdd: 237,
      symbol: "Pankaj.Jasoria",
      symbolValue: "JKRF",
      folderId: "30aaa5b2-087c-43fe-b44b-24024e04010e",
      currentBidPrice: 10000,
      finalPrice: 10000,
      totalBid: 4,
      userBidPrice: 10000,
      highestBidPrice: 10000,
      isWatchlist: true,
      antiSnipTimestamp: "2023-10-20T06:35:58.3558Z",
    },
    {
      id: "501c7d42-8a00-40be-ba17-b1fdfe920e9b",
      assetId: "aee1d337-7636-4f7e-861a-8aee4673323b",
      name: "auction 02",
      auctionName: "Item name",
      createdBy: "pqr",
      description: "this is description",
      assetImage: [
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/auction 02-1697609585000.image/jpeg",
      ],
      assetIcon:
        "https://storage.googleapis.com/satschel-assets-public/backend.token/stage/auction-assets/auction 02-1697612153000.image/png",
      specification: {},
      tradeType: "classic",
      status: "completed",
      startPrice: 100,
      endPrice: 0,
      timeStepHours: 0,
      timeStepMinutes: 0,
      dutchStepQuantity: 0,
      dutchDuration: 0,
      stepPrice: 20,
      buynowPrice: 2000,
      reservePrice: 0,
      antiSnipTimeStep: 200,
      isBidAgentEnabled: false,
      startDateAndTime: "Sept 15, 2023",
      endTime: "2023-10-24T20:15:00.000Z",
      autoRestart: false,
      restartSize: 0,
      auctionVisibility: false,
      createdAt: "2023-10-18T06:13:05.556Z",
      isPublished: true,
      antiSnipTimeToAdd: 175,
      symbol: "Pankaj.Jasoria",
      symbolValue: "JKRF",
      folderId: "",
      currentBidPrice: 2000,
      finalPrice: 2000,
      totalBid: 7,
      userBidPrice: 2000,
      highestBidPrice: 2000,
      isWatchlist: false,
      antiSnipTimestamp: "2023-10-20T06:38:37.3837Z",
    },
  ],
};
export const CollapseRow = ({ sbaData, _selectedSba }: any) => {
  const tableRows = useMemo(() => {
    if (!AuctionData.data) return [];
    const rows: any = [];
    AuctionData.data?.forEach((items: any, index: number) => {
      if (items) {
        let row: any = {};
        AUCTION_TABLE_HEADER.forEach(({ format, key }) => {
          // row.tradeType = items.tradeType ?? "--";
          row.id = items.id;
          row.instances = items.instances ?? [];
          row.createdAt = items.createdAt;
          // const { status } = items;
          // row.price = 123;
          const { status } = items;

          if (format === "jsx" && key === "arrow" && items?.instances?.length) {
            const value = () => (
              <div
                // onClick={(e) => e.stopPropagation()}
                className="row-arrow"
              >
                <i className="ri-arrow-right-s-fill"></i>
              </div>
            );
            return (row[key] = value);
          }
          if (format === "jsx" && key === "checkbox") {
            const value = () => (
              <div
                onClick={(e) => e.stopPropagation()}
                className="sessions-row-checkbox"
              >
                <input
                  type="checkbox"
                  className="row-checkbox"
                  onChange={() => null}
                />
              </div>
            );
            return (row[key] = value);
          }
          if (key === "actions") {
            const value = () => (
              <>
                <button className="auction-delete-btn">
                  <i className="ri-more-2-line"></i>
                </button>
              </>
            );
            return (row[key] = value);
          }

          if (key === "supportingDoc") {
            const value = () => <>Pool</>;
            return (row[key] = value);
          }
          if (key === "startDateAndTime") {
            const value = () => (
              <>
                {items[key]}
                <div className="startTime">03:20PM</div>
              </>
            );
            return (row[key] = value);
          }
          if (format === "jsx") {
            const value = () => (
              <>
                <div className="text-ellipsis">{items[key]}</div>
              </>
            );

            return (row[key] = value);
          }

          row = { ...row, [key]: items[key] ?? "--" };
          return null;
        });
        rows.push(row);
      }
    });

    return rows;
  }, [AuctionData.data]);
  return (
    <>
      <ReactResponsiveTable
        className=""
        rows={tableRows}
        column={AUCTION_TABLE_HEADER}
        showSearch={false}
        showFilter={false}
        collapsible={false}
        showHeader={false}
      />
    </>
  );
};
