import { AdminHeader } from "views/admin/components";
import "./create-auction.scss";
import { AuctionMainForm } from "./components/auction-main-form";
import { useEffect, useLayoutEffect, useState } from "react";
import { useAuctionApis } from "hooks";
import { useParams } from "react-router-dom";

export const CreateAuction = () => {
  const params = useParams();
  const { fetchAuctionProductCategory } = useAuctionApis();
  const [editAuctionTypeData, setEditAuctionTypeData] = useState({
    auctionId: "",
    isEditable: false,
  });

  useLayoutEffect(() => {
    if (params.id) {
      setEditAuctionTypeData({
        auctionId: params.id,
        isEditable: true,
      });
    }
  }, [params.id]);

  useEffect(() => {
    fetchAuctionProductCategory();
  }, []);

  return (
    <div className="create-auction">
      <AuctionMainForm editAuctionData={editAuctionTypeData} />
    </div>
  );
};
