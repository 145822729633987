import { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import PropTypes from "prop-types";
import App from "../../App";

import "react-toastify/dist/ReactToastify.css";
import "remixicon/fonts/remixicon.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { PermissionProvider } from "@hooks/authentication/permissionsContext";
import { Json } from "types/common";

export class OnboardedDashboard extends Component<Json> {
  state = {
    isReady: false,
  };

  componentDidMount() {
    const clientId = this.props?.clientid  ;

    if (clientId) {
      sessionStorage.setItem("tenantToken", JSON.stringify(clientId));
    }

    this.setState({ isReady: true }); // Mark as ready after setting the item
  }

  render() {
    if (!this.state.isReady) {
      return <></>; 
    }

    return (
      <RecoilRoot>
        <BrowserRouter>
        <PermissionProvider>
          <App props={this.props} />
        </PermissionProvider>
        </BrowserRouter>
      </RecoilRoot>
    );
  }
}

(OnboardedDashboard as any).propTypes = {
  clientid: PropTypes.string,
   
  // bgcolor: PropTypes.string,
  // search: PropTypes.string,
  // theadcolor: PropTypes.string,
  // clientid: PropTypes.string,
  // clientsecret: PropTypes.string,
  // btnprimary: PropTypes.string,
  // btnsecondary: PropTypes.string,
  // notification: PropTypes.string,
  // type: PropTypes.string,
  // admin: PropTypes.string,
  // sidebar: PropTypes.string,
};
