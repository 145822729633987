export const filterOptions = [
  {
    header: "Bidding Status",
    key: "status",
    options: [
      {
        label: "Live",
        value: "live",
      },
      {
        label: "Completed",
        value: "completed",
      },
      {
        label: "Yet to start",
        value: "yet-to-start",
      },
    ],
  },
  {
    header: "Trade Type",
    key: "tradeType",
    options: [
      {
        label: "Dutch",
        value: "dutch",
      },
      {
        label: "Classic",
        value: "classic",
      },
    ],
  },
];
