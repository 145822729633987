// import { IDropdown } from "./type";

import { useState, FC } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import { Icon, Loader } from "components";

import styles from "./selectUserDropdown.module.sass";
import { useLocalStorage, useNetwork, useNotification } from "hooks";
import { APIS } from "constant";
import { useNavigate } from "react-router-dom";
import { userDetailsState } from "@states/user";
import { useRecoilValue } from "recoil";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

interface ISetValue {
  (user: { name: string; image: string; id: string }): void;
}
export interface IDropdown {
  className: string;
  classLabel?: string;
  value: { name: string; image: string; id: string };
  setValue: ISetValue;
  options?: string[] | number[];
  label?: string | JSX.Element;
  classDropdownHead?: string;
  classDropdownArrow?: string;
  classDropdownBody?: string;
  classDropdownOption?: string;
  users?: { name: string; image: string; id: string; customerId?: string }[];
}

export const SelectUserDropdown: FC<IDropdown> = ({
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  users,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
}) => {
  const [visible, setVisible] = useState(false);
  const { put: switchUser, loading } = useNetwork();
  const navigate = useNavigate();
  const { set: setLocalStorage } = useLocalStorage();
  const userDetails = useRecoilValue(userDetailsState);
  const darkMode = useDarkMode();
  const {svg: {avatar_dark, avatar_light}} = imageConfig;

  const {errorNotification}=useNotification();

  const {
     onboardingData
  } = userDetails?.data || {};
  const {
    kycStatus,
    kybStatus,
  } = onboardingData || {};

  const handleClick = (user: {
    name: string;
    image: string;
    id: string;
    isPrimary: boolean;
  }) => {
    setValue(user);
    setVisible(false);

    if (user?.id) {
      if (user?.isPrimary) {
        const payload = { isPrimary: true };
        switchUser(`${APIS.SWITCHACCOUNT}/${user.id}`, payload).then((res) => {
          if (res.success) {
            navigate("/exchange");
            window.location.reload();
          }
        });
      } else {
        if((kybStatus && kybStatus !== "completed" ) || (kycStatus !== "completed")){
                  errorNotification("KYC approval is pending. Please complete the KYC process.");
                  return;
        }
        switchUser(`${APIS.SWITCHACCOUNT}/${user.id}`, {}).then((res) => {
          if (res.success) {
            navigate("/exchange");
            window.location.reload();
          }
        });
      }
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        <div
          className={cn(classDropdownHead, styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <div className={styles.userValue}>
            <Image className={styles.userValueImage} fileName={darkMode.value ? avatar_dark : avatar_light}
                   useFileNameOnFail {...!!value?.image && { url: value?.image }} />
            <div className={styles.selection}>{value?.name}</div>
          </div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            {loading ? (
              <Loader className="loader-grey" dimension={24} />
            ) : (
              <Icon name="arrow-down" size="24" />
            )}
          </div>
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          <div className={styles.main}>
            {users?.map((user: any, index: number) => (
              <div
                className={cn(
                  classDropdownOption,
                  styles.userSection,
                  styles.option,
                  {
                    [styles.selectioned]: user?.id === value?.id,
                  }
                )}
                onClick={() => handleClick(user)}
                key={index}
              >
                <div className={styles.optionLeft}>
                  <div className={styles.userImageBox}>
                     <Image className={styles.userImage} fileName={darkMode.value ? avatar_dark : avatar_light}
                   useFileNameOnFail {...!!user?.image && { url: user?.image }} />
                  </div>
                  <div className={styles.detailsLeft}>
                    <div className={styles.details}>
                      <div className={styles.details__name}>{user?.name}</div>
                      <div className={styles.details__id}>
                        Account ID: {user?.customerId}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.arrowBox}>
                  <i
                    className={cn(
                      "ri-arrow-right-s-line",
                      ` ${styles.arrowIcon}`
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
