import { useCallback, useState } from "react";

import { Button, TextInput } from "components";

import styles from "./AccountBalances.module.sass";
import "./account.scss";

export const AddCard = () => {
  const [accountNumber, setAccountNumber] = useState<string>("");

  const handleOnChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setAccountNumber(value);
  }, []);

  const handleClickBtn = useCallback(() => {}, []);

  return (
    <>
      <div className={styles.addCardHeader}>Add Card</div>
      <div className={styles.addAccount}>
        <div className={styles.addInput}>
          <TextInput
            label="Card Number"
            name="cardNumber"
            placeholder="1234 1234 1234"
            type="text"
            value={accountNumber}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className={styles.addInput}>
          <TextInput
            label="Card Holders Name"
            name="holderNmae"
            placeholder="Enter Name"
            type="text"
            value={accountNumber}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className={styles.expCvv}>
          <div className={styles.addInput}>
            <TextInput
              label="Expiry"
              name="amount"
              type="text"
              value={accountNumber}
              onChange={handleOnChange}
              required
            />
          </div>
          <div className={styles.addInput}>
            <TextInput
              label="CCV"
              name="amount"
              type="text"
              value={accountNumber}
              onChange={handleOnChange}
              required
            />
          </div>
        </div>
        <Button
          label="Add Card"
          handleClick={handleClickBtn}
          type="button__outline button__large add-account"
        />
      </div>
    </>
  );
};
