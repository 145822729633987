export const ORDERS_PAGE_TABS = [
  { title: "Open", type: "PENDING" },
  { title: "Executed", type: "EXECUTED" },
  { title: "Canceled", type: "CANCELLED" },
];

export const ORDER_TYPE: any = {
  PENDING: "PENDING",
  EXECUTED: "EXECUTED",
  CANCELLED: "CANCELLED",
} as const;

export const ORDER_STATUS_DISPLAY: Record<typeof ORDER_TYPE[keyof typeof ORDER_TYPE], string> = {
  CANCELLED: "Canceled",
};

export const emptyOrder = {
  NO_OPEN_ORDER: "No Open Orders",
  NO_EXECUTED_ORDER: "No Executed Orders",
  NO_CANCELLED_ORDER: "No Canceled Orders",
};

export const getTransactionIcon: any = {
  PENDING: "ri-time-fill",
  EXECUTED: "ri-checkbox-circle-fill",
  CANCELLED: "ri-close-circle-fill",
};

export const MUTUAL_FUND = {
  Text: "Mutual Fund",
  Asset: "MCTDX",
};

export const ORDER_TAGS = {
  BUY: "BUY",
  BOT: "BOT",
  SLD:"SLD"
};
