import { useCallback } from 'react';
import axios from 'axios';

import { APIS } from 'constant';
import { REACT_APP_API_HOST  as API_HOST } from 'envs';
import { signInWithTokenAndSetPersistence } from "libs";
import { useNotification } from '@hooks/notification';


export const useHandleSignInFireBase = () => {
  const  API = typeof API_HOST !== "undefined" && API_HOST 

  const { errorNotification } = useNotification();
  const handleSigninFireBase = useCallback(
    async (token: string) => {
      try {
        const response = await axios.get(`${API}${APIS.FIREBASE}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          signInWithTokenAndSetPersistence(response.data.data.token);
        }
        return response.data;
      } catch (error) {
        errorNotification("Something went wrong!")
      }
    },
    [errorNotification]
  );

  return { handleSigninFireBase };
};

