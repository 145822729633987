import { useRecoilValue } from "recoil";

import { userDropDownState } from "states";
import { Accounts, ProfileNav, Cards, Myprofile, AuthorisedUser, LinkedDevice } from "./components";

import styles from "./AccountOverview.module.sass";
import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "routes";
import { Transactions } from "views/MyWallet/conponent/transactions";
import { TransactionDetails } from "views/MyWallet/conponent/TransactionDetails";

export const Account = () => {

  const isActiveUser = useRecoilValue(userDropDownState);

  const { AUTHORISED_USERS, WALLET, PROFILE, ACCOUNTS, CARDS, TRANSACTIONS, TRANSACTION_DETAILS, LINKED_DEVICES, EXCHANGE } =
    ROUTES;


  let isAuthorized = useMemo(() => {
    const dropDownLocalHost = JSON.parse((localStorage.getItem("dropdown") as string));  
    if (!dropDownLocalHost.isPrimary) return false;
    else return true;

  }, [isActiveUser.isPrimary])

  const routing = useMemo(() => {
    const commonRoutes = (
      <Routes>
        {/* <Route path={WALLET} element={<Navigate to={EXCHANGE} />}/> */}
        {/* <Route path={TRANSACTIONS} element={<Transactions />} /> */}
        {/* <Route path={TRANSACTION_DETAILS} element={<TransactionDetails />} />
        <Route path={ACCOUNTS} element={<Accounts />} /> */}
        <Route path={"*"} element={<Navigate to={EXCHANGE} />}/>
        {/* Add any other co-owner/authorized-user routes here */}
      </Routes>
    );

    const authorizedRoutes = (
      <Routes>
        <Route index element={<Myprofile />} />
        <Route path={PROFILE} element={<Myprofile />} />
        <Route path={AUTHORISED_USERS} element={<AuthorisedUser />} />
        {/* <Route path={WALLET} element={<MyWallet />} /> */}
        {/* <Route path={ACCOUNTS} element={<Accounts />} /> */}
        <Route path={CARDS} element={<Cards />} />
        {/* <Route path={TRANSACTIONS} element={<Transactions />} />
        <Route path={TRANSACTION_DETAILS} element={<TransactionDetails />} /> */}
        <Route path={LINKED_DEVICES} element={<LinkedDevice />} />
        <Route path={"*"} element={<Navigate to={"/profile"}/>}/> 
      </Routes>
    );

    return isAuthorized ? authorizedRoutes : commonRoutes;
  }, [AUTHORISED_USERS, CARDS, EXCHANGE, LINKED_DEVICES, PROFILE, isAuthorized]);

  return (
    <>
      <div className={styles.profileMainView}>
       <div  className={styles.profileView}>
       <ProfileNav />
        {routing as any}
       </div>
      </div>
    </>
  );
};

export default Account