import React from "react";
import "./wallet-selection-cards.scss";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

export const WalletSelectionCards = (props: any) => {
  const { setWithdrawType, selected } = props;
  const { trackEvent } = useFullStoryTrackEvent();

  const handleClick = (type: string) => {
    trackEvent("WITHDRAW_METHOD", {
      method: type,
    });
    setWithdrawType(type);
  }

  return (
    <div className="wallet-select-card">
      <div
        className={`card ${selected === "bank" ? "active-card" : ""} `}
        onClick={() => handleClick("bank")}
      >
        <span>
          <i className="ri ri-bank-fill"></i>
        </span>
        <p>Banks</p>
      </div>
      {/* <div
        className="card"
        style={{ cursor: "none", pointerEvents: "none" }}
        onClick={() => handleClick("card")}
      >
        <span>
          <i className="ri ri-bank-card-2-fill"></i>
        </span>
        <p>Cards</p>
      </div> */}
      <div
        className={`card ${selected === "wire" ? "active-card" : ""} `}
        onClick={() => handleClick("wire")}
      >
        <span>
          <i className="ri ri-global-line"></i>
        </span>
        <p>Wire</p>
      </div>
    </div>
  );
};
