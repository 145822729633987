import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Header, Input, Pagination, DataFilter } from "components";

import "./auction-consumer.scss";
import { BidCard, SkeletonBidCard } from "./components";
import {
  useAuctionApis,
  useDebounce,
  useNotification,
  usePrevious,
} from "hooks";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AuctionBidPostState,
  AuctionListState,
  AuctionProductCategoryState,
} from "../../states";
import { Json } from "../../types";
import { AUCTION_FILTERS } from "./constant";
import { Image } from "../../@storybook";
import { getObjectFromUseSearchParams } from "@utils/common";
import { useSearchParams } from "react-router-dom";

export const AuctionConsumer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFilter = { limit: 20, offset: 0, status: "yet-to-start,live" };
  const { fetchAuctionList, fetchAuctionProductCategory } = useAuctionApis();
  const { errorNotification } = useNotification();
  const [{ data: auctions = {}, loading }, setAuctionList] = useRecoilState(AuctionListState);
  const prevLoading = usePrevious(loading);
  const { data: bidDetails = {}, loading: bidLoading } = useRecoilValue(AuctionBidPostState);
  const prevBidLoading = usePrevious(bidLoading);
  const { data: auctionCategories = {} } = useRecoilValue(AuctionProductCategoryState);
  const [filters, setFilters] = useState<Json>({ ...initialFilter, ...getObjectFromUseSearchParams(searchParams) });
  const debouncedFilters = useDebounce(filters, { immediate: filters.immediate }, 600);

  useEffect(() => {
    fetchAuctionProductCategory();
  }, []);

  useEffect(() => {
    if (auctions?.message !== "ok" && !loading && prevLoading) {
      errorNotification(auctions?.message);
    }
  }, [auctions]);

  useEffect(() => {
    if (bidDetails?.message !== "ok" && !bidLoading && prevBidLoading) {
      errorNotification(bidDetails?.message);
    } else if (bidDetails?.message === "ok" && !bidLoading && prevBidLoading) {
      const {
        currentBidPrice, endTime, finalPrice, id, status, totalBid, bidStatus,
        userBidPrice, userMaxBidPrice, maxAuctionBidPrice
      } = bidDetails?.data || {};

      setAuctionList((prev) => {
        let temp = JSON.parse(JSON.stringify(prev?.data?.data));
        const idx = temp.findIndex((_data: Json) => _data?.id === id);
        temp[idx] = {
          ...temp[idx], currentBidPrice, endTime, finalPrice, status, totalBid, bidStatus,
          userBidPrice, userMaxBidPrice, maxAuctionBidPrice
        };
        return { ...prev, data: { ...prev.data, data: [...temp] } };
      });
    }
  }, [bidDetails]);

  const filterOptions = useMemo(() => {
    let finalValue: Json = {
      header: "Categories",
      key: "category",
      options: [],
    };
    if (auctionCategories?.data) {
      Object.keys(auctionCategories?.data || {})?.forEach((key) => {
        finalValue.options.push({ label: key, value: key });
      });
    }

    return [finalValue, ...AUCTION_FILTERS];
  }, [auctionCategories]);

  useEffect(() => {
    handleCallAuction();
  }, [debouncedFilters]);

  const handleCallAuction = () => {
    const { immediate, ..._filters } = filters;
    setSearchParams(_filters)
    fetchAuctionList(_filters);
  };

  const onReset = () => {
    setFilters({ ...initialFilter, immediate: true });
  };

  const handleFilter = (filterObj: Json, debounce: boolean = true) => {
    setFilters((prev) => ({ ...prev, ...filterObj, immediate: !debounce }));
  };

  const handleOnWishlist = useCallback((auctionId: string) => {
    setAuctionList((prev) => {
      let temp = JSON.parse(JSON.stringify(prev?.data?.data));
      temp = temp?.map((_data: Json) => {
        if (_data?.id === auctionId) _data.isWatchlist = !_data.isWatchlist;
        return _data;
      });
      return { ...prev, data: { ...prev.data, data: [...temp] } };
    });
  }, []);

  const handlePagination = useCallback((val: number, action: string) => {
    let pageChange: Json = {}
    if (action === "perPage") {
      pageChange.limit = val
      pageChange.offset = 0
    }
    if (action === "page") pageChange.offset = val
    handleFilter(pageChange);
  }, []);

  return (
    <>
      <div className="auction-container mb-50">
        <div className="auction-filter-container">
          <h1 className="auction-heading">Auction List</h1>
          <Input
            placeholder="Search"
            label=""
            value={filters.searchText || ""}
            inputType="text"
            handleChange={(e) => handleFilter({ searchText: e.target.value, offset: 0 })}
            suffixIcon={
              !!filters.searchText ? "ri-close-line" : "ri ri-search-line"
            }
            handleSuffixIcon={() =>
              !!filters.searchText && handleFilter({ searchText: "" }, false)
            }
          />
          <DataFilter
            selectedValue={filters}
            data={filterOptions}
            position="right"
            onChangeFilter={(obj) => handleFilter({ ...obj, offset: 0 })}
            onClickReset={onReset}
            isLoading={loading}
          />
        </div>
        {auctions?.data?.map((detail: Json) => (
          <BidCard
            key={detail?.id}
            detail={detail}
            handleOnWishlist={handleOnWishlist}
          />
        ))}
        {loading && <SkeletonBidCard listsToRender={4} />}
        {!auctions?.data?.length && !loading && (
          <div className="no-data-found">
            <Image fileName="icon/emptyCards.svg" />
            Your auction list is empty
          </div>
        )}
        <Pagination showCount={false} listCount={auctions?.data?.length} page={parseInt(filters.offset)}
                    perPage={parseInt(filters.limit)} onChangePage={(page) => handlePagination(page, "page")}
                    onChangePerPage={(perPage) => handlePagination(perPage, "perPage")} />
      </div>
    </>
  );
};

export default AuctionConsumer
