import {  LoginUserState } from "@states/user";
import { useNavigate } from "react-router-dom";
import {  useSetRecoilState } from "recoil";
import { useSwitchWorkSpace } from "./useSwitchWorkSpace";
import { ROUTES } from "@routes/constants";
import { useCookie } from "@hooks/cookie";
import { useNotification } from "@hooks/notification";

export const useLogin = () => {
  const navigate = useNavigate();

  const setLoginUser = useSetRecoilState(LoginUserState);
  const { fetchWorkSpaceUser } = useSwitchWorkSpace();
  const { set: setCookieToken } = useCookie();
  const { EXCHANGE, SWITCH_ACCOUNT } = ROUTES;
  const { successNotification } = useNotification();

  const loginUser = async (token: string, message?: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      const res = await fetchWorkSpaceUser();
      if (res?.message !== "ok") return;
      if (res?.data?.length > 1) {
        navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
        return;
      }

      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      if (message) {
        successNotification(message);
      }
      navigate(EXCHANGE);
    }
  };

  return { loginUser };
};
