import React, { useEffect } from "react";

import "../AuctionDetails/auction-details.scss";
import {
  RadialSeparators,
  SkeletonLogo,
  SkeletonTable,
  SkeletonWallet
} from "../../../components";
import { useParams } from "react-router-dom";
import { useCurrency, useNetwork, useNotification, usePrevious } from "../../../hooks";
import { APIS } from "../../../constant";
import { AuctionDataRoom, LatestBids } from "@views/AuctionConsumer/components";
import { asterisksString } from "@utils/common";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { BidSheetLoan } from "@views/auction/create-auction/components/BidSheetLoan";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { imageConfig } from "@utils/imageConfig";

export const UserBidDetails = () => {
  const params = useParams();
  const { errorNotification } = useNotification();
  const { formatCurrencyWithBillion } = useCurrency();
  const { get: fetchAuction, data: auctionData = {}, loading } = useNetwork();
  const prevLoading = usePrevious(loading);
  const auctionDetails = auctionData?.data || {};
  const { sbaAndNormalPrice, isSbaLoan } = useAuctionHook(auctionDetails);

  useEffect(() => {
    fetchAuction(`${APIS.Auction}/${params.id}`);
  }, []);

  useEffect(() => {
    if (auctionData?.message !== "ok" && !loading && prevLoading) {
      errorNotification(auctionData?.message);
    }
  }, [auctionData]);

  const {svg: {user}} = imageConfig;
  return (
    <div className="auction-details-container">
      <div className="details-and-latest-bids"
           style={auctionDetails?.tradeType === "dutch" ? { flex: "1 1 100%" } : {}}>
        <div className="details-container">
          <h2 className="auction-details-heading">Auction Details</h2>
          <div className="congratulations-image-container">
            {!loading &&
              <div className="auction-details-image">
                {!!auctionDetails?.assetImage?.[0] &&
                  <img src={auctionDetails?.assetImage?.[0]} alt="auction detail image" />}
                {!auctionDetails?.assetImage?.[0] && <i className="ri-image-add-line" />}
              </div>}
            {loading && <SkeletonLogo className="auction-details-image" />}
            {!!auctionDetails?.id && <div className="congratulations-container">
              {!!auctionDetails?.highestBidderDetails?.status ? <i className="ri-checkbox-circle-fill icon" /> :
                <i className="ri-information-fill icon" />}
              <p className="congratulations-heading">
                {auctionDetails?.highestBidderDetails?.isWinner ? "Congratulations" : "Auction completed"}
              </p>
              <p className="congratulations-desc">
                {!!auctionDetails?.highestBidderDetails?.isWinner ? "You won this auction" :
                  !!auctionDetails?.highestBidderDetails?.status ?
                    "Auction won by " + (asterisksString(auctionDetails?.highestBidderDetails?.name) || "--") :
                    `No ${auctionDetails?.tradeType !== "dutch" ? "bids" : "bid"} received, auction completed.`}
              </p>
            </div>}
          </div>

          {loading && (
            <SkeletonTable listsToRender={2} numberColumn={1} className="border-b-0" />
          )}
          {!loading && (
            <div className="auction-details">
              <div className="title-heading-symbol">
                <h2 className="auction-name">{auctionDetails?.name}</h2>
                <span
                  className="title-symbol">{auctionDetails?.symbol}.<span>{auctionDetails?.symbolValue}</span></span>
              </div>
              <p className="auction-desc">{auctionDetails?.description}</p>
            </div>
          )}
        </div>
        {auctionDetails?.tradeType === "classic" && <div className="auction-latest-bids">
          <LatestBids auctionId={params.id!} auctionDetails={auctionDetails} />
        </div>}
      </div>

      {loading && <SkeletonWallet listsToRender={1} />}
      {!loading && <div className="auction-detail-features mt-10 mb-10"
                        style={{ gridTemplateColumns: !!auctionDetails?.highestBidderDetails?.status ? "6fr 6fr" : "4fr 9fr" }}>
        <div className="auction-details latest-bids-container stats" style={{ borderLeft: 0 }}>
          {!!auctionDetails?.highestBidderDetails?.status &&
            <div className="latest-bid-card">
              <img className="bid-img"
                   src={auctionDetails?.highestBidderDetails?.profilePicture || user}
                   alt="User" />
              <div className="bid-detail">
                <span className="time">{auctionDetails?.highestBidderDetails?.status?.split("-").join(" ")} by</span>
                <h2 className="name">
                  {!!auctionDetails?.highestBidderDetails?.isWinner ? auctionDetails?.highestBidderDetails?.name || "--" :
                    asterisksString(auctionDetails?.highestBidderDetails?.name) || "--"}
                </h2>
              </div>
              <div className="bid-detail">
                <span className="time">Winning bid</span>
                <h2 className="name text-green">
                  {sbaAndNormalPrice(auctionDetails, {
                    price: auctionDetails?.highestBidderDetails?.bidPrice
                  })}
                </h2>
                {isSbaLoan && <span className="fs-16">
                      {formatCurrencyWithBillion(auctionDetails?.highestBidderDetails?.bidPrice, 2)}</span>}
              </div>
            </div>}

          {!auctionDetails?.highestBidderDetails?.status && <div style={{ width: "168px", margin: "auto" }}>
            <CircularProgressbarWithChildren
              value={100} maxValue={100} className="auction-progress-bar time-red"
              strokeWidth={6}>
              <RadialSeparators count={90} />
              <div className="stats-item text-center">
                <p className="stat-value">
                  {sbaAndNormalPrice(auctionDetails, {
                    price: auctionDetails?.currentBidPrice
                  })}
                </p>
                <p className="stat-label">00:00:00</p>
              </div>
            </CircularProgressbarWithChildren>
          </div>}
        </div>
        <div className="stats">
          <div className="stats-details">
            <div className="stats-item">
              <p className="stat-label">Auction Type</p>
              <p className="stat-value">{auctionDetails?.tradeType}</p>
            </div>
            <div className="stats-item">
              <p className="stat-label">Auction Status</p>
              <p className="stat-value">{auctionDetails?.status?.split("-").join(" ")}</p>
            </div>
            {auctionDetails?.tradeType === "classic" && !!auctionDetails?.highestBidderDetails?.status &&
              <div className="stats-item">
                <p className="stat-label">Bidders</p>
                <p className="stat-value">{auctionDetails?.totalBid}</p>
              </div>}
            <div className="stats-item">
              <p className="stat-label">Starting bid</p>
              <p className="stat-value">
                {sbaAndNormalPrice(auctionDetails, {
                  price: auctionDetails?.startPrice
                })}
              </p>
              {isSbaLoan && <p className="stat-label">
                {formatCurrencyWithBillion(auctionDetails?.startPrice, 2)}
              </p>}
            </div>
            {auctionDetails?.tradeType === "dutch" && <div className="stats-item">
              <p className="stat-label">Reserve price</p>
              <p className="stat-value">
                {sbaAndNormalPrice(auctionDetails, {
                  price: auctionDetails?.reservePrice
                })}
              </p>
              {isSbaLoan && <p className="stat-label">
                {formatCurrencyWithBillion(auctionDetails?.reservePrice, 2)}
              </p>}
            </div>}
            <div className="stats-item">
              <p className="stat-label">Price Step</p>
              <p className="stat-value">
                {sbaAndNormalPrice(auctionDetails, {
                  price: auctionDetails?.stepPrice
                })}
              </p>
              {isSbaLoan && <p className="stat-label">
                {formatCurrencyWithBillion(auctionDetails?.stepPrice, 2)}
              </p>}
            </div>
            {auctionDetails?.tradeType === "classic" && !!auctionDetails?.highestBidderDetails?.status &&
              <div className="stats-item">
                <p className="stat-label">My highest bid</p>
                <p className="stat-value">
                  {sbaAndNormalPrice(auctionDetails, {
                    price: auctionDetails?.userBidPrice
                  })}
                </p>
                {isSbaLoan && <p className="stat-label">
                  {formatCurrencyWithBillion(auctionDetails?.userBidPrice, 2)}
                </p>}
              </div>}
            {auctionDetails?.tradeType === "classic" && !auctionDetails?.highestBidderDetails?.status &&
              <div className="stats-item">
                <p className="stat-label">Buy Now Price</p>
                <p className="stat-value">
                  {sbaAndNormalPrice(auctionDetails, {
                    price: auctionDetails?.buynowPrice
                  })}
                </p>
                {isSbaLoan && <p className="stat-label">
                  {formatCurrencyWithBillion(auctionDetails?.buynowPrice, 2)}
                </p>}
              </div>}
            {auctionDetails?.tradeType === "dutch" && <div className="stats-item">
              <p className="stat-label">Time step</p>
              <p className="stat-value">
                {auctionDetails?.timeStepHours < 10 ? "0" : ""}{auctionDetails?.timeStepHours}:
                {auctionDetails?.timeStepMinutes < 10 ? "0" : ""}{auctionDetails?.timeStepMinutes}:00
              </p>
            </div>}
          </div>
        </div>
      </div>}
      <AuctionDataRoom auctionDetails={auctionData || {}} />
      {!!auctionDetails?.bidSheetJSON && !!auctionDetails?.sba1502JSON &&
        <BidSheetLoan auctionDetails={auctionDetails} />}
    </div>
  );
};
export default UserBidDetails
