export function throttle<T extends (...args: any[]) => void>(func: T, delay: number) {
    let locked = false;
  
    return (...args: Parameters<T>) => {
      if (!locked) {
        func(...args);
        locked = true;
        setTimeout(() => {
          locked = false;
        }, delay);
      }
    };
  }
  