import { SBA7A } from "@views/exchange/constants";

export const lastFourCharacterVisible = (inputString: string) => {
  if (typeof inputString !== "string" || inputString.length < 4) {
    return "Invalid input";
  }

  const visiblePart = inputString.slice(-4); // Get the last 4 characters
  const asterisks = "*".repeat(inputString.length - 4); // Create asterisks to cover the rest
  return asterisks + visiblePart;
};

export function maskString(inputString: string) {
  return inputString.replace(/^(.*?:.*?:).*(?=.{4}$)/, (match, part1) => {
    const maskedPart = "*".repeat(match.length - part1.length);
    return part1 + maskedPart;
  });
}

export function adjustStringLength(input: string, desiredLength: number, padChar: string = ' '): string {
  const currentLength = input.length;

  if (currentLength === desiredLength) {
    return input; // No need to adjust
  } else if (currentLength < desiredLength) {
    // Pad the string to the right with the specified character
    return input + padChar.repeat(desiredLength - currentLength);
  } else {
    // Truncate the string to the desired length
    return input.slice(0, desiredLength);
  }
}

export function limitFirstPart(input: string, limit: number, symbol?: string): string {
  if (!input || limit <= 0) {
    return input; // No need to process if input is empty or limit is non-positive
  }

  if (!symbol) {
    // If symbol is not provided, use an empty string as the default
    symbol = ' ';
  }

  const parts = input.split(symbol);

  if (parts.length === 1) {
    // Only one part, no need to split
    return parts[0].slice(0, limit);
  }

  const firstPart = parts[0].slice(0, limit);
  const result = `${firstPart}${symbol}${parts.slice(1).join(symbol)}`;
  return result;
}


export function isSubstringInArray(substring: string, stringArray: string[]) {
  for (let i = 0; i < stringArray.length; i++) {
    if (stringArray[i].includes(substring)) {
      return true;
    }
  }
  return false;
}

export const mergeStringWithSymbol = (val1: string, val3: string = "", symbol: string) => {
    return `${val1 || ""}${val1 ? symbol || "" : ""}${val3 || ""}`
}

export const getCategoryLabel = (category?: string): string => {
  switch (category) {
    case "privates":
      return "Private Stock";
    case "sba7":
      return SBA7A;
    case "manufactured_home_loans":
      return "MHL";
    case "horse_trade":
      return "Horses";
    default:
      return category !== undefined ? (category?.charAt(0).toUpperCase() + category?.slice(1).toLowerCase())?.replace(/-|_/g, ' ') : "-";
  }
};
