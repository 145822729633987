import { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import cn from "classnames";

import {
  AccountState,
  depositStepState,
  SelectedFundRecipientBank,
  SelectedFundRecipientBankAccount,
} from "states";
import { Image } from "@storybook";
import { getLogos } from "../../../account/constants";
import { Button } from "components";

import "./selectAccount.scss";
import styles from "../deposit/deposit.module.sass";
import { NoBankAccountAdded } from "views/MyWallet/conponent/NoBankAccountAdded";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { imageConfig } from "@utils/imageConfig";

export const SelectAccount = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selectedItem, setSelectedItem] = useRecoilState(SelectedFundRecipientBank);
  const fundRecipient = useRecoilValue(AccountState);
  const [subSelectedItem, setSubSelectedItem] = useRecoilState(
    SelectedFundRecipientBankAccount
  );

  const [selectedBankItem, setSelectedBankItem] = useState<any>({
    item: selectedItem,
    subitem: subSelectedItem,
  });

  const setDepositState = useSetRecoilState(depositStepState);
  const { trackEvent } = useFullStoryTrackEvent();
  const { logo } = imageConfig;

  const handleBank = useCallback(
    (index: number) => {
      if (selectedIndex === index) setSelectedIndex(-1);
      else setSelectedIndex(index);
    },
    [selectedIndex, setSelectedIndex],
  );

  const handleSubBank = useCallback(
    (item: any, subitem: any) => {
      setSelectedItem(item);
      setSubSelectedItem({ ...subitem, _id: item._id });
      setDepositState("amount");
      trackEvent("SELECT_BANK", {item, subitem});
      localStorage.setItem("selectedBank", JSON.stringify({ ...item }));
      localStorage.setItem("selectedBankSubItem", JSON.stringify({ ...subitem, _id: item._id }));
    },
    [setDepositState, setSelectedItem, setSubSelectedItem],
  );

  const handleClickBtn = useCallback(() => {
    // if (!selectedItem) return;
    setDepositState("amount");
    trackEvent("PAYMENT_BACK", {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleSelectBank = useCallback((item: any, subitem: any) => {
    setSelectedBankItem({ item, subitem });
  }, []);

  const renderBody = useMemo(() => {
    return (
      <div className="fi-wrapper__fr_inner__right">
        <div className="fi-wrapper__fr_inner__right__lists">
          {fundRecipient?.map((item: any, index: number) => (
            <div
              className={`fi-wrapper__fr_inner__right__lists__wrapper  ${
                selectedBankItem?.item?._id === item?._id ? "active" : ""
              }`}
              key={item._id}>
              <div
                className="fi-wrapper__fr_inner__right__lists__item "
                onClick={() => handleBank(index)}>
                {selectedBankItem?.item?._id === item?._id && (
                  <i className="ri-checkbox-circle-fill active-icon " />
                )}
                <div className="fi-wrapper__fr_inner__right__lists__item__details">
                  <Image
                    className="bank-logo"
                    fileName={logo[item.bankName] ?? logo["default"]}
                  />
                  <div className="fi-wrapper__fr_inner__right__lists__item__right">
                    <div className="fi-wrapper__title">{item.bankName}</div>
                    <div className="fi-wrapper__sub_title">
                      {selectedBankItem?.item?._id === item?._id ? (
                        <div>
                          {`${selectedBankItem?.subitem?.accountType}`} (&#8226;&#8226;&#8226;&#8226;
                          {selectedBankItem?.subitem?.mask})
                        </div>
                      ) : (
                        <>
                          {`${item?.accounts[0]?.accountType}`} (&#8226;&#8226;&#8226;&#8226;
                          {item?.accounts[0]?.mask})
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <i
                  className="ri-arrow-down-s-line dropdown-icon"
                  style={{
                    transform: selectedIndex === index ? "scale(-1)" : "unset",
                  }}
                />
              </div>
              {selectedIndex === index && (
                <>
                  <div className="line" />
                  <div className="radio-list__wrapper">
                    {item.accounts?.map((subitem: any) => (
                      <div
                        className="radio-list__wrapper__item"
                        onClick={() => handleSelectBank(item, subitem)}
                        key={subitem.accountId}>
                        <div className="radio-list__wrapper__item__right">
                          <input
                            id="primary"
                            type="radio"
                            checked={selectedBankItem?.subitem?.accountId === subitem?.accountId}
                            style={{ transform: "scale(1.5)" }}
                          />
                          <div className="radio-list__wrapper__item__right__sub_type">
                            {subitem.accountType}{" "}
                          </div>
                        </div>
                        <div className="">&#8226;&#8226;&#8226;&#8226; {subitem.mask}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }, [fundRecipient, handleBank, handleSelectBank, selectedBankItem?.item?._id, selectedBankItem?.subitem?.accountId, selectedBankItem?.subitem?.mask, selectedBankItem?.subitem?.accountType, selectedIndex]);

  return (
    <div className="change-method">
      <div className={styles.changeHeader}>Change Bank Account</div>
      <NoBankAccountAdded isVisibleStaticUI={false} depositState="method" />
      {renderBody}
      <div className={cn(styles.withdrawFooter, styles.selectAccountFooter)}>
        <Button
          label="Back"
          handleClick={handleClickBtn}
          type={cn("button__outline", styles.selectBtn)}
        />
        <Button
          label="Select"
          handleClick={() =>
            handleSubBank(selectedBankItem.item, selectedBankItem.subitem)
          }
          type={cn(
            `button__outline ${!selectedBankItem ? "disabledBtn" : ""}`,
            styles.selectBtn
          )}
          disabled={!selectedBankItem?.subitem}
        />
      </div>
    </div>
  );
};
