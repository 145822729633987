import React from "react";
import "./biometric-setup.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

const BiometricSetup = ({ isDarkMode = false, handleWebAuthn ,handleLogin }: any) => {
  const { config } = usePermissionContext();
  return (
    <div className={`biometric-setup-wrapper`}>
      <div className={`biometric-setup-wrapper__inner`}>
        <div className={`biometric-setup-wrapper__left`}>
          <div>
            <div>
              <h2 className="touch-id-title">
                Login your account with Touch ID
              </h2>
              <p className="touch-id-description">
                Easily log in using your fingerprint for faster and more secure
                access.
              </p>
              <ul className="touch-id-list">
                <li className="touch-id-list-item">Quick, hassle free login</li>
                <li className="touch-id-list-item">Enhanced security</li>
                <li className="touch-id-list-item">Set up in seconds</li>
              </ul>
              <div className="touch-id-button-group">
                <button
                  className="touch-id-button touch-id-add-btn"
                  onClick={handleWebAuthn}
                >
                  Add Biometric
                </button>
                <button className="touch-id-button touch-id-not-now-btn" onClick={handleLogin}>
                  Explore {config?.name}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`biometric-setup-wrapper__right`}>
          <div className="touch-id-fingerprint" >
            <i className="ri-fingerprint-line"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiometricSetup;
