import { useCurrency } from "@hooks/currency";
import React from "react";

type Props = {
  heading: string;
  Amount: string | number;
  format: boolean;
  isLoading: boolean
};

export const AmountCommision = (props: Props) => {
  const { heading, Amount, format, isLoading } = props;
  const { formatCurrencyNumber } = useCurrency();
  return (
    <div className="amount-commision">
      <p className="commision-heading">{heading}</p>
      {isLoading ? (
        <div>
          <span className="amount-commission-skeleton"></span>
        </div>
      ) : (
        <p className="commision-value">
          {format ? "$" + formatCurrencyNumber(Amount, 2) : Amount}
        </p>
      )}
    </div>
  );
};
