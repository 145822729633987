import { Button } from "components";
import { useState, useRef, useEffect } from "react";
import "./auction-icon.scss";
import { useNotification } from "hooks";
import { Json } from "types";
import { VALID_IMAGE_FILE_FORMAT } from "constant";
import { CropImageOnUpload } from "@components/CropImageOnUpload";

type IAuctionImageForm = {
  imageType: string;
  handleChange: (value: any) => void;
  icon: string;
  content: string;
  subContent?: string;
  className?: string;
};

export const AuctionIcon = ({
  imageType,
  handleChange,
  icon,
  content = "",
  subContent="",
  className = "",
}: IAuctionImageForm) => {
  const inputFileRef = useRef<any>();
  const [imageChangedByInput, setImageChangedByInput] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  const { errorNotification } = useNotification();

  useEffect(() => {
    if (!imageChangedByInput) setImage(icon);
  }, [icon, imageChangedByInput]);

  const onImageChange = (event: any) => {
    const size = (event.target.files[0].size / 1024 / 1024).toFixed(2);
    if (parseFloat(size) > 2) return errorNotification("File must be less than 2MB");
    const isFileValidated = VALID_IMAGE_FILE_FORMAT[event.target.files[0].type];
    if (isFileValidated) {
      if (event.target.files && event.target.files[0]) {
        setImageFile(event.target.files?.[0]);
      }
    } else {
      errorNotification(`${event.target.files[0].name} is not a valid file`);
      inputFileRef.current.value = null;
    }
  };

  const openFileinput = () => {
    inputFileRef?.current?.click();
  };

  const textContent = {
    title: "Icon",
    subtitle: "To adjust the icon position, Click and hold on the icon, then drag it.",
  };

  const onImageCrop = (cropData: Json) => {
    setImageChangedByInput(true);
    setImage(cropData?.blobUrl);
    handleChange(cropData?.croppedFile);
    setImageFile(null);
  };

  return (
    <div className="auction-form-image-wrapper">
      {!image ? (
        <div onClick={openFileinput} className="auction-form-image">
          <span className="ri ri-file-upload-line"></span>
        </div>
      ) : (
        <img
          onClick={openFileinput}
          alt="preview"
          className="auction-form-image-preview"
          src={image}
        />
      )}
      <div className="auction-form-image-text">
        <p>{content}</p>
        <p className="auction-form-image-text__subcontent">{subContent}</p>
        <Button
          type="btn-browse btn-h-auto"
          label={"Browse"}
          buttonType="button"
          handleClick={openFileinput}
        />
        <div className="cropImageIcon">
        {!!imageFile && <CropImageOnUpload onClose={() => setImageFile(null)} open={!!imageFile}
                                         details={{ file: imageFile, cropSize: { width: 300, height: 300 }, xaxis:50, yaxis:50 }}
                                         onCropDone={onImageCrop} 
                                         textContent={textContent} className="cropImageIcon"/>}
        </div>
      
        <input value=""
          ref={inputFileRef}
          type="file"
          className="auction-form-image-file"
          onChange={onImageChange}
          accept="image/*" 
        />
      </div>
    </div>
  );
};
