import  {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Loader } from "components";
import { Json } from "types/common";import {
  checkKYBDetails,
  kybModalState,
  refAtomCaptable,
} from "@states/user";
import NewModal from "@components/new-modal/new-modal";
import { SimplciConfirmation } from "@storybook";

export const UpgradeKyb = () => {
  const [iframeLoading, setIframeLoading] = useState(true);

  const [isVerificationClose, setIsVerificationClose] = useState(false);
  const [kybModalVisible, setKybModalVisible] = useRecoilState(kybModalState);
  const kybDetail = useRecoilValue(checkKYBDetails);
  const refCaptable = useRecoilValue(refAtomCaptable);

  useEffect(() => {
    window.addEventListener("message", handleMessageEvent);
    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [kybDetail]);

  const handleMessageEvent = useCallback(
    (event: Json) => {
      const receivedData = event.data;
      if (receivedData?.type === "onLoaded") {
        setIframeLoading(false);
      }
      if (receivedData?.type === "onComplete") {
        setKybModalVisible(false);
          refCaptable?.contentWindow?.postMessage(
            {
              data: { success: true },
              type: "assetKYBStatus",
              key: "liquidity",
            },
            "*"
          );
      }
  
    },
    [kybDetail]
  );

  const onCloseKyb = () => {
    setIsVerificationClose(true);
  };
  const handleCloseModal = () => {
    setKybModalVisible(false);
    setIsVerificationClose(false);
  };
  const onCancelVerification = () => {
    setIsVerificationClose(false);
  };

  const renderIframe = useMemo(() => {
    return (
      <NewModal
        isOpen={kybModalVisible}
        closeModal={onCloseKyb}
        modalName={"Compliance"}
        showCloseBtn={true}
        isStopOutsideClick={false}
        closeOnEscBtn={false}
        className={`${"Modal__kyc"} Modal__wrapper exchange-modal-wrapper model-title-margin kybUpgrade`}
      >
        <div>
          {isVerificationClose && (
            <div className="SimplciConfirmationDialog">
              <div className="SimplciConfirmationDialog--container">
                <SimplciConfirmation
                  title={"Cancel verification process"}
                  visible={isVerificationClose}
                  description={
                    "Are you sure you want to cancel your ongoing verification? We suggest that you complete your verification."
                  }
                  handleModal={handleCloseModal}
                  handleClose={onCancelVerification}
                  boldDescription=""
                  label="Yes"
                  cancelLabel="No"
                  type="sell"
                />
              </div>
            </div>
          )}
          <iframe
            src={kybDetail}
            title="Compliance"
            className="kybUpgrade-iframe"
            allow="camera; microphone"
            // onLoad={handleLoadComplete}
            style={{ display: iframeLoading ? "none" : "" }}
          />
          {iframeLoading && (
            <div className="accreditation-iframe__loading">
              <Loader className="loader-blue" />
            </div>
          )}
        </div>
      </NewModal>
    );
  }, [
    iframeLoading,
    isVerificationClose,
    kybDetail,
    kybModalVisible,
  ]);

  return <>{renderIframe}</>;
};
