import { useSetRecoilState } from "recoil";
import { useCallback } from "react";
import { DepositModalOpenState, WithdrawModalOpenState } from "@states/wallet";
import useDarkMode from "use-dark-mode";
import { useNavigate } from "react-router-dom";
import { imageConfig } from "@utils/imageConfig";

import "./insufficientFunds.scss";

type InsufficientFundsProps = {
  subHeading?: string;
  fromAction?: boolean;
  setVisibleModify?: (visible: boolean) => void;
  showButton?: boolean;
};

const InsufficientFunds: React.FC<InsufficientFundsProps> = ({
  subHeading,
  fromAction,
  setVisibleModify,
  showButton = true
}: any) => {
  const setIsDepositModalOpen = useSetRecoilState(DepositModalOpenState);
  const setIsWithdrawModalOpen = useSetRecoilState(WithdrawModalOpenState);
  const darkMode = useDarkMode(false);
  const navigate = useNavigate();

  const {svg: {WalletLight, WalletDark}} = imageConfig;
  const handleClickDeposit = useCallback(() => {
      if (fromAction) {
        setVisibleModify(false);
        navigate("/wallet");
      }
      setIsWithdrawModalOpen(false);
      setIsDepositModalOpen(true);
    },
   [fromAction, navigate, setIsDepositModalOpen, setIsWithdrawModalOpen, setVisibleModify]);

  return (
    <div className="InsufficientFunds">
      <div className="InsufficientFunds__img">
        <img src={darkMode.value ? WalletDark : WalletLight} alt="" />
      </div>
      <div className="InsufficientFunds__texts">
        <div className="InsufficientFunds__texts__heading">
          Insufficient fund available
        </div>
        <div className="InsufficientFunds__texts__subHeading">
          {subHeading || ""}
        </div>
      </div>
      {!!showButton && <button className="InsufficientFunds__btn" onClick={handleClickDeposit}>
        Deposit
      </button>}
    </div>
  );
};

export default InsufficientFunds;
