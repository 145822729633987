import React, { FC } from "react";
import "./bank-details-card.scss";
import { useNavigate } from "react-router-dom";

type IbankDetailsCard = {
  icon: string;
  header: string;
  details: string;
  url: string;
};

export const BankDetailsCard: FC<IbankDetailsCard> = (props) => {
  const { icon, header, details, url } = props;
  const navigate = useNavigate();

  const redirect = (url: string) => {
    navigate(url);
  };

  return (
    <div className="bank-detail-card" onClick={() => redirect(url)}>
      <img src={icon} alt={icon} />
      <div className="bank-detail-card-info">
        <p className="info-header">{header}</p>
        <p className="info-description">{details}</p>
      </div>
    </div>
  );
};
