import type { IConfirmationModal } from "./type";

import { FC } from "react";

import { Loader } from "components";
import { CANCEL_ORDER_BTN } from "./constant";
import { imageConfig } from "@utils/imageConfig";

import styles from "./confirmation.module.sass";


export const SimplciConfirmation: FC<IConfirmationModal> = ({
  visible,
  title,
  description,
  handleModal,
  handleClose,
  boldDescription = "",
  label,
  cancelLoading = false,
  type,
  cancelLabel,
  confirmationHeaderStyle,
}) => {
  const { CANCEL_NO, CANCEL_YES } = CANCEL_ORDER_BTN;
  const { svg: { cancelLight } } = imageConfig;

  if (!visible) {
    return null;
  }

  return (
    <div className={`${styles.confirmationModal}`}>
      <div className={`${styles.simplciConfirmationHeader}`}>
        <img
          src={cancelLight}
          alt=""
          className={styles.confirmationImage}
        />
        <div className={styles.simplciConfirmationTitle}>{title}</div>
      </div>
      <div>
        <div className={styles.simplciModalDes}>
          <span>
            {description} <strong>{boldDescription}</strong>
          </span>
        </div>
        <div className={styles.modalFooter}>
          <button
            className={`${styles.simplcibuttonCancel}`}
            onClick={handleClose}
            disabled={cancelLoading}
          >
            {cancelLabel ?? CANCEL_NO}
          </button>
          <button
            className={styles.buttonCancelYes}
            onClick={handleModal}
            disabled={cancelLoading}
          >
            <span className={`${styles.btnText}`}>{label ?? CANCEL_YES}</span>
            <i className={`${styles.loaderIcon}`}>
            <Loader className="loader-white" dimension={26} />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
};
