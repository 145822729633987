import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useDarkMode from "use-dark-mode";
import cn from "classnames";
import { Button, Loader, ReactModal, SkeletonWallet } from 'components';
import { InviteUser } from './invite-user';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { InviteEmailInputState, InviteFirstNameInputState, InviteLastNameInputState, InvitePhoneInputState, allCoOwnerState, invitedUsersState, showEmailNotificationState, tradingPermissionState, transactionPermissionState, userEmailState, userMobileNumberState, userNameState, userProfileState, userTypeState } from 'states';
import { useLocalStorage, useNetwork, useNotification } from 'hooks';
import { APIS } from 'constant';
import { DropOptions, RemoveCoOwner } from './components';
import { Confirmation, Image } from '@storybook';
import { RemovalRequestFilesState, UseAuthorisedUser, authorisedDeleteFileStatus, authorisedUploadedFileStatus } from './store';
import { useNavigate } from 'react-router-dom';
import { ManageRemovalRequest } from './components/manageRemovalRequest';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Json } from '../../../../types';
import { selectedFlagState } from '@components/country-code/state';
import { imageConfig } from '@utils/imageConfig';

import styles from "./authorisedUser.module.sass"

interface IUserDetails {
    _id: string;
    isTradeEnabled: boolean;
    status: string;
    type: string;
    firstName: string;
    lastName: string;
    image: string;
    phone: string;
    countryCode: string;
    email: string;
    name?: string;
    customerId?: string;
}
interface IPhone {
    phone: string;
    countryCode: string;
}

interface IUsername {
    firstName: string;
    lastName: string;
}

export const AuthorisedUser: React.FC = () => {
    const [invitedUser, setInvitedUser] = useRecoilState<IUserDetails[]>(invitedUsersState);
    const [userName, setUserName] = useRecoilState<IUsername | Record<string, never>>(userNameState);
    const [userEmail, setUserEmail] = useRecoilState(userEmailState)
    const [mobileNumber, setMobileNumber] = useRecoilState<IPhone>(userMobileNumberState);
    const [userType, setUserType] = useRecoilState(userTypeState);
    const setTradingPermission = useSetRecoilState(tradingPermissionState)
    const [transactionPermission, setTransactionPermission] = useRecoilState(transactionPermissionState)

    const showInviteEmailError = useSetRecoilState(InviteEmailInputState)
    const setShowFirstNameError = useSetRecoilState(InviteFirstNameInputState);
    const setShowLastNameError = useSetRecoilState(InviteLastNameInputState);
    const setShowPhoneError = useSetRecoilState(
        InvitePhoneInputState
    );

    const setFileStatus = useSetRecoilState(authorisedUploadedFileStatus)


    const tradingPermission = useRecoilValue(tradingPermissionState)
    const emailNotificationValue = useRecoilValue(showEmailNotificationState);
    const coOwnerList = useRecoilValue(allCoOwnerState);
    const setRemovalFileState = useSetRecoilState(RemovalRequestFilesState)
    const deleteFileStatus = useSetRecoilState(authorisedDeleteFileStatus);
    const  setSelectedFlag = useSetRecoilState(selectedFlagState);


    const [visibleCancel, setVisibleCancel] = useState(false);
    const [visibleManageDelete, setVisibleMangeDelete] = useState(false)
    const [visibileCancelRemoval, setVisibileCancelRemoval] = useState(false)
    const [editingRemovalRequest, setEditingRemovalRequest] = useState(false)
    const [visibleCancelDataroom, setVisibleCancelDataroom] = useState(false)
    const [deleteID, setDeleteId] = useState<string>('');
    const [manageCoownerId, setManageCoownerId] = useState<string>('');
    const [isDeleted, setDeleted] = useState<boolean>(false);
    const [confirmationType, setConfirmationType] = useState("")
    const [loader, setLoader] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [isVisible, setIsVisible] = useState(false)

    const darkMode = useDarkMode(false);
    const { post: sendUser, loading: inviteuserLoading } = useNetwork();
    const { get: getusers, data: UsersData, loading } = useNetwork()
    const { successNotification, errorNotification } = useNotification();
    const { removeAuthoisedUser, uploadedFileStatus, updateDataroomFileLoading, cancelCoownerRemove, deleteUplaodedFile } = UseAuthorisedUser()

    const navigate = useNavigate();
    const { get: getLocalStorage } = useLocalStorage();
    const profilePic = useRecoilValue(userProfileState);


    const emailRegex = /^[\w+.-]+@\w+([.-]?\w+)*(\.\w{2,4})+$/;
    const nameRegex = /^[a-zA-Z]+$/i;

    const [coownerFileData, setCoownerFileData] = useState<Json>({ length: 0, isDeleted: false })

    const {svg: {noInviteDark, noInviteLight, avatar_dark, avatar_light}} = imageConfig;
   

    const isDisabled =
        mobileNumber?.phone?.length < 7 ||
        userEmail?.trim()?.length < 1 ||
        (userName?.firstName && userName?.firstName?.length < 3) ||
        (userName?.lastName && userName?.lastName?.length < 3) ||
        !nameRegex.test(userName?.firstName ?? "") ||
        !nameRegex.test(userName?.lastName ?? "") ||
        !emailRegex.test(userEmail ?? "");

    useEffect(() => {
        getusers(APIS.COOWNER)
    }, [getusers])

    useEffect(() => {
        if (UsersData) {
            setInvitedUser(UsersData?.data)
        }
    }, [UsersData, setInvitedUser])

    const primaryUser = useMemo(() => {
        const isPrimary: any = getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
        return isPrimary;
    }, [getLocalStorage]);

    const handleInviteUser = useCallback(() => {
        if (userEmail === "" || userName.firstName === "" || userName.lastName === "" || mobileNumber.phone === "" || mobileNumber.countryCode === "") {
            errorNotification("Please fill all details")
        } else {
            const payload = {
                name: (userName?.firstName + " " + userName?.lastName) || "",
                email: userEmail,
                countryCode: mobileNumber.countryCode,
                mobile: mobileNumber.phone,
                type: userType,
                isTradeEnabled: tradingPermission,
                isTransactionEnabled: transactionPermission
            }
            sendUser(APIS.COOWNER, payload, { apiResponse: true }).then(res => {
                if (res?.data?._id) {
                    setIsVisible(false)
                    setAddCard(false)
                    successNotification("Invite sent successfully")
                    setShowFirstNameError(false);
                    setShowLastNameError(false);
                    showInviteEmailError(false)
                    setUserName({})
                    setUserEmail("")
                    setTradingPermission(true)
                    setTransactionPermission(true)
                    setUserType("co-owner")
                    setShowPhoneError(false)
                    setMobileNumber({
                        phone: "",
                        countryCode: "+1",
                    })
                    getusers(APIS.COOWNER).then((res) => {
                        setInvitedUser(res?.data)
                    })
                } else {
                    errorNotification(res?.message)
                }
            })

        }


    }
        , [errorNotification, getusers, mobileNumber.countryCode, mobileNumber.phone, sendUser, setInvitedUser, setMobileNumber, setShowFirstNameError, setShowLastNameError, setShowPhoneError, setTradingPermission, setTransactionPermission, setUserEmail, setUserName, setUserType, showInviteEmailError, successNotification, tradingPermission, transactionPermission, userEmail, userName.firstName, userName.lastName, userType])



    const items = [
        {
            title: "Authorised User",
            userCount: invitedUser?.length,
            color: "#9757D7",
            btnText: "Invite User",
            type: "account",
        },
    ];

    const handleAdd = useCallback(() => {
        setAddCard(true);
        setIsVisible(true)
    }, []);

    const handleClose = useCallback((isModalCloseAllow?: any) => {
        if (isModalCloseAllow) {
            setAddCard(false);
        }
        setIsVisible(false)
        setShowFirstNameError(false);
        setShowLastNameError(false);
        showInviteEmailError(false)
        setUserName({})
        setUserEmail("")
        setShowPhoneError(false)
        setMobileNumber({
            phone: "",
            countryCode: "+1",
        })
        setSelectedFlag("")
        setTradingPermission(true)
        setTransactionPermission(true)
        setUserType("co-owner")
    }, [setMobileNumber, setSelectedFlag, setShowFirstNameError, setShowLastNameError, setShowPhoneError, setTradingPermission, setTransactionPermission, setUserEmail, setUserName, setUserType, showInviteEmailError])

    useEffect(() => {
        if (!addCard) {
            handleClose(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addCard])

    const capitalizeFirstLetter = useCallback((str: string) => {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }, []);

    const handleDelete = useCallback(
        (item: any, index: number) => {
            setManageCoownerId("")
            if (item?.type === "Authorised User") {
                setVisibleCancel(true);
                setDeleteId(item?._id)
                setConfirmationType(item?.type)
            } else {
                if (item?.status === "pending") {
                    setVisibleCancel(true);
                    setDeleteId(item?._id)
                    setConfirmationType(item?.type)

                } else if (item?.status === "joined") {
                    setVisibleCancelDataroom(true)
                    setDeleteId(item?._id)
                }

            }

        },
        []
    );

    useEffect(() => {
        if (isDeleted) {
            removeAuthoisedUser(deleteID)
            setDeleted(false);
        }
    }, [deleteID, isDeleted, removeAuthoisedUser])

    const handleDeleteAction = useCallback(() => {
        setVisibleCancel(false);
        setDeleted(true);
    }, []);

    const handleCloseDelete = useCallback(() => {
        setVisibleCancel(false);
    }, []);
    const handleCloseCancelRemovalRequest = useCallback(() => {
        setVisibileCancelRemoval(false)
        setManageCoownerId("")
    }, []);

    const handleManageDelete = useCallback((item: any, index: number) => {
        setManageCoownerId(item._id)
        setVisibleMangeDelete(true)

    }, [])
    const handleFileCount = useCallback((data: Json) => {
        if (data?.length !== coownerFileData?.length || data?.isDisabled !== coownerFileData?.isDisabled) {
            setCoownerFileData(data)
        }
    }, [coownerFileData])

    const handleCancelRemoval = useCallback(() => {
        cancelCoownerRemove(manageCoownerId, setManageCoownerId)
        setVisibileCancelRemoval(false)
        setRemovalFileState([])

    }, [cancelCoownerRemove, manageCoownerId, setRemovalFileState])


    const renderConfirmation = useMemo(() => {
        return (
            <Confirmation
                title={confirmationType === "co-owner" ? "Remove Co-owner" : "Remove Authorised User"}
                visible={visibleCancel}
                description={`Are you sure you want to remove ${confirmationType === "co-owner" ? "Co-owner" : "Authorised User"}? `}
                handleModal={handleDeleteAction}
                handleClose={handleCloseDelete}
                boldDescription="Do you want to cancel it?"
                label={"Remove"}
                cancelLabel={"Cancel"}
                type="primary"
            />
        );
    }, [confirmationType, handleCloseDelete, handleDeleteAction, visibleCancel]);

    const renderCancelRemovalConfirmation = useMemo(() => {
        return (
            <Confirmation
                title={"Cancel Co-owner removal request?"}
                visible={visibileCancelRemoval}
                description={"Are you sure you want to cancel co-owner removal request?"}
                handleModal={handleCancelRemoval}
                handleClose={handleCloseCancelRemovalRequest}
                boldDescription=" Do you want to cancel it?"
                label={"Cancel request"}
                cancelLabel={"Keep it"}
                type="cancelRemoval"
            />
        );
    }, [handleCancelRemoval, handleCloseCancelRemovalRequest, visibileCancelRemoval]);

    const handleCancelRemove = useCallback(() => {
        setVisibleCancelDataroom(false)
        setFileStatus([])
        deleteFileStatus([])
        setRemovalFileState([])
        setDeleteId("")
        setManageCoownerId("")
        setEditingRemovalRequest(false)
        setVisibleMangeDelete(false)
    }, [])
    const handleCancelRemovalRequest = useCallback(() => {
        setVisibileCancelRemoval(true)
        setVisibleMangeDelete(false)
        setEditingRemovalRequest(false)
    }, [])

    const handleCancelMangeRemove = useCallback(() => {
        setVisibleMangeDelete(false)
        setVisibileCancelRemoval(false)
        setRemovalFileState([])
    }, [setRemovalFileState])

    const handleRemoveCowner = useCallback(() => {
        const obj = {
            setVisibleCancelDataroom, deleteID, manageCoownerId, setEditingRemovalRequest, setVisibleMangeDelete, setDeleteId, setManageCoownerId, fileLength: coownerFileData?.length
        }
        uploadedFileStatus(obj)
    }, [uploadedFileStatus, deleteID, manageCoownerId, coownerFileData?.length,])

    const handleEditRequest = useCallback(() => {
        setVisibleCancelDataroom(true)
        setEditingRemovalRequest(true)
    }, [])


    const heightForEmailNotification =
        emailNotificationValue === true
            ? "calc(100vh - 200px)"
            : "calc(100vh - 155px)";

    const renderInvitedUser = useMemo(() => {
        return (
            <div className={styles.userSection} style={{ height: heightForEmailNotification }}>{loading ? <SkeletonWallet listsToRender={3} /> :
                invitedUser?.map((item, index) => (
                    <div className={styles.invitedUsersContainer}>
                        <div className={styles.invitedUsers}>
                            <div className={styles.invitedUsers__userName}>    <Image
            fileName={darkMode.value ? avatar_dark : avatar_light}
            useFileNameOnFail {...!!profilePic && { url: profilePic }}
            className={styles.userAvatar}
          />
                            <div>
                            <div>{item?.name}</div>
                             <div className={styles.invitedUsers__accountid}>
                             Account ID: {item?.customerId}
          </div></div></div>
                            
                            <div>
                                <DropOptions
                                    handleDelete={() => handleDelete(item, index)}
                                    handleMangeDelete={() => handleManageDelete(item, index)}
                                    isVisible={visibleCancel}
                                    status={item?.status}
                                />
                            </div>
                        </div>
                        <div className={styles.userDetails}>
                            <div className={styles.divider}>

                                <div className={styles.userDetails__left} id={item.email}>
                                    <div className={cn(styles.details)}>
                                        <div>Email</div>
                                        <div className={styles.userDetails__emailfield} >{item?.email}</div>
                                        
                                    </div>
                                </div>
    
    
                                <ReactTooltip anchorId={item.email} className='theme-tooltip' place="bottom" content={item?.email} />

                                <div className={styles.speration}></div>
                                <div className={styles.userDetails__left}>
                                    <div className={styles.details}>
                                        <div>Phone number</div>
                                        <div>({item.countryCode}) {item?.phone}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.divider}>
                                <div className={styles.userDetails__left}>

                                    <div className={styles.details}>
                                        <div>User type</div>
                                        <div>{capitalizeFirstLetter(item?.type)}</div>
                                    </div>

                                </div>
                                <div className={styles.speration}></div>
                                <div className={styles.userDetails__left}>

                                    <div className={styles.details}>
                                        <div>Status</div>
                                        <div className={styles?.status}>
                                            <div
                                                className={cn(styles.dotColor, {
                                                    [styles.dotPending]: item?.status === "pending",
                                                    [styles.dotJoined]: item?.status === "joined",
                                                })}
                                            ></div>

                                            <div >{capitalizeFirstLetter(item?.status)}</div>
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>

                    </div >

                ))
            }</div>


        )


    }, [capitalizeFirstLetter, handleDelete, handleManageDelete, heightForEmailNotification, invitedUser, loading, visibleCancel])

    return (
        <>
            <div className={styles.list}>
                <>
                    {invitedUser?.length || loading ?
                        <>
                            {items.map((item, index) => (
                                <div className={cn(styles.item)} key={index}>
                                    <div className={styles.head}>
                                        <div className={styles.title}>{item.title} <span className={styles.userCount}>{item.userCount} {item.userCount > 1 ? "users" : "user"}</span></div>
                                        <div className={styles.details}>
                                            <button
                                                className={cn(
                                                    "button__filled button__filled--primary",
                                                    "button",
                                                    styles.buttonAaddAccount,
                                                )}
                                                onClick={handleAdd}
                                            >
                                                {loader ? <Loader dimension={16} /> : item.btnText}
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.body}>{renderInvitedUser}</div>
                                </div>
                            ))}
                        </> :
                        <div className={styles.list}>
                            <div className={styles.emptyState}>
                                <img src={darkMode.value ? noInviteDark : noInviteLight} alt="" />

                                <div className={styles.heading}>Nothing Here</div>
                                <div className={styles.noDataText}>Add auth user or co-owner to allow them access your portfolio</div>
                                <button
                                    className={cn("button__filled button__filled--primary", "button", styles.buttonAaddAccountEmpty)}
                                    onClick={handleAdd}
                                >

                                    {"Invite User"}
                                </button>
                            </div>
                        </div>
                    }
                </>
            </div >
            <ReactModal
                visible={addCard}
                onClose={handleClose}
                outerClassName={styles.confirmationModalOuter}
                closeBtn={true}
                maskClosable={false}
            >
                <div className={styles.modelSection}>
                    <InviteUser inviteuserLoading={inviteuserLoading} />
                    <div className={styles.inviteUser__footer}>
                        <Button
                            label="Cancel"
                            handleClick={handleClose}
                            type={cn(styles.inviteUser__cancel)}
                        />
                        {
                            <Button
                                label={inviteuserLoading ? <Loader dimension={20} className='loader-white' /> : "Invite User"}
                                handleClick={handleInviteUser}
                                type={cn("button__filled button__filled--primary", styles.inviteUser__invite)}
                                disabled={isDisabled}
                            />
                        }
                    </div>
                </div>
            </ReactModal>
            <ReactModal
                visible={visibleCancel}
                onClose={() => setVisibleCancel(false)}
                outerClassName={styles.confirmationModalOuter}
            >
                {renderConfirmation}
            </ReactModal>
            <ReactModal
                visible={visibileCancelRemoval}
                onClose={() => setVisibileCancelRemoval(false)}
                outerClassName={styles.confirmationModalOuter}
            >
                {renderCancelRemovalConfirmation}
            </ReactModal>
            <ReactModal
                visible={visibleCancelDataroom}
                onClose={handleCancelRemove}
                closeBtn={true}
                outerClassName={styles.deleteModal}
                maskClosable={false}
            >
                <RemoveCoOwner callback={handleFileCount} coOwnerId={deleteID} editUserId={manageCoownerId} editingRemovalRequest={editingRemovalRequest} />
                <div className={styles.removeUser__footer}>
                    <Button
                        label="Cancel"
                        handleClick={handleCancelRemove}
                        type={cn(styles.removeUser__cancel)}
                    />
                    {
                        <Button
                            label={updateDataroomFileLoading ? <Loader dimension={20} className='loader-white' /> : editingRemovalRequest ? "Save Request" : "Request Removal"}
                            handleClick={handleRemoveCowner}
                            type={cn(styles.removeUser__remove, { [styles.editingRemovalRequest]: editingRemovalRequest })}
                            disabled={coownerFileData?.isDisabled}
                        />
                    }
                </div>
            </ReactModal>
            <ReactModal
                visible={visibleManageDelete}
                onClose={handleCancelMangeRemove}
                closeBtn={true}
                outerClassName={styles.deleteModal}
                maskClosable={false}
            >
                <ManageRemovalRequest id={manageCoownerId} />
                <div className={styles.removeUser__footer}>
                    <Button
                        label="Cancel Request"
                        handleClick={(handleCancelRemovalRequest)}
                        type={cn(styles.removeUser__remove)}
                    />
                    {
                        <Button
                            label={updateDataroomFileLoading ? <Loader dimension={20} className='loader-white' /> : "Edit Request"}
                            handleClick={handleEditRequest}
                            type={cn(styles.removeUser__editUser)}
                        />
                    }
                </div>
            </ReactModal>



        </>


    )
}
