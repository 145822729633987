import React, { FC } from "react";
import { useCurrency } from "hooks";
import "./transaction-card.scss";
import { useNavigate } from "react-router-dom";
import { formatDate } from "utils";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

const iconType: any = {
  incoming: "ri-arrow-down-line withdrawl",
  outgoing: "ri-arrow-up-line",
  buy: "ri-money-dollar-circle-line green",
  sell: "ri-money-dollar-circle-line red ",
};

type ITransactionCard = {
  transactionType: string;
  transactiomnStatus: string;
  amount: string | number;
  bankName: string;
  transactionDate: string;
  id?: string;
  flow: string;
  transactionId: string;
  accountNumber: string;
  intendedTransaction: boolean;
};

const statusColor: any = {
  PROCESSING: "processing",
  CANCELLED: "cancelled",
  SUCCESS: "completed",
  FAILED: "failure",
};

export const TransactionCard: FC<ITransactionCard> = (props) => {
  const { trackEvent } = useFullStoryTrackEvent();
  const {
    transactionType,
    transactiomnStatus,
    amount,
    bankName,
    transactionDate,
    flow,
    transactionId,
    accountNumber,
    id,
    intendedTransaction
  } = props;
  const { formatCurrencyWithBillion } = useCurrency();

  const navigate = useNavigate();
  const getTransactionDetails = () => {
    trackEvent("TRANSACTION_DETAIL", {
      amount,
      bankName,
      transactionDate,
      flow,
      transactionId,
      accountNumber
    });
    navigate(`/wallet/transactions/${id}`);
  };

  function capitalizeFirstLetterOnly(input: string): string {
    if (input.length === 0) {
      return input;
    }
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
  }

  const isInternal = transactionType === "INTERNAL";
  const isIncoming = flow === "incoming";

  const actionLabel = isInternal
    ? isIncoming
      ? "SELL"
      : "BUY"
    : transactionType;
  const iconLabel = isInternal ? (isIncoming ? "sell" : "buy") : flow;

  return (
    <div className="transaction-card-container" onClick={getTransactionDetails}>
      <div className="transaction-card">
        <span className="icon">
          <i className={`ri ${iconType[iconLabel]}`}></i>
        </span>
        <div className="transaction-details">
          <div className="transaction-info-status">
            <p className="transaction-info-type">
              {capitalizeFirstLetterOnly(actionLabel)}
              {intendedTransaction && <span className="wireTag">Wire Intent</span> }
            </p>
            <p>{formatCurrencyWithBillion(amount, 2)}</p>
          </div>
          <div className="transaction-info-details">
            <p>{transactionId}</p>
            <div className="status-flex">
              <span className={`status-dot ${statusColor[transactiomnStatus]}`}></span>
              <p>{capitalizeFirstLetterOnly(transactiomnStatus)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="transaction-bank-details">
        <p>
          {bankName} (****{accountNumber})
        </p>
        <p>{formatDate(transactionDate)}</p>
      </div>
    </div>
  );
};
