import React, { useCallback,  useEffect,  useState } from "react";
import "./assetList.scss";
import { convertFileToBase64 } from "@utils/base64";
import { useNotification } from "@hooks/notification";
import { useNetwork } from "@hooks/network";
import { useNavigate } from "react-router-dom";
import { assestType } from "@views/exchange/constants";
import { useRecoilState, useSetRecoilState } from "recoil";
import { visibleListAssetState } from "@states/user";
import { APIS } from "constant";
import { Loader } from "@components/Loader2";
import { getKeyValueObjectFromArray } from "@utils/common";
import { PrivatesState } from "@states/exchange";

interface AssetFormI {
  icon: File | null;
  tickerSymbol: string;
  issuerName: string;
  numberOfShares: number;
  pricePerShare: number;
  shareClass: string;
  offeringAssetType: string;
}

interface FormErrors {
  icon?: string;
  tickerSymbol?: string;
  issuerName?: string;
  numberOfShares?: string;
  pricePerShare?: string;
  shareClass?: string;
  offeringAssetType?: string;
}

const SHARE_CLASSES = ["Class A", "Class B", "Class C", "Preferred"];


interface AssetFormProps {
  onClose: () => void;
}

export const AssetForm: React.FC<AssetFormProps> = ({
  onClose,

}) => {
const [form, setForm] = useState<AssetFormI>({
  icon: null,
  tickerSymbol: "YOURTOKEN",
  issuerName: "yourcompany",
  numberOfShares: 0,
  pricePerShare: 0,
  shareClass: "",
  offeringAssetType: "",
});

 const OFFERING_TYPE_VALUE = [
  "Reg A (Non-Accredited)",
  "Reg A+ (Non-Accredited)",
  "Reg CF (Non-Accredited)",
  "Reg D [506(b)] (Accredited)",
  "Reg D [506(c)] (Accredited)",
  "Reg S (Non-Accredited)",
  "QIB (Accredited)",
  "Retail (Non-Accredited)",
];
const [errors, setErrors] = useState<FormErrors>({});
const [iconPreview, setIconPreview] = useState<string>("");

 const [visibleListAsset, setVisiblListAsset] = useRecoilState(
    visibleListAssetState
  );
    const { get: getPrivates, } = useNetwork();
  const  setPrivatesState = useSetRecoilState(PrivatesState);

  const { errorNotification,successNotification } = useNotification();
  const navigate = useNavigate();
  const { post: postCreateAsset, loading: assetLoading } = useNetwork()
const validateField = (name: string, value: any) => {
  let error = "";
  switch (name) {
    case "tickerSymbol":
      if (!value) {
        error = "Ticker symbol is required";
      } else if (!/^[A-Za-z]{3,10}$/.test(value)) {
        error = "Ticker must be 3-10 uppercase letters";
      }
      break;
    case "issuerName":
      if (!value) {
        error = "Issuer name is required";
      } else if (value.length > 12) {
        error = "Issuer name must be max 12 characters";
      }
      break;
    case "numberOfShares":
      if (!value || value <= 0) {
        error = "Number of shares must be greater than 0";
      }
      break;
    case "pricePerShare":
      if (!value || value <= 0) {
        error = "Price per share must be greater than 0";
      }
      break;
    case "shareClass":
      if (!value) {
        error = "Share class is required";
      }
      break;
    case "offeringAssetType":
      if (!value) {
        error = "Asset type is required";
      }
      break;
    case "icon":
      if (!value) {
        error = "Icon is required";
      }
      break;
    default:
      break;
  }
  return error;
};

const validateForm = (): boolean => {
  const newErrors: FormErrors = {};
  Object.keys(form).forEach((key) => {
    const fieldName = key as keyof AssetFormI;
    const error = validateField(fieldName, form[fieldName]);
    if (error) {
      newErrors[fieldName] = error;
    }
  });
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const handleChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
) => {
  const { id, value } = e.target;
  const newValue =
    id === "numberOfShares" 
      ? value.replace(/[^0-9]/g, "")
      : id === "pricePerShare"
      ? value.replace(/[^0-9.]/g, "")
      : id === "tickerSymbol"
      ? value.toUpperCase()
      : id === "issuerName"
      ? value.toLocaleLowerCase()
      : value;
  setForm({ ...form, [id]: newValue });
  setErrors({ ...errors, [id]: validateField(id, newValue) });
};


 const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
   if (
     (e.target as HTMLInputElement).id === "numberOfShares" ||
     (e.target as HTMLInputElement).id === "pricePerShare"
   ) {
     if (e.key === "e" || e.key === "E") {
       e.preventDefault();
     }
   }
 };

const handleIconChange = async  (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.target.files?.[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) {
      setErrors({ ...errors, icon: "Image must be less than 5MB" });
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setIconPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
    const fileName: any = await convertFileToBase64(file);
    const logo = fileName.toString();
    setForm({ ...form, icon: logo });
    setErrors({ ...errors, icon: undefined });
  }
};


  const updatePrivate = useCallback((assetId:string) => {
  getPrivates(`${APIS.Explorers}?include=privates`).then((res) => {
    if(res){
      const tempPrivate = getKeyValueObjectFromArray(
        "id",
        res?.data?.privates || []
      );
      setPrivatesState(tempPrivate);
       navigate(`/${assetId}?type=${assestType.privates}`);
    }
  })
  }, []);
  
   const onSubmit = (formData: any) => {
     postCreateAsset(APIS.CREATE_ASSET, formData)
       .then((res) => {
         const assetId = res?.id ;
         if (res?.id) {
          updatePrivate(assetId);
           successNotification("Asset created");
         } else {
           errorNotification(res?.message);
         }
         setVisiblListAsset(false);
       })
       .catch((error) => {
         errorNotification(error?.message ?? "Something went wrong");
       });;
   };

const handleSubmit = (e: React.FormEvent) => {
  e.preventDefault();
  if (validateForm()) {
    onSubmit(form);
  }
};

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (
        document.activeElement instanceof HTMLInputElement &&
        (document.activeElement.id === "numberOfShares" || document.activeElement.id === "pricePerShare")
      ) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="asset-form-wrapper">
      <div className="form-container">
        <div className="form-header">
          <h2>Asset Detail</h2>
          <p>Enter the details below to create your asset listing.</p>
          {/* <button onClick={onClose} className="close-button">
            ✕
          </button> */}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="icon-upload">
            <div className="icon-preview">
              {iconPreview ? (
                <img src={iconPreview} alt="Icon preview" />
              ) : (
                <div className="upload-placeholder">
                  <i className="ri-image-add-fill"></i>
                </div>
              )}
            </div>
            <div>
              <div className="label-icon">Icon</div>
              <label htmlFor="icon" className="upload-label">
                Upload Image
              </label>
              <input
                type="file"
                id="icon"
                accept="image/*"
                onChange={handleIconChange}
              />
              {errors.icon && <p className="error-message">{errors.icon}</p>}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="tickerSymbol">Ticker Symbol</label>
            <input
              type="text"
              id="tickerSymbol"
              value={form.tickerSymbol}
              onChange={handleChange}
              className={errors.tickerSymbol ? "error" : ""}
              placeholder="ABCD"
              maxLength={11}
            />
            {errors.tickerSymbol && (
              <p className="error-message">{errors.tickerSymbol}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="issuerName">Issuer Name (Max. 12 characters)</label>
            <input
              type="text"
              id="issuerName"
              value={form.issuerName}
              onChange={handleChange}
              className={errors.issuerName ? "error" : ""}
              placeholder="YourCompany"
              maxLength={13}
            />
            {errors.issuerName && (
              <p className="error-message">{errors.issuerName}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="numberOfShares">Number of Shares</label>
            <input
              type="number"
              id="numberOfShares"
              value={form.numberOfShares || ""}
              onChange={handleChange}
              className={errors.numberOfShares ? "error" : ""}
              onKeyDown={handleKeyDown}
              step="any"
            />
            {errors.numberOfShares && (
              <p className="error-message">{errors.numberOfShares}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="pricePerShare">Price Per Share (USD)</label>
            <input
              type="number"
              id="pricePerShare"
              step="any"
              value={form.pricePerShare || ""}
              onChange={handleChange}
              className={errors.pricePerShare ? "error" : ""}
              onKeyDown={handleKeyDown}
            />
            {errors.pricePerShare && (
              <p className="error-message">{errors.pricePerShare}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="shareClass">Share Class</label>
            <div className="select-container">
              <select
                id="shareClass"
                value={form.shareClass}
                onChange={handleChange}
                className={errors.shareClass ? "error" : ""}
              >
                <option value="">Select</option>
                {SHARE_CLASSES.map((shareClass) => (
                  <option key={shareClass} value={shareClass}>
                    {shareClass}
                  </option>
                ))}
              </select>
            </div>
            {errors.shareClass && (
              <p className="error-message">{errors.shareClass}</p>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="offeringAssetType">Offering Asset Type</label>
            <div className="select-container">
              <select
                id="offeringAssetType"
                value={form.offeringAssetType}
                onChange={handleChange}
                className={errors.offeringAssetType ? "error" : ""}
              >
                <option value="">Select</option>
                {OFFERING_TYPE_VALUE.map((assetType) => (
                  <option key={assetType} value={assetType}>
                    {assetType}
                  </option>
                ))}
              </select>
            </div>
            {errors.offeringAssetType && (
              <p className="error-message">{errors.offeringAssetType}</p>
            )}
          </div>

          <div className="form-actions">
            <button type="button" className="cancel" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit">
              {assetLoading ? (
                <Loader dimension={20} />
              ) : (
                "Proceed to Asset Details"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};


