export const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/pdf": "pdf",
  pdf: "pdf",
  xlsx: "xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "docx",
  docx: "docx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
  "application/msword": "doc",
  doc: "doc",
  "text/csv": "csv",
  csv: "csv",
  pptx: "ppt",
};