import { ReactModal } from "@components/react-modal";
import classNames from "classnames";
import React, {
  useEffect,
  useMemo,
  useState,
} from "react";

import styles from "./InvestmentRoundDropdown.module.sass";
import { Button } from "@components/button";
import { Json } from "types/common";

interface IInvestmentRoundDropdown  {
  onClose: () => void;
  setVisibleCancel: (value: boolean) => void;
  showInvestmentRound: boolean;
  handleSubmitFundingRound:(value: Json)=>void;
}
const InvestmentRoundDropdown = ({
  onClose,
  showInvestmentRound,
  handleSubmitFundingRound
}: IInvestmentRoundDropdown) => {
  const [selectedRound, setSelectedRound] = useState("Seed Round");
  const [isOpen, setIsOpen] = useState(false);

  const investmentRounds = useMemo(() => [
    "Seed Round",
    "Series A",
    "Series B",
    "Series C",
    "Series D",
    "Series E",
    "Bridge Financing",
  ], []);

  const renderInvestmentRoundsModal= useMemo(
    () => (
      <>
        <div className={styles.investmentRoundsModal__top}>
          <div className={styles.investmentRoundsModal__top__heading}>
          Primary issuance sell order details
          </div>
         
        </div>
        <div className={styles.investmentRoundsModal__middle}>
          <p className={styles.investmentRoundsModal__middle__label}>Investment Round</p>
          <div className={styles.investmentRounds__dropdownSection}>
            <div className={styles.investmentRounds__dropdownSection__selectedRoundSection} onClick={() => setIsOpen(!isOpen)}><span className={styles.investmentRounds__dropdownSection__selectedRoundSection__selectedRound}>{selectedRound}</span> <i className={classNames("ri-arrow-down-s-line" ,styles.investmentRoundArrowDownIcon)}/></div>
            {isOpen && (
              investmentRounds.map((option, index) => (
                <div
                  key={index}
                  className={styles.investmentRounds__dropdownSection__selectedRoundSection__option}
                  onClick={() => {
                    setSelectedRound(option);
                    setIsOpen(false);
                  }}
                >
                  {option}
                </div>
              ))
              )}
          </div>
        </div>
        <div className={styles.investmentRoundsModalBtnContainer}>
          <Button
            label="Cancel"
            buttonType="submit"
            handleClick={()=>onClose()}
            type={classNames(styles.investmentRoundsModalBtnContainer__cancel)}
          />
          {
            <Button
              label={"Next"}
              handleClick={()=>{
                handleSubmitFundingRound({fundingRound:selectedRound})
              }}
              type={classNames(styles.investmentRoundsModalBtnContainer__save)}
            />
          }
        </div>
      </>
    ),
    [handleSubmitFundingRound, investmentRounds, isOpen, onClose, selectedRound]
  );

  return (
    <ReactModal
      visible={showInvestmentRound}
      onClose={() => onClose()}
      closeBtn={true}
      maskClosable={false}
      outerClassName={classNames(styles.investmentRoundsModal)}
    >
      {renderInvestmentRoundsModal}
    </ReactModal>
  );
};

export default InvestmentRoundDropdown;
