import { useCallback, useState } from "react";
import useDarkMode from "use-dark-mode";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useNetwork, useNotification, useWebAuthentication, useWehAuthn } from "hooks";
import {
  InfoState,
  SelectedOrderState,
  VisibleCancelState,
  LoginPhoneNumberState,
  ActiveCurrencyState,
  ModalType,
  VerifyOrderModalType,
  VerfyOrderState,
  userPersonalDetails,
} from "states";
import { APIS, Messages } from "constant";
import { Loader } from "components";
import { CANCEL_ORDER, CANCEL_ORDER_BTN } from "./constant";

import styles from "./cancel.module.sass";

import { DeviceBiometrics } from "@components/device-biometrics";
import { Json } from "../../../../types/common";
import UseOrdersApi from "../Orders/store/hook";
import { ALLOW_MPC_ENV } from "../../../../envs";
import { imageConfig } from "@utils/imageConfig";

type ICancelOrder = {
  ordersData: Json;
  setOrdersData: any;
};

export const CancelOrder = ({ ordersData, setOrdersData }: ICancelOrder) => {
  const selectedOrder = useRecoilValue(SelectedOrderState);
  const setVisibleCancel = useSetRecoilState(VisibleCancelState);
  const darkMode = useDarkMode(false);
  const setInfoData = useSetRecoilState(InfoState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const { getWebAuthnSupported } = useWehAuthn();
  const setModalType = useSetRecoilState<ModalType>(VerifyOrderModalType);
  const setOpenVerfyOrder = useSetRecoilState(VerfyOrderState);
  const personalData = useRecoilValue(userPersonalDetails);

  const {svg: {cancelDark, cancelLight}} = imageConfig;
 

  const { ORDER_CANCEL, ORDER_CANCEL_ERROR } = Messages;
  const { TITLE, DESCRIPTION, BOLD_DESCRIPTION } = CANCEL_ORDER;
  const { CANCEL_NO, CANCEL_YES } = CANCEL_ORDER_BTN;

  const { remove: postCancelOrder, loading: cancelLoading } = useNetwork();
  const { get: getInfo } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const { id: activeCurrencyId }: any = activeCurrency ?? {};

  const {getUsersAssetPortfolio}=UseOrdersApi();

   const [initiateBiometric, setInitiateBiometrics] = useState(false);
   const [isbiometricLoading, setIsbiometricLoading] = useState(false);
  

   const {isVerifiedEmail} = personalData;

  const handleCancelOrder = useCallback(() => {
    if(!isVerifiedEmail){
      errorNotification("Please verify your email first");
      return;
    }
    if (!getWebAuthnSupported()) {
      handleConfirm()
    } else {
      setIsbiometricLoading(true);
      setInitiateBiometrics(true);
    }
  },[isVerifiedEmail])

  const setModalVisible =  useCallback((value: boolean, isModalOpen: boolean) => {
     setInitiateBiometrics(isModalOpen);
     setIsbiometricLoading(value);
  }, [])


  const handleConfirm = useCallback(async () => {
    const url = ALLOW_MPC_ENV ? APIS.ORDERS_EXCHANGE_MODIFICATION : APIS.CancelOrders;
    const resp = await postCancelOrder(`${url}/${selectedOrder.id}`);
    const condition = ALLOW_MPC_ENV ? resp?.data?.orderRequestId : resp
    if (condition) {
      if (ALLOW_MPC_ENV) {
        setOpenVerfyOrder({...resp?.data,VerificationType : "cancelOrder"});
        setModalType(ModalType.CONFIRM);
      }
      getUsersAssetPortfolio(activeCurrencyId)
      getInfo(APIS.Info).then((wallet) => {
        if (wallet?.data?.stellar) {
          setInfoData(wallet?.data?.stellar);
        }
      });
    } else {
      errorNotification(ORDER_CANCEL_ERROR);
    }
    setVisibleCancel(false);
  }, [selectedOrder.id, activeCurrencyId]);

  const handleFail = useCallback(() => {
    errorNotification("Authentication failed");
  }, [errorNotification]);

  return (
    <>
      <div className={styles.confirmationModal}>
        <div className={styles.confirmationHeader}>
          <img
            src={darkMode.value ? cancelDark : cancelLight}
            alt=""
            className={styles.confirmationImage}
          />
          <div className={styles.confirmationTitle}>{TITLE}</div>
        </div>
        <div>
          <div className={styles.modalDes}>
            <span>
              {DESCRIPTION} <strong>{BOLD_DESCRIPTION}</strong>
            </span>
          </div>
          <div className={styles.modalFooter}>
            <button
              className={styles.buttonCancel}
              onClick={() => {
                setInitiateBiometrics(false);
                setVisibleCancel(false)
              } }
              disabled={cancelLoading || initiateBiometric}
            >
              {CANCEL_NO}
            </button>
            <button
              className={styles.buttonCancelYes}
              onClick={handleCancelOrder}
              disabled={cancelLoading || initiateBiometric}
            >
              {!cancelLoading && !isbiometricLoading && !initiateBiometric ? (
                CANCEL_YES
              ) : (
                <Loader className="loader-white" dimension={26} />
              )}
            </button>
          </div>
        </div>
      </div>
      {initiateBiometric && (
        <DeviceBiometrics
          onSuccess={handleConfirm}
          onFailure={handleFail}
          render={(isWebAuthLoading: boolean, isModalOpen: boolean) =>
            setModalVisible(isWebAuthLoading, isModalOpen)
          }
        />
      )}
    </>
  );
};
