import { Image } from "@storybook";
import "./switch-account.scss";
import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@routes/constants";
import { useNotification } from "@hooks/notification";
// @ts-ignore
import { Json } from "@types/common";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LoginUserState, userPersonalDetails } from "@states/user";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { APIS, loginToasterMessages } from "../../../../constant";
import { useNetwork } from "@hooks/network";
import { Loader } from "@components/Loader2";
import { useCookie } from "@hooks/cookie";
import axios from "axios";
import { REACT_APP_API_HOST as API_HOST } from "../../../../envs";
import { signInWithTokenAndSetPersistence } from "../../../../libs";
import { imageConfig } from "@utils/imageConfig";

const { LOGIN_SUCCESS } = loginToasterMessages;

export const SwitchAccount = () => {
  const navigate = useNavigate();
  const { LOGIN, EXCHANGE,NO_ROUTE } = ROUTES;
  const { successNotification, errorNotification } = useNotification();
  const { state: locationState } = useLocation();
  const { set: setCookieToken, get: getCookieData } = useCookie();
  const setLoginUser = useSetRecoilState(LoginUserState);
  const { post: switchAccountsApi, loading: switchLoading } = useNetwork();
  const switchUserRef = useRef<Json>({});
  const userDetails = useRecoilValue(userPersonalDetails);
  
  const  API = typeof API_HOST !== "undefined" && API_HOST 

  const { response: workSpaceUsers } = locationState || {}

  useEffect(() => {
    if (!locationState?.token && !locationState?.response) {
      navigate(!userDetails?.id ? LOGIN : EXCHANGE)
    }
  }, []);

  async function fetchDataWithToken(token: string) {
    try {
      const response = await axios.get(API + APIS.FIREBASE, {
        headers: {
          Authorization: `Bearer ${token}`, // Set Bearer token in Authorization header
        },
      });

      // Log or handle the response data
      if (response.data) {signInWithTokenAndSetPersistence(response.data.data.token);}
      return response.data;
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  const handleAfterToken = useCallback((token?: string) => {
    const userCookie = getCookieData("userDetails");
    let loginDetails = {
      ...userCookie,
    };
    if (token) {
      loginDetails.token = token;
    }
    setLoginUser((prevState) => ({
      ...prevState,
      isLoggedIn: true,
      token: loginDetails.token,
    }));
    setCookieToken("userDetails", loginDetails);
    fetchDataWithToken(loginDetails.token);
    successNotification(LOGIN_SUCCESS);
    navigate(NO_ROUTE);
  }, []);

  const handelSwitchWorkSpace = useCallback(async (user: Json = {}): Promise<void> => {
    if (switchLoading) return;
    if (user?.isPrimary) {
      handleAfterToken()
      return
    }
    switchUserRef.current = user;

    try {
      const res = await switchAccountsApi(APIS.WORK_SPACE_SWITCH,
        { customerId: user?.customerId }, { apiResponse: true });
      if (res?.message === "ok") {
        handleAfterToken(res?.data?.data?.token);
      } else {
        errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    }
  }, [switchLoading]);

  const renderProfileName = useCallback((account: Json) => {
    return account?.accountName ? account?.accountName : account?.email ? account?.email : account?.customerId
  }, [])

  if (!locationState?.token && !locationState?.response) return <></>

  const {svg: {avatar_dark}} = imageConfig;

  return (
    <div className="switch-account-container">
      <div className="body">
        <div>
          <h2 className="heading">Select an account to continue</h2>
          <p className="para">
            Please choose the account you’d like to access. You can easily switch between accounts later if needed.
          </p>
        </div>
        <div className="switch-account-list">
          {workSpaceUsers?.data?.map((user: Json) => (
            <div className="user-details-container" key={user?.customerId}
                 onClick={() => handelSwitchWorkSpace(user)}>
              <Image className="user-img" fileName={avatar_dark} useFileNameOnFail
                     {...!!user?.profileUrl && { url: user?.profileUrl }} />
              <div className="user-details">
                <div className="user-details-name" data-tooltip-id={user?.customerId + "name"}>
                  {renderProfileName(user)}
                </div>
                {renderProfileName(user)?.length >= 20 &&
                  <ReactTooltip
                    className='theme-tooltip custom-tooltip' id={user?.customerId + "name"} place="top"
                    content={renderProfileName(user)} />}

                <div className="user-details-acc-id"
                     data-tooltip-id={user?.customerId + "mail"}>{user?.email}</div>
                {user?.email?.length >= 30 && <ReactTooltip
                  className='theme-tooltip custom-tooltip'
                  id={user?.customerId + "mail"} place="top" content={user?.email} />}
              </div>
              {switchUserRef?.current?.customerId === user?.customerId && switchLoading ?
                <span className="switch-tag"><Loader dimension={20} /></span>
                :
                <i className="ri-arrow-right-s-line switch-tag" />
              }
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SwitchAccount;
