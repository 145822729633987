import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import useDarkMode from "use-dark-mode";

import { ActiveCurrencyState, AssetLoaderState } from "states";
import { SkeletonTable } from "components";
import { tableHeaders } from "./constants";
import { imageConfig } from "@utils/imageConfig";

import styles from "./other_songs.module.sass";

export const OtherSongs = () => {
  const assestLoaderState = useRecoilValue(AssetLoaderState);
  const { artist } = useRecoilValue(ActiveCurrencyState);

  const {svg: {noDataDark, noDataLight}} = imageConfig;

  // local state
  const [error, setError] = useState<{ [key: string]: boolean }>({});

  // hooks
  const darkMode = useDarkMode();

  const topTracks = useMemo(() => artist?.topTracks ?? {}, [artist?.topTracks]);

  const getLogo = useCallback(
    ({ name, image_url }: any) => {
      return image_url && !error?.[name] ? (
        <img
          src={image_url}
          alt=""
          onError={() => setError((pre) => ({ ...pre, [name]: true }))}
          className={styles.assetImage}
        />
      ) : (
        <div className={styles.assetLetter}>
          {name?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    },
    [error]
  );

  const renderTableColumn = useMemo(
    () => (
      <div className={styles.row}>
        {tableHeaders.map(({ key, label }) => {
          // if (key === "last_dividend") label = label + ` (${currencyCode})`;
          return (
            <div key={key} className={`${styles.col}`}>
              <div className={styles.sorting}>{label}</div>
            </div>
          );
        })}
      </div>
    ),
    []
  );

  //TODO: utility fn
  const isPositive = useCallback((value: number) => {
    return (value ?? 0) >= 0;
  }, []);

  //TODO: we don't have any functionality for this handler
  // const handleClickOnSong = useCallback(
  //   (song: ISongsList) => {
  //     const {
  //       symbol,
  //       name,
  //       image,
  //       id,
  //       marketPrice,
  //       changesPercentage,
  //       change,
  //     } = song ?? {};
  //     const newActiveMusic = {
  //       id: "",
  //       name,
  //       symbol,
  //       marketPrice,
  //       change,
  //       changesPercentage,
  //       image,
  //       perDay: [],
  //       type: "music",
  //       songId: id,
  //     };
  //     setActiveMusic(newActiveMusic);
  //   },
  //   [setActiveMusic]
  // );

  const renderTableRows = useMemo(
    () =>
      topTracks &&
      Array.isArray(topTracks) &&
      topTracks?.map((track: any, index: any) => {
        return (
          <div
            //TODO: onClick={() => handleClickOnSong(track)}
            key={index}
            className={styles.row}
          >
            <div className={styles.col}>
              <div className={styles.flex}>
                {getLogo(track)}
                <div className={styles.block}>
                  <p className={styles.name}>{track?.name}</p>
                </div>
              </div>
            </div>
            <div className={styles.col}>
              <p className={styles.name}>{artist?.name}</p>
            </div>
            <div className={styles.col}>
              <div>{track?.album[0]?.name}</div>
            </div>
            <div className={styles.col}>
              <div>{track?.album[0]?.release_date?.substring(0, 4)}</div>
            </div>
          </div>
        );
      }),
    [getLogo, topTracks, artist]
  );

  return (
    <div className={styles.songs}>
      <div className={styles.row}>
        <div className={styles.header}>
          <span className={styles.rectangle}></span>
          <p> Other songs by this artist </p>
        </div>
      </div>
      <div className={styles.table_wrapper}>
        {assestLoaderState.IsLoading ? (
          <SkeletonTable listsToRender={4} numberColumn={6} />
        ) : topTracks?.length ? (
          <div className={styles.table}>
            {renderTableColumn}
            {renderTableRows}
          </div>
        ) : (
          <div className={styles.emptyState}>
            <img
              src={darkMode.value ? noDataDark : noDataLight}
              alt=""
              height={60}
            />
            <span>No Songs</span>
          </div>
        )}
      </div>
    </div>
  );
};
