import { IActiveTabData } from "../type";

import { FC, useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import cn from "classnames";

import { AddWatchlist } from "components";
import { ActiveAssetTab, ActiveCurrencyState, userPersonalDetails } from "states";
import { Price } from "../Price";

import styles from "./../Currency/Currency.module.sass";
import { assestType, assetTabs } from "../../constants";
import React from "react";
import { useNavigate } from "react-router-dom";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ACCREDITATION_LABEL } from "@views/exchange/components/Main/constants";
import { imageConfig } from "@utils/imageConfig";
import { AssetStatus } from "constant";

interface IrealStatAddress{
  city:string;
  state:string;
  country:string;
}
interface IPrivateItem {
  index: number;
  symbol: string;
  id: string;
  name: string;
  marketPrice: number;
  change: number;
  changesPercentage: number;
  image: string;
  issuerDetails?: any;
  accreditationType?: string;
  lastTradedPrice: number;
  amount: number;
  realStatAddress?: IrealStatAddress;
  type?: string;
  status?:string;
}

interface ILogo {
  symbol: string;
  image: string;
}

export const PrivateItem: FC<IPrivateItem> = (props) => {
  const {
    index,
    symbol,
    id,
    name,
    marketPrice,
    change,
    changesPercentage,
    image,
    issuerDetails,
    accreditationType,
    lastTradedPrice,
    realStatAddress,
    amount,
    status
  } = props;
  const activeTab = useRecoilValue(ActiveAssetTab);
  const isRealEstate = activeTab.key === assetTabs.REALESTATE
  const isHorses = activeTab.key === assetTabs.HORSES
  const isSM = activeTab.key === assetTabs.SM

  const [activeCurrency, setActiveCurrency] =
  useRecoilState(ActiveCurrencyState);
  
  const navigate = useNavigate();
  const {issuerName} = issuerDetails || {}

  const handleRowClick = useCallback(async () => {
    // if (!cookieData?.token) {
      navigate(`/${symbol}`);
    // } else {
      // navigate(
      //   `/exchange/${id}?type=${
      //     isRealEstate
      //       ? assestType?.realEstate
      //       : isHorses
      //       ? assestType?.Horses
      //       : isSM
      //       ? assestType.SM
      //       : assestType.privates
      //   }`
      // );
    // }
  }, [navigate, symbol]);
  const address= realStatAddress

  const [isError, setIsError] = useState(false);



  const getLogo = useCallback(
    ({ symbol, image }: ILogo) => {
      return image && !isError ? (
        <img
          src={image}
          alt=""
          onError={() => setIsError(true)}
          className={styles.assetImage}
        />
      ) : (
        <div className={styles.assetLetter}>
          {symbol?.charAt(0).toUpperCase() ?? "?"}
        </div>
      );
    },
    [isError]
  );

  const { svg } = imageConfig;
  return (
    <div
      onClick={handleRowClick}
      className={cn(styles.row, {
        [styles.active]:
          id === activeCurrency?.id && symbol === activeCurrency?.symbol,
      })}
      key={index}
    >
      <div className={`${styles.left}`}>
        <div className={styles.assetImageContain}>
          {getLogo({ symbol, image })}
          {!!ACCREDITATION_LABEL[accreditationType || ""] && (
            <img
              className={styles.accreditationLabel}
              src={svg[accreditationType ?? ""]}
              alt="Accreditation"
            />
          )}
        </div>
        <div className={styles.middlePart}>
          <div className={styles.symbol} data-tooltip-id={id}>
            {isRealEstate
              ? (  status !== AssetStatus.DRAFT ? name : "YourAsset")
              : isHorses
              ? name
              : limitFirstPart(
                  mergeStringWithSymbol(issuerName, symbol?.split("-")[0], "."),
                  12,
                  "."
                ) ?? "-"}
          </div>
          <ReactTooltip
            className="theme-tooltip"
            id={id}
            place="bottom"
            content={
              isRealEstate
                ? (  status !== AssetStatus.DRAFT ? name : "YourAsset")
                : isHorses
                ? name
                : mergeStringWithSymbol(issuerName, symbol?.split("-")[0], ".")
            }
          />
          {!isRealEstate ? (
            <div className={styles.compony_name}>
              {isHorses
                ? symbol
                : mergeStringWithSymbol(
                    issuerName,
                    status !== AssetStatus.DRAFT ? name : "YourAsset",
                    " "
                  ) ?? "NA"}{" "}
            </div>
          ) : address?.city || address?.state || address?.country ? (
            <span className={styles.realEstateAddress}>
              {`${address?.city ?? ""}, ${address?.state ?? ""}, ${
                address?.country ?? ""
              }`.replace(/(, )+$/, "")}
            </span>
          ) : (
            "--"
          )}
          {(status === AssetStatus.DRAFT ||
            status === AssetStatus.REJECTED) && (
              <div className={styles.draftAsset}>
                <span>My Asset</span>
                <span>Saved Draft</span>
              </div>
            )}
          {status === AssetStatus.PENDING && (
            <div className={styles.draftAsset}>
              <span>Approval Pending</span>
            </div>
          )}
        </div>
      </div>
      <Price
        marketPrice={lastTradedPrice || amount}
        change={change}
        changesPercentage={changesPercentage}
        symbol={symbol}
        type={props?.type}
      />
      {status === AssetStatus.APPROVED && (
        <AddWatchlist
          item={props}
          assetType={
            isRealEstate
              ? assestType?.realEstate
              : isHorses
              ? assestType?.Horses
              : assestType.privates
          }
        />
      )}
    </div>
  );
};
