import React, { useCallback, useEffect, useState, useMemo } from "react";
import "../../create-auction/components/auctionLoanForm/auction-loan-form.scss";
import { Json } from "../../../../types/common";
import classNames from "classnames";
import { useNotification } from "@hooks/notification";
import { APIS, DOC_TYPES, FILE_EXTENSION_URL } from "constant";
import { useNetwork } from "@hooks/network";
import axios from "axios";
import { formatFileSize } from "@utils/common";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { Button } from "@components/button";
import NewModal from "@components/new-modal/new-modal";
import { UPLOAD_TYPE } from "@views/auction/create-auction/components/auctionLoanForm/constant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Loader } from "@components/Loader2";
import { API_HOST_2 } from "../../../../envs";
import { SBA7A } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";

import styles from "./bidSheetModal.module.sass";
import "./bidSheetModal.scss";

const fileFormat: any = {
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  xlsx: "xlsx",
  "application/vnd.ms-excel": "xls",
  xls: "xls",
};
type IBidSheetModal = {
  open: boolean;
  onClose: () => void;
  onCallBack: (value?: any) => void;
  details: Json;
};
const BidSheetModal = ({ open, onClose, details, onCallBack }: IBidSheetModal) => {
  const { errorNotification, successNotification } = useNotification();
  const { post: getSignedUrl, loading: signedUrlLoading } = useNetwork();
  const { put: updateDataRoomFileStatus } = useNetwork();
  const { patch: deleteDataRoomFile } = useNetwork();

  const [loanFormDataRoomFiles, setLoanFormDataRoomFiles] = useState<Json[]>([]);
  const [loanFormDeletedFiles, setLoanFormDeletedFiles] = useState<Json[]>([]);
  const [formStep, setFormStep] = useState<Json>({ loading: false, status: "FORM" });
  const [fileMatchedData, setFileMatchedData] = useState<Json>({});
  const { loading, mismatched, bidsheet_mismatched, sba7_1502_mismatched } = fileMatchedData || {};

  const {svg: {greenCompleted}} = imageConfig;

  useEffect(() => {
    const loanArr: any = [];
    details?.files?.forEach((items: any) => {
      let data = {
        ...items,
        fileName: items.docName,
        name: items.docName,
        size: items.fileSize,
        uploadType: items.uploadType,
        downLoadingStatus: 100,
        isCancelled: false,
        abortController: null,
        isfileuploading: false,
        isSuccessfullyUploaded: true,
        loadingStatus: "completed",
        status: "SUCCESS",
      }
      if (!!items.uploadType) {
        const index = items.uploadType === UPLOAD_TYPE.BIDSHEET ? 0 : 1
        loanArr[index] = data;
      }
    });
    setLoanFormDataRoomFiles([...loanArr]);
  }, []);

  const fileValidations = useCallback(
    (files: any) => {
      if (!fileFormat[files.type]) {
        errorNotification(`${files.name} is not a valid file format`);
        return false;
      }
      return true;
    },
    [errorNotification]
  );

  const handleBrowse = useCallback((event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    let IsFilesValid: boolean | undefined = true;
    const file = event.target.files?.[0];
    event.target.value = "";
    IsFilesValid = fileValidations(file);
    if (IsFilesValid) {
      setLoanFormDataRoomFiles((prevFiles: any) => {
        const newIndex = fileType === UPLOAD_TYPE.BIDSHEET ? 0 : 1;
        const updatedFiles = [...prevFiles];
        updatedFiles[newIndex] = file;
        if (updatedFiles?.[0]?.name === updatedFiles?.[1]?.name) {
          errorNotification(`${file?.name} already exist. Please check`);
          return prevFiles;
        }
        if (updatedFiles[0] && updatedFiles[1]) {
          fileDetailUploading(updatedFiles as any);
        }
        return updatedFiles;
      });
    }
  }, [fileValidations, loanFormDataRoomFiles]);

  const fileDetailUploading = useCallback(async (files: FileList | any) => {
    setFileMatchedData({ loading: true });
    const formData = new FormData();
    formData.append("folder_id", details?.folderId);
    formData.append("sba_type", details?.sbaType);
    formData.append("loan_type", details?.metadata?.loanType);
    if (!files?.[0]?.fileUrl) {
      formData.append("bidsheet", files?.[0]?.fileId ? files?.[0]?.file : files?.[0]);
    }
    if (!files?.[1]?.fileUrl) {
      formData.append("sba7_1502", files?.[1]?.fileId ? files?.[1]?.file : files?.[1]);
    }
    formData.append("metadata", JSON.stringify({
      bidsheet: files?.[0]?.fileUrl || "", sba7_1502: files?.[1]?.fileUrl || ""
    }));
    try {
      const apiUrl = details?.status === "yet-to-start" ? `${API_HOST_2}/auctionsba7file/api/sba7-filescheck` : `${API_HOST_2}/auctionsba7file/api/sba7-updated-filescheck`
      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });
      const res = await response.json();
      if (res?.data) {
        setFileMatchedData({ loading: false, ...(res?.data || {}) });
        const { mismatched, bidsheet_mismatched, sba7_1502_mismatched } = res?.data || {};
        if (details?.status === "yet-to-start" && mismatched === false) {
          await uploadToGcp(files)
        }
        if (mismatched === false && bidsheet_mismatched === false && sba7_1502_mismatched === false && details?.status !== "yet-to-start") {
          await uploadToGcp(files)
        }
      } else {
        setFileMatchedData({ loading: false });
        errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      setFileMatchedData({ loading: false });
      errorNotification(e.message);
    }
  }, [details]);

  const uploadToGcp = useCallback(async (files: any) => {
    const fileList: any = [...files]?.map((file: any, index) => {
      if (!!file?.fileId || !!file.fileUrl) return file;
      return {
        fileId: uuidv4(),
        name: file.name,
        size: file.size,
        type: file.type || DOC_TYPES[file.extension],
        extension: file.type || DOC_TYPES[file.extension],
        downLoadingStatus: 0,
        isCancelled: false,
        abortController: null,
        isfileuploading: false,
        isSuccessfullyUploaded: false,
        loadingStatus: "uploading",
        status: "pending",
        file: file,
        fileLink: file,
        fileUrl: file?.fileUrl || "",
        uploadType: index === 0 ? UPLOAD_TYPE.BIDSHEET : UPLOAD_TYPE.SBA1502
      }
    });

    const signedurlPayload = {
      folderId: details?.folderId,
      files: fileList.filter(({ status }: Json) => status === "pending")?.map(({
        name, size, extension, fileId, uploadType
      }: any) => {
        return {
          fileId,
          fileName: name,
          fileSize: size,
          extension,
          uploadType,
        };
      }),
    };

    try {
      const signedurlResponse = await getSignedUrl(
        APIS.AuctionDataRoom,
        signedurlPayload,
        { apiResponse: true }
      );
      if (signedurlResponse.message === "ok") {
        const { data: signedUrlData } = signedurlResponse;

        let apiCallCount = 0;
        let apiSuccessCount = 0;
        await signedUrlData?.file?.map(async (files: any) => {
          const controller = new AbortController();
          const signal = controller.signal;
          let found = fileList?.find((e: any) => e.fileId === files.fileId);

          found = {
            ...found,
            id: files.id,
            abortController: controller,
          };

          setLoanFormDataRoomFiles((prev: any) => {
            const temp = prev?.map((item: any) => {
              if (item.name === files.fileName) {
                item = found
              }
              return item
            });
            return [...temp];
          });

          const res = axios.put(files.signedUrl, found?.file, {
            headers: {
              "Content-Type": found?.extension,
            },
            onUploadProgress: (progressEvent) => {
              if (
                typeof progressEvent.total === "number" &&
                !isNaN(progressEvent.total)
              ) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setLoanFormDataRoomFiles((prev: any) => {
                  const temp = prev?.map((item: any) => {
                    if (item.id === files.id) {
                      item.isfileuploading = true;
                      item.downLoadingStatus = percentCompleted;
                    }
                    return item;
                  });
                  return [...temp];
                });
              }
            },
            signal: signal,
          }).then((response) => {
            setLoanFormDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item?.id === files?.id) {
                  item.loadingStatus = response?.status === 200 ? "" : "failed";
                  item.abortController = null;
                  item.isSuccessfullyUploaded = response?.status === 200 ? true : false;
                  item.status = response?.status === 200 ? "SUCCESS" : "FAILED";
                  item.time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
                }
                return item;
              });
              return [...temp];
            });

            ++apiCallCount
            if (response?.status === 200) ++apiSuccessCount;
            if (signedUrlData?.file?.length === apiCallCount) {
              successNotification(`${apiSuccessCount} file successfully uploaded`);
            }
          }).catch((err) => {
            setLoanFormDataRoomFiles((prev: any) => {
              const temp = prev?.map((item: any) => {
                if (item?.id === files?.id) {
                  item.loadingStatus = "Cancelled";
                  item.abortController = null;
                  item.isSuccessfullyUploaded = false;
                  item.status = "FAILED";
                  item.time = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX");
                }
                return item;
              });
              return [...temp];
            });
            setTimeout(() => {
              setLoanFormDataRoomFiles((prev: any) => {
                const temp = prev?.map((item: any) => {
                  if (item?.id === files?.id) return null;
                  return item;
                });
                return [...temp];
              });
            }, 1000);
          });
          return res;
        });
      } else {
        errorNotification(signedurlResponse?.message);
      }
    } catch (err: any) {
      errorNotification(err?.message);
    }
  }, [getSignedUrl, details?.folderId, loanFormDataRoomFiles]);

  const deleteFile = (key: string) => {
    let tempData: any = loanFormDataRoomFiles;
    let deletedData: Json;
    if (key === UPLOAD_TYPE.BIDSHEET) {
      deletedData = tempData[0];
      tempData[0] = null;
    } else {
      deletedData = tempData[1];
      tempData[1] = null;
    }

    if (!!deletedData?.id) {
      setLoanFormDeletedFiles((prev: any) => {
        return [...prev, {
          id: deletedData.id,
          time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
        }]
      })
    }
    setLoanFormDataRoomFiles([...tempData]);
    setFileMatchedData(() => ({}));
  };

  const cancelFileUpload = (index: number) => {
    const abortController = loanFormDataRoomFiles[index]?.abortController;
    abortController.abort();
  };

  const disableSubmit = useMemo(() => {
    const files = loanFormDataRoomFiles?.filter((file) => !!file?.name);
    if (files?.length !== 2) return true
    const updatedFile = loanFormDataRoomFiles?.filter((file) => !file?.fileUrl);
    if (!updatedFile?.length) return true
    const notSuccessFiles = loanFormDataRoomFiles?.filter((file) => file?.status !== "SUCCESS");
    if (notSuccessFiles?.length) return true
    else if (mismatched || bidsheet_mismatched || sba7_1502_mismatched || loading) return true
    return false;
  }, [loanFormDataRoomFiles, mismatched, loading])

  const handleClickSubmit = useCallback(async () => {
    try {
      setFormStep((pre) => ({ ...pre, loading: true }))
      const isFileSuccess = await uploadGcpfileStatus();
      const isDeleteSuccess = await deleteDataRoomFiles();
      if (isFileSuccess && isDeleteSuccess) {
        setFormStep({ loading: false, status: "SUCCESS" })
      } else {
        setFormStep((pre) => ({ ...pre, loading: false }))
      }
    } catch (e) {

    }
  }, [loanFormDataRoomFiles]);

  const uploadGcpfileStatus = async () => {
    const folderId = details.folderId;
    if (folderId) {
      const loanFiles = loanFormDataRoomFiles?.filter(({ fileUrl }: Json) => !fileUrl)?.map((data: Json) => ({
        id: data.id,
        status: data.status,
        time: data.time,
        uploadType: data.uploadType,
        loanType: details?.metadata?.loanType,
      }))
      const payload = {
        files: loanFiles,
      };
      try {
        const response = await updateDataRoomFileStatus(
          `${APIS.AuctionDataRoom}/${folderId}`,
          payload
        );
        if (!!response?.message) {
          errorNotification(response?.message);
          return false
        }
        if (response?.length) {
          let tempObj: Json = {}
          response?.forEach((item: Json) => {
            const { docName, fileUrl, id, fileSize, fileName, fileIndex, uploadType } = item || {}
            tempObj[uploadType] = {
              docName, fileUrl, id, fileSize, fileName, fileIndex, uploadType
            }
          });
          onCallBack(tempObj);
        }
        return true;
      } catch (err: any) {
        errorNotification(err?.message);
        return false;
      }
    }
  };

  const deleteDataRoomFiles = async () => {
    try {
      const loanDeletedFiles = loanFormDeletedFiles?.map((data: Json) => ({
        id: data.id,
        time: data.time,
      }))
      if (!loanDeletedFiles?.length) return true;
      const response = await deleteDataRoomFile(`${APIS.DeleteDataroomFile}/${details.folderId}`,
        {
          files: loanDeletedFiles,
        },
        {
          apiResponse: true,
        }
      )
      if (response?.message === "ok") {
        return true
      } else {
        errorNotification("failed to delete file");
        return false
      }
    } catch (e: any) {
      errorNotification(e?.message);
      return false
    }
  };

  return (
    <NewModal
      isOpen={open}
      modalName="Bid Sheet modal"
      closeModal={onClose}
      className="new-react-modal bidsheetModal">
      {formStep.status === "FORM" &&
        <>
          <div className="bidSheetModal-Header">
            <div className="bidSheetModal-Header__title">
              Upload update files
            </div>
            <p className="bidSheetModal-Header__subTitle">
              Please ensure that the loan numbers are consistent across the
              files before updating.
            </p>
          </div>

          <div className="bidSheetModal-middleSection">
            {!!mismatched && <div className="bidsheetModal__MismatchWarning">
              <span className="bidsheetModal__MismatchWarning__alertIcon">
                <i className={classNames("ri-alert-fill")} />
              </span>
              <span className="bidsheetModal__MismatchWarning__TextContainer">
                <p className="bidsheetModal__MismatchWarning__heading">
                  Loan number mismatch
                </p>
                <p className="bidsheetModal__MismatchWarning__text">
                 The bid sheet contains loan numbers that are not present in Form 1502, and vice versa. Please
                 ensure that the loan numbers are consistent across both files.
                </p>
              </span>
            </div>}
            <div
              className={classNames(
                "auction-loan-file-section",
                "auction-loan-modal-file-section"
              )}>
              <div className="bidsheetModalpart">
                <div className="auction-loan-file-section__FileBrowse">
                  <p className="auction-loan-file-section__heading">
                    Bid sheet (Required)
                  </p>
                  <div
                    className={classNames(
                      "auction-loan-file-section__container",
                      "auction-loan-modal-file-section__container"
                    )}
                  >
                    <div className="auction-loan-files">
                      <div className="auction-loan-main">
                        <div className="auction-loan-details">
                          {!!loanFormDataRoomFiles[0]?.name && (
                            <div className="fileLogo">
                              <img
                                alt={loanFormDataRoomFiles[0]?.name}
                                src={
                                  FILE_EXTENSION_URL[
                                    loanFormDataRoomFiles[0]?.name?.split(".").pop().toLowerCase()
                                    ]
                                }
                              />
                            </div>
                          )}
                          <div className="auction-loan-files-description-container">
                            <div className="auction-loan-files-description">
                              <div
                                className={classNames(
                                  "auction-loan-files-description__upper",
                                  {
                                    "auction-loan-files-description__upper__uploading":
                                      !loanFormDataRoomFiles[0]?.isSuccessfullyUploaded,
                                  }
                                )}
                              >
                                <div className="auction-loan-file-names">
                                  <p className="fileName">
                                    {loanFormDataRoomFiles[0]?.name ||
                                      "Upload bid sheet"}
                                  </p>
                                  {!!loanFormDataRoomFiles[0]?.isSuccessfullyUploaded && (
                                    <small className="fileSize">
                                      {loanFormDataRoomFiles[0]
                                        ? formatFileSize(
                                          loanFormDataRoomFiles[0]?.size
                                        )
                                        : ""}
                                    </small>
                                  )}
                                </div>
                                {!!loanFormDataRoomFiles[0]?.loadingStatus && loanFormDataRoomFiles[0]?.loadingStatus !== "completed" &&
                                  <span className="auction-loan-file-loading-status">
                                    {loanFormDataRoomFiles[0]?.loadingStatus}
                                  </span>}
                              </div>
                              {loanFormDataRoomFiles[0]?.isSuccessfullyUploaded === false && (
                                <div className="auction-loan-loading-placeholder">
                                  <div
                                    style={{
                                      width:
                                        loanFormDataRoomFiles[0]?.downLoadingStatus + "%",
                                    }}
                                    className="auction-loan-loading-percentage"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="auction-loan-right">
                          {loanFormDataRoomFiles[0]?.abortController ? (
                            <i
                              onClick={() => cancelFileUpload(0)}
                              className="ri ri-close-circle-line auction-loan-file"
                            />
                          ) : (
                            <>
                              {loanFormDataRoomFiles[0] && !fileMatchedData?.loading && !signedUrlLoading && (
                                <i
                                  onClick={() => deleteFile(UPLOAD_TYPE.BIDSHEET)}
                                  className="ri ri-delete-bin-6-line auction-loan-file auction-loan-file__bin"
                                />
                              )}
                              <label className="auction-loan-file-section__browse"
                                     data-tooltip-id={UPLOAD_TYPE.BIDSHEET}>
                                <input
                                  type="file"
                                  className="loanFileInput"
                                  placeholder="select File"
                                  accept=".xlsx,.xls"
                                  onChange={(e) => handleBrowse(e, UPLOAD_TYPE.BIDSHEET)}
                                  disabled={!!loanFormDataRoomFiles[0]}
                                />
                                {!!loading ? <Loader className="loader-blue" dimension={15} /> : "Browse"}
                              </label>
                              {!!loanFormDataRoomFiles[0] &&
                                <ReactTooltip id={UPLOAD_TYPE.BIDSHEET} place="top"
                                              content="Please delete and browse" />}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!!bidsheet_mismatched && <div className="bidsheetModal__singleMismatchWarning">
                  <span className="bidsheetModal__singleMismatchWarning__alertIcon">
                    <i className={classNames("ri-alert-fill")} />
                  </span>
                  <span className="bidsheetModal__singleMismatchWarning__TextContainer">
                    <p className="bidsheetModal__singleMismatchWarning__heading">
                      Loan number are changed
                    </p>
                    <p className="bidsheetModal__singleMismatchWarning__text">
                      The new bid sheet contains loan numbers that are not present in the previous bid sheet.
                      Please ensure that the loan numbers are consistent across both files.
                    </p>
                  </span>
                </div>}
              </div>
              <div className="bidsheetModalpart">
                <div className="auction-loan-file-section__FileBrowse">
                  <p className="auction-loan-file-section__heading">
                    SBA form 1502 (Required)
                  </p>
                  <div
                    className={classNames(
                      "auction-loan-file-section__container",
                      "auction-loan-modal-file-section__container"
                    )}
                  >
                    <div className="auction-loan-files">
                      <div className="auction-loan-main">
                        <div className="auction-loan-details">
                          {!!loanFormDataRoomFiles[1]?.name && (
                            <div className="fileLogo">
                              <img
                                alt={loanFormDataRoomFiles[1]?.name}
                                src={
                                  FILE_EXTENSION_URL[
                                    loanFormDataRoomFiles[1]?.name?.split(".").pop().toLowerCase()
                                    ]
                                }
                              />
                            </div>
                          )}
                          <div className="auction-loan-files-description-container">
                            <div className="auction-loan-files-description">
                              <div
                                className={classNames(
                                  "auction-loan-files-description__upper",
                                  {
                                    "auction-loan-files-description__upper__uploading":
                                      !loanFormDataRoomFiles[1]?.isSuccessfullyUploaded,
                                  }
                                )}
                              >
                                <div className="auction-loan-file-names">
                                  <p className="fileName">
                                    {loanFormDataRoomFiles[1]?.name ||
                                      "Upload SBA form 1502"}
                                  </p>
                                  {!!loanFormDataRoomFiles[1]?.isSuccessfullyUploaded && (
                                    <small className="fileSize">
                                      {loanFormDataRoomFiles[1]
                                        ? formatFileSize(
                                          loanFormDataRoomFiles[1]?.size
                                        )
                                        : ""}
                                    </small>
                                  )}
                                </div>
                                {!!loanFormDataRoomFiles[1]?.loadingStatus && loanFormDataRoomFiles[1]?.loadingStatus !== "completed" &&
                                  <span className="auction-loan-file-loading-status">
                                    {loanFormDataRoomFiles[1]?.loadingStatus}
                                  </span>}
                              </div>
                              {loanFormDataRoomFiles[1]?.isSuccessfullyUploaded === false && (
                                <div className="auction-loan-loading-placeholder">
                                  <div
                                    style={{
                                      width:
                                        loanFormDataRoomFiles[1]?.downLoadingStatus + "%",
                                    }}
                                    className="auction-loan-loading-percentage"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="auction-loan-right">
                          {loanFormDataRoomFiles[1]?.abortController ? (
                            <i
                              onClick={() => cancelFileUpload(1)}
                              className="ri ri-close-circle-line auction-loan-file"
                            />
                          ) : (
                            <>
                              {loanFormDataRoomFiles[1] && !fileMatchedData?.loading && !signedUrlLoading &&
                                <i onClick={() => deleteFile(UPLOAD_TYPE.SBA1502)}
                                   className="ri ri-delete-bin-6-line auction-loan-file auction-loan-file__bin"
                                />}
                              <label className="auction-loan-file-section__browse"
                                     data-tooltip-id={UPLOAD_TYPE.SBA1502}>
                                <input
                                  type="file"
                                  className="loanFileInput"
                                  placeholder="select File"
                                  accept=".xlsx,.xls"
                                  onChange={(e) => handleBrowse(e, UPLOAD_TYPE.SBA1502)}
                                  disabled={!!loanFormDataRoomFiles[1]}
                                />
                                {!!loading ? <Loader className="loader-blue" dimension={15} /> : "Browse"}
                              </label>
                              {!!loanFormDataRoomFiles[1] &&
                                <ReactTooltip id={UPLOAD_TYPE.SBA1502} place="top"
                                              content="Please delete and browse" />}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!!sba7_1502_mismatched && <div className="bidsheetModal__singleMismatchWarning">
                  <span className="bidsheetModal__singleMismatchWarning__alertIcon">
                    <i className={classNames("ri-alert-fill")} />
                  </span>
                  <span className="bidsheetModal__singleMismatchWarning__TextContainer">
                    <p className="bidsheetModal__singleMismatchWarning__heading">
                      Loan number are changed
                    </p>
                    <p className="bidsheetModal__singleMismatchWarning__text">
                      The new {SBA7A} form 1502 contains loan numbers that are not present in the previous {SBA7A} form 1502.
                      Please ensure that the loan numbers are consistent across both files.
                    </p>
                  </span>
                </div>}
              </div>
            </div>
          </div>
          <div className={styles.bidSheetModal_buttonSection}>
            <Button
              label="Cancel"
              handleClick={onClose}
              type={classNames(styles.bidSheetModal_buttonSection__cancel)}
            />
            <Button
              disabled={disableSubmit}
              label={formStep.loading ? <Loader className="white-transparent" dimension={15} /> : "Submit"}
              handleClick={handleClickSubmit}
              type={classNames(styles.bidSheetModal_buttonSection__submit)}
            />
          </div>
        </>}

      {formStep.status === "SUCCESS" &&
        <div className="bidsheetModal bidsheetSuccessScreen">
          <img src={greenCompleted} alt="completed" />
          <span className="bidsheetSuccessScreen__textContainer">
            <p className="bidsheetSuccessScreen__textContainer__heading">
              Files uploaded successfully
            </p>
            <p className="bidsheetSuccessScreen__textContainer__subheading">
              Files has been successfully uploaded.
            </p>
          </span>
          <Button
            label="Close"
            handleClick={onClose}
            type={classNames(styles.bidSheetModal_buttonSection__cancel)}
          />
        </div>}
    </NewModal>
  );
};

export default BidSheetModal;
