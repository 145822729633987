import { useState, useRef, useEffect, useCallback } from "react";
import { useNotification } from "hooks";
import { Json } from "types";
import "./auction-banner.scss";
import { VALID_IMAGE_FILE_FORMAT } from "constant";
import { CropImageOnUpload } from "@components/CropImageOnUpload";
import { isValidFileSize } from "@utils/common";
import { Tooltip as ReactTooltip } from "react-tooltip";

type IAuctionImageForm = {
  imageType: string;
  handleChange: (value: any) => void;
  icon: string;
  content?: string;
  setAuctionFormData: (value: any) => void;
};

export const AuctionBanner = ({
  setAuctionFormData,
  imageType,
  handleChange,
  icon,
  content = "",
}: IAuctionImageForm) => {
  const inputFileRef = useRef<any>();
  const [imageChangedByInput, setImageChangedByInput] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  const [originalImage, setOriginalImage] = useState<any>(null);
  const { errorNotification } = useNotification();
  useEffect(() => {
    if (!imageChangedByInput && icon.length > 0) setImage(icon?.[0] || "");
  }, [icon, imageChangedByInput]);

  const onImageChange = async (event: any) => {
    const isFileValidated = VALID_IMAGE_FILE_FORMAT[event.target.files[0].type];
    if (isFileValidated) {
      /* const checkValidImageRatio = await checkAspectRatio(
         event.target.files[0],
         1066,
         380,
         true
       );*/
      const isFilevalid = await isValidFileSize(event.target, 5);
      /*if (!checkValidImageRatio) {
        errorNotification("Please select an image with 1066 x 380 dimension");
      } else*/
      if (!isFilevalid) {
        errorNotification("Please upload an image within 5 MB");
      } else {
        setImageFile(event.target.files?.[0]);
        setOriginalImage(event.target.files?.[0]);
      }
      inputFileRef.current.value = null;
    } else {
      errorNotification(`${event.target.files[0].name} is not a valid file`);
      inputFileRef.current.value = null;
    }
  };

  const onImageCrop = (cropData: Json) => {
    setImageChangedByInput(true);
    setImage(cropData?.blobUrl);
    handleChange(cropData?.croppedFile);
    setImageFile(null);
  };

  const handleResize = useCallback(()=>{
    setImageFile(originalImage)
  }, [originalImage])

  const handleDelete = useCallback(()=>{
    setImage("");
    setAuctionFormData((prev: any) => ({
      ...prev,
      assetImage: "",
    }));
  }, [setAuctionFormData])

  const textContent = {
    title: "Product Image",
    subtitle: "Adjust image to fit the perfect ratio.",
    stepsTitle: "Follow below steps:",
    steps: [
      "Ensure the image dimensions are 1066 x 320 pixels, maintaining a 1:3 ratio for the cover image.",
      "To adjust the image position, click and hold on the image, then drag it."
    ]
  };

  return (
    <div className="auction-banner-container">
     {!!image && originalImage && <div className="auction-banner-container__resizeButton-container">
        <div className="auction-banner-container__resizeButton" onClick={handleResize} data-tooltip-id={"ri-shape-2-line"}><i className="ri-shape-2-line ri-xl resizeIcon"></i></div>
        <ReactTooltip id={"ri-shape-2-line"} className='theme-tooltip' place="bottom" content={"Resize Image"} />
        <div className="auction-banner-container__deleteButton" onClick={handleDelete} data-tooltip-id={"ri-delete-bin-6-line"}><i className="ri-delete-bin-6-line ri-xl deleteIcon"></i></div>
        <ReactTooltip id={"ri-delete-bin-6-line"} className='theme-tooltip' place="bottom" content={"Delete Image"} />
      </div>}
      {!!imageFile && <CropImageOnUpload onClose={() => setImageFile(null)} open={!!imageFile}
                                         details={{ file: imageFile, cropSize: { width: 1066, height: 320 }, xaxis:0, yaxis:50 }}
                                         onCropDone={onImageCrop} 
                                         textContent={textContent}/>}
      <input
        ref={inputFileRef}
        type="file"
        className="auction-form-image-file"
        onChange={onImageChange}
        accept="image/*"
      />
      {image ? (
        <img alt="preview" className="auction-form-image-preview" src={image} />
      ) : (
        <div className="auction-form-image">
          <span className="auction-form-image__image-icon"><i className="ri-image-add-line"></i></span>
          <div className="auction-banner-text">
            <p className="banner-content">Add a Product Image</p>
            <p className="banner-content-instruction">
              Drag and drop image or <span>browse</span>
            </p>
            <p className="banner-cuntent-restriction">
              Supported file format: jpg, jpeg, and png.
              <br />
              Image minimum dimension: 1066 x 320 pixels
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
