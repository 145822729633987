import { Input } from "components";
import "./filter.scss";

export const Filter = ({
  showAuctionModalOnClick,
  inputValue,
  handleInputChange,
}: any) => {
  const change = (value: string) => {
    handleInputChange(value);
  };

  return (
    <div className="filter__container">
      <div className="filter__container-search">
        <Input
          placeholder="Search"
          label=""
          inputType="text"
          value={inputValue}
          handleChange={(e) => change(e.target.value)}
          suffixIcon="ri ri-search-line"
        />
      </div>
    </div>
  );
};
