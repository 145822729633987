// This component is for android playstore- @awadhesh

import { useCallback, useState } from "react";
import "./request-account-delete.scss";
import { useNetwork } from "@hooks/network";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";
import {  useSetRecoilState } from "recoil";
import { isSignupPopupVisibleState } from "@states/user";

export const AccountDeletionForm = () => {
  const [reason, setReason] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [successAccountDelete, setSuccessAccountDelete] = useState(false);
  const setShowSignPopUp = useSetRecoilState(isSignupPopupVisibleState);

  const { post: accoountDelete, loading: inviteuserLoading } = useNetwork();
  const { errorNotification, successNotification } = useNotification();

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      // Handle submission logic here
      console.log("Account deletion requested for reason:", reason);
      accoountDelete(APIS.ACCOUNT_DELETE, { reason: reason })
        .then((res) => {
          if (res?.success) {
            setReason("");
            setIsButtonEnabled(false);
            setSuccessAccountDelete(true);
            successNotification("Account deletion request submitted");
          } else {
             setShowSignPopUp(true);
            errorNotification(
              res?.message || "Something went wrong. Please try again"
            );
          }
        })
        .catch((err) => {
           setShowSignPopUp(true);
          errorNotification(
            err?.message || "Something went wrong. Please try again"
          );
        });
    },
    [reason]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setReason(value);
    setIsButtonEnabled(value.trim().length > 0);
  };
  return (
    <>
      {!successAccountDelete ? (
        <div className="account-deletion">
          <div className="account-delete-title">Request Account Deletion</div>
          <p>
            If you would like to delete your account, please submit a request.
            Our support team will review your request and get in touch with you
            within 7 days. If everything is in order, your account will be
            deleted.
          </p>
          <ul>
            <li>Your request will be reviewed by our support team.</li>
            <li>You will be contacted within 7 days for further details.</li>
            <li>
              If the account deletion request is valid, the deletion process
              will be initiated.
            </li>
            <li>
              Once your account is deleted, all your data will be permanently
              removed and cannot be recovered.
            </li>
          </ul>
          <p className="data-safety">
            Data Safety: We take your privacy and security seriously. All data
            you provide to us is handled securely, and we follow strict
            protocols to ensure your information is protected during the
            deletion process.
          </p>
          <div onSubmit={handleSubmit} className="deletion-form">
            <label htmlFor="reason">
              Reason for Deletion (Please specify):
            </label>
            <textarea
              id="reason"
              rows={5}
              cols={50}
              placeholder="Enter your reason for account deletion here..."
              value={reason}
              onChange={(e) => handleChange(e)}
            ></textarea>
            <button
              type="button"
              className="request-deletion"
              onClick={handleSubmit}
              disabled={!isButtonEnabled || inviteuserLoading}
            >
              {" "}
              Request Deletion
            </button>
          </div>
        </div>
      ) : (
        <div className="ThankYou">
          <div className="ThankYou__card">
            <div className="ThankYou__check-icon">
              <i className="ri-check-double-line"></i>
            </div>

            <h1 className="ThankYou__title">Thank You!</h1>

            <div className="ThankYou__message">
              <p className="ThankYou__text">We have received your message.</p>
              <p className="ThankYou__text">
                Our support team will contact you via email within 7 days.
              </p>
              <p className="ThankYou__text">Thank you for your patience!</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDeletionForm;
