import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CountdownTimer, RadialSeparators,
  SkeletonLogo,
  SkeletonSearch,
  SkeletonTable,
} from "../../../components";
import { CircularProgressbar, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { LatestBids } from "views/AuctionConsumer/components";
import {
  useCurrency,
  useNetwork,
  useNotification,
  useRemainingSecPercentage,
} from "hooks";
import { APIS, FILE_EXTENSION_URL } from "../../../constant";
import { useParams } from "react-router-dom";
import { Json } from "../../../types";
import { formatFileSize } from "../../../utils";
import "./admin-auction-details.scss";
import { addMinutes, subMinutes } from "date-fns";
import { BidSheetLoan } from "@views/auction/create-auction/components/BidSheetLoan";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { LOAN_NAME } from "../../AuctionConsumer/constant";
import { imageConfig } from "@utils/imageConfig";

export const AdminAuctionDetails = () => {
  const params = useParams();
  const { errorNotification } = useNotification();
  const { formatCurrencyWithBillion } = useCurrency();
  const { get: fetchAuction } = useNetwork({ updateState: false });
  const [auctionData, setAuctionData] = useState<Json>({});
  const { data: auctionDetails = {}, loading } = auctionData;
  const secPercentage = useRemainingSecPercentage(
    auctionDetails?.startTime,
    auctionDetails?.endTime
  );
  const { sbaAndNormalPrice, isSbaLoan: isSba7Loan } = useAuctionHook(auctionDetails);

  const {svg: {user}} = imageConfig;
  
  const prevDutchStepTime = useMemo(() => {
    return subMinutes(
      new Date(auctionDetails?.dutchPriceUpdateTime),
      auctionDetails?.timeStepHours * 60 + auctionDetails?.timeStepMinutes
    );
  }, [auctionDetails]);
  const isDutchLive =
    auctionDetails?.tradeType === "dutch" && auctionDetails?.status === "live";
  const dutchStepTimePer = useRemainingSecPercentage(
    prevDutchStepTime,
    auctionDetails?.dutchPriceUpdateTime,
    isDutchLive
  );

  useEffect(() => {
    handleFetchAuction();
  }, []);

  const handleFetchAuction = useCallback(async () => {
    setAuctionData({ loading: true });
    const res = await fetchAuction(`${APIS.Auction}/${params.id}`);
    if (res?.message === "ok") {
      setAuctionData({ loading: false, data: res?.data });
    } else {
      setAuctionData({ loading: false });
      errorNotification(
        res?.message || "Something went wrong! Please try again"
      );
    }
  }, [params]);

  const renderStartDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json) => {
      if (completed) {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          temp = { ...temp, status: "live" };
          return { ...prev, data: { ...temp } };
        });
      }
      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const renderDate = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json, type?: string) => {
      if (type === "endTimer" && completed) {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          temp = { ...temp, status: "completed" };
          return { ...prev, data: { ...temp } };
        });
      }

      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const renderDutchStepTime = useCallback(
    ({ days, hours, minutes, seconds, completed }: Json) => {
      if (
        completed &&
        auctionDetails?.currentBidPrice > auctionDetails?.reservePrice
      ) {
        setAuctionData((prev) => {
          let temp = JSON.parse(JSON.stringify(prev.data));
          const {
            currentBidPrice,
            stepPrice,
            maxAuctionBidPrice,
            userMaxBidPrice,
            reservePrice,
            timeStepMinutes,
            timeStepHours,
            dutchPriceUpdateTime,
          } = temp || {};
          const price = Math.max(
            currentBidPrice - stepPrice,
            maxAuctionBidPrice,
            reservePrice
          );

          if (price === maxAuctionBidPrice) {
            return {
              ...prev,
              data: {
                ...temp,
                currentBidPrice: price,
                userBidPrice: userMaxBidPrice,
                status: "completed",
              },
            };
          }

          const totalMinutes =
            price === reservePrice ? 0 : timeStepHours * 60 + timeStepMinutes;
          temp = {
            ...temp,
            currentBidPrice: price,
            dutchPriceUpdateTime: addMinutes(
              new Date(dutchPriceUpdateTime),
              totalMinutes
            ),
          };
          return { ...prev, data: { ...temp } };
        });
      }

      return `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${
        (minutes < 10 ? "0" : "") + minutes
      }:${(seconds < 10 ? "0" : "") + seconds}`;
    },
    [auctionDetails]
  );

  const handleOpenDataRoom = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  return (
    <>
      <div className="auction-details-containers">
        <div className="details-and-latest-bids">
          <div className="details-container">
            <h2 className="auction-details-heading">Overview</h2>
            {!loading && (
              <img
                className="auction-details-image"
                src={
                  auctionDetails?.assetImage?.[0] ??
                  "https://upload.wikimedia.org/wikipedia/commons/5/5f/Grey.PNG"
                }
                alt="auction details"
              />
            )}
            {loading && <SkeletonLogo className="auction-details-image" />}
            {loading && (
              <SkeletonTable
                listsToRender={2}
                numberColumn={1}
                className="border-b-0"
              />
            )}
            {!loading && (
              <div className="auction-details">
                <h2 className="auction-name">{auctionDetails?.name}</h2>
                <span className="title-symbol">
                    {auctionDetails?.symbol}.
                    <span>{auctionDetails?.symbolValue}</span>
                  </span>
                <pre
                  className="auction-desc"
                  dangerouslySetInnerHTML={{
                    __html: auctionDetails?.description,
                  }}
                />
              </div>
            )}
          </div>
          <div className="auction-latest-bids">
            <LatestBids
              auctionId={params.id!}
              auctionDetails={auctionDetails}
            />
          </div>
        </div>

        <div className="auction-stats-container">
          <h2 className="auction-details-heading">Auction Stats</h2>

          <div className="stats-row">
            <div className="stats">
              {loading && <SkeletonLogo className="stats-percent" />}
              {!loading && (
                <div className="stats-percent">
                  <CircularProgressbarWithChildren
                    maxValue={100}
                    strokeWidth={6}
                    counterClockwise
                    className={`auction-progress-bar ${
                      (secPercentage || 0) < 10 ? "time-red" : ""
                    } ${auctionDetails?.status === "completed" && "time-over"}`}
                    value={
                      auctionDetails?.status === "completed"
                        ? 0
                        : auctionDetails?.status === "live"
                          ? secPercentage || 0
                          : 100
                    }
                  >
                    <RadialSeparators count={100} />

                    {auctionDetails?.tradeType === "dutch" &&
                      auctionDetails?.status !== "yet-to-start" && (
                        <CircularProgressbar
                          className="inner-time-circle"
                          counterClockwise
                          value={
                            auctionDetails?.status === "completed"
                              ? 0
                              : dutchStepTimePer || 0
                          }
                          maxValue={100}
                          strokeWidth={5}
                        />
                      )}
                    <div className="stats-item text-center">
                      <p className="stat-value">
                        {sbaAndNormalPrice(auctionDetails, { price: auctionDetails?.currentBidPrice, brake: true })}
                      </p>
                      <p className="stat-label">
                        {auctionDetails?.status === "live" && (
                          <CountdownTimer
                            dateTime={auctionDetails?.endTime}
                            renderer={renderDate}
                          />
                        )}
                        {auctionDetails?.status !== "live" && "00:00:00"}
                      </p>
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
              )}
              {loading && (
                <SkeletonTable
                  listsToRender={2}
                  numberColumn={4}
                  className="border-b-0"
                />
              )}
              {!loading && (
                <div className="stats-details">
                  <div className="stats-item">
                    <p className="stat-label">Status</p>
                    <p className="stat-value">
                      {auctionDetails?.status?.split("-").join(" ")}
                    </p>
                  </div>
                  <div className="stats-item">
                    <p className="stat-label">Auction Type</p>
                    <p className="stat-value">{auctionDetails?.tradeType}</p>
                  </div>
                  <div className="stats-item">
                    <p className="stat-label">Start price</p>
                    <p className="stat-value">
                      {sbaAndNormalPrice(auctionDetails, { price: auctionDetails?.startPrice })}
                    </p>
                    {isSba7Loan && (
                      <p className="stat-label">
                        {formatCurrencyWithBillion(
                          auctionDetails?.startPrice,
                          2)}
                      </p>
                    )}
                  </div>
                  <div className="stats-item">
                    <p className="stat-label">step price</p>
                    <p className="stat-value">
                      {sbaAndNormalPrice(auctionDetails, { price: auctionDetails?.stepPrice })}
                    </p>
                    {isSba7Loan && (
                      <p className="stat-label">
                        {formatCurrencyWithBillion(
                          auctionDetails?.stepPrice,
                          2)}
                      </p>
                    )}
                  </div>
                  {auctionDetails?.tradeType === "classic" && (
                    <div className="stats-item">
                      <p className="stat-label">Buy Now Price</p>
                      <p className="stat-value">
                        {sbaAndNormalPrice(auctionDetails, { price: auctionDetails?.buynowPrice })}
                      </p>
                      {isSba7Loan && (
                        <p className="stat-label">
                          {formatCurrencyWithBillion(
                            auctionDetails?.buynowPrice,
                            2)}
                        </p>
                      )}
                    </div>
                  )}
                  {auctionDetails?.tradeType === "dutch" && (
                    <div className="stats-item">
                      <p className="stat-label">Reserve price</p>
                      <p className="stat-value">
                        {sbaAndNormalPrice(auctionDetails, { price: auctionDetails?.reservePrice })}
                      </p>
                      {isSba7Loan && (
                        <p className="stat-label">
                          {formatCurrencyWithBillion(
                            auctionDetails?.reservePrice,
                            2)}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="stats-item">
                    <p className="stat-label">
                      {auctionDetails?.status === "yet-to-start"
                        ? "Time left to start"
                        : "Time left for auction"}
                    </p>
                    <p className="stat-value">
                      {auctionDetails?.status === "yet-to-start" && (
                        <CountdownTimer
                          dateTime={auctionDetails?.startTime}
                          renderer={renderStartDate}
                        />
                      )}

                      {auctionDetails?.status === "live" && (
                        <CountdownTimer
                          dateTime={auctionDetails?.endTime}
                          renderer={(value) => renderDate(value, "endTimer")}
                        />
                      )}

                      {auctionDetails?.status === "completed" && "00:00:00"}
                    </p>
                  </div>
                  {auctionDetails?.tradeType === "dutch" && (
                    <div className="stats-item">
                      <p className="stat-value">
                        {auctionDetails?.status === "live" && (
                          <CountdownTimer
                            dateTime={auctionDetails?.dutchPriceUpdateTime}
                            renderer={renderDutchStepTime}
                          />
                        )}
                        {auctionDetails?.status !== "live" && "00:00:00"}
                      </p>
                      <p className="stat-label">Time left to next step</p>
                    </div>
                  )}
                  {isSba7Loan && (
                    <div className="stats-item">
                      <p className="stat-label">Weighted Average Coupon</p>
                      <p className="stat-value">
                        {(auctionDetails?.metadata?.currentYield || 0)?.toFixed(6)}%
                      </p>
                    </div>
                  )}
                  {isSba7Loan && (
                    <div className="stats-item">
                      <p className="stat-label">Listing type</p>
                      <p className="stat-value">
                        {auctionDetails?.sbaType || "--"}
                      </p>
                    </div>
                  )}
                  {isSba7Loan && (
                    <div className="stats-item">
                      <p className="stat-label">Loan Type</p>
                      <p className="stat-value">
                        {LOAN_NAME?.[auctionDetails?.metadata?.loanType] || "--"}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <h2 className="auction-details-heading">Data Room</h2>
          {loading && (
            <SkeletonSearch className="data-room-container" listsToRender={2} />
          )}
          {!loading && (
            <div className="data-room-container">
              {auctionDetails?.dataRoom?.map((file: Json) => (
                <div className="data-room-item">
                  <div className="data-room-img">
                    <img
                      src={
                        FILE_EXTENSION_URL[file?.fileName?.split(".")?.[1]] ||
                        user
                      }
                      alt={file?.docName}
                      onClick={() => handleOpenDataRoom(file?.fileUrl)}
                    />
                  </div>
                  <div className="data-room-details">
                    <div
                      className="pointer"
                      onClick={() => handleOpenDataRoom(file?.fileUrl)}
                    >
                      {file?.docName}
                    </div>
                    <span>{formatFileSize(file?.fileSize || 0)}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {!!auctionDetails?.bidSheetJSON && !!auctionDetails?.sba1502JSON && (
          <BidSheetLoan auctionDetails={auctionDetails} />
        )}
      </div>
    </>
  );
};
