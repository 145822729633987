import { ALLOW_MPC_ENV } from "../../envs";

export const API_HOST_CLOUD = "https://gateway.pinata.cloud/ipfs/";

export const APIS = {
  Wallets: "/wallets",
  Info: "/info",
  Users: "/users",
  TokensUserId: "/tokens?userId=",
  Events: "/events",
  Countries: "/countries",
  StatesCountry: "/states?country=",
  VerifyPhones: "/verify-phones",
  Trust: "trust",
  Transactions: "/transactions",
  PhoneCodes: "/phone-codes",
  Status: "/status",
  Connections: "/connections",
  Requests: "/requests",
  WatchLists: "/watchlists",
  Explorers: "/exchange-explorers",
  StockSummaries: "/stock-summaries",
  CommoditySummaries: "/commodity-summaries",
  ForexSummaries: "/forex-summaries",
  MarketIndexSummaries: "/market-index-summaries",
  AddToWatchlist: "/watchlists/",
  NEWS: "/news",
  ASSETS_CHAT: "/asset-chats",
  ASSET_CHAT_HISTORY: "/asset-chats-history",
  Portfolio: "/portfolio",
  PrivateSummaries: "/privates-summaries",
  RealEstateSummaries: "/real-estate-summaries",
  Offers: "/offers-exchange",
  ORDERS_EXCHANGE: "/orders-exchange",
  ORDERS_EXCHANGE_MODIFICATION: "/order-exchange-update",
  OrderBookBuy: "/exchange-orderbook",
  OrderBookParam1: "&buyAssetType=native",
  OrderbookSell: "/exchange-orderbook",
  OrderbookParam2: "&buyAssetAddress=",
  Search: "/search-assets",
  DeviceTokens: "/device-tokens",
  NOTIFICATION: "/in-app-notification",
  Tokens: "/tokens",
  OrderHistory: "/exchange-order-history",
  CancelOrders: "/exchange-order-history",
  EditOrders: "/edit-orders",
  MultiLanguage: "/multilanguage",
  SessionStatus: "/check-session-status",
  WalletKey: "/wallet-key",
  WebAuthLogin: "/web-auth-login",
  WaitingLogin: "/events",
  LoginStatus: "/status",
  AssetOverview: "/fund-overview/",
  UserProfile: "/profile-picture",
  REPORTS: "/reports",
  MONTHLYSTATEMENT: "/monthly-statement",
  COOWNER: "/co-owners",
  UPLOADDOCUMENT: "/co-owners-document",
  GETCOOWNER: "/co-owners-exchange",
  GETREMOVEALDOCUMENT: "/co-owners-document",
  COOWNERDENYREQUEST: "/co-owners-deny-request",
  REMOVEACCEPTANCE: "/co-owners-document",
  ORDERS: "/order-details",
  SWITCHACCOUNT: "/switch-account",
  Transaction_exchange: "/transaction-exchange",
  Form1099: "/form-1099-exchange",
  REPORTS_CHARTS: "/report-charts",
  GenerateLinkToken: "/liquidity-link-tokens",
  Accounts: "/bank-accounts",
  TokenExchange: "/liquidity-token-exchange",
  Cards: "/liquidity-cards",
  FormDownload: "/form-8949-exchange",
  Payment: "/payments",
  MusicSummaries: "/exchange-music",
  ResendVerificationEmail: "/resend-verification-email",
  News: "/news",
  ExchangePortfolio: "/exchange-portfolio",
  ExchangeAssets: "/exchange-assets",
  Auction: "/auction",
  AuctionProductCategory: "/auction-product-and-category",
  LatestBid: "/latest-bid-price",
  AuctionBid: "/auction-bid",
  AuctionMaxBid: "/auction-max-bid",
  AuctionWatchlist: "/auction-watchlist",
  AuctionDataRoom: "/auction-dataroom",
  DeleteDataroomFile: "/auction-dataroom-files",
  updateAuctionDataRoom: "/auction-dataroom-files",
  PAYMENT_INITIATION: "/payment-initiation",
  TRANSACTION: "/fortress-transactions",
  walletPayment: "/payment-initiation",
  ACCOUNTINFO: "/fortress-account-info",
  auctionDataRoomLog: "/auction-dataroom-log",
  sbaDataRoomLog: "/exchange-dataroom-log",
  UsersDeviceLogin: "/users-device-login",
  VerifyPhone: "/verify-phone",
  userVerifyLink: "/user-login",
  CLIENT_WEBAUTHN: "/device-biometric",
  WAITLIST: "/waitlists",
  SubAsset: "/sub-assets",
  SubAssetList: "/sub-asset-list",
  BulkOrder: ALLOW_MPC_ENV ? "/orders-exchange" : "/bulk-order",
  AssetCharts: "/asset-chats",
  chatInitiate: "/chat-initiate",
  exchangeOrderBookGroup: "/exchange-orderbook-group",
  transactionDetails: "/fortress-transactions",
  CHECK_SYMBOL: "/unique-symbol",
  USER_DVP: "/user-dvp",
  ACCREDITATION: "/accreditation",
  APP_VERSION:"/app-version/123",
  FIREBASE: "/firebase",
  REINITIATE: "/reinitiate-session",
  PRIMARY_ISSUANCE: "/primary-issuance",
  DELETE_BANK_ACCOUNT: "/liquidity-token-exchange",
  SIMPLICI_TOKEN: "/simplici-token",
  PORTFOLIO_SUMMARY: "/portfolio-summary",
  WORK_SPACE: "/workspace",
  WORK_SPACE_SWITCH: "/workspace-switch",
  tenants: "/wl-tenants",
  social_login: "/social-login",
  Wire_Deposit: "/wire-deposit",
  CREATE_ASSET: "/list-assets",
  LIST_COMAPANIES: "/list-companies",
  LINK_COMAPANY: "/link-company",
  ASSET_SESSION: "/asset-session",
  ORDER_DOCUMENTS: "/order-documents",
  ORDER_SETTLEMENT_STEPS: "/order-settlement-steps",
  ACCOUNT_DELETE: "/account-deletion",
};
