import { Button, Loader, SkeletonWallet } from "components"
import cn from "classnames";
import { Image } from "@storybook";
import { FILE_EXTENSION_URL } from "constant";
import { useCallback, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { formatFileSize, getFormattedDate } from "utils";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { RemovalFileState, UseAuthorisedUser } from "../../store";

import styles from "./removalProcess.module.sass"
import { useLocation, useNavigate } from "react-router-dom";
import { useCookie } from "@hooks/cookie";
import { ROUTES } from "@routes/constants";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import useDarkMode from "use-dark-mode";


const RemovalProcess = () => {
    const { COOWNERSREMOVALREQUEST } = ROUTES
    const {svg: {view}, logo: {Liquidity_Blue_Logo}} = imageConfig
   
    const onRemovalFiles = useRecoilValue<any>(RemovalFileState)
    const { config } = usePermissionContext();
    const darkMode = useDarkMode(false);

    const navigate = useNavigate()
    const location = useLocation();
    const { set: setCookieToken } = useCookie();
    const { getRemovalFiles, AcceptRemovalRequest, removalFileLoading, denyLoading,
        acceptLoading, removalDecision } = UseAuthorisedUser()

    useEffect(() => {
        getRemovalFiles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (location?.pathname.includes("/co-owners-email-remove/")) {
            const urlId = location.pathname.split("/");
            const ownerId = urlId[urlId.length - 1];
            const ownerDetail = {
                id: ownerId
            };
            setCookieToken("ownerId", ownerDetail)
        }
    }, [location.pathname])

    const handleDeny = useCallback(() => {
        navigate(COOWNERSREMOVALREQUEST)
    }, [COOWNERSREMOVALREQUEST, navigate])

    const handleApprove = useCallback(() => {
        AcceptRemovalRequest()
    }, [AcceptRemovalRequest])

    const handleFileView = useCallback((fileName: any) => {
        window.open(fileName, "_blank");

    }, [])
const handleLogo = useCallback(()=>{
    navigate("/exchange")
},[])

    return (
        <>
            <div className={styles.removalDecisionSection}>
                <div className={styles.invitationContainer}>
                    <div >
                        <Image
                            className={styles.logoContainer}
                            fileName={config?.name !== "Liquidity" ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.dark : Liquidity_Blue_Logo}
                            props={{onClick:handleLogo}}
                        />
                    </div>
                    <div className={styles.invitationDecisionDetails}>

                        <div className={styles.Details}>
                            <div className={styles.invitation}>Co-owner Removal Process</div>
                            <div className={styles.messageContainer}>
                                <p className={styles.viewDocument}>View documents</p>
                                <p className={styles.message}>
                                    Account owner has initiated a co-owner removal process. Please find the attached documents detailing this request.
                                </p>
                            </div>
                            <div className={styles.removalProcess}>
                                <p className={styles.removalProcess__details}>Details</p>
                                <div className={styles.removalProcess__dateHeading}>
                                    <p>Date:</p>
                                    <p ><span className={styles.removalProcess__date}>{onRemovalFiles ? getFormattedDate(onRemovalFiles?.updatedAt) : ""}</span> (last edited)</p>
                                </div>
                            </div>
                            <div className={styles.documentHeader}>
                                <p className={styles.documentHeader__document}>Documents</p>
                                <p className={styles.documentHeader__file}>{onRemovalFiles?.filesData?.length} {onRemovalFiles?.filesData?.length > 1 ? "Files" : "File"}</p>
                            </div>

                            <div className={cn(styles.fileSection, { [styles.sectionHeight]: onRemovalFiles?.removalStatus !== "PENDING" })}>
                                {removalFileLoading ? <SkeletonWallet listsToRender={3} /> : onRemovalFiles?.filesData?.map((file: any) => {
                                    return <div className={styles.fileContainer} >
                                        <div className={styles.fileLeft}>
                                            <div className={styles.fileLogo}>
                                                <img src={FILE_EXTENSION_URL[file?.docName?.split(".").pop().toLowerCase()]}
                                                    alt={file?.docName} />
                                            </div>
                                            <div className={styles.fileDetails}>
                                                <p className={styles.fileDetails__name} data-tooltip-id={file?._id}>{file?.docName}</p>
                                                <ReactTooltip
                                                    id={file?._id}
                                                    className={"theme-tooltip"}
                                                    place="bottom"
                                                    content={file?.docName}
                                                />
                                                <span
                                                    style={{
                                                        width: "4px",
                                                        height: "4px",
                                                        borderRadius: "4px",
                                                        backgroundColor: "#878C99",
                                                        display: "inline-block",
                                                    }}
                                                ></span>
                                                <p className={styles.fileDetails__size}> {formatFileSize(file?.fileSize)}</p>
                                            </div>
                                        </div>
                                        <div className={styles.fileRight}><button onClick={() => handleFileView(file?.fileName)} className={styles.viewButton}>View <img src={view} alt="view" /></button></div>
                                    </div>
                                })}
                            </div>


                        </div >
                        {onRemovalFiles?.removalStatus === "PENDING" && <div className={styles.buttonSection} >
                            <Button
                                label={denyLoading ? <Loader dimension={20} className='loader-white' /> : "Deny"}
                                handleClick={handleDeny}
                                type={cn(styles.deny)}
                                disabled={removalDecision || acceptLoading}
                            />
                            {
                                <Button
                                    label={acceptLoading ? <Loader dimension={20} className='loader-white' /> : "Accept"}
                                    handleClick={handleApprove}
                                    type={cn("button__filled button__filled--primary", styles.approve)}
                                    disabled={removalDecision || denyLoading}
                                />
                            }
                        </div>}


                    </div>
                    <div className={styles.invitationFooter}>
                        <div className={styles.invitaionSocial}>
                            <span className={styles.invitaionSocial__twitterLogo}>
                                <a href="https://www.linkedin.com/company/liquidity-io/" > <img src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/linkedIn.svg" alt="LinkedIn" /></a>
                            </span>
                            <span className={styles.invitaionSocial__twitterLogo}>
                                <a href="https://twitter.com/LiquidityIO" >  <img className={styles.invitaionSocial__twitterImg} src=" https://storage.googleapis.com/satschel-assets-public/assets/illustrations/twitter-logo.png" alt="Twiiter" /></a>
                            </span>
                        </div>
                        <p className={styles.footerDisclaimer}>© 2024 Satschel Inc. All rights reserved.</p>
                    </div>
                </div>
            </div >
        </>
    )
}
export default RemovalProcess