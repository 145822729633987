import { createRef, FC } from "react";
import classNames from "classnames";
import type { ISideDrawer } from "./type";
import { useOutsideClick } from "hooks";
import { MORNING_LINE } from "@views/exchange/constants";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

import "./drawer.scss";

export const SideDrawer: FC<ISideDrawer> = ({
  visible,
  setVisible,
  body,
  logo,
}) => {
  const ref = createRef<HTMLDivElement>();
  const { config } = usePermissionContext()
  useOutsideClick(ref, () => {
    setVisible(false);
  });
  return (
    <div className={`drawer ${visible ? "drawer--open" : ""} `} ref={ref}>
      <div className={classNames("drawer__logo", config?.name === MORNING_LINE ? "drawer__mlcLogo" : "")}>{logo}</div>
      <div className="drawer__close-btn" onClick={() => setVisible(false)}>
        <i className="ri-menu-fold-line" />
      </div>
      <div className="drawer__body">{body}</div>
    </div>
  )
    ;
};
