import React, { useCallback, useEffect, useState } from "react";
import { useNotification } from "@hooks/notification";
import { useNetwork } from "@hooks/network";
import { Button } from "@components/button";
import NewModal from "@components/new-modal/new-modal";
import "./add-switch-account-modal.scss";
import { Image } from "../../../../@storybook";
import { useRecoilValue } from "recoil";
import { darkThemeState } from "@states/user";
import { Input } from "@components/input";
import cn from "classnames";
import { Loader } from "@components/Loader2";
// @ts-ignore
import { Json } from "@types/common";
import { APIS } from "../../../../constant";
import { useWebSocket } from "@hooks/web-socket";
import { imageConfig } from "@utils/imageConfig";


type IAddSwitchAccountModal = {
  open: boolean;
  onClose: () => void;
};
const AddSwitchAccountModal = ({ open, onClose }: IAddSwitchAccountModal) => {
  const { errorNotification, successNotification } = useNotification();
  const themeDark = useRecoilValue(darkThemeState);
  const { socket } = useWebSocket();
  const { post: postAccount, loading: postAccountLoading } = useNetwork();
  const [email, setEmail] = useState("");
  const [steps, setSteps] = useState(0);

  const {svg: {email_sent_dark, email_sent_light}} = imageConfig;

  useEffect(() => {
    socket?.on("workspace", (data) => {
      if (data?.type === "EMAIL_ACCEPTED") {
        setSteps(2);
      }
    });
    return () => {
      socket?.off("workspace");
    }
  }, [socket]);

  const handleSubmit = useCallback(async (e: Json) => {
    if (postAccountLoading) return
    e?.preventDefault();
    try {
      const res = await postAccount(APIS.WORK_SPACE, { email }, { apiResponse: true });
      if (res?.message === "ok") {
        successNotification(res?.data?.message);
        setSteps(1);
      } else {
        errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    }
  }, [email, postAccountLoading]);

  return (
    <NewModal
      isOpen={open}
      modalName=""
      closeModal={onClose}
      overlayStyles={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      className={`new-react-modal add-switch-account-modal ${steps ? "email-verify" : ""}`}>
      {!steps && <>
        <div className="add-switch-account-modal-header">
          <div className="header-heading">Add Account</div>
          <div className="header-des">
            Easily connect another account using a different email. Switch <br /> between
            accounts whenever needed.
          </div>
        </div>

        <div className="email-warning">
          <i className="ri-alarm-warning-fill" />
          A different email address is required from the one associated with your
          current account.
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            className="account-input" required
            showAsterisk={true}
            placeholder="example@mail.com"
            title="example@mail.com"
            label="Enter new email address"
            inputType="email"
            value={email}
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            handleChange={(e) => setEmail(e.target.value)} />

          <div className="add-switch-account-modal-footer">
            <Button
              buttonType="button"
              label="Cancel"
              handleClick={() => onClose()}
              type={cn("button__filled gray-btn")}
            />
            <Button
              label={postAccountLoading ? <Loader dimension={20} /> : "Next"} buttonType="submit"
              disabled={!email || postAccountLoading}
              type={cn("button__filled button__filled--primary")}
            />
          </div>
        </form>
      </>}
      {steps === 1 &&
        <div className="switch-account-email-verify">
          {themeDark ?
            <Image fileName={email_sent_dark} height={"120px"} /> :
            <Image fileName={email_sent_light} height={"120px"} />
          }
          <h2 className="switch-account-email-verify-heading mt-20">Verification email sent</h2>
          <p className="switch-account-email-verify-des bottom-border">
            A verification email has been sent to&nbsp;<span className="email">{email}</span>&nbsp;<span
            className="edit" onClick={() => setSteps(0)}>Edit</span>
          </p>
          <p className="switch-account-email-verify-des mb-20">
            Please check your inbox and spam folder. Kindly click on the link provided in the email and complete
            the verification process from there.</p>
          <p className="switch-account-email-verify-des">Not received the code yet?&nbsp;
            <span className="resend" onClick={handleSubmit}>{postAccountLoading ?
              <Loader dimension={20} /> : "Resend"}</span></p>
        </div>}

      {steps === 2 &&
        <div className="switch-account-email-verify success">
          <Image fileName={"icon/greenCompleted.svg"} height={"80px"} />
          <h2 className="switch-account-email-verify-heading mt-20">Email verified successfully</h2>
          <p className="switch-account-email-verify-des mb-20">Thank you for verifying your email.</p>
          <div className="add-switch-account-modal-footer">
            <Button
              label="Close"
              handleClick={() => onClose()}
              type={cn("button__filled gray-btn")}
            />
          </div>
        </div>}
    </NewModal>
  );
};

export default AddSwitchAccountModal;
