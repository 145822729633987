export const validateIssuerName = (name: string): string => {
  const trimmedName = name.trim();
  
  if (!trimmedName) {
    return "Issuer name is required.";
  }
  
  if (/[A-Z]/.test(trimmedName)) {
    return "Issuer name must be in lowercase.";
  }
  const validNamePattern = /^[A-Za-z0-9 ]+$/;
  if (!validNamePattern.test(trimmedName)) {
    return "Issuer name contains invalid characters. Please use only letters and numbers.";
  }

  const alphanumericPattern = /[A-Za-z0-9]/g;
  const alphanumericMatches = trimmedName.match(alphanumericPattern);
  if (!alphanumericMatches || alphanumericMatches.length < 3) {
    return "Issuer name must be at least 3 characters long.";
  }

  if (trimmedName.length > 12) {
    return "Issuer name must not exceed 12 characters.";
  }

  return "";
};

