import { useRecoilState, useRecoilValue } from "recoil";

import NewModal from "@components/new-modal/new-modal";
import { isPrivateModalState } from "@states/user";
import { AssetDetail, Main } from "@views/exchange";
import { assestType } from "@views/exchange/constants";

import { ActiveCurrencyState } from "states/exchange";

export const PrivateModal = () => {

  const [editPrivate,setEditPrivate] = useRecoilState(isPrivateModalState);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);

  return (
    <>
      {editPrivate && (
        <div className="">
          <NewModal
            isOpen={editPrivate}
            modalName={"Update email address"}
            title=""
            isStopOutsideClick={true}
            closeOnEscBtn={true}
            className=" Modal__kyc Modal__wrapper exchange-modal-wrapper model-title-margin private-modal-captable"
            closeModal={() => setEditPrivate(false)}
            showCloseBtn={true}
          >
            <Main isCaptable={true} />
            <AssetDetail
              isPrivate={activeCurrency?.type === assestType.privates}
              isCaptable={true}
            />
          </NewModal>
        </div>
      )}
    </>
  );
};


