import React, { useCallback, useEffect, useMemo, useState } from "react";
import NewModal from "components/new-modal/new-modal";
import { WalletSelectionCards } from "../WalletSelectionCards";
import {useNetwork } from "hooks";
import { WireVerifyForm } from "./component/WireVerifyForm";
import { SplashScreen } from "../SplashScreen";
import "./deposit.scss";
import { NoBankAccountAdded } from "../NoBankAccountAdded";
import { useRecoilState } from "recoil";
import { AccountState, depositStepState } from "states";
import { DepositFunds } from "../deposit-fund";
import { APIS } from "constant";
import { WireDepositForm } from "./component/wireDepositForm";
import useApplePay from "../apple-pay/use-apple-pay";
import { AmountScreen, CardDetailsScreen } from "./component";
import { useGooglePay } from "../google-pay";
import { Loader } from "@components/Loader2";

export const Deposit = (props: any) => {
  const { onClose, openModal } = props;
  const [formState, setFormState] = useState(0);
  const [moveNextActive, setMoveNextActive] = useState(true);
  const [visibleFooter, setVisibleFooter] = useState(true);
  const [withdrawType, setWithdrawType] = useState("");
  const [accounts, setAccounts] = useRecoilState(AccountState);
  const [isNextButtonLoading, setIsNextButtonLoading]= useState<boolean>(false);
  const [userAmountInfo , setUserAmountInfo]= useState<{
    amount : string, error : boolean
  }>({
    amount : "", error : false
  });
  
  // Hooks 
  const { handleApplePayClick, hasApplePayTransactionError, updateApplePayTransactionError } = useApplePay();
  const { handleGooglePayClick, loading, hasGooglePayTransactionError, updateGooglePayTransactionError } = useGooglePay();


  const [depositState, setDepositState] = useRecoilState(depositStepState);
  const { get: getAccounts, data: accountsList } = useNetwork();

  useEffect(() => {
    if (openModal) {
      setWithdrawType("");
      setFormState(0);
      setIsNextButtonLoading(false)
      setMoveNextActive(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (hasGooglePayTransactionError || hasApplePayTransactionError) {
      setFormState(0);
      setUserAmountInfo({
        amount : "0", error : false
      })
    }
  }, [hasGooglePayTransactionError, hasApplePayTransactionError]);

  useEffect(()=>{
    setIsNextButtonLoading(loading.buttonLoader);
  },[loading.buttonLoader])

  useEffect(() => {
    if (accountsList?.data) {
      setAccounts(accountsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);

  useEffect(() => {
    if (accounts.length === 0) getAccounts(APIS.Accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (withdrawType === "bank" && accounts.length > 0) {
      setFormState(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts?.length]);

  const handleValueChange = useCallback((amount: string , isError: boolean)=>{
    setUserAmountInfo({
      amount : amount, error : isError
    })
  },[])


  const handleClose = useCallback(
    (closeModal?: boolean) => {
      if (closeModal) {
        onClose();
        return;
      } else {
        setUserAmountInfo({ amount: "0", error: false });
        setMoveNextActive(true);
        setFormState(0);
        setVisibleFooter(false);
      }
    },
    [onClose]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const WithdrawForm: any = {
    wire: {
      forms: [
        {
          key: "wireDepositForm",
          heading: "Wire transfer detail",
          subHeading:
            "Please wire transfer money to the given account details below.",
          component: <WireDepositForm onClose={onClose}/>,
        },
        {
          heading: "Verify wire details",
          subHeading: "some text",
          component: <WireVerifyForm />,
        },
        {
          heading: "",
          subHeading: "",
          component: <SplashScreen />,
        },
      ],
    },
    bank: {
      forms: [
        {
          key: "noBankAdded",
          heading: "",
          subHeading: "",
          component: <NoBankAccountAdded />,
        },
        {
          heading: "",
          subHeading: "",
          component: <DepositFunds infoMessage="Note : Fee: $0.25 per transaction " handleCloseDeposit={onClose} />,
        },
      ],
    },
    apple_pay: {
      forms: [
        {
          key: "applePayAmountInput",
          heading: "Deposit details",
          subHeading:
            "Enter the amount you wish to deposit (up to $10,000 per transaction).",
          infoMessage : "Note : Fee: $0.25 per transaction ", 
          component: <AmountScreen methodType={withdrawType} onAmountChange={handleValueChange}  />,
        },        
      ],
    },
    google_pay: {
      forms: [
        {
          key: "applePayAmountInput",
          heading: "Deposit details",
          subHeading:
            "Enter the amount you wish to deposit (up to $2,000 per transaction).",
            infoMessage : "Note : Fee: $0.25 per transaction ",
          component: <AmountScreen loading={loading.bodyLoader} methodType={withdrawType}  onAmountChange={handleValueChange}  />,
        },        
      ],
    },
    credit_card: {
      forms: [
        {
          key: "creditCardPayAmountInput",
          heading: "Deposit details",
          subHeading:
            "Enter the amount you wish to deposit (up to $10,000 per transaction).",
            infoMessage : "Note : Fee: $0.25 per transaction ",
          component: <AmountScreen methodType={withdrawType}  onAmountChange={handleValueChange}  />,
        },  
        {
          heading: "Enter your card details",
          subHeading: "Securely enter your payment information",
          component: <CardDetailsScreen amount={userAmountInfo?.amount} handleCloseDeposit={handleClose} />,
        },       
      ],
    },
    debit_card: {
      forms: [
        {
          key: "debitCardPayAmountInput",
          heading: "Deposit details",
          subHeading:
            "Enter the amount you wish to deposit (up to $10,000 per transaction).",
            infoMessage : "Note : Fee: $0.25 per transaction ",
          component: <AmountScreen methodType={withdrawType}  onAmountChange={handleValueChange}  />,
        },  
        {
          heading: "Enter your card details",
          subHeading: "Securely enter your payment information",
          component: <CardDetailsScreen amount={userAmountInfo?.amount} handleCloseDeposit={handleClose} />,
        },       
      ],
    }
  };

  useEffect(() => {
    const formKey = WithdrawForm[withdrawType]?.forms.find((item: any) =>
      ["noBankAdded", "wireDepositForm"]?.includes(item?.key)
    );
    if (formKey && formState > 0) {
      setVisibleFooter(false);
    } else {
      setVisibleFooter(true);
    }
  }, [WithdrawForm, withdrawType, setVisibleFooter, moveNextActive, formState]);

  const setForm = () => {
    if (!withdrawType) return;
    if (
      formState !== 0 &&
      (withdrawType === "apple_pay" || withdrawType === "google_pay")
    ) {
      if (withdrawType === "apple_pay") {
        updateApplePayTransactionError(false);
        updateGooglePayTransactionError(false);
        handleApplePayClick(userAmountInfo.amount, handleClose);
      } else {
        updateApplePayTransactionError(false);
        updateGooglePayTransactionError(false);
        handleGooglePayClick(userAmountInfo.amount, handleClose);
      }
      return;
    }
    const currentFormLength = WithdrawForm?.[withdrawType]?.length;

    if (withdrawType === "bank" && accounts.length > 0) {
      setFormState((prev) => prev + 2);
      return;
    }
    if (currentFormLength - 1 === formState) {
      setMoveNextActive(false);
    }
    
    setFormState((prev) => prev + 1);
    if((withdrawType === 'credit_card' || withdrawType === 'debit_card') && formState === 1){
      setVisibleFooter(false)
      setMoveNextActive(false);
    }
  };

  const getForm = useCallback(() => {
    const currentFormData =
      WithdrawForm?.[withdrawType]?.forms?.[formState - 1];
    const { heading, subHeading, component, infoMessage = "" } = currentFormData;

    if (heading === "") {
      return <div className="wallet-container-body">{component}</div>;
    } else {
      return (
        <>
          <div className="wallet-modal-container-header">
            <p className="heading">{heading}</p>
            <p className="sub-heading">{subHeading}</p>
            <p>{infoMessage}</p>
          </div>
          <div className="wallet-container-body">{component}</div>
        </>
      );
    }
  }, [WithdrawForm, formState]);  

  const closeModal = useCallback(() => {
    if (
      formState !== 0 &&
      (withdrawType === "apple_pay" || withdrawType === "google_pay")
    ) {
      setUserAmountInfo({ amount: "0", error: false });
      setFormState(0);
      return;
    } else {
      onClose();
    }
    
    setDepositState("amount");
  }, [formState, onClose, setDepositState, withdrawType]);


  const selectedForm: any = useMemo(() => {
    switch (formState) {
      case 0:
        return (
          <>
            <div className="wallet-modal-container-header">
              <p className="heading">Select a deposit method</p>
              <p className="sub-heading">
                Please select your preferred deposit method type.
              </p>
            </div>
            <div className="wallet-container-body">
              {
                <WalletSelectionCards
                  type="deposit"
                  selected={withdrawType}
                  setWithdrawType={setWithdrawType}
                />
              }
            </div>
          </>
        );
      default:
        return getForm();
    } 
  }, [formState, getForm, withdrawType]); 

  const getDisbledValue = useMemo(() => {
    if (withdrawType === "bank" || withdrawType === "wire") {
      return false;
    } else if (isNextButtonLoading) {
      return true;
    } else if (formState !== 0) {
      return !(parseFloat(userAmountInfo.amount) > 0) || userAmountInfo.error;
    }
  }, [
    formState,
    isNextButtonLoading,
    userAmountInfo.amount,
    userAmountInfo.error,
    withdrawType,
  ]);

  const renderLabel = useMemo(()=>{
    return loading.buttonLoader ? <Loader dimension={20}  /> : "next"
  },[loading.buttonLoader])

  return (
    <NewModal
      isOpen={openModal}
      modalName="Deposit Modal"
      className={`wallet-modal ${depositState === "success" && "successModal"}`}
      closeModal={closeModal}
      title=""
      showCloseBtn={false}
    >
      <div className="wallet-modal-container">
        <i className="ri ri-close-line close-btn" onClick={closeModal}></i>
        {selectedForm}

        {visibleFooter && moveNextActive && (
          <div className="wallet-modal-footer">
            <button className="btn-secondary" onClick={closeModal}>
              Cancel
            </button>
            <button
              className={`btn-primary ${!withdrawType ? "disabledBtn" : ""}`}
              onClick={setForm}
              disabled={getDisbledValue}
            >
              {renderLabel}
            </button>
          </div>
        )}
      </div>
    </NewModal>
  );
};
