import { useRecoilValue, useSetRecoilState } from "recoil";

import { WithdrawAmountState, depositStepState } from "states";
import { capitalizeFirstLetterOnly, useFormatNumber } from "utils";
import { useCurrency } from "hooks";

import styles from "./withdraw.module.sass";
import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

export const WithdrawSuccess = ({
  transactionID,
  status,
  handleCloseDeposit,
  transactionHash,
  id,
}: any) => {
  const WithdrawAmount = useRecoilValue(WithdrawAmountState);
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();

  const setDepositState = useSetRecoilState(depositStepState);

  useEffect(() => {
    return () => {
      setDepositState("amount");
    };
  }, [setDepositState]);

  const getTransactionDetails = () => {
    handleCloseDeposit();
    navigate(`/wallet/transactions/${id}`);
  };

  const renderMessage = useMemo(() => {
    switch (status) {
      case "SUCCESSFUL":
        return (
          <div>
            You successfully withdrawal{" "}
            <span className={styles.highlightedAmount}>
              {currencySymbol}
              {Number(WithdrawAmount).toFixed(2)}
            </span>{" "}
            from your wallet.
          </div>
        );
      case "PROCESSING":
        return (
          <div>
            We are processing your{" "}
            <span className={styles.highlightedAmount}>
              {currencySymbol}
              {Number(WithdrawAmount).toFixed(2)}
            </span>{" "}
            withdrawal request. Please wait for a while as this may take some
            time.
          </div>
        );
      case "FAILED":
        return "Your withdrawal request could not be processed at this time. Please try again later.";
      default:
        return "";
    }
  }, []);

  return (
    <div className={styles.transactionSuccess}>
      <div className={styles.successSubHead}>
        {status === "SUCCESSFUL"
          ? "Yay! 🎉"
          : status === "PROCESSING"
          ? "Processing withdrawal request"
          : "Oops! 😬"}
      </div>
      <div className={styles.successPara}>{renderMessage}</div>

      <div className={styles.transactionDetailsContainer}>
        <div className={styles.transactionDetail}>
          <div className={styles.transactionCol}>
            Status
            <div
              className={classNames(styles.transactionStatus, {
                [styles.transactionStatus__processing]: status === "PROCESSING",
                [styles.transactionStatus__success]: status === "SUCCESS",
                [styles.transactionStatus__failed]: status === "FAILED",
              })}
            >
              {capitalizeFirstLetterOnly(status)}
            </div>
          </div>
          <div className={styles.transactionCol}>
            Transaction ID
            <div className={styles.transactionId}>{transactionID}</div>
          </div>
        </div>
        {status === "SUCCESS" && (
          <div>
            <div className={styles.transactionCol}>
              Transaction hash
              <div className={styles.transactionId}>
                {transactionHash?.polygonData?.transaction?.hash}
                <i
                  className="ri-share-box-fill"
                  onClick={() => {
                    if (transactionHash?.transactionHash !== null)
                      window.open(
                        `${transactionHash?.polygonData?.transaction?.url}`,
                        "_blank"
                      );
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.transactionSuccess__footer}>
        <button
          className={styles.transactionSuccess__footer__cancel}
          onClick={handleCloseDeposit}
        >
          Close
        </button>
        <button
          className={styles.transactionSuccess__footer__view}
          onClick={getTransactionDetails}
        >
          {status === "FAILED" ? "Try Again" : "View transaction"}
        </button>
      </div>
    </div>
  );
};
