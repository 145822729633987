import { AppConfig } from "./types";

export const mapApiResponseToAppConfig = (apiResponse: any): AppConfig => {
  const branding = apiResponse.configuration.branding;
  
  const tabs = apiResponse?.configuration?.features.map((tab: any) => ({  
    name: tab.name,
    permission: tab?.permissions,
    subFeatures: tab?.subFeatures?.map((subFeature: any) => ({
      name: subFeature?.name,
      permissions: {
        view: subFeature?.permissions?.view || false,
        edit: subFeature?.permissions?.edit || false,
        create: subFeature?.permissions?.create || false,
        delete: subFeature?.permissions?.delete || false,
      },
    })),
  }));

  return {
    branding: {
      color: {
        primary: branding?.color?.primary || null,
        secondary: branding?.color?.secondary || null,
      },
      banner: branding?.banner || null,
      colorScheme: branding?.colorScheme || null,
      contactDetials: branding?.contactDetials || null,
      desclaimer: branding?.desclaimer || null,
      logo: branding?.logo || null,
    },
    blockchain: apiResponse?.blockchain,
    disclaimer: apiResponse?.disclaimer,
    supportEmail: apiResponse?.supportEmail,
    isAccountStepUp: apiResponse?.isAccountStepUp,
    tabs,
    name: apiResponse?.name,
  };
};
