import { useMobileAuthentication } from "@views/SignIn/hooks/useMobileAuthentication";
import { useSearchParams } from "react-router-dom";
import { useNotification } from "@hooks/notification";
import { useMemo, useState } from "react";
import { ErrorIcons } from "../status";
import { imageConfig } from "@utils/imageConfig";

import "./mobile-approve.scss";

export const MobileApprove = () => {
  const [searchParams] = useSearchParams();
  const { errorNotification } = useNotification();
  const { mobileLinkAction } = useMobileAuthentication();
  const [linkExpired, setLinkExpired] = useState(false);
  const [linkApproved, setLinkApproved] = useState(false);
  const appendValueToString = (appendString: string, actualValue: string) => {
    return appendString + actualValue.trim();
  };

  const {svg: {approve}, gif: {success}} = imageConfig;

  const handleClickAction = async (action: string) => {
    const code =
      searchParams.get("loginCode") ?? searchParams.get("biometricCode");
    const phone = searchParams.get("phone");
    const country = searchParams.get("countryCode");
    let countryCode = appendValueToString("+", country ?? "");

    if (code && phone && countryCode) {
      const payload = {
        type: "phoneCode",
        code: code,
        choice: action,
        countryCode: countryCode,
        phone: phone,
      };
      const response = await mobileLinkAction(payload);
      if (!response) {
        setLinkExpired(true);
      } else {
        if (action === "reject") {
          setLinkExpired(true);
        } else {
          setLinkApproved(true);
        }
      }
    } else {
      errorNotification("Invalid code");
    }
  };

  const renderSuccessScreen = useMemo(() => {
    return (
      <div className="mobile-link">
        <div className="mobile-verification__success">
          <img loading="lazy" src={success} alt="" />
          <div className="mobile-verification__success-message">
            Approved successfully
          </div>
        </div>
      </div>
    );
  }, []);

  const renderErrorScreen = useMemo(() => {
    return (
      <div className="mobile-link">
        <div className="mobile-verification__error">
          <div className="mobile-verification__svg-height">
            <ErrorIcons />
          </div>
          <div className="mobile-verification__success-message">
            Approval denied or link expired
          </div>
        </div>
      </div>
    );
  }, []);

  if (linkExpired) return <>{renderErrorScreen}</>;
  if (linkApproved) return <>{renderSuccessScreen}</>;
  return (
    <div className="mobile-link">
      <div className="mobile-approve">
        <div className="mobile-approve__container">
          <div className="mobile-approve__header">
            Is it you trying to login?
          </div>
          <div className="mobile-approve__image">
            <img
              loading="lazy"
              className="MobileApprove__logo"
              src={approve}
              alt="logo"
            />
          </div>
          <p className="mobile-approve__description">
            Click approve if it is you trying to sign-in to your account
          </p>
          <div className="mobile-approve__actions">
            <button onClick={() => handleClickAction("accept")}>Approve</button>
            <button onClick={() => handleClickAction("reject")}>Deny</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MobileApprove;
