import { Dropdown, Input } from "components";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { AuctionProductCategoryState } from "states";
import { Json } from "types";
import "./auction-basic-form.scss";

import TextArea from "components/TextArea";
import { AuctionBanner } from "../auction-banner";
import { AuctionIcon } from "../auction-image-form";

type IAuctionBasicForm = {
  handleImageInputChange: (file: any, value: string) => void;
  setAuctionFormData: (value: any) => void;
  setAssetTrade: (value: any) => void;
  auctionFormData: Json;
  onChangeHandler: any;
};

const tradeType = [
  {
    label: "Classic",
    value: "classic",
  },
  {
    label: "Dutch",
    value: "dutch",
  },
];

export const AuctionBasicForm = ({
  handleImageInputChange,
  setAuctionFormData,
  setAssetTrade,
  auctionFormData,
  onChangeHandler,
}: IAuctionBasicForm) => {
  const [assetCategoryList, setAssetCategoryList] = useState<any>([]);
  const { data: AuctionDropDownData, loading } = useRecoilValue(
    AuctionProductCategoryState
  );
  const { assetIcon, assetImage } = auctionFormData;
  const [dropDownValue, setDropDownValues] = useState<any>(null);

  useEffect(() => {
    if (!loading && AuctionDropDownData) {
      // set dropdown values
      setDropDownValues(AuctionDropDownData?.data);
    }
  }, [AuctionDropDownData, loading]);

  useEffect(() => {
    if (dropDownValue) {
      // fill the category list
      let newValue = Object.keys(dropDownValue);
      setAssetCategoryList(newValue);
      // if auction form API is not called before the product category API, to prevent override chances
      if (!auctionFormData.id) {
        setAuctionFormData((prev: any) => ({
          ...prev,
          category: newValue[0],
          product: dropDownValue[newValue[0]][0],
        }));
      }
    }
  }, [dropDownValue]);

  const setCategory = (value: string) => {
    setAuctionFormData((prev: any) => ({
      ...prev,
      category: value,
      product: dropDownValue[value][0] ?? "",
    }));
  };

  const setProduct = (value: string) => {
    setAuctionFormData((prev: any) => ({ ...prev, product: value }));
  };

  const setTradeType = (value: string) => {
    setAuctionFormData((prev: any) => ({ ...prev, tradeType: value }));
    setAssetTrade(value);
  };

  return (
    <div className="auction-basic-form">
      <div className="auction-basic-form-flex-container">
        <div className="auction-basic-form-container-left">
          <AuctionBanner
            setAuctionFormData={setAuctionFormData}
            imageType="assetImage"
            icon={assetImage}
            handleChange={(file: any) =>
              handleImageInputChange(file, "assetImage")
            }
          />
        </div>
        <div className="auction-basic-form-container-right">
          <div className="auction-basic-form-container-fields">
          <AuctionIcon
            content="Upload an icon. Max file size 2Mb."
            subContent="Dimension: 300 x 300 pixels"
            imageType="assetIcon"
            icon={assetIcon}
            handleChange={(file: any) =>
              handleImageInputChange(file, "assetIcon")
            }
          />
          <Input
            inputType="text"
            label="Product Name"
            placeholder=""
            value={auctionFormData.name}
            handleChange={onChangeHandler}
            inputName="name"
            required={true}
            pattern="^(?![\s-])([A-Za-z0-9]+\s?)*$"
            maxLength={30}
          />
          <Input
            inputType="text"
            label="Symbol"
            placeholder=""
            value={auctionFormData.symbolValue}
            handleChange={onChangeHandler}
            inputName="symbolValue"
            required={true}
            prefixValue={auctionFormData.symbol}
            prefixValueClassName="symbolPlaceholder"
            maxLength={10}
          />
          </div>
         
        </div>
        <div >
            <TextArea
              className={""}
              inputType="text"
              label="Description"
              placeholder=""
              value={auctionFormData.description}
              onChange={onChangeHandler}
              name="description"
            />
          </div>
      </div>
      <div className="auction-form-selected-container">
        <Dropdown
          className={`asset-dropdown ${
            auctionFormData.id && auctionFormData.isPublished
              ? "disable-dropdown"
              : ""
          }`}
          label="Auction Type"
          value={auctionFormData?.tradeType ?? ""}
          setValue={(value: any) => setTradeType(value)}
          options={tradeType}
        />
        <Dropdown
          className={`asset-dropdown ${
            !assetCategoryList?.length || (auctionFormData.id && auctionFormData.isPublished)? "disable-dropdown" : ""
          }`}
          label="Categories"
          value={auctionFormData?.category ?? ""}
          setValue={(value: any) => setCategory(value)}
          options={assetCategoryList}
        />
        <Dropdown
          className={`asset-dropdown ${
            !dropDownValue?.[auctionFormData?.category]?.length
              ? "disable-dropdown"
              : ""
          }`}
          label="Product"
          value={auctionFormData?.product ?? ""}
          setValue={(value: any) => setProduct(value)}
          options={dropDownValue?.[auctionFormData?.category] ?? []}
        />
      </div>
    </div>
  );
};
