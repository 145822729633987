export const CHART_INTERVALS = {
  DAY: "1D",
  WEEK: "1W",
  MONTH: "1M",
  YEAR: "1Y",
  "5YEAR": "5Y",
  ALL: "ALL"
};

export const CHART_TABS = [
  CHART_INTERVALS.DAY,
  CHART_INTERVALS.WEEK,
  CHART_INTERVALS.MONTH,
  CHART_INTERVALS.YEAR,
  CHART_INTERVALS["5YEAR"],
  CHART_INTERVALS["ALL"],
];

export const mapApiUrl: { [type: string]: string } = {
  stocks: "stock",
  cryptos: "crypto",
  commodities: "Commodity",
  privates: "privates",
  marketIndexes: "marketIndex",
  music: "privates",
  horse_trade: "privates",
  real_estate : "privates",
  crypto : "privates"
};
export const CHART_INTERVALS_TYPE = {
  [CHART_INTERVALS.DAY]: "day",
  [CHART_INTERVALS.WEEK]: "week",
  [CHART_INTERVALS.MONTH]: "month",
  [CHART_INTERVALS.YEAR]: "year",
  [CHART_INTERVALS["5YEAR"]]: "5year",
  [CHART_INTERVALS["ALL"]]: "all",
};

export const navigation = [ "icon/line.svg"];

export const chartDropDown: { [key: string | number]: number } = {
  "1D": 0,
  "1W": 1,
  "1M": 2,
  "1Y": 3,
  "5Y": 4,
  "ALL": 5,
};

export const DUMMY_MUSIC_PERDAY = [
  {
    open: 390.44,
    low: 389.87,
    high: 390.44,
    value: 390.3,
    time: 1685019600000,
    volume: 20126,
  },
  {
    open: 390.27,
    low: 390.27,
    high: 391.38,
    value: 391.08,
    time: 1685019900000,
    volume: 17067,
  },
  {
    open: 391.15,
    low: 391.15,
    high: 392.15,
    value: 391.75,
    time: 1685020200000,
    volume: 19888,
  },
  {
    open: 391.56,
    low: 391.54,
    high: 392.57,
    value: 392.52,
    time: 1685020500000,
    volume: 21287,
  },
  {
    open: 392.49,
    low: 392.35,
    high: 392.85,
    value: 392.83,
    time: 1685020800000,
    volume: 21583,
  },
  {
    open: 392.9076,
    low: 392.7388,
    high: 393.85,
    value: 393.72,
    time: 1685021100000,
    volume: 32889,
  },
  {
    open: 393.72,
    low: 393.3421,
    high: 394.4,
    value: 394.095,
    time: 1685021400000,
    volume: 28138,
  },
  {
    open: 394.14,
    low: 393.08,
    high: 394.39,
    value: 393.507,
    time: 1685021700000,
    volume: 35728,
  },
  {
    open: 393.58,
    low: 392.7,
    high: 393.8374,
    value: 393.41,
    time: 1685022000000,
    volume: 37395,
  },
  {
    open: 393.47,
    low: 392.865,
    high: 393.57,
    value: 393.42,
    time: 1685022300000,
    volume: 41393,
  },
  {
    open: 393.3,
    low: 393.29,
    high: 393.9,
    value: 393.62,
    time: 1685022600000,
    volume: 34018,
  },
  {
    open: 393.71,
    low: 393.54,
    high: 394.73,
    value: 394.695,
    time: 1685022900000,
    volume: 56800,
  },
  {
    open: 394.6701,
    low: 394.67,
    high: 396.1314,
    value: 396.1314,
    time: 1685023200000,
    volume: 65159,
  },
  {
    open: 396.01,
    low: 395.78,
    high: 396.14,
    value: 395.85,
    time: 1685023500000,
    volume: 45438,
  },
  {
    open: 395.87,
    low: 395.78,
    high: 396.63,
    value: 396.58,
    time: 1685023800000,
    volume: 52150,
  },
  {
    open: 396.565,
    low: 396.42,
    high: 396.98,
    value: 396.9001,
    time: 1685024100000,
    volume: 50729,
  },
  {
    open: 397,
    low: 396.98,
    high: 397.61,
    value: 397.42,
    time: 1685024400000,
    volume: 66490,
  },
  {
    open: 397.405,
    low: 396.95,
    high: 397.6515,
    value: 397.46,
    time: 1685024700000,
    volume: 61369,
  },
  {
    open: 397.48,
    low: 395.73,
    high: 397.54,
    value: 396.282,
    time: 1685025000000,
    volume: 93417,
  },
  {
    open: 396.23,
    low: 395,
    high: 396.23,
    value: 395.46,
    time: 1685025300000,
    volume: 76950,
  },
  {
    open: 395.445,
    low: 395.36,
    high: 395.77,
    value: 395.69,
    time: 1685025600000,
    volume: 44776,
  },
  {
    open: 395.69,
    low: 393.83,
    high: 395.77,
    value: 393.84,
    time: 1685025900000,
    volume: 84473,
  },
  {
    open: 393.6702,
    low: 393.0001,
    high: 393.92,
    value: 393.07,
    time: 1685026200000,
    volume: 45155,
  },
  {
    open: 393,
    low: 392.28,
    high: 393.17,
    value: 392.48,
    time: 1685026500000,
    volume: 33698,
  },
  {
    open: 392.4,
    low: 392.08,
    high: 393.23,
    value: 393.23,
    time: 1685026800000,
    volume: 41090,
  },
  {
    open: 393.325,
    low: 393.325,
    high: 394.4363,
    value: 393.455,
    time: 1685027100000,
    volume: 39026,
  },
  {
    open: 393.675,
    low: 393.5624,
    high: 394.22,
    value: 394.2,
    time: 1685027400000,
    volume: 44965,
  },
  {
    open: 394.29,
    low: 393.75,
    high: 394.46,
    value: 394.435,
    time: 1685027700000,
    volume: 55477,
  },
  {
    open: 394.68,
    low: 394.08,
    high: 394.795,
    value: 394.29,
    time: 1685028000000,
    volume: 32712,
  },
  {
    open: 394.47,
    low: 393.59,
    high: 394.47,
    value: 394.16,
    time: 1685028300000,
    volume: 30466,
  },
  {
    open: 393.74,
    low: 392.595,
    high: 393.76,
    value: 392.84,
    time: 1685028600000,
    volume: 62038,
  },
  {
    open: 392.9,
    low: 392.7125,
    high: 393.56,
    value: 393.09,
    time: 1685028900000,
    volume: 67275,
  },
  {
    open: 393.125,
    low: 392.885,
    high: 393.525,
    value: 392.91,
    time: 1685029200000,
    volume: 60968,
  },
  {
    open: 392.685,
    low: 392.26,
    high: 392.82,
    value: 392.31,
    time: 1685029500000,
    volume: 69273,
  },
  {
    open: 391.98,
    low: 391.72,
    high: 392.77,
    value: 392.055,
    time: 1685029800000,
    volume: 105352,
  },
  {
    open: 391.64,
    low: 391.5,
    high: 392.31,
    value: 392,
    time: 1685030100000,
    volume: 173249,
  },
  {
    open: 392.06,
    low: 392.06,
    high: 392.06,
    value: 392.06,
    time: 1685030400000,
    volume: 307982,
  },
];
