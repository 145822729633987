import { useCallback } from "react";
import useDarkMode from "use-dark-mode";
import { useSetRecoilState } from "recoil";

import { useNetwork } from "hooks";
import { APIS } from "constant";
import { Loader } from "components";
import { CANCEL_ORDER, CANCEL_ORDER_BTN } from "./constant";

import styles from "./remove-device.module.sass";

import { linkedDeviceRefresh } from "@states/user";
import { imageConfig } from "@utils/imageConfig";

export const RemoveDevice = ({device, closeRemoveModal}: any) => {
  const darkMode = useDarkMode(false);
  const { TITLE, DESCRIPTION, BOLD_DESCRIPTION } = CANCEL_ORDER;
  const { CANCEL_NO, CANCEL_YES } = CANCEL_ORDER_BTN;
  const { patch: removeLinkedDevice, loading: cancelLoading } = useNetwork();
  const setRefreshDeviceList = useSetRecoilState(linkedDeviceRefresh);

  const {svg: {cancelDark, cancelLight}} = imageConfig;
 

  const handleConfirm = useCallback(() =>{
    const payload = {ids: [device?._id],origin: "web" };
    removeLinkedDevice(`${APIS.UsersDeviceLogin}/${device?.userId}`,payload, {apiResponse: true})
    .then(()=>{
      setRefreshDeviceList(prev=> !prev);
      closeRemoveModal();
    })
  },[closeRemoveModal, device?._id, device?.userId]);

  const handleCancel = useCallback(async () => {
    closeRemoveModal();
  }, [closeRemoveModal]);

  return (
    <div className={styles.confirmationModal}>
      <div className={styles.confirmationHeader}>
        <img
          src={darkMode.value ? cancelDark : cancelLight}
          alt=""
          className={styles.confirmationImage}
        />
        <div className={styles.confirmationTitle}>{TITLE}</div>
      </div>
      <div>
        <div className={styles.modalDes}>
          <span>
            {DESCRIPTION} <strong>{BOLD_DESCRIPTION}</strong>
          </span>
        </div>
        <div className={styles.modalFooter}>
          <button
            className={styles.buttonCancel}
            onClick={handleCancel}
            disabled={cancelLoading}
          >
            {CANCEL_NO}
          </button>
          <button
            className={styles.buttonCancelYes}
            onClick={handleConfirm}
            disabled={cancelLoading}
          >
            {!cancelLoading ? (
              CANCEL_YES
            ) : (
              <Loader className="loader-white" dimension={26} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
