import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNetwork, useNotification } from "hooks";
import { APIS } from "constant";

import { Loader } from "components";
import {
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { AccountState, DisplayUserState, PlaidTokenState, depositStepState, userPersonalDetails } from "states";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

import "./no-bank-account-added.scss";
import { useAppPlaidLink } from "@views/account/components/AccountBalances/plaidLinkHook";

export const NoBankAccountAdded = ({ isVisibleStaticUI = true, depositState = "amount" }) => {
  const setToken = useSetRecoilState(PlaidTokenState);
  const [loader, setLoader] = useState<boolean>(false);
  const { post: generateToken, data: tokenResponse, loading: tokenloading, statusCode: tokenStatusCode, setStatusCode:setApiStatusCode } = useNetwork();
  const { post: submitExchangeToken } = useNetwork();
  const { get: getAccounts, loading, data: accountsList } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const [accounts, setAccounts] = useRecoilState(AccountState);
  const setDepositState = useSetRecoilState(depositStepState);
  const displayUser = useRecoilValue<any>(DisplayUserState);
  const { trackEvent } = useFullStoryTrackEvent();
  const personalDetails = useRecoilValue(userPersonalDetails);
  const { open, acccountGetLoading: loadingAfterAccountAdd, bankProceedLoader } = useAppPlaidLink();

  const handleAdd = useCallback(() => {
    const payload = {
      language: "en",
      countryCodes: ["US"],
    };
    trackEvent("ADD_NEW_BANK", {
      ...payload
    });
    if (!personalDetails?.accounts?.length) {
      errorNotification(
        "Once your account verification is approved, you can add a bank account"
      );
      return;
    }
    generateToken(APIS.GenerateLinkToken, payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tokenStatusCode === 400 && displayUser?.type === "Authorised User") {
      errorNotification("As an authorised user, you are not allowed to add new bank account.");
    }
    if (tokenStatusCode === 401) {
      errorNotification("Logged in required");
    }
    setLoader(tokenloading);
    setApiStatusCode(null);
  }, [tokenloading, errorNotification, displayUser?.type, setApiStatusCode, tokenStatusCode]);

  useEffect(() => {
    if (accountsList) {
      setAccounts(accountsList?.data);
    }
  }, [accountsList, setAccounts]);

  useEffect(() => {
    if (tokenResponse?.token) {
      setToken(tokenResponse?.token);
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tokenResponse?.token]);

  const renderStaticUI = () => {
    return (
      <Fragment>
        <span>
          <i className="ri ri-bank-fill"></i>
        </span>
        <p className="account-added">No bank account added</p>
        <p className="deposit-amount">Add bank account to deposit amount to account.</p>
      </Fragment>
    );
  };

  return (
    <div className="screen-container">
      {isVisibleStaticUI && renderStaticUI()}
      <button className="add-new-button" onClick={handleAdd} disabled={loadingAfterAccountAdd || bankProceedLoader}>
        {loader ? <Loader dimension={30} /> : "Add New Bank Account"}
      </button>
    </div>
  );
};
