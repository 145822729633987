import { formatFileSize } from "utils";
import { FILE_EXTENSION_URL } from "constant";
import { EditDataRoomFileNameModal } from "./components/createAssetModal";
import { fileFormat } from "./constant";
import { useDataRoom } from "./useDataRoom";
import { useEffect } from "react";
import { imageConfig } from "@utils/imageConfig";

import "./dataroom.scss";
// @ts-ignore
import { Json } from "@types/common";

type TDataRoom = {
  allowFilesRearrange: boolean;
  allowFilesRename: boolean;
  dataRoomFilesRes: any;
  editId: string;
  editFolderid: string;
  onSuccess: (files: any) => void;
  className?: string;
  multipleFiles?: boolean;
  dataRoomApi?: string;
  payloadForSignUrl?: Json;
};

export const DataRoom = ({
  dataRoomFilesRes,
  allowFilesRearrange,
  allowFilesRename,
  editId,
  editFolderid,
  onSuccess,
  className,
  multipleFiles = true,
  dataRoomApi,
  payloadForSignUrl
}: TDataRoom) => {
  const {
    handleOnDrop,
    handleOnChange,
    cancelFileUpload,
    openEditDatafileModal,
    updateDataRoomFile,
    deleteFile,
    getStringAccordingCount,
    onDragStart,
    onDragEnter,
    onDragEnd,
    dataRoomFilesFiltered,
    dataRoomEditState,
    fileInputRef,
    dataRoom,
    resetDataRoomModalState,
  } = useDataRoom({
    dataRoomFilesRes,
    editId,
    editFolderid,
    dataRoomApi,
    payloadForSignUrl
  });

  const { svg: { draggableIcon, user } } = imageConfig;

  useEffect(() => {
    if (dataRoom.files.length) onSuccess(dataRoom);
  }, [dataRoom])

  const getButtonByActions = ({
    id,
    abortController,
    loadingStatus,
    fileName,
  }: any) => {
    if (abortController) {
      return (
        <i
          onClick={() => cancelFileUpload(abortController, id)}
          className="ri ri-close-circle-line"
        />
      );
    } else if (loadingStatus === "cancelled") {
      return <i
        onClick={() => cancelFileUpload(abortController, id, 0)}
        className="ri ri-close-line delete-file"></i>;
    } else {
      return (
        <i
          onClick={() => deleteFile(id, fileName)}
          className="ri ri-delete-bin-6-line delete-file"
        ></i>
      );
    }
  };

  return (
    <>
      <div className={`auction-form-data-room ${className}`}>
        <div className={`data-room-file-container`} onDrop={handleOnDrop}>
          <div className="data-room-content">
            <span className="ri ri-file-upload-line upload-icon"></span>
            <p>Drag and Drop files or <span className="data-room-content-browse">browse</span></p>
            <small>Supported file format: Pdf, CSV, XSL, Docx, and Doc</small>
            <input
              id={"dataroom-file-browse"}
              ref={fileInputRef}
              type="file"
              placeholder="select logo"
              className={"image_browse"}
              accept=".pdf, .docx, .CSV, .xsl, .doc, .xlsx, .xls"
              onChange={handleOnChange}
              multiple={multipleFiles}
            />
          </div>
        </div>
        {dataRoomFilesFiltered.length > 0 && (
          <div className="data-room-files-container">
            <div className="data-room-files-header">
              <p> Files </p>
              <p className="file-count">
                {getStringAccordingCount(dataRoomFilesFiltered.length, "file")}
              </p>
            </div>

            <div className="date-room-files">
              {dataRoomFilesFiltered?.map(
                (
                  {
                    fileName,
                    fileSize,
                    extension,
                    abortController,
                    id,
                    loadingStatus,
                    downLoadingStatus,
                    isSuccessfullyUploaded,
                    isDragging,
                  }: any,
                  index: number
                ) => {
                  return (
                    <>
                      <div
                        key={id}
                        className={`${
                          allowFilesRearrange
                            ? "file-details-gap"
                            : "file-details-no-gap"
                        }`}
                        draggable={allowFilesRearrange ? true : false}
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragEnter={(e) => onDragEnter(e, index)}
                        onDragEnd={(e) => onDragEnd(e, index)}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        {allowFilesRearrange && (
                          <img
                            src={draggableIcon}
                            alt={"icon"}
                            className="pointer-evenet-none"
                          />
                        )}

                        <div className="file-icon-container">
                          <img
                            src={
                              FILE_EXTENSION_URL[fileFormat[extension]] || user
                            }
                            alt={fileName}
                            className="pointer-evenet-none"
                          />
                        </div>
                        <div className="files-description-container">
                          <div className="files-description">
                            <div className="file-names">
                              <p title={fileName}>{fileName}</p> -
                              <small>{formatFileSize(fileSize)}</small>
                            </div>
                            {loadingStatus !== "completed" ? (
                              <span className="file-loading-status">
                                {loadingStatus}
                              </span>
                            ) : (
                              allowFilesRename && (
                                <span
                                  role="button"
                                  className="edit-file-btn"
                                  onClick={() =>
                                    openEditDatafileModal(fileName, id)
                                  }
                                >
                                  Edit Name
                                </span>
                              )
                            )}
                          </div>
                          {!isSuccessfullyUploaded && (
                            <div className="loading-placeholder">
                              <div
                                style={{ width: downLoadingStatus + "%" }}
                                className="loading-percentage"
                              ></div>
                            </div>
                          )}
                        </div>
                        {getButtonByActions({
                          id,
                          abortController,
                          loadingStatus,
                          fileName,
                        })}
                      </div>
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
      {allowFilesRename && dataRoomEditState.modalVisible && (
        <EditDataRoomFileNameModal
          showModal={dataRoomEditState.modalVisible}
          data={dataRoomEditState}
          files={dataRoom?.files}
          updateDataRoomFile={updateDataRoomFile}
          hideAuctionModalOnClick={resetDataRoomModalState}
        />
      )}
    </>
  );
};
