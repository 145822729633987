import { Button, Input } from "components";
import { DataFilter } from "components/data-filter";
import "./filter.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { SelectedAuctionState, AuctionListState, GroupBulkState } from "states";
import { ReactSwitch } from "@storybook";
import { useState } from "react";

export const MyFilter = ({
  showAuctionModalOnClick,
  inputValue,
  handleInputChange,
  data,
  filters,
  handleFilter,
  onReset,
}: any) => {
  const [selectedAuction, setSelectedAuction] =
    useRecoilState(SelectedAuctionState);
  const [groupBulk, setGroupBulk] = useRecoilState(GroupBulkState);

  const change = (value: string) => {
    handleInputChange(value);
  };

  const { loading } = useRecoilValue(AuctionListState);

  return (
    <div className="filter__container_sba">
      <div className="filter__container-search">
        <Input
          placeholder="Search"
          label=""
          inputType="text"
          value={inputValue}
          handleChange={(e) => change(e.target.value)}
          suffixIcon="ri ri-search-line"
        />
        <DataFilter
          selectedValue={filters}
          data={data}
          position="left"
          onChangeFilter={handleFilter}
          onClickReset={onReset}
          isLoading={loading}
        />
        <div className="selected-btn">
          {!!selectedAuction?.length && (
            <>
              <button className="selected-btn__select-count">{`${selectedAuction?.length} Selected`}</button>
              <button className="selected-btn__delet">Delete Selected</button>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="filter__container-create">
          <Button
            label="New Auction Listing"
            type=" asset-btn"
            buttonType="button"
            handleClick={showAuctionModalOnClick}
          />
        </div>
        <div className="group-bulk">
          Group Bulk Uploads
          <ReactSwitch
            checked={groupBulk}
            onColor="#3772FF"
            handleChange={() => setGroupBulk(!groupBulk)}
          />
        </div>
      </div>
    </div>
  );
};
