import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "../container";
import { WalletHeader } from "../WalletHeader";
import { useCurrency, useNetwork, useNotification } from "hooks";
import { useParams } from "react-router-dom";
import { Loader } from "components";
import { APIS } from "constant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";

import "./transaction-details.scss";
import { formatDateWithCustomFormat } from "@utils/getTime";
import { formatNumberWithCommas } from "@utils/format-number";


export const TransactionDetails = () => {
  const params = useParams();
  const { formatCurrencyWithBillion } = useCurrency();
  const {get: getTransactionDetails, loading: transactionLoader } = useNetwork();
  const [initiatorDetail, setInitiatorDetail] = useState<any>()
  const { trackPageEvent } = useFullStoryTrackEvent();
  const [transactionDetailsData, setTransactionDetails] = useState<any>([]);
  const { errorNotification } = useNotification();
 
  const transactionDetails = useMemo(() => {
    if(transactionDetailsData[0]) {
     return transactionDetailsData[0] || {}
    } 
  } , [transactionDetailsData]) 
  
  useEffect(() => {
     if (params.id) {
      getTransactionDetails(`${APIS.transactionDetails}/${params?.id}`).then((res) => {
        if (res.message === 'ok') {
          setTransactionDetails(res?.data)
          setInitiatorDetail(res?.data[0]?.coOwnerData);
        }
        else{
          errorNotification('We are getting an unexpected error')
        }
      }).catch((err: any) => {
        console.error(err?.message)
      })
    }
    trackPageEvent({
      pageName: "TRANSACTION_DETAIL",
    });
  }, [params?.id]);

  
  const capitalizeFirstLetterOnly = useCallback((input: string): string => {
    if (input?.length === 0) {
      return input;
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1).toLowerCase();
  }, []);

  const data = useMemo(() => {
    if(transactionDetails){
      return {
        totalAmount:
          transactionDetails?.flow === "incoming"
            ? parseFloat(
                (
                  parseFloat(transactionDetails?.amount) +
                  transactionDetails?.tax?.taxAmount
                )?.toFixed(2)
              )
            : parseFloat(
                (
                  parseFloat(transactionDetails?.amount) -
                  transactionDetails?.tax?.taxAmount
                )?.toFixed(2)
              ),
        transactionType: `${capitalizeFirstLetterOnly(
          transactionDetails?.type
        )}`,
        intendedTransaction: transactionDetails?.intendedTransaction,
        transactionStatus: transactionDetails?.status,
        details: [
          {
            label: "Transaction ID",
            value: transactionDetails?.transactionId,
          type: "string",

          },
          {
            label: "Time & Date",
            value: formatDateWithCustomFormat(transactionDetails?.createdAt, {}),
            type: "string"
,
          },
          {
            label: "bank",
            value: transactionDetails?.bankName?.length
              ? transactionDetails?.bankName
              : "--",
            type: "string",
          },
          {
            label: "account number",
            value: `********${transactionDetails?.accountNumber}`,
            type: "string",
          },
          {
            label: `Total amount ${transactionDetails?.type}`,
            value: transactionDetails?.amount 
                    ? `$${formatNumberWithCommas(Number(transactionDetails.amount))}` 
                    : "$0",
            type: "string",
          },
          // {
          //   label: "Time & Date",
          //   value: allTranstions ? getDateWithTime(transactionDetails?.createdAt) : 0,
          //   type: "string"
          // },
          {
            label: `fee (${transactionDetails?.tax?.taxPercent}%)`,
            value: transactionDetails?.tax?.taxAmount,
            type: "price",
          },
          {
            label: "Transaction hash",
            value: (
              <span className="transactionHashContainer">
                <span
                  className="transactionHash"
                  data-tooltip-id={transactionDetails?.transactionId}
                >
                  {transactionDetails?.polygonData?.transaction?.hash}
                </span>
                <ReactTooltip
                  id={transactionDetails?.transactionId}
                  place="bottom"
                  content={transactionDetails?.polygonData?.transaction?.hash}
                  style={{
                    zIndex: 1000,
                    color: "#ffffff",
                  }}
                />
                <i
                  className="ri-share-box-fill transactionHashIcon"
                  onClick={() => {
                    if (transactionDetails?.transactionHash !== null)
                      window.open(
                        `${transactionDetails?.polygonData?.transaction?.url}`,
                        "_blank"
                      );
                  }}
                />
              </span>
            ),
            type: "string",
          },
          {
            label: "Transaction done by",
            value: (
              <span className="transactionBy" style={{ height: "10px" }}>
                {initiatorDetail?.firstName + " " + initiatorDetail?.lastName ??
                  "--"}
              </span>
            ),
            type: "string",
          },
          
        ],
      };
    }
   
  }, [capitalizeFirstLetterOnly, initiatorDetail?.firstName, initiatorDetail?.lastName, transactionDetails?.accountNumber, transactionDetails?.amount, transactionDetails?.bankName, transactionDetails?.flow, transactionDetails?.polygonData?.transaction?.hash, transactionDetails?.polygonData?.transaction?.url, transactionDetails?.status, transactionDetails?.tax?.taxAmount, transactionDetails?.tax?.taxPercent, transactionDetails?.transactionHash, transactionDetails?.transactionId, transactionDetails?.type]);

  const computedStatus = data?.intendedTransaction && data?.transactionStatus === "CANCELLED" ? "AUTOCANCELLED" : data?.transactionStatus;
  const computedStatusLabel = capitalizeFirstLetterOnly(computedStatus === "AUTOCANCELLED" ? "Expired" : computedStatus);

  const dataFilter = useMemo(() => {
    if (transactionDetails?.polygonData?.transaction?.hash) {
      return data;
    } else {
      const filteredDetails = data?.details.filter((detail: { label: string; }) => detail.label !== "Transaction hash");
      const filteredData = { ...data, details: filteredDetails };
      return filteredData;
    }
  }, [data, transactionDetails?.polygonData?.transaction?.hash]);

  const getValue = (value: string | number, type: string) => {
    if (type === "string") {
      return value;
    } else if (type === "price") {
      return formatCurrencyWithBillion(value, 2);
    }
  };

  const getTransactionIcon: any = {
    SUCCESS: "ri-checkbox-circle-fill",
    PROCESSING: "ri-time-fill",
    FAILED: "ri-error-warning-fill",
    CANCELLED: "ri-close-circle-fill",
    AUTOCANCELLED: "ri-close-circle-fill",
  };

  const getActionBtnValue: any = {
    PROCESSING: "cancel withdrawl",
    FAILED: "retry",
  };

  const title = useMemo(() => {
    if (data?.transactionType) {
      return `${capitalizeFirstLetterOnly(data?.transactionType)} Detail`;
    }
    return;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  }, [capitalizeFirstLetterOnly, data?.transactionType]);

  return (
    <Container>
      <div className="transaction-wrapper">
        <WalletHeader title={title} />
        {!transactionLoader && transactionDetails?.transactionId ? (
          <div className="transaction-details-main">
            <div className="transaction-details-main-status-info">
              <p className="status-type">Amount</p>
              <p className="amount">
                {formatCurrencyWithBillion(data?.totalAmount ?? 0, 2)}
              </p>
              <span
                className={`transaction-status ${computedStatus} `}
              >
                <i
                  className={`ri ${
                    getTransactionIcon[computedStatus]
                  } `}
                ></i>
                <p>{computedStatusLabel}</p>
              </span>
            </div>
            <div className="transaction-details-body">
              {dataFilter?.details &&
                dataFilter?.details?.map((items: any) => {
                  return (
                    <div key={items?.label} className="transaction-panel">
                      <small>{items?.label}</small>
                      <span style={{ color: "#878C99" }}></span>
                      <p>{getValue(items?.value, items?.type)}</p>
                    </div>
                  );
                })}
            </div>
            {/* Todo: Enable it after when filtered data comes from backend */}
            {/* {data?.details && (data?.transactionStatus === "PROCESSING" ||
          data?.transactionStatus === "FAILURE") && (
            <div className="transaction-status-button">
              <button className={`status-btn ${data?.transactionStatus} `}>
                {getActionBtnValue[data?.transactionStatus]}
              </button>
            </div>
          )} */}
          </div>
        ) : (
          transactionLoader ? <Loader /> : null
        )}
      </div>
    </Container>
  );
};
