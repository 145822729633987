import { useRecoilValue } from "recoil";

import { userDropDownState } from "states";

import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "routes";
import { Transactions } from "views/MyWallet/conponent/transactions";
import { TransactionDetails } from "views/MyWallet/conponent/TransactionDetails";
import MyPortfolio from "./MyPortfolio";
import { Accounts } from "@views/account/components";

export const MyPortfolioRoutes = () => {

  const isActiveUser = useRecoilValue(userDropDownState);

  const {  ACCOUNTS, TRANSACTIONS, TRANSACTION_DETAILS } =
    ROUTES;


  let isAuthorized = useMemo(() => {
    const dropDownLocalHost = JSON.parse((localStorage.getItem("dropdown") as string));  
    if (!dropDownLocalHost.isPrimary) return false;
    else return true;

  }, [isActiveUser.isPrimary])
  

  const routing = useMemo(() => {
    const commonRoutes = (
      <Routes>
        <Route path={"/"} element={<MyPortfolio />} />
        <Route path={TRANSACTIONS} element={<Transactions />} />
        <Route path={TRANSACTION_DETAILS} element={<TransactionDetails />} />
        <Route path={ACCOUNTS} element={<Accounts />} />
        <Route path={"*"} element={<Navigate to={ROUTES.WALLET} />}/>
        {/* Add any other co-owner/authorized-user routes here */}
      </Routes>
    );

    const authorizedRoutes = (
      <Routes>
        <Route path={"/"} element={<MyPortfolio />} />
        <Route path={ACCOUNTS} element={<Accounts />} />
        <Route path={TRANSACTIONS} element={<Transactions />} />
        <Route path={TRANSACTION_DETAILS} element={<TransactionDetails />} />
        <Route path={"*"} element={<Navigate to={ROUTES.WALLET}/>}/> 
      </Routes>
    );

    return isAuthorized ? authorizedRoutes : commonRoutes;
  }, [ACCOUNTS, TRANSACTIONS, TRANSACTION_DETAILS, isAuthorized]);

  return (
    <>
      {routing as any}
    </>
  );
};

export default MyPortfolioRoutes
