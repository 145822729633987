import React, { useCallback, useEffect } from 'react'
import { SkeletonWallet } from '@components/skeleton-loader'
import { FILE_EXTENSION_URL } from 'constant'
import classNames from 'classnames'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { formatFileSize } from '@utils/common'

import styles from "./manageRemovalRequest.module.sass"
import { RemovalRequestFilesState, UseAuthorisedUser } from '../../store';
import { useRecoilValue } from 'recoil';
import { getFormattedDate } from '@utils/getTime';
import { imageConfig } from '@utils/imageConfig';


export const ManageRemovalRequest = ({ id }: any) => {
    const removalFiles = useRecoilValue<any>(RemovalRequestFilesState);
    const {svg: {view}} = imageConfig;
    
    const { getCoownersDocument, removalFilesLoading } = UseAuthorisedUser()

    useEffect(() => {
        getCoownersDocument(id)
    }, [getCoownersDocument, id])

    const handleFileView = useCallback((fileName: any) => {
        window.open(fileName, "_blank");
    }, [])

    return (
        <div className={styles.manage_removalContainer}>
            <div className={styles.manage_removal}>Manage Removal Request</div>
            <div className={styles.manage_removalSection}>
                <p className={styles.manage_removalSection__details}>Details</p>
                <div className={styles.manage_removalSection__dateHeading}>
                    <p>Date:</p>
                    <p ><span className={styles.manage_removalSection__date}>{getFormattedDate(removalFiles?.updatedAt)}</span> (last edited)</p>
                </div>
            </div>
            <div>
                <div className={styles.documentHeader}>
                    <p className={styles.documentHeader__document}>Documents</p>
                    <p className={styles.documentHeader__file}>{removalFiles?.filesData?.length} {removalFiles?.filesData?.length > 1 ? "Files" : "File"}</p>
                </div>
                <div className={classNames(styles.fileSection)}>
                    {removalFilesLoading ? <SkeletonWallet listsToRender={3} /> : removalFiles?.filesData?.map((file: any) =>
                        < div className={styles.fileContainer} >
                            <div className={styles.fileLeft}>
                                <div className={styles.fileLogo}>
                                    <img src={FILE_EXTENSION_URL[file?.docName?.split(".").pop().toLowerCase()]}
                                        alt="file" />
                                </div>
                                <div className={styles.fileDetails}>
                                    <p className={styles.fileDetails__name} data-tooltip-id={file?._id}>{file?.docName}</p>
                                    <ReactTooltip
                                        id={file?._id}
                                        place="bottom"
                                        content={file?.docName}
                                        className={"theme-tooltip"}
                                    />
                                    <span
                                        style={{
                                            width: "4px",
                                            height: "4px",
                                            borderRadius: "4px",
                                            backgroundColor: "#878C99",
                                            display: "inline-block",
                                        }}
                                    ></span>
                                    <p className={styles.fileDetails__size}> {formatFileSize(file?.fileSize)}</p>
                                </div>
                            </div>
                            <div className={styles.fileRight}><button className={styles.viewButton} onClick={() => handleFileView(file?.fileName)}>View <img src={view} alt="view" /></button></div>
                        </div>
                    )}
                </div>

            </div>

        </div >
    )
}
