import { getEnv } from "@utils/common";
import { Env } from "types";

interface IProp {
    hostUrl: string;
}

export class InjectScript {
    static alreadyLoaded = {
        ad: false,
        ma: false
    }
    private readonly env: Env;

    constructor({hostUrl}: IProp) {
        this.env = getEnv(hostUrl);
    }
  
    private readonly addScript = (url: string) => {
      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.src = url;
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
  
    inject = () => {
      const {maUrl, adUrl} = this.sciptUrls();

      if (!InjectScript.alreadyLoaded.ma) {
        InjectScript.alreadyLoaded.ma = true
        this.addScript(maUrl);
      }
      if (!InjectScript.alreadyLoaded.ad) {
        InjectScript.alreadyLoaded.ad = true
        this.addScript(adUrl);
      }
    }
  
    private readonly sciptUrls = () => {
      enum ADScript {
        "local" = "https://api-designer.stage.satschel.com/web-component.stage.js",
        "stage" = "https://api-designer.stage.satschel.com/web-component.stage.js",
        "pp" = "https://api-designer.pp.satschel.com/web-component.pp.js",
        "beta" = "https://api-designer.beta.satschel.com/web-component.beta.js",
        "prod" = "https://api-designer.satschel.com/web-component.js",
      }
      enum MAScript {
        "local" = "http://localhost:3001/web-component.dev.js",
        "stage" = "https://app.stage.liquidity.io/web-component.stage.js",
        "pp" = "https://app.pp.liquidity.io/web-component.pp.js",
        "beta" = "https://app.beta.liquidity.io/web-component.beta.js",
        "prod" = "https://tokens.liquidity.io/web-component.js",
      }
  
      const adUrl = ADScript[this.env];
      const maUrl = MAScript[this.env];
      return {maUrl, adUrl}
    }
}
