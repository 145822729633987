import { getAllUrlParams } from "@utils/url";
import { ONYXPLUS_CLIENT_SECRET, ONYXPLUS_SSO_URL ,ONYXPLUS_OIDC_TOKEN_URL } from "envs";

class OAuthService {
  /**
   * Call this method after you've already obtained the `code` from the popup.
   * 
   * @param {string} code - The authorization code received in the URL after redirect.
   * 
   * @returns {Promise<void>} - Resolves when the token is exchanged successfully.
   */
  async exchangeCodeForToken(code: string): Promise<any> {
    if (!code) {
       return 
    }

    type ONYXPLUSURLMETA = {
        client_id: string
        redirect_uri:string 
    }
      
    const allParams = getAllUrlParams(ONYXPLUS_SSO_URL) as ONYXPLUSURLMETA
    const tokenEndpoint = ONYXPLUS_OIDC_TOKEN_URL;
    const params = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: allParams.client_id,
        code,
        redirect_uri: allParams.redirect_uri,
        client_secret: ONYXPLUS_CLIENT_SECRET
    });
    try {
        const response = await fetch(tokenEndpoint, {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'content-type': 'application/x-www-form-urlencoded',
            },
            body: params.toString()
        });

        const responseData = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP ${response.status}: ${responseData.error_description || 'Unknown error'}`);
        }

        return responseData;
    } catch (error) {
        console.error('❌ Token exchange failed:', error);
        throw error;
    }
}

}

export const oauthService = new OAuthService();


