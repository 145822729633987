import React, { useCallback, useState, FC, useEffect, useMemo } from "react";
import cn from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import OutsideClickHandler from "react-outside-click-handler";

import { ReactModal, SkeletonLogo } from "components";
import { ROUTES } from "routes";
import {
  INIT_CONFIGURATION_SAVE,
  useCookie,
  useIndexedDB,
  useLocalStorage,
  useNetwork,
  useNotification,
} from "hooks";
import {
  LoginPhoneNumberState,
  UsersLoadingState,
  coUserDetailsLoading,
  imageUplaodingState,
  userDropDownState,
  userPersonalDetails,
  userProfileState,
  linkedDeviceState,
} from "states";
import { Image } from "@storybook";

import styles from "./User.module.sass";
import { useNavigate } from "react-router-dom";
import { USER_DETAILS, APIS } from "constant";
import useDarkMode from "use-dark-mode";
import { ITEM, COOWNERS_ITEM } from "../constant";
// @ts-ignore
import { Json } from "@types/common";
import UserAccountModal from "@components/Header/component/UserAccountModal/UserAccountModal";
import AddSwitchAccountModal from "@components/Header/component/AddSwitchAccountModal/AddSwitchAccountModal";
import classNames from "classnames";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

let items = [
  {
    title: "Profile",
    icon: "user",
    content: "Important account details",
    url: "/profile",
  },
// "TODO: We hide the code of Auction and User Bidding section"
  // {
  //   title: "User Bidding",
  //   icon: "auction",
  //   viewBox: "0 0 24 24",
  //   content: "",
  //   url: "/user-bid-list",
  // },
  {
    title: "Co-owner/Authorised User",
    icon: "parent",
    viewBox: "0 0 24 24",
    content: "",
    url: "/profile/authorise-users",
  },
  {
    title: "My Wallet",
    icon: "wallet",
    content: "",
    url: "/profile/wallet",
  },
  // {
  //   title: "2FA security",
  //   icon: "lock",
  //   content: "Setup 2FA for more security",
  //   url: "/2fa",
  // },
  // {
  //   title: "Settings",
  //   icon: "cog",
  //   content: "View additional settings",
  //   url: "/api-keys",
  // },
  {
    title: "Dark mode",
    icon: "theme-dark-moon",
    content: "Switch dark/light mode",
  },
  {
    title: "Admin",
    icon: "admin",
    viewBox: "0 0 24 24",
    url: "/admin/minted/assets",
  },
  {
    title: "Log out",
    icon: "exit",
  },
];

interface IClass {
  className: string;
}

export const User: FC<IClass> = ({ className }) => {
  const navigate = useNavigate();
  const darkMode = useDarkMode(false);

  const [visible, setVisible] = useState(false);
  const [activeProfile, setActiveProfile] = useState(false);
  const { LOGIN, PROFILE } = ROUTES;
  const { deleteCookie } = useCookie();
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const userPersonal = useRecoilValue(userPersonalDetails);
  const [profilePic, setProfilePic] = useRecoilState(userProfileState);
  const { get: getLocalStorage } = useLocalStorage();
  const uploading = useRecoilValue(imageUplaodingState);
  const profileRefresh = useRecoilValue(UsersLoadingState);
  const isActiveUser = useRecoilValue(userDropDownState);
  const coUserLoaoding = useRecoilValue(coUserDetailsLoading);
  const { get: getCookieData } = useCookie();
  const devicelist = useRecoilValue(linkedDeviceState);
  const { patch: removeLinkedDevice } = useNetwork();
  const { clear: clearAssetDataFromDb } = useIndexedDB(INIT_CONFIGURATION_SAVE);
  const [openUserAccountModal, setOpenUserAccountModal] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [showKYCReview, setShowKYCReview] = useState(false);
  const { errorNotification } = useNotification();
  const { config } = usePermissionContext();

  const {svg: {avatar_dark, avatar_light}} = imageConfig;


  useEffect(() => {
    const picture = getLocalStorage(USER_DETAILS)?.["profileImage" as any] ?? "";
    setProfilePic(picture);
  }, [getLocalStorage, setProfilePic]);

  const logoutAction = () => {
    const registrations = JSON.parse(localStorage.exchageAppRegistration || "[]");
    localStorage.clear();
    deleteCookie("userDetails");
    clearAssetDataFromDb();
    // localStorage.setItem("exchageAppRegistration", JSON.stringify(registrations));
    deleteCookie("userDetails");
    window.location.href = LOGIN;
  };

  const onLogout = useCallback(() => {
    //remove all local storage expect but dont want to exchageAppRegistration
    //can not done one by one so keep in one varibale before delelating all localstorage
    //and create again localstorage with same key

    const userDetails = getCookieData("userDetails");
    const currentDeviceDetail = devicelist.find((dev: any) => dev?.deviceToken === userDetails?.token);
    const payload = {
      ids: [currentDeviceDetail?._id],
      origin: "web"
    };
    removeLinkedDevice(`${APIS.UsersDeviceLogin}/${currentDeviceDetail?.userId}`, payload, { apiResponse: true }).then(resp => {
      logoutAction();
    }).catch(err => {
      logoutAction();
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicelist]);

  const handelProfile = useCallback((url: string) => {
    setVisible(false);
    navigate(url);
  }, []);

  const userClickHandler = useCallback(() => {
    // setVisible(!visible);
    setOpenUserAccountModal((prev => !prev));
  }, [visible]);

  const allActiveUsers = useMemo(() => {
    if (Object.keys(isActiveUser || {})?.length > 0 && !isActiveUser?.isPrimary) {
      return COOWNERS_ITEM
    } else {
      return ITEM
    }
  }, [isActiveUser]);

  const handelClickNavRow = useCallback((itemObj: Json) => {
    if (itemObj?.key === "SWITCH_ACCOUNT") {
      setOpenUserAccountModal(true)
    }

  }, []);

  const handleCreateAccount = useCallback(() => {
    setOpenUserAccountModal(false)
    if (!userPersonal?.onboardingData?.isOnboardingComplete ) {
      setShowKYCReview(true)
      return;
    }
    if (!userPersonal?.isVerifiedEmail) {
      errorNotification("Please verify your email to create a new account.")
      return;
    }
    setCreateAccount(true)
  }, [userPersonal?.onboardingData?.isOnboardingComplete, userPersonal?.isVerifiedEmail])

  const handleClose = useCallback(()=>{
    setShowKYCReview(false)
  },[])

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenUserAccountModal(false)}>
      {createAccount && <AddSwitchAccountModal open={createAccount} onClose={() => setCreateAccount(false)} />}

      {showKYCReview && <ReactModal
        visible={showKYCReview}
        onClose={() => setShowKYCReview(false)}
        closeBtn={true}
        outerClassName={classNames(styles.KYCReviewModal)}
      >
        <KycReview 
        handleClose={handleClose} />
      </ReactModal>}

      <div className={cn(className, styles.user, { [styles.active]: visible })}>
      <span
  className={styles.profileName}
  onClick={userClickHandler}
  {...(userPersonal?.email && {
    "data-tooltip-id": "emailTooltip-user",
    "data-tooltip-content": `${userPersonal.email}`,
  })}
>
  {userPersonal?.accountName 
    ? userPersonal?.accountName 
    : userPersonal?.email 
    ? userPersonal?.email 
              : `${config?.name} User`}
</span>

{userPersonal?.email && <ReactTooltip id="emailTooltip-user" place="bottom-end" className="theme-tooltip" style={{
            whiteSpace: "pre-wrap", 
            wordWrap: "break-word", 
            width:"400px",
            overflow:"hidden",
            zIndex:"99999"

          }}/>}
        <button
          className={cn(
            styles.head,
            { [styles.coUserLoading]: coUserLoaoding === true },
          )}
          onClick={userClickHandler}
          disabled={coUserLoaoding}>
          {/* <img src={avatar} alt="Avatar" /> */}
          {/* <Image
            // className={styles.bankLogo}
            fileName={`icon/avatar.svg`}
          /> */}
          {uploading || profileRefresh ? (
              <SkeletonLogo />
            ) :
            <Image
              fileName={darkMode.value ? avatar_dark : avatar_light}
              useFileNameOnFail {...!!profilePic && { url: profilePic }}
              className={styles.brand_logo}
            />
          }
        </button>

        {openUserAccountModal &&
          <UserAccountModal open={openUserAccountModal} onClose={() => setOpenUserAccountModal(false)}
                            onLogout={onLogout} onClickCreateAccount={handleCreateAccount} setShowKYCReview={setShowKYCReview} />}

        {/*<div className={styles.body}>
          <div className={styles.menu}>
            {loginPhoneNumber?.phone && (
              <div className={styles.item_logout}>
                <div className={styles.icon}>
                  <Icon name="laptop" size="20" />
                </div>
                <div className={styles.details}>
                  <div className={styles.line}>
                    <div className={styles.title}>
                      {loginPhoneNumber?.countryCode}
                      {"  "}
                      {loginPhoneNumber?.phone}
                    </div>
                  </div>
                  <div className={styles.content}>{"Logged In By"}</div>
                </div>
              </div>
            )}
            {allActiveUsers.map(
              (item: any, index) => (
                // x.url ? (
                //   <Link
                //     className={styles.item}
                //     to={x.url}
                //     onClick={() => setVisible(!visible)}
                //     key={index}
                //   >
                //     <div className={styles.icon}>
                //       <Icon name={x.icon} size="20" />
                //     </div>
                //     <div className={styles.details}>
                //       <div className={styles.title}>{x.title}</div>
                //       <div className={styles.content}>{x.content}</div>
                //     </div>
                //   </Link>
                // ) : (
                <div key={index}>
                  {item.title === "Admin" && !isAdmin ? (
                      <></>
                    ) :

                    (
                      <>

                        {item.title === "Log out" ? (
                          <>
                            <div className={styles.item_logout} key={index} onClick={onLogout}>
                              <div className={styles.icon}>
                                <Icon name={item.icon} size="18" viewBox={item?.viewBox} />
                              </div>
                              <div className={styles.details}>
                                <div className={styles.line}>
                                  <div className={styles.title}>{item.title}</div>
                                </div>
                                <div className={styles.content}>{item.content}</div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {item.title !== "Dark mode" ? (
                              <>
                                {item.title === "Raise A Concern" ? (
                                  <>
                                    <div className={styles.item_logout} key={index} onClick={handleReportIssues}>
                                      <div className={styles.icon}>
                                         <Icon name={item.icon} size="20" viewBox={item?.viewBox} />
                                        <i className={`ri-bug-line ri-xl ${styles.bug_icon}`}></i>
                                      </div>
                                      <div className={styles.details}>
                                        <div className={styles.line}>
                                          <div className={styles.title}>{item.title}</div>
                                        </div>
                                        <div className={styles.content}>{item.content}</div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className={styles.item_logout}
                                    key={index}
                                    onClick={() => item?.url ? handelProfile(item?.url ?? "") : handelClickNavRow(item)}>
                                    <div className={styles.icon}>
                                      <Icon name={item.icon} size="20" viewBox={item?.viewBox} />
                                    </div>
                                    <div className={styles.details}>
                                      <div className={styles.line}>
                                        <div className={styles.title}>{item.title}</div>
                                      </div>
                                      <div className={styles.content}>{item.content}</div>
                                    </div>
                                  </div>

                                )}

                              </>
                            ) : (
                              <>
                                <div className={styles.item} key={index}>
                                  <div className={styles.icon}>
                                    <Icon name={darkMode?.value ? "theme-dark-moon" : "theme-light"} size="20"
                                          viewBox={item?.viewBox} />
                                  </div>
                                  <div className={styles.details}>
                                    <div className={styles.line}>
                                      <div className={styles.title}>{item.title}</div>
                                      <Theme className={styles.theme} small icon="" />
                                    </div>
                                    <div className={styles.content}>{item.content}</div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                </div>
              ),
              // )
            )}
          </div>
        </div>*/}
      </div>
    </OutsideClickHandler>
  );
};
