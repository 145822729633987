import React, { useCallback, useEffect, useState } from "react";

import "../PlaceBidModal/place-bid-modal.scss";
import { Json } from "../../../../types";
import NewModal from "../../../../components/new-modal/new-modal";
import { Button, CountdownTimer, Loader } from "../../../../components";
import { useAuctionApis, useCurrency, usePrevious } from "../../../../hooks";
import { AuctionBidPostState } from "../../../../states";
import { useRecoilValue } from "recoil";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { formatFileSize, getDateWithTime } from "../../../../utils";
import { Image } from "../../../../@storybook";
import { FILE_EXTENSION_URL } from "../../../../constant";
import useAuctionHook from "@views/AuctionConsumer/hook";
import { imageConfig } from "@utils/imageConfig";

type IBuyNowBidModal = {
  open: boolean
  onClose: () => void
  details: Json
}

export const BuyNowBidModal = ({ open, onClose, details }: IBuyNowBidModal) => {
  const { formatCurrencyWithBillion } = useCurrency();
  const { postAuctionBid } = useAuctionApis();
  const { data: bidPostData = {}, loading } = useRecoilValue(AuctionBidPostState);
  const prevLoading = usePrevious(loading);
  const [showSteps, setShowSteps] = useState(0)
  const { calculateWeightedYieldPer, sbaAndNormalPrice, isSbaLoan } = useAuctionHook(details);

  const {svg: {ArrowTail, no_data_light}, images: {pattern}} = imageConfig
 
  const handleBuyNowBid = useCallback(() => {
    const payload: Json = {
      assetId: details?.assetId,
      auctionId: details?.id,
      bidPrice: details?.buynowPrice,
      isBuyNowPrice: true,
    }
    postAuctionBid(payload);
  }, [details]);

  useEffect(() => {
    if (bidPostData?.message === "ok" && !loading && prevLoading) {
      setShowSteps(2);
    }
  }, [bidPostData, details]);

  const renderDate = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    const classText = !days && !hours && minutes < 10 ? "time-red" : "time-color";
    const time = `${!!days ? days + "D " : ""}${(hours < 10 ? "0" : "") + hours}:${(minutes < 10 ? "0" : "") + minutes}:${(seconds < 10 ? "0" : "") + seconds}`
    return <span className={`${classText}`}>{time}</span>
  }, []);

  const renderWeightedYieldPer = useCallback((value: string | number) => {
    const amount: any = value || 0;
    const avgYieldPer = calculateWeightedYieldPer(details, amount, 6);
    return `Equivalent weighted avg. yield : ${avgYieldPer}%`
  }, [details]);

  return (
    <NewModal isOpen={open} modalName="Place Bid" className="new-react-modal place-bid-modal-container"
              closeModal={onClose} showCloseBtn={!showSteps}>
      {showSteps === 0 && <>
        <div className="bid-card-image">
          <span className="label">{details?.tradeType}</span>
          {!!details?.assetImage?.[0] && <img className="image" src={details?.assetImage?.[0]} alt="asset" />}
          {!details?.assetImage?.[0] && <i className="ri-image-add-line" />}
          <div className="arrow-label">
            {details?.category}
            <img className="arrow-label-img" src={ArrowTail} alt="Assets" />
          </div>
        </div>

        <div className="title-heading-symbol">
          <h2 className="title-heading" data-tooltip-id={details.id}>{details?.name}</h2>
          <span className="title-symbol">{details?.symbol}.<span>{details?.symbolValue}</span></span>
          {details?.name?.length >= 45 &&
            <ReactTooltip className="theme-tooltip" id={details.id} place="top" content={details?.name} />}
        </div>

        <div className={`stats-details row-colon rm-border`}>
          {details?.tradeType === "classic" && <>
            <p className="stat-label">Auction status</p>
            <p className="stat-value">{details?.status?.split("-").join(" ")}</p>
            <p className="stat-label">Starting price</p>
            <div>
              <p className="stat-value">
                {sbaAndNormalPrice(details, {
                  price: details?.startPrice
                })}
              </p>
              {isSbaLoan && <small>
                {formatCurrencyWithBillion(details?.startPrice, 2)}
              </small>}
            </div>
            <p className="stat-label">Auction ending</p>
            <p className="stat-value">{details?.endTime
              ? getDateWithTime(details?.endTime, "EEE, MMM dd, hh:mm a")
              : "--"}</p>
            <p className="stat-label">Time to end</p>
            <p className="stat-value">
              <CountdownTimer dateTime={details?.endTime} renderer={renderDate} /></p>
          </>}
        </div>

        <div className="data-room-section">
          <h2 className="data-room-heading">Data Room</h2>
          <div className="data-room-files edit-history-container">
            {details?.files?.map((file: Json) =>
              <div className="history-file" key={file?.id}>
                <Image className="edit-img" url={FILE_EXTENSION_URL[file?.fileName?.split(".")?.[1]]} />
                <div className="file-name">
                  <span className="file-docname">{file?.docName}</span>
                  <span className="file-size">{formatFileSize(file?.fileSize || 0)}</span>
                </div>
                <Button type="view-btn btn-h-auto" label="View"
                        handleClick={() => window.open(file?.fileUrl, "_blank")} />
              </div>)}
            {!details?.files?.length && <div className="no-data-found">
              <Image fileName={no_data_light} />
              No data available
            </div>}
          </div>
        </div>

        <div className="stats-details">
          <div className="stats-item text-center user-input-bid-container">
            <p className="stat-value fs-24">
              {sbaAndNormalPrice(details, {
                price: details?.buynowPrice,
                labelClass: "stat-label"
              })}
            </p>
            {isSbaLoan && <p className="stat-value">
              {formatCurrencyWithBillion(details?.buynowPrice, 2)}
            </p>}
            {isSbaLoan && <p className="stat-label">{renderWeightedYieldPer(details?.buynowPrice)}</p>}
            <p className="stat-label">Buy Now Price</p>
          </div>
        </div>

        <div className="text-center">
          <Button label="Buy Now" type="button-green buy-btn" handleClick={() => setShowSteps(1)} />
        </div>
      </>}

      {showSteps === 1 && <div className="buy-now-confirmation">
        <div className="confirmation-text mb-30">Are you sure you want to buy it now ?</div>
        <div className="buy-now-image mb-20">
          <i className="ri-money-dollar-circle-line money-icon" />
          <Image fileName={pattern} />
          <div className="buy-now-details">
            <span>
              {sbaAndNormalPrice(details, {
                price: details?.buynowPrice
              })}
            </span>
            {isSbaLoan && <small>
              {formatCurrencyWithBillion(details?.buynowPrice, 2)}
            </small>}
            {isSbaLoan && <small className="fs-14">{renderWeightedYieldPer(details?.buynowPrice)}</small>}
            <small>Buy Now Price</small>
          </div>
        </div>

        <Button label={loading ? <><Loader className="white-transparent" dimension={15} /> Confirm</> : "Confirm"}
                type="button-green buy-btn mb-10" handleClick={handleBuyNowBid} disabled={loading} />
        <Button label="Cancel" type="btn-grey-1 buy-btn" handleClick={() => setShowSteps(0)} />
      </div>}

      {showSteps === 2 && <div className="buy-now-confirmation">
        <Image fileName="icon/successCheck.svg" />
        <div className="confirmation-text mb-20 mt-20">You bought the auction successfully</div>
        <Button label="Close" type="btn-grey-1 buy-btn w-150" handleClick={onClose} />
      </div>}
    </NewModal>
  );
};
