import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { Json } from "types";
import { APIS } from "constant";
import { useCallback } from "react";

export const useSwitchWorkSpace = () => {
  const { get: fetchWorkSpaceUsersApi, loading: workSpaceUsersLoading } = useNetwork();
  const { errorNotification } = useNotification();

  const fetchWorkSpaceUser = useCallback(async (): Promise<Json> => {
    let res
    try {
      res = await fetchWorkSpaceUsersApi(APIS.WORK_SPACE);
      if (res?.message !== "ok") {
        errorNotification(res?.message || "Something went wrong!");
      }
    } catch (e: any) {
      errorNotification(e?.message || "Something went wrong!");
    } finally {
      return res;
    }
  }, []);

  return {
    fetchWorkSpaceUser,
    workSpaceUsersLoading
  };
};
