import { SBA7A } from "@views/exchange/constants";
import parsePhoneNumberFromString from "libphonenumber-js";
import numeral from "numeral";

const exponentialToDecimal = (exponential: number) => {
  let decimal = exponential.toString().toLowerCase();
  if (decimal.includes("e+")) {
    const exponentialSplitted = decimal.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
      (exponentialSplitted[0].includes(".")
        ? exponentialSplitted[0].split(".")[1].length
        : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text: string) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text.slice(0, textLength - j)},${text.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};

export const useFormatNumber = () => {
  const numberDecimal = (value: number | undefined | string) => {
    if (value?.toString()?.toLowerCase()?.includes?.("e-")) {
      return exponentialToDecimal(Number(value));
    } else {
      return numeral(Math.abs(Number(value)) ?? 0).format("0,0.0000");
    }
  };

  const NumberWithCommas = (number: number) => {
    return Number(number ?? 0).toLocaleString();
  };

  const dollarFormat = (value: number | undefined | string) => {
    if (value?.toString().toLowerCase().includes("e-")) {
      return numeral(0).format("$0,0.00");
    } else {
      return numeral(Math.abs(Number(value)) ?? 0).format("$0,0.00");
    }
  };

  const percentFormat = (value: number | undefined | string) => {
    //note: numeral function can handle value till 1e-8, that's why using threshold = 1e-5.
    const threshold = 1e-5;
    if(isNaN(Number(value))) return "0.00%";
    
    if (Math.abs(Number(value)) < threshold) {
      return "0.00%";
    } else {
      return numeral(Math.abs(Number(value) / 100) ?? 0).format("0,0.00%");
    }
  };
  return { numberDecimal, dollarFormat, percentFormat, NumberWithCommas };
};

export const AddZeroInSingleNumbers = (integer: string) => {
  return integer.length === 1 ? `0${integer}` : integer;
};

export const convertToCurrencySystem = (amount: string | number, decimal: number = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(amount)) >= 1.0e9
    ? Number((Math.abs(Number(amount)) / 1.0e9).toFixed(decimal)) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(amount)) >= 1.0e6
      ? Number((Math.abs(Number(amount)) / 1.0e6).toFixed(decimal)) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(amount)) >= 1.0e3
        ? Number((Math.abs(Number(amount)) / 1.0e3).toFixed(decimal)) + "K"
        : Number(Math.abs(Number(amount)).toFixed(decimal));
};

// formatNumberWithCommas international standard

export const getNumberWithDynamicDecimal = (value: number | string) => {
  if (Math.abs(Number(value)) >= 1) {
    return Number(value).toFixed(2);
  } else if (Math.abs(Number(value)) > 0) {
    const decimalPlaces = Math.min(8, Math.max(2, -Math.floor(Math.log10(Math.abs(Number(value)))) + 3));
    const formattedValue = Number(value).toFixed(decimalPlaces);
    const trimmedValue = formattedValue.replace(/(\.\d*?[1-9])0+$/, '$1');
    if (Number(trimmedValue) === 0) return "0.00"
    return trimmedValue.includes('.') && trimmedValue.split('.')[1].length === 1
      ? trimmedValue + '0'
      : trimmedValue;
  } else if (Number(value) === 0) {
    return "0.00";
  } else {
    const formattedValue = parseFloat(Number(value).toFixed(8)).toString().replace(/(\.\d*?[1-9])0+$/, '$1');
    return formattedValue.includes('.') && formattedValue.split('.')[1].length === 1
      ? formattedValue + '0'
      : formattedValue;
  }
}

export const formatNumberWithCommas = (value: number | string, decimal = 2, dynamicDecimal = true) => {
  const strValue = decimal === 8 && dynamicDecimal ? getNumberWithDynamicDecimal(value?.toString()) : value?.toString();
  if (!strValue) return "";
  const cleanedValue = strValue?.replace(/,/g, "");
  
  if (!isNaN(Number(cleanedValue))) {
    return Number(cleanedValue).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: decimal,
    });
  }
  return strValue; 
};




export const formatPhoneNumber = (phoneNumber:string) => {
  try {
    // Parse the phone number (auto-detects the country from the calling code)
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);

    // Check if parsing was successful
    if (!parsedNumber) {
      throw new Error("Invalid phone number");
    }
    if (parsedNumber) {
      // Check if the country is India
      if (parsedNumber.country === 'IN') {
        return parsedNumber.formatNational().replace(/^0/, '');
      }
    }

    // Format the phone number in the national format
    return parsedNumber.formatNational();
  } catch (error : any) {
    return `Error: ${error.message}`;
  }
}




